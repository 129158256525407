import React from 'react'
import { RangeSelectorWithRadio } from '@wastehero/storybook/lib/components'
import { SelectProps } from 'antd'
import { SelectProject, SelectContainerStatus } from '../../select'
import {
  createFilter,
  FiltersProiver as Provider,
  initialFilters,
  useFiltersContext,
} from './filters.context'

const defaultProps: Partial<SelectProps> = {
  style: {
    width: '100%',
  },
}

export const FiltersProiver: React.FC = (props) => (
  <Provider {...props} initialValue={initialFilters} />
)

export const FilterByDateRange = () => {
  const [{ dateRange }, set] = useFiltersContext()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <RangeSelectorWithRadio
      value={dateRange}
      onChange={(val) => set({ dateRange: val })}
    />
  )
}

export const FilterByCategory = createFilter({
  key: 'category',
  Component: SelectProject,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})

export const FilterByType = createFilter({
  key: 'type',
  Component: SelectContainerStatus,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})
