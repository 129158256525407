import React, { FC } from 'react'
import { Row, Col } from 'antd'
import { useFormikContext } from 'formik'
import { Form, SubmitButton, Input } from 'formik-antd'
import { T } from '@transifex/react'
import { createForm } from '@wastehero/storybook/lib/components/formik-kit'

import { FormSchema } from './types'
import { isRequired, validationSchema } from './validation'

type UIProps = { disabled?: boolean }

export * from './types'

const { FormItem, withFormik } = createForm<FormSchema, UIProps>({
  validationSchema,
  isRequired,
})

export const initialValues: FormSchema = {
  weight: 0,
}

const FormUI: FC<UIProps> = ({ disabled: formDisabled = false }) => {
  const { isSubmitting, isValidating, dirty, isValid } =
    useFormikContext<FormSchema>()

  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <FormItem name="weight" label={<T _str="Weight" />}>
            <Input type="number" name="weight" addonAfter="KG" />
          </FormItem>
        </Col>
        <Col span={24}>
          <Row gutter={16} justify="end">
            {[
              <SubmitButton
                disabled={!dirty || isValidating || !isValid || formDisabled}
                loading={isSubmitting}
              >
                <T _str="Submit" />
              </SubmitButton>,
            ].map((button, i) => (
              <Col key={i}>{button}</Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default withFormik(FormUI)
