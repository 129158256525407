import styled from 'styled-components'

export const Styled = styled.div`
  &&& {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 10px;
    }

    .ant-collapse-ghost
      > .ant-collapse-item
      > .ant-collapse-content
      > .ant-collapse-content-box {
      padding-left: 0px;
      padding-top: 0px;
    }

    .panel-level-1 {
      .ant-collapse-arrow {
        right: 0px;
      }

      .ant-collapse-header {
        padding-right: 12px;
      }
    }

    .ant-timeline-item {
      padding-bottom: 0px;
    }

    .ant-timeline-item-last > .ant-timeline-item-content {
      min-height: auto;
    }

    .ant-timeline-item-tail {
      left: 8px;
    }
    .ant-timeline-item-head-custom {
      left: 8px; // due to the border-left adjustment of item-tail
    }
  }
`
