import { EnvironmentOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { T } from '@transifex/react'
import { TagsMultiple, Typography } from '@wastehero/storybook/lib/components'
import StyledTag from '@wastehero/storybook/lib/components/StyledTag'
import { NO_DATA } from '@wastehero/storybook/lib/components/table-kit'
import { Col, Divider, Row, Tag } from 'antd'
import moment from 'moment'
import React from 'react'
import { getTypeAndData } from '.'
import { getStyledTagProps } from '../../../../../api/helpers'
import { ColWithHeadingAndData } from '../../PopupContainerDetail'
import { GET_STOP_MAP_NODE } from '../api'

type StopViewProps = {
  id: string
  isNext?: boolean
}

const StopView = ({ id, isNext }: StopViewProps) => {
  const { data, loading } = useQuery(GET_STOP_MAP_NODE, { variables: { id } })

  const {
    type,
    data: dataParsed,
    title,
    href,
  } = getTypeAndData(data?.pickupOrder)

  const {
    location,
    container,
    ticket,
    scheduledDriverArrival,
    completedAt,
    stopNumber,
    depot,
  } = dataParsed || {}

  const styledTagProps = getStyledTagProps(data?.pickupOrder || {}, isNext)

  return (
    <Row gutter={[0, 8]} style={{ width: 380 }}>
      <Col xs={24}>
        <Row align="middle" gutter={0}>
          <Col>
            <StyledTag
              style={{
                width: 28,
                height: 16,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              {...styledTagProps}
            >
              {stopNumber}
            </StyledTag>
          </Col>
          <Col span={21}>
            <Typography
              ellipsis
              loading={loading}
              component="Link"
              href={href}
              weight="semi-bold"
              variant="body-14"
            >
              {title}
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row gutter={8} align="middle">
          <Col>
            <EnvironmentOutlined style={{ fontSize: 14, color: '#BFBFBF' }} />
          </Col>
          <Col>
            <Typography loading={loading} variant="body-14">
              {location?.name}
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      </Col>
      {type === 'depot' && (
        <>
          {depot.wasteFractions?.edges?.length !== 0 && (
            <ColWithHeadingAndData
              heading={<T _str="Waste fractions" />}
              data={
                <TagsMultiple
                  tags={depot.wasteFractions.edges.map(
                    ({ node }: $TSFixMe) => node.name
                  )}
                />
              }
              loading={loading}
              xs={12}
            />
          )}
          {depot.containerSet?.edges?.length !== 0 && (
            <ColWithHeadingAndData
              heading={<T _str="Container Type" />}
              data={
                <TagsMultiple
                  tags={Array.from(
                    new Set(
                      depot.containerSet?.edges?.map(
                        ({ node }: $TSFixMe) => node?.containerType?.name
                      )
                    )
                  )}
                />
              }
              loading={loading}
              xs={12}
            />
          )}
          <ColWithHeadingAndData
            heading={<T _str="Opening" />}
            data={
              depot
                ? `${moment(depot.openingTime, 'hh:mm:ss').format(
                    'hh:mm'
                  )} - ${moment(depot.closingTime, 'hh:mm:ss').format('hh:mm')}`
                : NO_DATA
            }
            loading={loading}
            xs={12}
          />
        </>
      )}
      {type !== 'depot' && (
        <>
          <ColWithHeadingAndData
            heading={<T _str="Scheduled" />}
            data={
              scheduledDriverArrival
                ? moment(scheduledDriverArrival, 'hh:mm:ss').format('LLL')
                : NO_DATA
            }
            loading={loading}
            xs={12}
          />
          <ColWithHeadingAndData
            heading={<T _str="Completed at" />}
            data={completedAt ? moment(completedAt).format('LLL') : NO_DATA}
            loading={loading}
            xs={12}
          />
          <ColWithHeadingAndData
            heading={<T _str="Waste fractions" />}
            data={
              container?.wasteFraction?.name ? (
                <Tag>{container?.wasteFraction?.name}</Tag>
              ) : (
                NO_DATA
              )
            }
            loading={loading}
            xs={12}
          />
        </>
      )}
      {type === 'container' && (
        <ColWithHeadingAndData
          heading={<T _str="Ticket" />}
          data={container?.tickets?.totalCount}
          loading={loading}
          xs={12}
        />
      )}
      {type === 'ticket' && (
        <ColWithHeadingAndData
          heading={<T _str="Ticket Type" />}
          data={ticket?.ticketType?.name}
          loading={loading}
          xs={12}
        />
      )}
    </Row>
  )
}

export default StopView
