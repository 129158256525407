import { gql } from '@apollo/client'

export type GetCallResponseEdgeNode = {
  id: string
  containerId: string
}

export type GetCallResponse = {
  allContainers: {
    totalCount: number
    pageInfo: {
      endCursor: string
    }
    edges: {
      node: GetCallResponseEdgeNode
    }[]
  }
}

export type GetCallVariables = {
  activeProjects?: string
  containerTypes?: string[]
  wasteFractionIds?: string
  pickupSetting?: string[]
  status?: string[]
  storedAtDepot?: string[]
}

export const GET_CALL = gql`
  query ComponentsSelectContainerGetAllContainers(
    $activeProjects: String
    $first: Int
    $search: String
    $offset: Int
    $ids: [ID]
    $containerTypes: [ID]
    $wasteFractionIds: String
    $pickupSetting: [ID]
    $status: [String]
    $storedAtDepot: [ID]
  ) {
    allContainers(
      activeProjects: $activeProjects
      first: $first
      search: $search
      offset: $offset
      ids: $ids
      containerTypes: $containerTypes
      wasteFractionIds: $wasteFractionIds
      pickupSetting: $pickupSetting
      status: $status
      storedAtDepot: $storedAtDepot
    ) {
      edges {
        node {
          id
          containerId
        }
      }
    }
  }
`
