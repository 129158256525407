/* eslint-disable */

import { useT } from '@transifex/react'
import deepmerge from 'deepmerge'
import { useEffect } from 'react'

/**
 * See: https://sdk.birdeatsbug.com/latest/docs/
 */
function init(options: $TSFixMe = {}) {
  // Create a queue, but don't obliterate an existing one!
  const birdeatsbug = (window.birdeatsbug = window.birdeatsbug || [])
  // If the real SDK is already on the page, return.
  if (birdeatsbug.initialize) return
  // If the snippet was invoked already, show an error.
  if (birdeatsbug.invoked) {
    if (window.console && console.error) {
      console.error('birdeatsbug snippet included twice.')
    }
    return
  }
  // Invoked flag, to make sure the snippet is never invoked twice.
  birdeatsbug.invoked = true
  // A list of the methods in the SDK to stub.
  birdeatsbug.methods = [
    'setOptions',
    'startSession',
    'resumeSession',
    'startRecording',
    'stopRecording',
    'stopSession',
    'uploadSession',
    'deleteSession',
  ]
  // Define a factory to create stubs. These are placeholders
  // for methods in the SDK so that you never have to wait
  // for it to load to record method calls. The `method` is
  // stored as the first argument, so we can replay the data.
  birdeatsbug.factory = function (method: $TSFixMe) {
    return function () {
      var args = Array.prototype.slice.call(arguments)
      args.unshift(method)
      birdeatsbug.push(args)
      return birdeatsbug
    }
  }
  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < birdeatsbug.methods.length; i++) {
    var key = birdeatsbug.methods[i]
    birdeatsbug[key] = birdeatsbug.factory(key)
  }
  // Define a method to load the SDK
  // and that will be sure to only ever load it once.
  birdeatsbug.load = function () {
    // Create an async script element.
    var script = document.createElement('script')
    script.type = 'module'
    script.async = true
    script.src = 'https://sdk.birdeatsbug.com/latest/core.js'
    // Insert the script next to the first script element.
    var first = document.getElementsByTagName('script')[0]
    first?.parentNode?.insertBefore(script, first)

    // Create an async style element
    const style = document.createElement('link')
    style.rel = 'stylesheet'
    style.type = 'text/css'
    style.href = 'https://sdk.birdeatsbug.com/latest/style.css'
    // Insert the styles next to the first script element.
    first?.parentNode?.insertBefore(style, first)
  }
  // Load SDK
  birdeatsbug.load()

  window.birdeatsbug.setOptions(
    deepmerge(
      {
        publicAppId: process.env.REACT_APP_BIRDS_EAT_BUG_SDK_PUBLIC_ID,
        ui: {
          defaultButton: false,
          previewScreen: {
            title: 'requried',
            description: 'optional',
            email: false,
          },
          submitConfirmationScreen: { sessionLink: false },
        },
      },
      options
    )
  )
}

const useInit = () => {
  const t = useT()

  useEffect(() => {
    init({
      ui: {
        text: {
          introductionScreen: {
            title: t('Report a bug'),
            message: t(
              'Please show us what went wrong with a screen recording.'
            ),
            startRecordingButton: t('Start screen recording'),
            skipRecordingButton: t('Skip screen recording'),
          },
          recordingControls: {
            starting: t('Starting...'),
            recording: t('Recording...'),
            recordingProgress: t('Recording for'),
            stopRecordingButton: t('Stop recording'),
          },
          previewScreen: {
            title: t('Report a bug'),
            titleInputPlaceholder: t('Add a title'),
            descriptionInputPlaceholder: t('Add a description'),
            emailInputPlaceholder: t('Add an email'),
            inputOptional: t('optional'),
            discardButton: t('Cancel'),
            submitButton: t('Submit'),
            uploadFinished: t('uploaded'),
          },
          submitConfirmationScreen: {
            title: t('Bug report submitted'),
            message: t(
              'Thanks for reporting this issue to us. A member of the team will investigate this and get back to you soon.'
            ),
            copyLink: t('Copy link to your bug report'),
            copiedLink: `&check; ${t('Link copied!')}`,
            confirmationButton: t('Done'),
          },
        },
      },
    })
    // Only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

function trigger(email?: string) {
  if (window.birdeatsbug?.isBrowserSupported) {
    window.birdeatsbug.setOptions({
      /*...otherOptions, */
      user: { email },
    })
    window.birdeatsbug.startSession()
  }
}

export const BirdsEatBug = {
  trigger,
  useInit,
}
