import React from 'react'
import { FormProject } from '../../../../../../../new-components'

export const ProjectGeneralSettingsView = () => {
  return (
    <div style={{ height: 'calc(100vh - 215px)' }}>
      <FormProject formId="ProjectGeneralSettingsView" />
    </div>
  )
}
