import { pathJoin } from '../../../../utils'
import { createRouteMap as createDetailRoutesMap } from '../../../../../../components/main/routes/customer-management/properties/id'

export const createPropertyRoutesMap = (prefix: string) => ({
  '/:id': (id: string) =>
    pathJoin(prefix)(id).chainMapAuto(createDetailRoutesMap),
  search: pathJoin(prefix)('search').chain,
  create: pathJoin(prefix)('create').chain,
  'edit/:id': (id: string) => pathJoin(prefix)('edit')(id).chain,
})
