import { gql } from '@apollo/client'

export type GetCallResponseEdgeNode = {
  id: string
  firstName: string
  lastName: string
  username: string
  logo: string
}

export type GetCallResponse = {
  allUsers: {
    edges: {
      node: GetCallResponseEdgeNode
    }[]
  }
}

export type GetCallVariables = {
  activeProjects?: string
  first?: number
  offset?: number
  q?: string
  ids?: string[]
  company?: string
  isDriver?: boolean
}

export const GET_CALL = gql`
  query Component_Select_User_GetAllUsers(
    $activeProjects: String
    $first: Int
    $offset: Int
    $q: String
    $ids: [ID]
    $company: ID
    $isDriver: Boolean
  ) {
    allUsers(
      activeProjects: $activeProjects
      first: $first
      offset: $offset
      q: $q
      ids: $ids
      company: $company
      isDriver: $isDriver
    ) {
      edges {
        node {
          id
          firstName
          lastName
          username
          logo
        }
      }
    }
  }
`
