import React from 'react'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { getSavedActiveProjects } from '../../../components/shared/utils'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'

const formatResponse = ({
  node: { id, name },
}: {
  node: GetCallResponseEdgeNode
}): Opt => ({
  label: name,
  value: id,
  key: id,
})

type SelectInquiryTypeProps = {
  variables?: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps']

export const SelectInquiryType = ({
  variables,
  ...props
}: SelectInquiryTypeProps) => {
  const activeProjects = useSelector((state) => getSavedActiveProjects(state))
  const queryVariables = {
    activeProjects,
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allInquiryTypings.edges.map((val: $TSFixMe) => formatResponse(val))
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      selectProps={{
        placeholder: <T _str="Inquiry Type" />,
        allowClear: true,
        ...props,
      }}
      value={props.value}
    />
  )
}
