import { Button } from 'antd'
import styled from 'styled-components'

/**
 * @deprecated - Use ant-design simple button.
 */
export const IconButton = styled(Button)`
  &&& {
    .anticon {
      margin-right: 8px;
    }
  }
`
