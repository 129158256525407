import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

/* Fragments */
export const FrgVehicle = gql`
  fragment FrgVehicle on VehicleType {
    id
    logo
    name
    status
    licensePlateNumber
    vehicleType {
      id
      name
      Type
      body
      bodyMechanization
      liftingMechanization
      loadingLocation
    }
    realtimeLocation {
      id
      latitude
      longitude
      name
    }
    drivers {
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
            username
          }
        }
      }
    }
    project {
      id
      name
    }
  }
`

/* Queries */
export const GET_VEHICLE = gql`
  ${FrgVehicle}
  query GetVehicle($vehicleId: ID!) {
    vehicle(id: $vehicleId) {
      ...FrgVehicle
      manufacturer
      model
      manufacturerYear
      trim
      fixedCost
      isActive
      co2EmissionPerKm
      externalIdentifier
      fuelCostPerKm
      fuelUsagePerKm
      fuelCostPerLtr
      fuelTankCapacity
      fuelType
      fixedCost
      externalIdentifier
      daysAvailable
      realtimeLocation {
        longitude
        latitude
      }
      hours
      wasteCapacity {
        edges {
          node {
            id
            maxAllowedVolume
            maxAllowedWeight
            wasteFraction {
              id
              name
              wasteCategory
              wasteSubstance
            }
          }
        }
      }
      depots {
        edges {
          node {
            id
            name
            depotType
          }
        }
      }
    }
  }
`

export const GET_ALL_VEHICLES = gql`
  query GetAllVehicles(
    $pageSize: Int
    $offset: Int
    $name: String
    $status: String
    $order: String
    $orderBy: String
    $activeProjects: String
    $Type: String
    $search: String
    $drivers: [ID]
    $vehicleTypes: [ID]
  ) {
    allVehicles(
      first: $pageSize
      offset: $offset
      name: $name
      status_Iexact: $status
      order: $order
      orderBy: $orderBy
      activeProjects: $activeProjects
      Type: $Type
      search: $search
      drivers: $drivers
      vehicleTypes: $vehicleTypes
    ) {
      totalCount
      edges {
        node {
          id
          logo
          name
          status
          licensePlateNumber
          vehicleType {
            id
            name
            Type
            body
            bodyMechanization
            liftingMechanization
            loadingLocation
          }
          realtimeLocation {
            id
            latitude
            longitude
            name
          }
          drivers {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  username
                }
              }
            }
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`
export const K_GET_ALL_VEHICLES = apiUtilGetKey(GET_ALL_VEHICLES)

/* Mutations */
