import { createBrowserHistory } from 'history'

const history = createBrowserHistory()
// Todo: Remvoe prevPath code and see it's not being used anywhere.
let prevPath: string | null = null

history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname
  }
})
export default history
