import styled from 'styled-components'
import { Row } from 'antd'

export const StyledRow = styled(Row)`
  padding: 1rem;

  /* Collapse styles overwrite - removing paddings. */

  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-top: -8px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
    padding-right: 8px;
    padding-bottom: 16px;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 0;
    top: 25%;
  }

  /* timeline styles overwrite */

  .ant-timeline-item {
    padding-bottom: 0px;
  }

  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: auto;
  }

  .ant-timeline-item-tail,
  .ant-timeline-item-head-custom {
    left: 8px;
  }
`
