import React from 'react'
import { T } from '@transifex/react'
import { useMutation } from '@apollo/client'
import { asyncAlertAction } from '@wastehero/storybook/lib/components'
import { message } from 'antd'

import { MUTATION_DELETE_CARD, K_GET_COMPANY_BILL_CARDS } from './api'

export const useDeleteCard = () => {
  const [apiCallDeleteCard] = useMutation(MUTATION_DELETE_CARD, {
    refetchQueries: [K_GET_COMPANY_BILL_CARDS],
  })

  return (cardId: string, billId: string) =>
    asyncAlertAction({
      modalProps: {
        title: <T _str="Delete card" />,
      },
      alertProps: {
        message: <T _str="Delete card" />,
        description: (
          <T _str="You are about to delete a card which can't be undone." />
        ),
        type: 'warning',
        showIcon: true,
      },
      asyncCall: async ({ closeModal, setLoading }) => {
        try {
          await apiCallDeleteCard({
            variables: {
              billingInformationId: billId,
              cardId,
            },
          })
          message.success(<T _str="Card successfully deleted" />)
          closeModal()
        } catch (err: $TSFixMe) {
          setLoading(false)
          message.error(err?.message || 'Something went wrong')
        }
      },
    })
}
