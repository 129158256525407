import React, { useMemo } from 'react'
import moment from 'moment'
import { Row, Col, Tag, Space, message, Button } from 'antd'
import { T } from '@transifex/react'
import { Typography } from '@wastehero/storybook/lib/components'
import { useQuery, DocumentNode } from '@apollo/client'
import { MessageFilled } from '@ant-design/icons'

import UI, { TProps, TimelineItem, TTimelineItemProps } from './ui'
import DaysFilter, { Opts } from './DaysFilter'
import { Styled } from './styles'
import { useSelect } from '../../../../../../ui/useSelect'
import { CssScrollerDiv } from '../../../../../../ui'

import EventChangeTag, {
  TEventChangeTagProps,
} from '../../../../../../new-components/EventChangeTag'
import DetailView from './DetailView'

import {
  eventTicketEventEventTypeChoicesLabelMap,
  TEventTicketEventEventTypeChoices,
} from '../../../../../../api/consts'

import { TTypeNames } from './types'

type TFilters = {
  days: number
}

type FormattedLogs = Array<
  {
    id: string
    createdAt: string
    __typename: TTypeNames
    ticket: {
      ticketType: {
        id: string
        name: string
      }
      container?: {
        id: string
        containerId?: string
      }
    }
    receiverPropertyUser?: {
      id: string
      name: string
    }
    senderPropertyUser?: {
      id: string
      name: string
    }
  } & TEventChangeTagProps
>

type TMode = 'compact' | 'default'

const LogTitle = ({
  __typename: type,
  eventType,
  mode,
  ticket: { container },
  receiverPropertyUser,
  ...rest
}: FormattedLogs[0] & { mode?: TMode }) => {
  return (
    <Space
      style={{
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      {type === 'TicketEventDef' &&
        eventTicketEventEventTypeChoicesLabelMap[
          eventType as TEventTicketEventEventTypeChoices
        ]}
      {type === 'TicketCommentDef' && <T _str="Internal Note" />}
      {type === 'TicketMessageDef' &&
        (receiverPropertyUser ? <T _str="Sent" /> : <T _str="Recieved" />)}
      {mode !== 'compact' && (
        <Space>
          {type === 'TicketEventDef' && (
            <EventChangeTag short eventType={eventType} {...rest} />
          )}
          {type === 'TicketEventDef' && container?.containerId && (
            <Tag>
              <T _str="Container Id: {id}" id={container?.containerId} />
            </Tag>
          )}
        </Space>
      )}
    </Space>
  )
}

type TCRMEventLogProps<TResponse, TVariables> = {
  query: DocumentNode
  queryVariableFormatter: (args: { filter: TFilters }) => TVariables
  queryResultFormatter: (data: TResponse) => FormattedLogs | undefined
  mode?: TMode
  queryVariableFormatterDeps: $TSFixMe
}

type R = Record<string, unknown>

export const CRMEventLog = <TR extends R = R, TV extends R = R>({
  query,
  queryResultFormatter,
  queryVariableFormatter,
  queryVariableFormatterDeps,
  mode,
}: TCRMEventLogProps<TR, TV>) => {
  /* filters */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [days, jsxDaysSelect] = useSelect<Opts>({
    init: 0,
    Component: DaysFilter,
    style: { width: `100%` },
    size: 'middle',
  })

  const variables = useMemo(
    () =>
      queryVariableFormatter({
        filter: {
          days,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryVariableFormatterDeps, days]
  )

  const { data, loading } = useQuery(query, {
    variables,
    onError: (e) => message.error(e?.message || <T _str="Error occured" />),
  })

  const logsFormatted = queryResultFormatter(data)

  const renderIconFn = (
    type: TTypeNames,
    { name }: FormattedLogs[0]['ticket']['ticketType'] = { name: '', id: '' }
  ) =>
    type === 'TicketEventDef' ? (
      <Tag
        color="cyan"
        style={{
          padding: '0px 2px',
          margin: 0,
          fontWeight: 'bold',
          marginLeft: 11,
        }}
      >
        {name?.slice(0, 2)}
      </Tag>
    ) : (
      <Button
        style={{
          marginLeft: 8,
          background: '#F38804',
          border: 'none',
          width: 20,
          height: 20,
        }}
        icon={<MessageFilled style={{ color: 'white', fontSize: 11 }} />}
        size="small"
      />
    )

  type Logs = TProps<
    TTimelineItemProps & {
      type: TTypeNames
      eventType?: TEventTicketEventEventTypeChoices
    }
  >['data']
  const logs: Logs =
    logsFormatted
      ?.filter((e: $TSFixMe) => e?.id)
      ?.map((log): Logs[0] => {
        const {
          id,
          createdAt,
          __typename: type,
          ticket: { ticketType } = {},
          eventType,
        } = log
        return {
          time: moment(createdAt),
          timelineItemProps: {
            dot: renderIconFn(type, ticketType),
          },
          data: {
            id,
            time: moment(createdAt),
            title: <LogTitle {...log} mode={mode} />,
            type,
            eventType,
          },
        }
      }) || []

  return (
    <Styled
      style={{
        padding: `1rem`,
        rowGap: `1rem`,
      }}
      className="wh-vertical-scroller-container"
    >
      <Row justify="space-between" align="top" gutter={16}>
        <Col flex="none">
          <Typography variant="subHeader-16" weight="semi-bold">
            <T _str="Event log" />
          </Typography>
        </Col>
        <Col flex="auto">
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            {jsxDaysSelect}
          </Space>
        </Col>
      </Row>
      <CssScrollerDiv
        className="wh-vertical-scroller-container-scroll"
        style={{ paddingRight: 0 }}
        defaultYScroller
        offset={16}
      >
        <UI
          loading={loading}
          data={logs}
          renderTimelineItem={({ data: data1 }) =>
            data1 && (
              <TimelineItem {...data1}>
                <DetailView
                  id={data1.id}
                  type={data1.type}
                  eventType={data1.eventType}
                />
              </TimelineItem>
            )
          }
        />
      </CssScrollerDiv>
    </Styled>
  )
}
