import apolloClient from '../../../../components/graphql/apollo'

import { TGetClusterPointsFn } from '../../types'

import { GET_CLUSTER_POINTS, TVariables } from './api'

export const getContainerSourceClusterPoints: TGetClusterPointsFn<TVariables> =
  (clusterId, bbox, filters) => {
    return apolloClient
      .query({
        query: GET_CLUSTER_POINTS,
        variables: {
          clusterId,
          bbox,
          ...filters,
        },
      })
      .then(({ data }) => {
        return data?.allContainers?.edges?.map((e: $TSFixMe) => e.node.id) || []
      })
  }
