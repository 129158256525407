export const CLUSTER_SIZE_STEPS = [
  10, 2, 13, 10, 15, 1000, 18, 10000, 20, 15000, 23, 100000, 25,
]

export const SERVER_CLUSTER_SELECTION_LIMIT = 5000

export const GROUP_CIRCLE_CONFIG = {
  translate: {
    x: 4,
    y: 5,
  },
}
