import React from 'react'
import { T } from '@transifex/react'

import {
  createTableUIStatefulColumns,
  getTagColumn,
  getDateColumn,
  getActionsBtns,
} from '@wastehero/storybook/lib/components/table-kit'
import {
  crmInvoiceStatusChoicesLabelMap,
  crmInvoiceStatusChoicesTagColorMap,
  TCrmInvoiceStatusChoices,
} from '../../../../api/consts'
import { LinkCell, getLinkColumn } from '../../../../ui/table-kit-extended'

const dateColumn = getDateColumn('YYYY-MM-DD HH:mm')
const linkColumn = getLinkColumn()

export type DataCell = {
  _id: string
  invoiceId: string
  status: TCrmInvoiceStatusChoices
  amount: string
  dueDate?: string
  issuedAt?: string
  createdAt: string
  property: LinkCell
}

export type ColumnKey = keyof Omit<DataCell, '_id'>

const { useTableUI } = createTableUIStatefulColumns<
  DataCell,
  ColumnKey,
  'invoice' | 'reciept'
>({
  actionsRender: ({ on: { invoice, reciept } }) =>
    getActionsBtns({
      actions: [
        {
          handler: invoice,
          btnProps: { children: <T _str="Invoice" /> },
        },
        {
          handler: reciept,
          btnProps: { children: <T _str="Reciept" /> },
          cond: ({ status }) => status === 'A_3',
        },
      ],
      btnProps: {
        size: 'small',
        type: 'link',
      },
    }),
  columnsRenderMap: () => ({
    invoiceId: {
      title: <T _str="Invoice ID" />,
      fixed: 'left',
    },
    status: {
      title: <T _str="Status" />,
      render: getTagColumn(
        crmInvoiceStatusChoicesTagColorMap,
        (val) =>
          crmInvoiceStatusChoicesLabelMap[val as TCrmInvoiceStatusChoices]
      ),
    },
    amount: {
      title: <T _str="Amount" />,
    },
    dueDate: {
      title: <T _str="Due date" />,
      render: dateColumn,
    },
    issuedAt: {
      title: <T _str="Isse date" />,
      render: dateColumn,
    },
    createdAt: {
      title: <T _str="Created at" />,
      render: dateColumn,
    },
    property: {
      title: <T _str="Property" />,
      render: linkColumn,
    },
  }),
  tableColumnsTreeFilterAndOrderProps: {
    key: 'CustomersOverview.Customer.Invoice.List',
    init: {
      checked: [
        'amount',
        'invoiceId',
        'status',
        'createdAt',
        'dueDate',
        'property',
      ],

      order: [
        'invoiceId',
        'status',
        'amount',
        'createdAt',
        'dueDate',
        'property',
      ],
    },
  },
})

export { useTableUI }
