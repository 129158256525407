import React, { createContext, useContext } from 'react'
import useLocalStorageState from 'use-local-storage-state'

export const createStatefulContextSimplePersistent = <State extends $TSFixMe>(
  persistentKey: string
) => {
  type iStatefulSimpleContext = [
    State,
    React.Dispatch<React.SetStateAction<State>>
  ]

  const StatefulSimpleContext = createContext<iStatefulSimpleContext>(
    null as unknown as iStatefulSimpleContext
  )

  const useStatefulSimpleContext = () => {
    const store = useContext(StatefulSimpleContext)

    if (!store) {
      throw new Error(
        'Cannot use `useStatefulSimpleContext` outside of a StatefulSimpleProvider'
      )
    }

    return store
  }

  const StatefulSimpleStore = (
    initialState: State
  ): [State, React.Dispatch<React.SetStateAction<State>>] => {
    const [c, d] = useLocalStorageState<State>(persistentKey, initialState)
    return [c, d]
  }

  const Provider: React.FC<{
    initialValue: State
  }> = ({ children, initialValue }) => {
    return (
      <StatefulSimpleContext.Provider value={StatefulSimpleStore(initialValue)}>
        {children}
      </StatefulSimpleContext.Provider>
    )
  }

  return {
    Provider,
    Context: StatefulSimpleContext,
    useContext: useStatefulSimpleContext,
  }
}
