import React from 'react'
import { T } from '@transifex/react'
import {
  getSimpleColumn,
  CreateTableUIStatefulColumnsArgs,
  createTableUIStatefulColumns,
} from '@wastehero/storybook/lib/components/table-kit'
import { getLinkColumn, LinkCell } from '../../../../ui/table-kit-extended'

export type TActions = 'edit' | `view`

export type DataCell = {
  _id: string
  category: $TSFixMe
  type: $TSFixMe
  ticket: LinkCell
  container: LinkCell
  details: $TSFixMe
  triggeredTime: string
}

export type ColumnKey = keyof Omit<DataCell, '_id'>

export type SortKey = 'ascend' | 'descend'

const simpleColumn = getSimpleColumn()
const linkColumn = getLinkColumn()

export const columnsRenderMap: CreateTableUIStatefulColumnsArgs<
  DataCell,
  ColumnKey,
  TActions
>['columnsRenderMap'] = () => ({
  category: {
    title: <T _str="Category" />,
    render: simpleColumn,
  },
  type: {
    title: <T _str="Type" />,
    render: simpleColumn,
  },
  ticket: {
    title: <T _str="Ticket" />,
    render: linkColumn,
  },
  container: {
    title: <T _str="Container" />,
    render: linkColumn,
  },
  details: {
    title: <T _str="Details" />,
    render: (val) => <span>{val}</span>,
  },
  triggeredTime: {
    title: <T _str="Triggered time" />,
    render: simpleColumn,
  },
})

const { useTableUI } = createTableUIStatefulColumns<
  DataCell,
  ColumnKey,
  TActions
>({
  columnsRenderMap,
  tableColumnsTreeFilterAndOrderProps: {
    init: {
      checked: [
        'category',
        'type',
        'ticket',
        'container',
        'details',
        'triggeredTime',
      ],
      order: [
        'category',
        'type',
        'ticket',
        'container',
        'details',
        'triggeredTime',
      ],
    },
  },
})

export { useTableUI }
