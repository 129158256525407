import React, { ReactNode, useEffect, useState, HTMLAttributes } from 'react'

export type TConditionalRenderWithMapProps<T extends string = string> = {
  renderMap: Record<T, ReactNode>
  active: T
  childDivProps?: HTMLAttributes<HTMLDivElement>
} & HTMLAttributes<HTMLDivElement>

export const ConditionalRenderWithMap = <T extends string = string>({
  renderMap,
  active,
  childDivProps,
  ...rest
}: TConditionalRenderWithMapProps<T>) => {
  const [opended, setOpended] = useState<Set<T>>(
    new Set(active ? [active] : undefined)
  )

  useEffect(() => {
    if (!opended.has(active))
      setOpended((p) => {
        const newSet = new Set(p)
        newSet.add(active)
        return newSet
      })
  }, [active, opended])

  return (
    <div {...rest}>
      {(Object.keys(renderMap) as T[]).map((k) => (
        <div
          {...childDivProps}
          style={{
            ...childDivProps?.style,
            display:
              active === k ? childDivProps?.style?.display || 'block' : 'none',
          }}
        >
          {opended.has(k) && renderMap[k]}
        </div>
      ))}
    </div>
  )
}
