import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { Empty } from 'antd'
import { T } from '@transifex/react'

import List, { ListEle, ListProps } from './List'
import { GET_COMPANY_BILL_CARDS, K_GET_COMPANY_BILL_CARDS } from './api'
import { useDeleteCard } from './useDeleteCard'
import { useChangeDefaultCardModal } from './useChangeDefaultCard'

export type TListCompanyPaymentCardsWithActionsProps = {
  companyId: string
  billingId: string
} & Pick<ListProps, 'actions'>

type ListCompanyPaymentCardsWithActionsWithQuery =
  FC<TListCompanyPaymentCardsWithActionsProps> & {
    refetchQueryKey: string
  }

const ListCompanyPaymentCardsWithActions: ListCompanyPaymentCardsWithActionsWithQuery =
  ({ companyId, billingId, actions }) => {
    /* API - fetching cards. */
    const { data, loading } = useQuery(GET_COMPANY_BILL_CARDS, {
      variables: {
        id: companyId,
      },
      skip: !companyId,
    })

    const deleteCard = useDeleteCard()
    const changeDefaultCard = useChangeDefaultCardModal()

    const listCards: ListEle[] =
      data?.company?.billinginformation?.cards.map(
        ({
          id,
          name,
          default: isDefault,
        }: {
          id: string
          name: string
          default: boolean
        }): ListEle => ({
          id,
          title: name,
          isDefault,
        })
      ) || []

    if (!loading && !listCards.length)
      return <Empty description={<T _str="No cards added." />} />

    return (
      <List
        loading={loading}
        list={listCards}
        on={{
          delete: (id) => deleteCard(id, billingId),
          setDefault: (id) => changeDefaultCard(id, billingId),
        }}
        actions={actions}
      />
    )
  }

ListCompanyPaymentCardsWithActions.refetchQueryKey = K_GET_COMPANY_BILL_CARDS

export { ListCompanyPaymentCardsWithActions }
