import React, { ReactNode } from 'react'
import { Space, TableColumnProps, SpaceProps } from 'antd'
import {
  AvatarWithName,
  TagsMultipleProps,
} from '@wastehero/storybook/lib/components'
import moment from 'moment'
import { getNullableColumn } from '@wastehero/storybook/lib/components/table-kit'

import { Link, LinkProps } from '../../reducers/routing/child-router-factory'

type TColumnRenderFnArgs<Data, Val extends $TSFixMe = $TSFixMe> = [
  value: Val,
  record: Data,
  index: number
]
type TColumnRenderFn<Data, R, Val extends $TSFixMe = $TSFixMe> = (
  ...args1: TColumnRenderFnArgs<Data, Val>
) => R

export type GetLinkColumnProps = {
  linkProps: LinkProps
}
export type LinkCell = {
  linkProps: GetLinkColumnProps['linkProps']
}

export type GetAvatarWithNameColumnProps = {
  data: {
    avatar?: string
    name: string
  }
}

export type AvatarWithNameCell = {
  data: GetAvatarWithNameColumnProps['data']
}

export type MultipleTagCell = TagsMultipleProps['tags']

type RecordAny = Record<string, $TSFixMe>

export const getLinkColumn =
  <
    RecordType extends RecordAny,
    Data extends GetLinkColumnProps = GetLinkColumnProps
  >(
    children?: TColumnRenderFn<RecordType, $TSFixMe, Omit<Data, 'linkProps'>>
  ): TableColumnProps<RecordType>['render'] =>
  ({ linkProps, ...rest }: Data, ...rest2) => {
    return (
      <Link {...linkProps}>
        {children?.(rest, ...rest2) || linkProps?.children}
      </Link>
    )
  }

export const getDateColumn = <Data extends RecordAny>(
  format = 'MMMM DD, YYYY hh:mm A',
  nullValue = undefined,
  inputFormat = ``
): TableColumnProps<Data>['render'] =>
  getNullableColumn(
    (value) => moment(value, inputFormat).format(format),
    nullValue
  )

export const getLinkColumnNullable = <
  RecordType extends RecordAny,
  Data extends GetLinkColumnProps = GetLinkColumnProps
>(
  children?: TColumnRenderFn<RecordType, $TSFixMe, Omit<Data, 'linkProps'>>
): TableColumnProps<RecordType>['render'] =>
  getNullableColumn(
    ({ linkProps, ...rest }: Data, ...rest2) => {
      return (
        <Link {...linkProps}>
          {children?.(rest, ...rest2) || linkProps?.children}
        </Link>
      )
    },
    undefined,
    (val: GetLinkColumnProps) => !!val.linkProps?.children
  )

export const getAvatarWithNameColumn =
  <
    RecordType extends RecordAny,
    Data extends GetAvatarWithNameColumnProps = GetAvatarWithNameColumnProps
  >(
    children?: TColumnRenderFn<RecordType, $TSFixMe, Omit<Data, 'data'>>
  ): TableColumnProps<RecordType>['render'] =>
  ({ data: { avatar, name }, ...rest }: Data, ...rest2) => {
    return (
      <>
        <AvatarWithName avatarProps={{ src: avatar }}>{name}</AvatarWithName>
        {children?.(rest, ...rest2)}
      </>
    )
  }

export const renderCell = (
  val: $TSFixMe,
  fn: TableColumnProps<$TSFixMe>['render'],
  args?: { suffix?: ReactNode; spaceProps?: SpaceProps }
) => (
  <Space size={4} {...args?.spaceProps}>
    {fn?.(val, {}, 0)}
    {args?.suffix}
  </Space>
)
