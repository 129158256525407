import React, { ReactNode, useState } from 'react'
import { T } from '@transifex/react'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { Col, Radio, RadioChangeEvent, Row, Spin } from 'antd'
import { useContainerTable, UseContainerTableProps } from './table'
import {
  FilterByContainerStatus,
  FilterByContainerType,
  FilterByProject,
  FilterByProperty,
  FilterBySensor,
  FilterByWasteFraction,
  FiltersProiver,
  FilterByDateRange,
  FilterByPropertyGroup,
} from './filters.elements'
import { TFilters, useFiltersContext } from './filters.context'
import { ColumnKey, DataCell } from './table/ui'
import { LayoutTableWithHideableFilters } from '../../../layouts'
import { TLayoutTableWithHideableFiltersProps } from '../../../layouts/LayoutWithHideableFilters'
import { Mapv3 } from '../../Map'
import { ConditionalRenderWithMap, DebouncedSearch } from '../../../ui'
import { withLength } from './utils'
import { convertEnumToNumberSnakeCase } from '../../../api/consts/utils'
import { sensorFilterMap } from '../../../components/main/routes/assset-management/routes/containers/routes/exact/filters.context'
import { ContainerSensorFilter } from '../../select'
import { useSavedActiveProjects } from '../../../hooks'
import { TSourceContainerConfig } from '../../Map/sources/container'

type ContainerTableIntegrationProps = {
  mapConfig?: TSourceContainerConfig
  loading?: boolean
  rowSelection?: UseContainerTableProps['rowSelection']
  bottomBar?: ReactNode
  headerRightJsx?: ReactNode
  initialFilters?: TFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
  customActionRender?: (data: DataCell) => ReactNode
  layoutProps?: Omit<TLayoutTableWithHideableFiltersProps, 'jsx'>
}

const { useDebouncedSearchSimple } = DebouncedSearch

type TUiView = 'table' | 'map'

const ContainerTableIntegration = ({
  rowSelection,
  loading = false,
  headerRightJsx,
  layoutProps,
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  bottomBar,
  customActionRender,
  mapConfig,
}: ContainerTableIntegrationProps) => {
  const [uiView, setUiView] = useState<TUiView>('table')

  const { search, jsx: jsxSearch } = useDebouncedSearchSimple()

  const savedActiveProjects = useSavedActiveProjects()

  const [
    {
      // dateRange,
      containerType,
      project,
      sensor,
      status,
      wasteFraction,
      // property,
      // propertyGroup,
      // containerGroupIds,
    },
  ] = useFiltersContext()

  const {
    jsx: { table, columnsFilter },
  } = useContainerTable({
    search,
    initialFilters,
    tableColumnsTreeFilterAndOrderProps,
    rowSelection,
    customActionRender,
  })

  const onChange = (e: RadioChangeEvent) => {
    setUiView(e.target.value)
  }

  return (
    <LayoutTableWithHideableFilters
      {...layoutProps}
      layoutOffset={220}
      jsx={{
        table: (
          <ConditionalRenderWithMap
            style={{ height: '100%', width: '100%' }}
            childDivProps={{ style: { height: '100%', width: '100%' } }}
            renderMap={{
              table: (
                <div
                  style={{
                    height: '100%',
                  }}
                  className="wh-spin-100"
                >
                  <Spin spinning={loading} style={{ height: '100%' }}>
                    {table}
                  </Spin>
                </div>
              ),
              map: (
                <Mapv3
                  sources={[
                    {
                      type: 'containers',
                      config: {
                        ...mapConfig,
                        queryOptions: (args) => {
                          const queryOptionsFromPorps =
                            mapConfig?.queryOptions?.(args)

                          return {
                            ...queryOptionsFromPorps,
                            variables: {
                              search,
                              activeProjects:
                                withLength(project) || savedActiveProjects,
                              containerTypes: withLength(containerType),
                              sensor_Isnull: sensor
                                ? sensorFilterMap[
                                    sensor as ContainerSensorFilter
                                  ]
                                : null,
                              wasteFractionIds: withLength(wasteFraction),
                              status: withLength(
                                status?.map((st) =>
                                  convertEnumToNumberSnakeCase(st)
                                )
                              ),
                              ...queryOptionsFromPorps?.variables,
                            },
                          }
                        },
                      },
                    },
                  ]}
                />
              ),
            }}
            active={uiView}
          />
        ),
        columnsFilter,
        search: jsxSearch,
        filters: [
          <FilterByContainerType />,
          <FilterBySensor />,
          <FilterByProject />,
          <FilterByWasteFraction />,
          <FilterByProperty />,
          <FilterByContainerStatus />,
          <FilterByPropertyGroup />,
        ],
        dateFilter: <FilterByDateRange />,
        addBtn: (
          <Row gutter={8}>
            <Col>{headerRightJsx}</Col>
            <Col>
              <Radio.Group
                buttonStyle="solid"
                value={uiView}
                onChange={onChange}
                defaultValue="table"
              >
                <Radio.Button value="table">
                  <T _str="List" />
                </Radio.Button>
                <Radio.Button value="map">
                  <T _str="Map" />
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        ),
        bottomBar,
      }}
    />
  )
}

const ContainerTable = ({
  headerRightJsx,
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  layoutProps,
  loading,
  rowSelection,
  bottomBar,
  customActionRender,
  mapConfig,
}: ContainerTableIntegrationProps) => {
  return (
    <FiltersProiver>
      <ContainerTableIntegration
        customActionRender={customActionRender}
        bottomBar={bottomBar}
        rowSelection={rowSelection}
        loading={loading}
        headerRightJsx={headerRightJsx}
        tableColumnsTreeFilterAndOrderProps={
          tableColumnsTreeFilterAndOrderProps
        }
        initialFilters={initialFilters}
        layoutProps={layoutProps}
        mapConfig={mapConfig}
      />
    </FiltersProiver>
  )
}

export default ContainerTable
