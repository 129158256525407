import booleanIntersects from '@turf/boolean-intersects'
import produce from 'immer'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DrawControl from 'react-mapbox-gl-draw'
import * as turf from '@turf/turf'
import isEqual from 'react-fast-compare'

import {
  getFeaturesInPolygon,
  useRefCopy,
  TGetFeaturesInPolygonArgs,
} from '../utils'

import { TMapbox, TGetMap } from '../types'
import { array } from '../../../utils/array'
import { useMemoCompare } from '../../../hooks/useMemoCompare'
import { usePrevious } from '../../../hooks/usePrevious'

export type TPointTypes = 'single' | 'multi' | 'cluster'

export type TAddonClusterAndPointSelectionWithPolygonsProps = {
  loadingSources?: boolean
  getMap: TGetMap
  sources: Record<
    string,
    {
      single?: string
      multi?: string
      cluster?: string // Todo: Add support for cluster support
    }
  >
  on?: {
    /** will only be updated on mount and not subsequently. So useRef inside the function to access states */
    selection?: (values: Record<string, string[]>) => void
  }
} & Pick<TGetFeaturesInPolygonArgs, 'handleServerClusterSelection'>

const getMapViewportPolygon = (map: TMapbox) => {
  const canvas = map.getCanvas()
  const w = canvas.width
  const h = canvas.height
  const cUL = map.unproject([0, 0]).toArray()
  const cUR = map.unproject([w, 0]).toArray()
  const cLR = map.unproject([w, h]).toArray()
  const cLL = map.unproject([0, h]).toArray()
  const coordinates = [cUL, cUR, cLR, cLL, cUL]
  return turf.polygon([coordinates])
}

const featureSourcesRecordToSourcesRecord = (
  input: $TSFixMe
): Record<string, $TSFixMe> =>
  Object.values(input).reduce(
    (acc: $TSFixMe, obj: $TSFixMe) => ({
      ...acc,
      ...Object.keys(obj).reduce((acc2: $TSFixMe, k) => {
        const newMerged = new Map(obj[k]).keys()
        return {
          ...acc2,
          [k]: array(acc?.[k], Array.from(newMerged)),
        }
      }, {}),
    }),
    {}
  ) as Record<string, $TSFixMe>

const AddonClusterAndPointSelectionWithPolygons = ({
  loadingSources,
  getMap,
  sources,
  on,
  handleServerClusterSelection,
}: TAddonClusterAndPointSelectionWithPolygonsProps) => {
  const drawRef = useRef() as React.MutableRefObject<$TSFixMe>
  const [selected, setSelected] = useState<Record<string, $TSFixMe>>({})
  const selectedRef = useRefCopy(selected)
  const [selectedClientClusters, setSelectedClientClusters] = useState<
    Record<string, $TSFixMe>
  >({})
  const selectedClientClustersRef = useRefCopy(selectedClientClusters)
  const [selectedServerClusters, setSelectedServerClusters] = useState<
    Record<string, $TSFixMe>
  >({})
  const selectedServerClustersRef = useRefCopy(selectedServerClusters)

  const [drawFeatures, setDrawFeatures] = useState<Record<string, $TSFixMe>>({})
  const drawFeaturesRef = useRefCopy(drawFeatures)

  const [dataUpdateCount, setDateUpdateCount] = useState(0)

  /* all selections layers to include */
  const layersToInclude = useMemo(() => {
    return Object.values(sources).reduce(
      (acc: string[], { cluster, multi, single }) =>
        array(acc, cluster, multi, single),
      []
    )
  }, [sources])

  useEffect(() => {
    /* Todo: FIX THIS TIMEOUT AND RATHER CALL IT WHEN THE SOURCE IS UPDATED.
    - Right now we set timeout of 100 to wait for the new source to be reflected on the map
    */
    if (!loadingSources) {
      setTimeout(() => {
        setDateUpdateCount((p) => p + 1)
      }, 100)
    }
  }, [loadingSources])

  const updateSelections = useCallback(
    async (features: Record<string, $TSFixMe>) => {
      const map = getMap()
      /*
      Base on current draw features and bounding box set the newly selected feautres.
      Only update those which are in the bbox.
    */
      /* perform deletion if required. */
      // no selection
      setSelected(
        produce((selectedRefValue) => {
          Object.keys(selectedRefValue).forEach((featureKey) => {
            // eslint-disable-next-line no-param-reassign
            if (!features[featureKey]) delete selectedRefValue[featureKey]
          })
        }, selectedRef())
      )
      setSelectedClientClusters(
        produce((selectedClientClustersRefValue) => {
          Object.keys(selectedClientClustersRefValue).forEach((featureKey) => {
            if (!features[featureKey])
              // eslint-disable-next-line no-param-reassign
              delete selectedClientClustersRefValue[featureKey]
          })
        }, selectedClientClustersRef())
      )
      setSelectedServerClusters(
        produce((selectedServerClustersRefValue) => {
          Object.keys(selectedServerClustersRefValue).forEach((featureKey) => {
            if (!features[featureKey])
              // eslint-disable-next-line no-param-reassign
              delete selectedServerClustersRefValue[featureKey]
          })
        }, selectedServerClustersRef())
      )
      if (Object.values(features).length) {
        /**
         * The selection state will be based on feature id.
         */
        const featuresToUpdate = Object.values(features).filter(
          (drawFeature: $TSFixMe) => {
            // check if this polygon is on screen.
            return booleanIntersects(getMapViewportPolygon(map), drawFeature)
          }
        )
        /* Updating the current selection based on featuresToUpdate. */
        const {
          allResult: selections,
          allClusterResults: allClientClusterResults,
          allServerClusterResults,
        } = await getFeaturesInPolygon(featuresToUpdate, {
          map,
          opts: {
            layers: layersToInclude,
          },
          format: (feature) => ({
            id: feature.properties.dataId,
          }),
          handleServerClusterSelection,
        })
        setSelected((p) => ({ ...p, ...selections }))
        setSelectedClientClusters((p) => ({ ...p, ...allClientClusterResults }))
        setSelectedServerClusters((p) => ({ ...p, ...allServerClusterResults }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    updateSelections(drawFeatures)
  }, [drawFeatures, updateSelections, dataUpdateCount])

  const updateOrCreateFeature = (id: string, feature: $TSFixMe) => {
    setDrawFeatures(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        draft[id] = feature
      }, drawFeaturesRef())()
    )
  }

  const deleteFeature = (id: string) => {
    setDrawFeatures(
      produce((draft) => {
        // eslint-disable-next-line no-param-reassign
        delete draft[id]
      }, drawFeaturesRef())()
    )
  }

  /* 
    Todo:
      1. Fix polygon selection which is not in the bbox.
    */
  const allSelectedPoints: $TSFixMe = useMemoCompare(
    featureSourcesRecordToSourcesRecord(selected),
    isEqual
  )

  const allSelectedMulti: $TSFixMe = useMemoCompare(
    featureSourcesRecordToSourcesRecord(selectedClientClusters),
    isEqual
  )

  const allSelectedCluster: $TSFixMe = useMemoCompare(
    featureSourcesRecordToSourcesRecord(selectedServerClusters),
    isEqual
  )

  const allSelectedMultiPrevious = usePrevious(Object.keys(allSelectedMulti))
  const allSelectedPointsPrevious = usePrevious(Object.keys(allSelectedPoints))
  const allSelectedClusterPrevious = usePrevious(
    Object.keys(allSelectedCluster)
  )

  useEffect(() => {
    on?.selection?.(allSelectedPoints)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedPoints])

  useEffect(() => {
    /* highlight cluster points */
    const map = getMap()
    if (map) {
      /* removing previous paint */
      allSelectedClusterPrevious?.forEach((sourceKey) => {
        const layerName = sources[sourceKey].cluster
        if (layerName) {
          map.setPaintProperty(layerName, `circle-stroke-width`, [
            'case',
            ['in', ['get', 'dataId'], ['literal', ``]],
            5,
            0,
          ])
        }
      })

      Object.keys(allSelectedCluster).forEach((sourceKey) => {
        const layerName = sources[sourceKey].cluster
        if (layerName) {
          map.setPaintProperty(layerName, `circle-stroke-width`, [
            'case',
            [
              'in',
              ['get', 'dataId'],
              ['literal', allSelectedCluster[sourceKey]],
            ],
            5,
            0,
          ])
        }
      })
    }
  }, [allSelectedCluster, allSelectedClusterPrevious, getMap, sources])

  useEffect(() => {
    /* highlight multi points */
    const map = getMap()
    if (map) {
      /* removing previous paint */
      allSelectedMultiPrevious?.forEach((sourceKey) => {
        const layerName = sources[sourceKey].multi
        if (layerName) {
          map.setPaintProperty(layerName, `circle-stroke-width`, [
            'case',
            ['in', ['get', 'cluster_id'], ['literal', ``]],
            5,
            0,
          ])
        }
      })

      Object.keys(allSelectedMulti).forEach((sourceKey) => {
        const layerName = sources[sourceKey].multi
        if (layerName) {
          map.setPaintProperty(layerName, `circle-stroke-width`, [
            'case',
            [
              'in',
              ['get', 'cluster_id'],
              ['literal', allSelectedMulti[sourceKey]],
            ],
            5,
            0,
          ])
        }
      })
    }
  }, [allSelectedMulti, allSelectedMultiPrevious, getMap, sources])

  useEffect(() => {
    /* highlight single points */
    const map = getMap()
    if (map) {
      /* removing previous paint */
      allSelectedPointsPrevious?.forEach((sourceKey) => {
        const layerName = sources[sourceKey].single
        if (layerName) {
          map.setPaintProperty(layerName, `circle-stroke-width`, [
            'case',
            ['in', ['get', 'dataId'], ['literal', ``]],
            5,
            0,
          ])
        }
      })

      Object.keys(allSelectedPoints).forEach((sourceKey) => {
        const layerName = sources[sourceKey].single

        if (layerName) {
          map.setPaintProperty(layerName, `circle-stroke-width`, [
            'case',
            [
              'in',
              ['get', 'dataId'],
              ['literal', allSelectedPoints[sourceKey]],
            ],
            5,
            0,
          ])
        }
      })
    }
  }, [allSelectedPoints, allSelectedPointsPrevious, getMap, sources])

  return (
    <DrawControl
      ref={(drawControl) => {
        drawRef.current = drawControl
      }}
      displayControlsDefault={false}
      controls={{ polygon: true, trash: true }}
      onDrawUpdate={(e) => {
        e.features.map((f: $TSFixMe) => updateOrCreateFeature(f.id, f))
      }}
      onDrawDelete={(e) => {
        e.features.map((f: $TSFixMe) => deleteFeature(f.id))
      }}
      onDrawCreate={(e) => {
        e.features.map((f: $TSFixMe) => updateOrCreateFeature(f.id, f))
      }}
    />
  )
}

export default AddonClusterAndPointSelectionWithPolygons
