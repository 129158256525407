import React, { useState, useCallback, useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { setLocale as setValidationLocale } from 'yup'
import 'moment/locale/el'
import 'moment/locale/en-gb'
import 'moment/locale/da'
import { T } from '@transifex/react'
import { tx } from '@transifex/native'
import { toast } from 'react-toastify'
import {
  addValidationMethods,
  validationMessages,
} from '../components/shared/utils'
import EnglishIcon from '../images/language_icons/united-kingdom.svg'
import GreeceIcon from '../images/language_icons/greece.svg'
import DenmarkIcon from '../images/language_icons/denmark.svg'

export const AVAILABLE_LOCALES = [
  { value: 'en', label: <T _str="English" />, icon: EnglishIcon },
  { value: 'el', label: <T _str="Greek" />, icon: GreeceIcon },
  { value: 'da', label: <T _str="Danish" />, icon: DenmarkIcon },
]

export const DEFAULT_LOCALE = 'en'
export const TranslationContext = React.createContext(null)

type OwnTranslationsProviderProps = {
  children?: React.ReactElement
  me?: {
    language?: string
  }
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'TranslationsProviderProps' circularly ... Remove this comment to see the full error message
type TranslationsProviderProps = OwnTranslationsProviderProps &
  typeof TranslationsProvider.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'TranslationsProvider' implicitly has type 'any' b... Remove this comment to see the full error message
const TranslationsProvider = ({ children, me }: TranslationsProviderProps) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE)

  const switchLocale = useCallback(
    (newLocale) => {
      tx.getLanguages().then((languages: $TSFixMe) => {
        if (languages.some((i: $TSFixMe) => i.code === newLocale)) {
          tx.setCurrentLocale(newLocale)
          moment.locale(newLocale)
          setLocale(newLocale)
          localStorage.setItem('locale', newLocale)
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ mixed: { default: JSX.Element;... Remove this comment to see the full error message
          setValidationLocale(validationMessages)
          addValidationMethods()
        } else {
          toast.error(
            <T _str="Language {lang} is not available" lang={locale} />
          )
        }
      })
    },
    [locale]
  )

  useEffect(() => {
    switchLocale(locale)
  }, [locale, switchLocale])

  useEffect(() => {
    switchLocale(me?.language?.toLowerCase() || DEFAULT_LOCALE)
  }, [me, switchLocale])

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ switchLocale: (newLocale: any) => void; lo... Remove this comment to see the full error message
    <TranslationContext.Provider value={{ switchLocale, locale }}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={locale}
      >
        {children}
      </MuiPickersUtilsProvider>
    </TranslationContext.Provider>
  )
}

TranslationsProvider.defaultProps = {
  children: null,
  me: {},
}

const mapStateToProps = (state: $TSFixMe) => ({
  me: state.settings.user,
})

export default connect(mapStateToProps)(TranslationsProvider)
