import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { T } from '@transifex/react'
import { message, Spin } from 'antd'
import { FormUtils } from '../../..'
import {
  GET_ALL_PICK_UP_ORDERS,
  GET_PICK_UP_ORDER,
  UPDATE_ROUTE_STOP,
} from '../../../../api'
import FormUi from './Form.ui'
import { FormConfig } from './types'

type EditFormProps = {
  pickupOrderId?: string
  routeId?: string
  orderId?: string
  on: {
    success: () => void
  }
}

const EditForm = ({ orderId, on, routeId, pickupOrderId }: EditFormProps) => {
  /* API */
  const [apiActionUpdate] = useMutation(UPDATE_ROUTE_STOP)
  const { data, loading } = useQuery(GET_PICK_UP_ORDER, {
    variables: {
      id: pickupOrderId,
    },
  })

  const onSubmit: FormConfig['onSubmit'] = async ({ weight }) => {
    await apiActionUpdate({
      variables: {
        weight,
        orderId,
        status: 'completed',
        routeId,
      },
      refetchQueries: [GET_ALL_PICK_UP_ORDERS],
    })
    message.success(<T _str="Customer updated" />)
    on.success()
  }

  return (
    <Spin spinning={loading || !pickupOrderId}>
      <FormUi
        formik={{
          initialValues: {
            weight: data?.pickupOrder?.weight,
          },
          onSubmit: FormUtils.formikErrorHandler({
            fn: onSubmit,
          }),
          enableReinitialize: true,
        }}
        //   disabled={loading}
      />
    </Spin>
  )
}

export default EditForm
