import moment from 'moment'
import { createStatefulContextSimple } from '../../../contexts/factories/createStatefulContextSimple'
import { createEnhancedFiltersSimpleStateWithCreateFilter } from '../../filters-utils/createEnhancedFiltersSimpleState'

export type TFilters = {
  createdAtFrom?: string
  createdAtTo?: string
  status?: string[]
  property?: string[]
  propertyGroup?: string[]
  containerGroupIds?: string[]
  containerIds?: string[]
}

export const initialFilters: TFilters = {
  createdAtFrom: moment().startOf('year').toISOString(),
  createdAtTo: moment().endOf('year').toISOString(),
}

const { Provider, useContext } = createStatefulContextSimple<TFilters>()

const { createFilter, useFiltersContext } =
  createEnhancedFiltersSimpleStateWithCreateFilter(useContext)

export { Provider as FiltersProiver, useFiltersContext, createFilter }
