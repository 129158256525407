import { gql } from '@apollo/client'

export type TVariables = {
  ids?: string[]
  activeProjects?: string
  bbox?: $TSFixMe
  zoomLevel?: number
  search?: string
  containerTypes?: string[]
  propertyIds?: string[]
  status?: string[]
  wasteFractionIds?: string[]
  // eslint-disable-next-line camelcase
  sensor_Isnull?: boolean
  measurementFillPercentage?: string
  pickupSetting?: string[]
  propertyGroupIds?: string[]
  containerGroupIds?: string[]
  storedAtDepot?: string[]
  allowedForRoute?: string
}

export const GET = gql`
  query SelectionTableAndMapContainersGetContainerForMap(
    $bbox: BBoxInput!
    $zoomLevel: Int!
    $search: String
    $containerTypes: [ID!]
    $propertyIds: [ID!]
    $activeProjects: String
    $measurement_FillPercentage: String
    $pickupSetting: [ID]
    $wasteFractionIds: String
    $status: [String]
    $propertyGroupIds: [ID]
    $containerGroupIds: [ID]
    $storedAtDepot: [ID]
    $allowedForRoute: String
    $sensor_Isnull: Boolean
    $ids: [ID!]
  ) {
    containerMap(
      activeProjects: $activeProjects
      bbox: $bbox
      zoomLevel: $zoomLevel
      search: $search
      containerTypes: $containerTypes
      propertyIds: $propertyIds
      measurement_FillPercentage: $measurement_FillPercentage
      pickupSetting: $pickupSetting
      wasteFractionIds: $wasteFractionIds
      status: $status
      propertyGroupIds: $propertyGroupIds
      containerGroupIds: $containerGroupIds
      storedAtDepot: $storedAtDepot
      allowedForRoute: $allowedForRoute
      sensor_Isnull: $sensor_Isnull
      ids: $ids
    ) {
      points {
        id
        latitude
        longitude
        fillLevel
      }
      clusters {
        id
        latitude
        longitude
        totalCount
      }
    }
  }
`

export const GET_CLUSTER_POINTS = gql`
  query GetContainerClusterPoints(
    $clusterId: String
    $bbox: BBoxInput!
    $search: String
    $containerTypes: [ID!]
    $propertyIds: [ID!]
    $activeProjects: String
    $measurement_FillPercentage: String
    $pickupSetting: [ID]
    $wasteFractionIds: String
    $status: [String]
    $propertyGroupIds: [ID]
    $containerGroupIds: [ID]
    $storedAtDepot: [ID]
    $allowedForRoute: String
    $sensor_Isnull: Boolean
  ) {
    allContainers(
      clusterId: $clusterId
      activeProjects: $activeProjects
      bbox: $bbox
      search: $search
      containerTypes: $containerTypes
      propertyIds: $propertyIds
      measurement_FillPercentage: $measurement_FillPercentage
      pickupSetting: $pickupSetting
      wasteFractionIds: $wasteFractionIds
      status: $status
      propertyGroupIds: $propertyGroupIds
      containerGroupIds: $containerGroupIds
      storedAtDepot: $storedAtDepot
      allowedForRoute: $allowedForRoute
      sensor_Isnull: $sensor_Isnull
    ) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
  }
`
