import { gql } from '@apollo/client'

export const FrgPropertyForList = gql`
  fragment FrgPropertyForList on PropertyDef {
    id
    company {
      name
      id
    }
    createdAt
    location {
      name
      latitude
      longitude
    }
    externalPropertyNumber
    type {
      id
      name
    }
    propertyGroup {
      id
      name
    }
  }
`

export const FrgPropertyForInfoCard = gql`
  fragment FrgPropertyForInfoCard on PropertyDef {
    id
    externalPropertyNumber
    location {
      name
      latitude
      longitude
    }
    company {
      id
      description
      name
    }
    metaData
  }
`
