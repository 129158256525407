import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { Row, Col, Divider, ColProps, RowProps } from 'antd'

export type LayoutWithDividerProps = {
  left: ReactNode
  right: ReactNode
  footer?: ReactNode
  style?: CSSProperties
  leftProps?: ColProps
  rightProps?: ColProps
  wrapperProps?: RowProps
  footerProps?: ColProps
  leftRatio?: number
  rightRatio?: number
}

export const LayoutWithDivider = ({
  footer,
  left,
  right,
  style = {},
  footerProps = {},
  leftProps = {},
  rightProps = {},
  wrapperProps = {},
  children,
  leftRatio,
  rightRatio,
}: PropsWithChildren<LayoutWithDividerProps>) => {
  return (
    <>
      <Divider style={{ borderTopWidth: 4, margin: 0 }} />
      <Row
        style={{ ...style, ...(wrapperProps.style || {}) }}
        {...wrapperProps}
      >
        <Col
          flex={1}
          {...leftProps}
          style={{
            padding: '1rem',
            width: leftRatio ? `calc(${leftRatio}% - 2px)` : 'auto',
            ...leftProps?.style,
          }}
        >
          {left}
        </Col>
        <Divider
          type="vertical"
          style={{ height: 'auto', margin: 0, borderLeftWidth: 4 }}
        />
        <Col
          flex={1}
          {...rightProps}
          style={{
            padding: '1rem',
            width: rightRatio ? `calc(${rightRatio}% - 2px)` : 'auto',
            ...rightProps?.style,
          }}
        >
          {right}
        </Col>
        {footer && (
          <Col xs={24} {...footerProps}>
            {footer}
          </Col>
        )}
        {children}
      </Row>
    </>
  )
}
