import React, { ReactNode } from 'react'
import moment from 'moment'
import { Col, ColProps, Divider, List, Row, Tag } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import { useQuery, gql } from '@apollo/client'
import { T } from '@transifex/react'
import { CssScrollerDiv, Typography } from '@wastehero/storybook/lib/components'
import { array } from '../../../utils/array'
import PopupLayout1 from './Layout'
import { routingMap } from '../../../reducers/routing/routing-map'

export type TPopupContainerDetailProps = {
  id: string
}

export const ColWithHeadingAndData = ({
  heading,
  data,
  loading,
  ...rest
}: {
  heading: ReactNode
  data?: ReactNode
  loading?: boolean
} & ColProps) => (
  <Col {...rest}>
    <Row>
      <Col xs={24}>
        <Typography style={{ color: '#8C8C8C' }} variant="body-14">
          {heading}
        </Typography>
      </Col>
      <Col xs={24}>
        <Typography loading={loading} variant="body-14">
          {data}
        </Typography>
      </Col>
    </Row>
  </Col>
)

const ContainerNode = ({
  value = {},
  loading,
}: {
  value: $TSFixMe
  loading?: boolean
}) => {
  const {
    id,
    containerId,
    location,
    latestPickUp,
    nextPickUp,
    tickets,
    wasteFraction,
  } = value

  return (
    <Row gutter={[0, 8]} style={{ width: 380 }}>
      <Col xs={24}>
        <Typography
          loading={loading}
          component="Link"
          href={
            routingMap.app['asset-management'].containers[':containerId'](id)._
          }
          weight="semi-bold"
          variant="body-14"
        >
          <T _str="Container {containerId}" containerId={containerId} />
        </Typography>
      </Col>
      <Col xs={24}>
        <Row gutter={8} align="middle">
          <Col>
            <EnvironmentOutlined style={{ fontSize: 14, color: '#BFBFBF' }} />
          </Col>
          <Col>
            <Typography loading={loading} variant="body-14">
              {location?.name}
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      </Col>
      <ColWithHeadingAndData
        heading={<T _str="Last Pickup" />}
        data={latestPickUp ? moment(latestPickUp).format('LLL') : '-'}
        loading={loading}
        xs={12}
      />
      <ColWithHeadingAndData
        heading={<T _str="Next Pickup" />}
        data={nextPickUp ? moment(nextPickUp).format('LLL') : '-'}
        loading={loading}
        xs={12}
      />
      <ColWithHeadingAndData
        heading={<T _str="WasteFractions" />}
        data={<Tag>{wasteFraction?.name}</Tag>}
        loading={loading}
        xs={12}
      />
      <ColWithHeadingAndData
        heading={<T _str="Ticket" />}
        data={tickets?.totalCount}
        loading={loading}
        xs={12}
      />
    </Row>
  )
}

export const PopupContainerDetail = ({
  id,
  onClose,
}: TPopupContainerDetailProps & { onClose: () => void }) => {
  const { data, loading } = useQuery(
    gql`
      query MapPopupSingleContainerGet($id: ID) {
        container(id: $id) {
          id
          location {
            id
            name
          }
          containerId
          latestPickUp
          nextPickUp
          tickets {
            totalCount
          }
          wasteFraction {
            id
            name
          }
        }
      }
    `,
    {
      variables: { id },
      skip: !id,
    }
  )

  return (
    <PopupLayout1 onClose={onClose} title={<T _str="Container" />}>
      <ContainerNode loading={loading} value={data?.container} />
    </PopupLayout1>
  )
}
export type TPopupContainersDetailProps = {
  ids: string[]
}

export const PopupContainersDetail = ({
  ids,
  onClose,
}: TPopupContainersDetailProps & { onClose: () => void }) => {
  const { data, loading } = useQuery(
    gql`
      query MapPopupSingleContainerGet($ids: [ID]) {
        allContainers(ids: $ids) {
          totalCount
          edges {
            node {
              id
              location {
                id
                name
              }
              containerId
              latestPickUp
              nextPickUp
              tickets {
                totalCount
              }
              wasteFraction {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: { ids },
      skip: !ids?.length,
    }
  )

  return (
    <PopupLayout1
      onClose={onClose}
      title={<T _str="Containers ({count})" count={ids?.length} />}
    >
      <CssScrollerDiv
        style={{ maxHeight: 400, minWidth: 300 }}
        defaultYScroller
        offset={10}
      >
        <List
          loading={loading}
          dataSource={array(
            data?.allContainers?.edges?.map((e: $TSFixMe) => e.node),
            loading &&
              Array.from({ length: ids.length }).fill({ loading: true })
          )}
          renderItem={(container: $TSFixMe, i) => (
            <List.Item style={{ ...(i === 0 ? { paddingTop: 0 } : {}) }}>
              <ContainerNode loading={container.loading} value={container} />
            </List.Item>
          )}
        />
      </CssScrollerDiv>
    </PopupLayout1>
  )
}
