import React from 'react'
// import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'
import { useQuery, gql } from '@apollo/client'

import { AsyncSelectType, Opt } from './types'
// import { getListOption } from '../../ui'

const GET_QUERY = gql`
  query Component_Select_PropertyFacility_Get_Query(
    $activeProjects: String
    $property: ID
  ) {
    allPropertyFacilities(
      activeProjects: $activeProjects
      property: $property
    ) {
      edges {
        node {
          id
          container {
            containerId
          }
        }
      }
    }
  }
`

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type Variables = {
  activeProjects?: string
  property?: string
}

type PropertyFacilityRequestType = {
  render?: (props: RenderFnProps) => JSX.Element
} & AsyncSelectType<Variables> &
  Omit<SelectProps<Opt>, 'options' | 'loading'>

export const SelectPropertyFacility = ({
  variables,
  render,
  ...selectProps
}: PropertyFacilityRequestType) => {
  const { data, loading } = useQuery(GET_QUERY, {
    variables,
  })

  const options: Opt[] =
    data?.allPropertyFacilities?.edges.map(({ node }: $TSFixMe) => ({
      label: node.id,
      value: node.id,
      key: node.id,
    })) || []

  return (
    render?.({
      options,
      loading,
    }) || (
      <Select
        options={options}
        showSearch
        loading={loading}
        filterOption
        labelInValue
        {...(selectProps || {})}
      />
    )
  )
}
