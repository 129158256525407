import { SelectProps } from 'antd'
import React from 'react'
import { T } from '@transifex/react'

import { createFilter } from './filters.context'
import {
  SelectContainerType,
  SelectContainerTypeProps,
  SelectPickupScheme,
  SelectWasteFraction,
} from '../../../../../../../../../new-components'

const defaultProps: Partial<SelectProps> = {
  style: {
    width: 150,
  },
}

export const FilterWasteFraction = createFilter({
  key: 'wasteFraction',
  Component: SelectWasteFraction,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    placeholder: <T _str="Waste Fraction" />,
  },
})

export const FilterContainerType = createFilter<SelectContainerTypeProps>({
  key: 'containerType',
  Component: SelectContainerType,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
  },
})

export const FilterPickupScheme = createFilter({
  key: 'pickUpScheme',
  Component: SelectPickupScheme.WithActiveProjects,
  componentProps: defaultProps,
})
