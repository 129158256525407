import { createGlobalStyle } from 'styled-components'
import { CssScroller } from '@wastehero/storybook/lib/components'

export const GlobalStyles = createGlobalStyle`
  .wh-child-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wh-fill-parent {
    height: 100%;
    width: 100%;
     
  }
 
  .wh-antd-collapse {
    &.header-full {
      .ant-collapse-header-text {
          width: 100%;
        }
    }
  }

  .mapboxgl-popup-content {
    padding: 0px;
  }

  .mapboxgl-popup {
    .mapboxgl-popup-content {
      box-shadow: 0px 3px 6px -4px #0000001f, 0px 6px 16px #00000014, 0px 9px 28px 8px #0000000d;
    }
  }
  
  .birdeatsbug-sdk>div {
    background-color: #001529;
  }
  .birdeatsbug-sdk button.btn-primary {
    background-color: #1890ff;
  }
`

export const GlobalStylesAntFillViewList = createGlobalStyle`
  .wh-ant-fill-view-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-spin-nested-loading {
      flex: 1;
      min-height: 0;
      ${CssScroller}
    }
  }
`
