import React, { useMemo, FC } from 'react'
import { T } from '@transifex/react'
import { Row, Col } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import {
  TBaseFormSchema,
  BaseForm,
  TBaseFormProps,
  TLevel,
} from './ticket/form-ticket'

import {
  FormFn,
  Footer as BaseFooter,
  useStore,
  WithFormInitializer,
  TCreateAntdFormElementsFormProps,
} from '../../antdform-kit-v2'
import {
  GET,
  GET_TICKET_TYPE,
  GET_CONTAINER_INFO,
  mutationKeyMapWithServiceTypeCreate,
  mutationKeyMapWithServiceTypeUpdate,
} from './api'
import { convertEnumToNumber } from '../../../api/consts/utils'
import {
  attachmentsApiToFormValue,
  attachmentsConvertFormValues,
} from './utils'
import { useMe } from '../../../hooks'
import {
  TCrmServiceServiceTypeChoices,
  TicketTicketPriorityChoices,
} from '../../../api/consts'
import { object } from '../../../utils/object'
import { array } from '../../../utils/array'
import { TicketSummary, TFormSummarySchema } from './Summary'

type TAfterSubmitProps = {
  id: string
}

export type TFormTicketSingleProps = {
  onSuccess?: (
    args: TAfterSubmitProps,
    values: $TSFixMe
  ) => Promise<$TSFixMe> | void
} & {
  id?: string
  /** in create mode only */
  containerId?: string
  /** in create mode only */
  propertyId?: string
  /** in create mode only */
  ticketTypeId?: string
  /** in create mode only */
  initialValues?: Partial<TBaseFormSchema>
  /** hide footer
   * default to false.
   * if true then use FormTicketSingle.Footer to render footer urself.
   */
  hideFooter?: boolean
  /** formId */
  formId: string
  /** extendInitialValues */
  overwriteInitialValues?: $TSFixMe
  /** addonContext fields */
  addonContextFields?: Array<FC>
  /** hideSummary
   * default to false.
   */
  hideSummary?: boolean
} & Pick<TCreateAntdFormElementsFormProps, 'onIsSubmittingChange' | 'loading'> &
  Pick<TBaseFormProps, 'addonPreFields'>

type TFooterProps = Pick<TFormTicketSingleProps, 'formId' | 'id'>

export type { TBaseFormSchema }

const Footer = WithFormInitializer<TFooterProps>(
  ({ formId, id }: TFooterProps) => {
    const editMode = !!id

    return <BaseFooter formId={formId} createMode={!editMode} />
  }
)

const FormTicketSingle: FC<TFormTicketSingleProps> & { Footer: typeof Footer } =
  WithFormInitializer<TFormTicketSingleProps>(
    ({
      onSuccess,
      onIsSubmittingChange,
      hideFooter,
      formId,
      loading: loadingFromParent,
      addonPreFields,
      addonContextFields,
      overwriteInitialValues,
      hideSummary,
      ...allProps
    }) => {
      const formMode = useStore((store) => store[formId].mode)
      const { company, id: currentUserId } = useMe()

      const id = allProps?.id

      const editMode = !!id

      const { data: dataGetTicketType, loading: loadingGetTicketType } =
        useQuery(GET_TICKET_TYPE, {
          variables: { id: allProps?.ticketTypeId },
          skip: editMode,
        })
      const { data, loading: loadingGetTAlreadyCreatedTicket } = useQuery(GET, {
        variables: { id },
        skip: !id,
      })

      const { data: dataGetContainerInfo, loading: loadingGetContainerInfo } =
        useQuery(GET_CONTAINER_INFO, {
          variables: { id: allProps?.containerId },
          skip: !allProps?.containerId,
        })

      const alreadyCreatedTicket = data?.ticket

      const { attachments } = alreadyCreatedTicket || {}

      const ticketType = editMode
        ? alreadyCreatedTicket?.ticketType
        : dataGetTicketType?.ticketType

      const serviceType = ticketType?.service
        ?.serviceType as TCrmServiceServiceTypeChoices

      const containerId = editMode
        ? alreadyCreatedTicket?.container?.id
        : allProps?.containerId

      const containerInfo: TBaseFormProps['containerInfo'] = useMemo(() => {
        const { wasteFraction, pickupSetting, containerType } =
          dataGetContainerInfo?.container ||
          alreadyCreatedTicket?.container ||
          {}

        return {
          containerType: containerType?.id,
          pickupSetting: pickupSetting?.id,
          wasteFraction: wasteFraction?.id,
        }
      }, [alreadyCreatedTicket?.container, dataGetContainerInfo?.container])

      const propertyId = editMode
        ? alreadyCreatedTicket?.property?.id
        : allProps?.propertyId

      const apollo = useApolloClient()

      const onSubmit = async (values: TBaseFormSchema & TFormSummarySchema) => {
        const mutationMap = editMode
          ? mutationKeyMapWithServiceTypeUpdate
          : mutationKeyMapWithServiceTypeCreate

        const {
          description,
          date,
          removeServiceFee,
          attachments: attachmentsFiles,
          containerType,
          pickupSetting,
          wasteFraction,
          wasteFractions,
          priority,
          newContainerID,
          containerSource,
          containerDestination,
          assignee,
          sendSummaryTo,
        } = values

        const [addAttachments, removeAttachmentIds] =
          await attachmentsConvertFormValues(attachmentsFiles, attachments)

        const baseFormFields = {
          description,
          preferredDate: date?.date,
          ignoreServiceFee: removeServiceFee,
          newContainerId: newContainerID,
          containerSource: containerSource && {
            depot: containerSource.depot,
          },
          containerDestination: containerDestination && {
            depot: containerDestination.depot,
          },
          containerToAdd: containerSource?.containerToAdd || undefined,
          ...(!editMode && {
            reporter: currentUserId,
            ticketType: ticketType.id,
            company: company?.id,
            container: containerId,
            property: propertyId,
            attachments: addAttachments,
            priority: convertEnumToNumber(priority),
            assignee: assignee?.assignee ? assignee.assignee : null,
            assignedTeam: assignee?.assignedTeam ? assignee.assignedTeam : null,
            sendSummary: {
              toUserIds: sendSummaryTo?.users,
              viaSms: sendSummaryTo?.sms || false,
              viaEmail: sendSummaryTo?.email || false,
            },
          }),
          ...(editMode && {
            addAttachments,
            removeAttachmentIds,
            id,
            priority: convertEnumToNumber(alreadyCreatedTicket?.priority),
          }),
        }
        let ticketId = ''

        // a ticket form is being used.
        switch (serviceType) {
          case 'ADD_CONTAINER':
          case 'CHANGE_CONTAINER_PICKUP_SETTING':
          case 'CHANGE_CONTAINER_TYPE':
          case 'REMOVE_CONTAINER':
            await apollo
              .mutate({
                mutation: mutationMap[serviceType],
                variables: {
                  input: {
                    ...baseFormFields,
                    containerType,
                    pickupSetting,
                    wasteFraction,
                  },
                },
              })
              .then((resp: $TSFixMe) => {
                if (!editMode) {
                  ticketId =
                    resp?.data?.createManageContainerTicket
                      ?.manageContainerTicket?.id
                }
              })
            break
          case 'BULK_WASTE_PICKUP':
            await apollo
              .mutate({
                mutation: mutationMap[serviceType],
                variables: {
                  input: {
                    ...baseFormFields,
                    items:
                      wasteFractions?.map((w) => ({
                        wasteFraction: w,
                      })) || [],
                  },
                },
              })
              .then((resp: $TSFixMe) => {
                if (!editMode) {
                  ticketId =
                    resp?.data?.createBulkWastePickupTicket
                      ?.bulkWastePickupTicket?.id
                }
              })
            break
          case 'EXTRA_EMPTYING':
          default:
            await apollo
              .mutate({
                mutation: mutationMap.EXTRA_EMPTYING,
                variables: { input: baseFormFields },
              })
              .then((resp: $TSFixMe) => {
                if (!editMode) {
                  ticketId = resp?.data?.createTicket?.ticket?.id
                }
              })
            break
        }
        await onSuccess?.(
          {
            id: editMode ? (id as string) : (ticketId as string),
          },
          values
        )
      }

      const initialValues: TBaseFormSchema = useMemo(() => {
        const {
          description,
          preferredDate,
          managecontainerticket,
          bulkwastepickupticket,
          attachments: attachmentsInitial,
          ignoreServiceFee,
        } = alreadyCreatedTicket || {}
        return object<TBaseFormSchema>(
          editMode && {
            containerSource: {
              depot: managecontainerticket?.containerSource?.depot?.id,
              containerToAdd: managecontainerticket?.containerToAdd?.id,
            },
            containerDestination: {
              depot: managecontainerticket?.containerDestination?.depot?.id,
            },
            newContainerID: managecontainerticket?.newContainerId,
            attachments: attachmentsApiToFormValue(attachmentsInitial),
            date: { date: preferredDate, dateASAP: !preferredDate },
            description,
            removeServiceFee: ignoreServiceFee,
            containerType: managecontainerticket?.containerType?.id,
            pickupSetting: managecontainerticket?.pickupSetting?.id,
            wasteFraction: managecontainerticket?.wasteFraction?.id,
            wasteFractions:
              bulkwastepickupticket?.items?.edges.map(
                ({ node }: $TSFixMe) => node?.wasteFraction?.id
              ) || [],
          },
          !editMode && {
            // default initial values,
            date: { date: ``, dateASAP: true },
            priority: 'A_2' as TicketTicketPriorityChoices, // no priority field in edit mode so no need to pass it's initial value.
            ...allProps.initialValues, // to extend any initial values in create mode.
            containerSource: {
              depot: ticketType?.containerSource?.depot.id,
              containerToAdd: '',
            },
            containerDestination: {
              depot: ticketType?.containerDestination?.depot.id,
            },
          }
        )
      }, [
        alreadyCreatedTicket,
        editMode,
        ticketType?.containerSource?.depot.id,
        ticketType?.containerDestination?.depot.id,
        allProps.initialValues,
      ])

      const loading =
        loadingGetTAlreadyCreatedTicket ||
        loadingGetTicketType ||
        loadingFromParent ||
        loadingGetContainerInfo

      const showSummary = !hideSummary && !id

      return (
        <FormFn
          msgSuccessName={<T _str="Ticket" />}
          formId={formId}
          initialValues={{
            ...initialValues,
            ...overwriteInitialValues,
          }}
          createMode={!editMode}
          onFinish={onSubmit}
          loading={loading}
          className="fill-height"
          onIsSubmittingChange={onIsSubmittingChange}
        >
          <div
            className="wh-vertical-scroller-container"
            style={{ rowGap: 16 }}
          >
            <div className="wh-vertical-scroller-container-scroll">
              <Row gutter={[32, 16]} style={{ paddingBottom: '1rem' }}>
                <Col span={!showSummary ? 24 : 16}>
                  <BaseForm
                    disabled={formMode === 'view'}
                    serviceType={serviceType}
                    config={{
                      editMode,
                    }}
                    addonPreFields={array(
                      addonPreFields,
                      addonContextFields?.map((C) => <C />)
                    )}
                    containerId={containerId}
                    propertyId={propertyId}
                    containerInfo={containerInfo}
                    ticketTypeId={ticketType?.id || ''}
                    level={
                      array<TLevel>(
                        ticketType?.isContainerLevel && 'container',
                        ticketType?.isPropertyLevel && 'property'
                      )?.[0]
                    }
                  />
                </Col>
                {showSummary && (
                  <Col span={8}>
                    <TicketSummary
                      containerId={containerId}
                      formRequiredFields={[]}
                      ticketTypeId={ticketType?.id}
                      propertyId={propertyId}
                    />
                  </Col>
                )}
              </Row>
            </div>
            {!hideFooter && (
              <BaseFooter formId={formId} createMode={!editMode} />
            )}
          </div>
        </FormFn>
      )
    }
  ) as FC<TFormTicketSingleProps> & { Footer: typeof Footer }

FormTicketSingle.Footer = Footer

export { FormTicketSingle }
