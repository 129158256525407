import { Row } from 'antd'
import styled from 'styled-components'
import { apiUtilCreatePrefixer } from '../../api'

const p = apiUtilCreatePrefixer(
  `wh-UI-DragAndDropMultipleVerticalListsSections-`
)

export const classes = {
  wrapper: p`wrapper`,
  section: p`section`,
}

export const StlyedWrapper = styled(Row)`
  & {
    .${classes.section} {
      border: solid 2px whitesmoke;
      border-top: none;
      border-bottom: none;
      padding: 0px 1rem;

      &:first-child {
        border-left-color: transparent;
        padding-left: 0px;
      }
      &:last-child {
        border-right-color: transparent;
        padding-right: 0px;
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding-left: 0px;
      }
    }
  }
`
