import React from 'react'
import { T } from '@transifex/react'
import { useModalWithButton } from '@wastehero/storybook/lib/components'

import CreateRequestForm, { TCreateRequestFormProps } from './CreateRequestForm'
import { TicketTypeLevelType } from '../../../../../../api/consts/TicketTypeLevelType'

type FormState = Omit<TCreateRequestFormProps, 'modalProps'>

export type { TCreateRequestFormProps }

export const useCreateRequestFormModal = ({
  type,
}: {
  type: TicketTypeLevelType
}) => {
  const {
    jsx: { modal },
    utils: { openModal, setState },
  } = useModalWithButton<Record<string, unknown>, Omit<FormState, 'type'>>({
    children: ({ state }) => <CreateRequestForm {...state} type={type} />,
    modelProps: {
      title: <T _str="Create ticket" />,
      width: 1280,
      footer: null,
      bodyStyle: {
        padding: 0,
      },
      destroyOnClose: true,
    },
  })

  return {
    modal,
    openModal: (state: Omit<FormState, 'type'>) => {
      setState(state)
      openModal()
    },
  }
}
