import React from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TicketTypeLevelType = `container` | `property`

export const ticketTypeLevelTypeLabelMap: Record<
  TicketTypeLevelType,
  React.ReactNode
> = {
  container: <T _str="Container" />,
  property: <T _str="Property" />,
}

export const TicketTypeLevelTypeOptions = createOptionsFromLabelMap(
  ticketTypeLevelTypeLabelMap
)
