import { createStatefulContextSimple } from '../../../../../../../../../contexts'
import { createEnhancedFiltersSimpleStateWithCreateFilter } from '../../../../../../../../../new-components/filters-utils/createEnhancedFiltersSimpleState'

export type TFilters = {
  wasteFraction?: string[]
  containerType?: string[]
  pickUpScheme?: string[]
}

export const initialFilters: TFilters = {}

const { Provider, useContext } = createStatefulContextSimple<TFilters>()

const { createFilter, useFiltersContext } =
  createEnhancedFiltersSimpleStateWithCreateFilter(useContext)

export { Provider as FiltersProiver, useFiltersContext, createFilter }
