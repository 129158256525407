import { gql } from '@apollo/client'

/* Queries */
export const GET_ALL_PICK_UP_ORDERS = gql`
  query GetAllPickupOrders(
    $activeProjects: String
    $completedAt_Isnull: Boolean
    $completedAt_Lte: DateTime
    $completedAt_Gte: DateTime
    $container_Isnull: Boolean
    $search: String
    $containerTypeIds: [ID]
    $wasteFractionIds: [ID]
    $routeIds: [ID]
    $containerIds: [ID]
    $driverIds: [ID]
    $propertyIds: [ID]
    $vehicleIds: [ID]
    $first: Int
    $orderBy: String
    $order: String
    $offset: Int
    $propertyGroupIds: [ID]
    $containerGroupIds: [ID]
  ) {
    allPickupOrders(
      activeProjects: $activeProjects
      completedAt_Isnull: $completedAt_Isnull
      completedAt_Lte: $completedAt_Lte
      completedAt_Gte: $completedAt_Gte
      container_Isnull: $container_Isnull
      search: $search
      containerTypeIds: $containerTypeIds
      wasteFractionIds: $wasteFractionIds
      routeIds: $routeIds
      containerIds: $containerIds
      driverIds: $driverIds
      vehicleIds: $vehicleIds
      propertyIds: $propertyIds
      first: $first
      orderBy: $orderBy
      order: $order
      offset: $offset
      propertyGroupIds: $propertyGroupIds
      containerGroupIds: $containerGroupIds
    ) {
      totalCount
      edges {
        node {
          id
          orderId
          route {
            id
            project {
              id
              name
            }
            driver {
              id
              user {
                id
                firstName
                lastName
                email
              }
            }
          }
          completedAt
          weight
          createdInquiry {
            id
          }
          ticket {
            id
          }
          container {
            id
            containerId
            containerType {
              id
              name
            }
            wasteFraction {
              id
              wasteCategory
              name
              wasteSubstance
            }
            location {
              id
              latitude
              longitude
              placeId
              name
            }
          }
          measurementCreatedAt
          measurementFillLevel
          measurementFillPercentage
          fillLevel
          fillPercentage
        }
      }
    }
  }
`

export const GET_PICK_UP_ORDER = gql`
  query GetPickupOrder($id: ID!) {
    pickupOrder(id: $id) {
      route {
        id
      }
      orderId
      container {
        id
      }
      direction {
        id
      }
      location {
        id
        placeId
        longitude
        latitude
      }
      inquiry {
        id
      }
      route {
        id
      }
      orderType
      depot {
        id
      }
      createdAt
      updatedAt
      scheduledDriverArrival
      completedAt
      status
      stopNumber
      volume
      weight
      id
    }
  }
`

/* Mutations */
// export const UPDATE_PICK_UP_ORDER = gql`
//   mutation UpdatePickupOrder(
//     $pickupOrderId: UUID!
//     $status: String!
//     $weight: Float
//   ) {
//     updatePickupOrder(
//       pickupOrderId: $pickupOrderId
//       status: $status
//       weight: $weight
//     ) {
//       pickupOrder {
//         id
//       }

//       id
//     }
//   }
// `
