import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

/* Fragments */
export const FrgDriver = gql`
  fragment FrgDriver on DriverType {
    id
    driverType
    user {
      id
      firstName
      lastName
      logo
      username
    }
    status
    routeStatus
    collectedBins
    vehicle {
      id
      name
      vehicleType {
        id
        Type
      }
      project {
        id
      }
    }
  }
`
/* Queries */
export const GET_ALL_DRIVERS = gql`
  ${FrgDriver}
  query GetAllDrivers(
    $name: String
    $pageSize: Int
    $offset: Int
    $status: [String]
    $order: String
    $orderBy: String
    $havingVehicle: Boolean
    $activeProjects: String
    $search: String
    $vehicles: String
  ) {
    allDrivers(
      name: $name
      first: $pageSize
      offset: $offset
      status_In: $status
      order: $order
      orderBy: $orderBy
      havingVehicle: $havingVehicle
      activeProjects: $activeProjects
      vehicles: $vehicles
      search: $search
    ) {
      totalCount
      edges {
        node {
          ...FrgDriver
        }
      }
    }
  }
`

export const K_GET_ALL_DRIVERS = apiUtilGetKey(GET_ALL_DRIVERS)
/* Mutations */
