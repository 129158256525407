import ReactMapboxGl from 'react-mapbox-gl'
import { Props } from 'react-mapbox-gl/lib/map'

const BaseMap = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '',
})

export const ReactMapBox = {
  Map: BaseMap,
  props: { style: 'mapbox://styles/ksaied/ckshjjq3xhcrp17q6ay482s5k' } as Props,
}
