import { gql } from '@apollo/client'

export type TVariables = {
  propertyId: string
  orderBy?: string
  order?: string
  offset?: number
  first?: number
  wasteFraction?: string[]
  pickupSetting?: string[]
  containerType?: string[]
  search?: string
}

export type TResponse = {
  property: {
    id: string
    company: {
      id: string
    }
    agreements: {
      edges: {
        node: {
          id: string
          container: {
            id: string
            containerId: string
            wasteFraction: {
              id: string
              name: string
              wasteSubstance: string
              wasteCategory: string
            }
            pickupSetting: { id: string }
            containerType: { id: string }
            location: {
              id: string
              latitude: number
              longitude: number
              name: string
            }
            measurement: {
              id: string
              fillPercentage: number
            }
            latestPickUp: string
            nextPickUp: string
          }
          containerPrice: {
            id: string
            amount: number
            recurringInterval: number
          }
          recurringPrice: {
            id: string
            name: string
            recurringInterval: number
            amount: number
          }
          prices: {
            changeContainerType: {
              price: {
                amount: number
                recurringInterval: number
              }
              containerType: {
                id: string
                name: string
              }
            }[]
            changePickupSetting: {
              price: {
                amount: number
                recurringInterval: number
              }
              pickupSetting: {
                id: string
                name: string
              }
            }[]
          }
        }
      }[]
    }
  }
}

export const GET_PROPERTY_AGREEMENTS = gql`
  query GetAllAgreements(
    $propertyId: ID!
    $orderBy: String
    $order: String
    $offset: Int
    $first: Int
    $wasteFraction: [ID]
    $pickupSetting: [ID]
    $containerType: [ID]
    $search: String
  ) {
    # Property detail view
    property(id: $propertyId) {
      id
      company {
        id
      }
      agreements(
        orderBy: $orderBy
        order: $order
        offset: $offset
        first: $first
        wasteFraction: $wasteFraction
        pickupSetting: $pickupSetting
        containerType: $containerType
        search: $search
      ) {
        edges {
          node {
            id
            containerPrice {
              id
              amount
              recurringInterval
            }
            recurringPrice {
              id
              name
              recurringInterval
              amount
            }
            container {
              id
              containerId
              location {
                id
                latitude
                longitude
                name
              }
              measurement {
                id
                fillPercentage
              }
              wasteFraction {
                id
                name
                wasteSubstance
                wasteCategory
              }
              containerType {
                id
              }
              pickupSetting {
                id
              }
              latestPickUp
              nextPickUp
            }
            prices {
              changeContainerType {
                price {
                  id
                  amount
                  recurringInterval
                }
                containerType {
                  id
                  name
                }
              }
              changePickupSetting {
                price {
                  id
                  amount
                  recurringInterval
                }
                pickupSetting {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
