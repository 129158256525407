import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const SuspenseLoading = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: '46px', color: '#001529' }}
            spin
          />
        }
      />
    </div>
  )
}
