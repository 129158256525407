import React from 'react'
import { Source, Layer, Feature } from 'react-mapbox-gl'
import { fixIfNotInt } from '../../../../utils/math'
import { CLUSTER_SIZE_STEPS } from '../../consts'

import { TPoint, TCluster, TGetMap } from '../../types'

type TContainerLayerProps = {
  points: TPoint[]
  clusters: TCluster[]
  getMap: TGetMap
}

const colors = {
  single: {
    color: 'white',
    stroke: '#8C8C8C',
    font: '#000000',
  },
}

const clusterLayout = {
  icon: (radiusVar: $TSFixMe) => ({
    type: 'circle',
    paint: {
      'circle-radius': ['step', radiusVar, ...CLUSTER_SIZE_STEPS],
      'circle-color': 'white',
      'circle-opacity': 0.6,
      'circle-stroke-color': '#ffffff',
      'circle-stroke-width': 3,
    },
  }),
  label: (textField: $TSFixMe) => ({
    type: 'symbol',
    layout: {
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'text-field': textField,
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
    paint: {
      'text-color': 'black',
    },
  }),
}

const LayersContainer = ({
  clusters,
  points,
  getMap,
}: TContainerLayerProps) => {
  return (
    <>
      <Source
        id="containers"
        geoJsonSource={{
          type: 'geojson',
          // cluster: true,
          clusterMaxZoom: 25,
          clusterRadius: 35,
          generateId: true,
          data: {
            type: 'FeatureCollection',
            features: points?.map(({ id, latitude, longitude, fillLevel }) => {
              const fillLevelParsed: $TSFixMe = fillLevel
                ? fixIfNotInt(fillLevel, 0).toString()
                : ``
              return {
                type: 'Feature',
                properties: {
                  dataId: id,
                  fillLevel: fillLevelParsed,
                  labelLength: fillLevelParsed.length,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [longitude, latitude],
                },
              }
            }),
          },
        }}
      />
      {/* Container Layers. */}
      <Layer
        id="container-point-single"
        sourceId="containers"
        type="circle"
        filter={['!', ['has', 'point_count']]}
        paint={{
          'circle-radius': ['step', ['get', 'labelLength'], 10, 1, 11, 2, 12],
          'circle-color': colors.single.color,
          'circle-opacity': 1,
          'circle-stroke-color': colors.single.stroke,
          'circle-stroke-width': 2,
        }}
      />
      <Layer
        id="container-point-single-label"
        sourceId="containers"
        type="symbol"
        filter={['!', ['has', 'point_count']]}
        layout={{
          'text-field': [
            'case',
            ['>', ['get', 'labelLength'], 0],
            [
              'format',
              ['get', 'fillLevel'],
              { 'font-scale': 1.2 },
              '\n%',
              { 'font-scale': 0.8 },
            ],
            '',
          ],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 10,
          'text-line-height': 0.9,
          'symbol-sort-key': ['*', ['get', 'zIndex'], -1],
          'text-padding': 6,
        }}
        paint={{
          'text-color': colors.single.font,
        }}
      />
      <Layer
        id="container-point-cluster"
        sourceId="containers"
        filter={['has', 'point_count']}
        {...clusterLayout.icon(['get', 'point_count'])}
      />
      <Layer
        id="container-point-cluster-count"
        sourceId="containers"
        filter={['has', 'point_count']}
        {...clusterLayout.label('{point_count_abbreviated}')}
      />
      {/* Container cluster layer from server */}
      <Layer
        id="container-cluster-point"
        {...clusterLayout.icon(['get', 'totalCount'])}
      >
        {clusters?.map(({ latitude, longitude, totalCount, id }) => (
          <Feature
            key={id}
            properties={{
              totalCount,
              dataId: id,
              serverCluster: true,
              source: 'containers',
            }}
            onClick={() => {
              getMap().easeTo({
                center: { lat: latitude, lng: longitude },
                zoom: getMap().getZoom() + 2,
              })
            }}
            coordinates={[longitude, latitude]}
          />
        ))}
      </Layer>
      <Layer
        id="container-cluster-point-count"
        {...clusterLayout.label(['get', 'totalCount'])}
      >
        {clusters?.map(({ latitude, longitude, totalCount, id }) => (
          <Feature
            key={id}
            properties={{ totalCount }}
            onClick={() => {
              getMap().easeTo({
                center: { lat: latitude, lng: longitude },
                zoom: getMap().getZoom() + 2,
              })
            }}
            coordinates={[longitude, latitude]}
          />
        ))}
      </Layer>
    </>
  )
}

export default LayersContainer
