import React, { useCallback, useEffect } from 'react'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { message } from 'antd'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { useRefEnhanced } from '../../hooks'
import { getJWTToken } from './apollo'

const { host, protocol } = window.location
const secure = protocol.includes('s') ? 'wss' : 'ws'

export const wsUri = `${
  process.env.REACT_APP_API_SSL === 'true' ? 'wss' : secure
}://${process.env.REACT_APP_API_URL || host}/ws`

const wsClient = new SubscriptionClient(`${wsUri}/graphql/`, {
  reconnect: true,
  // Establish a connection on first subscribe and close on last unsubscribe.
  lazy: true,
  connectionParams: () => {
    const jwtToken = getJWTToken()
    return jwtToken
      ? {
          auth_token: jwtToken,
        }
      : {}
  },
})

let showingDisconnected = false
let showErrorMessageTimeoutRef: $TSFixMe
const DISCONNECTED_MESSAGE_KEY = 'DISCONNECTED_MESSAGE_KEY'

export const WsDisconnectNotification = () => {
  const isLoggedInListener = useSelector(
    (store: $TSFixMe) => store?.settings?.user?.id
  )

  const [getIsLoggedIn, setIsLoggedIn] = useRefEnhanced(isLoggedInListener)

  const removeNotification = useCallback(() => {
    message.destroy(DISCONNECTED_MESSAGE_KEY)
    showingDisconnected = false
    if (showErrorMessageTimeoutRef !== false)
      clearTimeout(showErrorMessageTimeoutRef)
    showErrorMessageTimeoutRef = false
  }, [])

  const showNotification = useCallback(() => {
    if (!showingDisconnected) {
      showErrorMessageTimeoutRef = setTimeout(() => {
        if (wsClient.status !== 1) {
          // it's not open.
          // See: https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
          // then show the message.
          message.error({
            key: DISCONNECTED_MESSAGE_KEY,
            content: <T _str="Disconnected" />,
            duration: 0,
          })
        }
        // wait 5 seconds and if it doesn't reconnect then show the error
      }, 5000)
      showingDisconnected = true
    }
  }, [])

  useEffect(() => {
    setIsLoggedIn(isLoggedInListener)
    if (!isLoggedInListener) {
      removeNotification()
    }
  }, [isLoggedInListener, removeNotification, setIsLoggedIn])

  useEffect(() => {
    wsClient.onReconnected(() => {
      // need to close the message and set bool to false.
      removeNotification()
    })
    wsClient.onDisconnected(() => {
      if (getIsLoggedIn()) {
        showNotification()
      }
    })
  }, [getIsLoggedIn, removeNotification, showNotification])

  return <></>
}

export const wsLink = new WebSocketLink(wsClient)
