import { LinkProps } from '../../reducers/routing/child-router-factory'

import { TGetLinkPropsFn, defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'
import {
  getCellProjectName,
  getCellProjectNameWithTitle,
} from '../cells/getters/ProjectName'

export const getLinkPropsProjectNameNode: TGetLinkPropsFn =
  getLinkPropsMakeNullable(
    (
      /** Should be ProjectDef!
       */
      value,
      { includeLabel } = defaultConfig
    ): LinkProps => ({
      to: ({ routingMap }) =>
        routingMap.app.settings['project/:id'](value.id)._,
      children: includeLabel
        ? getCellProjectNameWithTitle(value)
        : getCellProjectName(value),
    })
  )
