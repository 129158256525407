import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { Row, Col, Divider, ColProps, RowProps } from 'antd'

export type LayoutWithDividerv3Props = {
  footer?: ReactNode
  footerDivider?: boolean
  /**
   * Append divider at the top
   * default to false */
  topDivider?: boolean
  style?: CSSProperties
  wrapperProps?: Omit<RowProps, 'className'>
  dividerWrapperProps?: RowProps
  footerProps?: ColProps
  /** in Px. */
  dividerMargin?: number
  /** in Px. */
  dividerWidth?: number
  dividerColor?: string
  colsRatio?: number[]
  /** Same length as colsRatio  */
  content: ReactNode[]
  className?: string
}

const px = (val: number) => `${val}px`

const matchIndex = (index: number, length: number) => {
  if (index < length) return index
  return index % length
}

/**
 * LayoutWithDividerv3
 * Renders Cols in one Row with Ant-design divider as separator.
 * It only supports one row content with footer
 */
export const LayoutWithDividerv3 = ({
  footer,
  footerDivider = false,
  style = {},
  footerProps = {},
  wrapperProps = {},
  dividerWrapperProps = {},
  dividerMargin = 16,
  dividerWidth = 1,
  colsRatio = [50, 50],
  content,
  children,
  topDivider,
  dividerColor,
  className,
}: PropsWithChildren<LayoutWithDividerv3Props>) => {
  const numberOfColsPerRow = colsRatio.length
  const numebrOfDividers = numberOfColsPerRow - 1

  return (
    <Row
      style={{ ...style, ...(wrapperProps.style || {}) }}
      {...wrapperProps}
      className={className}
    >
      {topDivider && (
        <Divider
          style={{
            borderTopWidth: dividerWidth,
            margin: 0,
            borderColor: dividerColor,
          }}
        />
      )}
      <Col xs={24}>
        <Row wrap={false} {...dividerWrapperProps}>
          {React.Children.toArray(
            content.map((contentC, i) => {
              const number = colsRatio[matchIndex(i, numberOfColsPerRow)]
              const isLast = i === numberOfColsPerRow - 1

              return (
                <>
                  <Col
                    flex={1}
                    style={{
                      width: `calc(${number}% - ${px(
                        dividerMargin * numebrOfDividers
                      )} - ${px(dividerWidth * numebrOfDividers)})`,
                    }}
                  >
                    {contentC}
                  </Col>
                  {!isLast && (
                    <Col flex="none">
                      <Divider
                        type="vertical"
                        style={{
                          height: '100%',
                          margin: `0 ${px(dividerMargin)}`,
                          borderLeftWidth: dividerWidth,
                          borderColor: dividerColor,
                        }}
                      />
                    </Col>
                  )}
                </>
              )
            })
          )}
        </Row>
      </Col>
      {footerDivider && (
        <Divider
          type="horizontal"
          style={{
            width: '100%',
            borderTopWidth: dividerWidth,
            margin: 0,
            borderColor: dividerColor,
          }}
        />
      )}
      {footer && (
        <Col xs={24} {...footerProps}>
          {footer}
        </Col>
      )}
      {children}
    </Row>
  )
}
