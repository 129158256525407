import React from 'react'
import { T } from '@transifex/react'
import { SelectableListWithSearch } from '@wastehero/storybook/lib/components'

import { useMe } from '../../../../hooks/useMe'
import { CellAvatarUserSet1 } from '../../../cells'
import { SelectUser } from '../../User'
import { useSavedActiveProjects } from '../../../../hooks'

type TSelectUserAssigneeProps = {
  value: string
  onChange?: (val: string) => void
}

const SelectUserAssignee = ({ value, onChange }: TSelectUserAssigneeProps) => {
  const me = useMe()
  const activeProjects = useSavedActiveProjects()

  return (
    <SelectUser
      variables={{
        activeProjects,
        company: me?.company?.id,
      }}
      render={({ options, loading, onSearch, onMore, loadingMore }) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          style={{ height: '100%' }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <SelectableListWithSearch
            style={{ padding: '0px 8px' }}
            onSearch={onSearch}
            mode="simple"
            value={[value]}
            onChange={([v]) => onChange?.(v)}
            loading={loading}
            showSearch
            notFound={!loading && !loadingMore && !options.length}
            loadingMore={loadingMore}
            loaderNode={
              <CellAvatarUserSet1
                loading
                value={{ firstName: '', lastName: '' }}
                wrapperProps={{ width: '100%' }}
              />
            }
            onMore={onMore}
          >
            {!!options?.length && (
              <>
                <SelectableListWithSearch.Option
                  value={me.id}
                  label={
                    me.id === value ? (
                      <T _str="Assigned to me" />
                    ) : (
                      <T _str="Assign to me" />
                    )
                  }
                />
                <SelectableListWithSearch.OptionGroup
                  title={<T _str="All users" />}
                >
                  {options
                    ?.filter(({ value: val }) => val !== me.id)
                    ?.map(({ label, value: v }) => (
                      <SelectableListWithSearch.Option
                        value={v}
                        inGroup
                        label={
                          <CellAvatarUserSet1
                            value={{
                              firstName: label as string,
                              lastName: '',
                            }}
                            avatarProps={{ size: 'small' }}
                          />
                        }
                      />
                    ))}
                </SelectableListWithSearch.OptionGroup>
              </>
            )}
          </SelectableListWithSearch>
        </div>
      )}
    />
  )
}

export default SelectUserAssignee
