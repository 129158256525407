import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Feature, Layer, Source } from 'react-mapbox-gl'

import { GET, TVariables } from './api'

import { TSourceRenderFn, TPoint, TCluster, TSourceConfig } from '../../types'
import { CLUSTER_SIZE_STEPS } from '../../consts'

type TAvailableVariables = Omit<TVariables, 'bbox' | 'zoomLevel'>

export type TSourcePropertyConfig = TSourceConfig<TAvailableVariables>

export { getPropertySourceClusterPoints } from './utils'

const SourceProperty: TSourceRenderFn<TVariables> = ({
  queryOptions,
  _bbox,
  _zoomLevel,
  _queryOptions,
  _getMap,
  _onLoadingChange,
  refreshCount,
}) => {
  const { loading, data, refetch } = useQuery<$TSFixMe, TVariables>(GET, {
    ...queryOptions?.({ baseVariables: { _bbox, _zoomLevel } }),
    ..._queryOptions(),
    variables: {
      ...queryOptions?.({ baseVariables: { _bbox, _zoomLevel } }).variables,
      ..._queryOptions().variables,
    },
  })

  const points: TPoint[] = useMemo(
    () => data?.propertyMap?.points || [],
    [data?.propertyMap?.points]
  )
  const clusters: TCluster[] = useMemo(
    () => data?.propertyMap?.clusters || [],
    [data?.propertyMap?.clusters]
  )

  useEffect(() => {
    _onLoadingChange(loading)
  }, [_onLoadingChange, loading])

  useEffect(() => {
    refetch()
  }, [refreshCount, refetch])

  return (
    <>
      <Source
        id="properties"
        geoJsonSource={{
          type: 'geojson',
          cluster: true,
          clusterMaxZoom: 25,
          clusterRadius: 35,
          generateId: true,
          data: {
            type: 'FeatureCollection',
            features: points?.map(({ id, latitude, longitude }) => {
              return {
                type: 'Feature',
                properties: {
                  dataId: id,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [longitude, latitude],
                },
              }
            }),
          },
        }}
      />
      {/* Property Layers. */}
      <Layer
        id="property-point-single"
        sourceId="properties"
        type="circle"
        filter={['!', ['has', 'point_count']]}
        paint={{
          'circle-radius': 8,
          'circle-color': 'yellow',
          'circle-opacity': 1,
          'circle-stroke-color': '#ffffff',
        }}
      />
      <Layer
        id="property-point-cluster"
        sourceId="properties"
        type="circle"
        filter={['has', 'point_count']}
        paint={{
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            2,
            13,
            10,
            15,
            99999,
            20,
          ],
          'circle-color': 'red',
          'circle-opacity': 1,
          'circle-stroke-color': '#ffffff',
        }}
      />
      <Layer
        id="property-point-cluster-count"
        sourceId="properties"
        type="symbol"
        filter={['has', 'point_count']}
        layout={{
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        }}
        paint={{
          'text-color': '#FFFFFF',
        }}
      />
      {/* Property cluster layer from server */}
      <Layer
        id="property-cluster-point"
        type="circle"
        paint={{
          'circle-radius': [
            'step',
            ['get', 'totalCount'],
            ...CLUSTER_SIZE_STEPS,
          ],
          'circle-color': 'blue',
          'circle-opacity': 1,
          'circle-stroke-color': '#ffffff',
        }}
      >
        {clusters?.map(({ latitude, longitude, totalCount, id }) => (
          <Feature
            key={id}
            properties={{
              totalCount,
              dataId: id,
              serverCluster: true,
              source: 'properties',
            }}
            onClick={() => {
              _getMap().easeTo({
                center: { lat: latitude, lng: longitude },
                zoom: _getMap().getZoom() + 2,
              })
            }}
            coordinates={[longitude, latitude]}
          />
        ))}
      </Layer>
      <Layer
        id="property-cluster-point-count"
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'text-field': ['get', 'totalCount'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        }}
        paint={{
          'text-color': '#FFFFFF',
        }}
      >
        {clusters?.map(({ latitude, longitude, totalCount, id }) => (
          <Feature
            key={id}
            properties={{ totalCount }}
            onClick={() => {
              _getMap().easeTo({
                center: { lat: latitude, lng: longitude },
                zoom: _getMap().getZoom() + 2,
              })
            }}
            coordinates={[longitude, latitude]}
          />
        ))}
      </Layer>
    </>
  )
}

export default SourceProperty
