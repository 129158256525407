import { gql } from '@apollo/client'

export const GET = gql`
  query GetTicketEventLog($id: ID!) {
    ticketEventLog(id: $id) {
      ... on TicketEventDef {
        id
        modifiedData
        ticket {
          id
          ticketType {
            id
            name
            isContainerLevel
          }
          property {
            id
            location {
              name
            }
          }
          container {
            id
            containerId
            location {
              name
            }
          }
          location {
            name
          }
          assignee {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
