import React, { ReactNode } from 'react'
import { Table, TableColumnProps } from 'antd'
import moment from 'moment'
import { T } from '@transifex/react'

import { useGetAll } from './mock'

type RecordType = {
  date: string
  requestedBy: string
  changedBy: string
  note: ReactNode
}

const columns: TableColumnProps<RecordType>[] = [
  {
    dataIndex: 'date',
    title: <T _str="Date" />,
  },
  {
    dataIndex: 'requestedBy',
    title: <T _str="Requested by" />,
  },
  {
    dataIndex: 'changedBy',
    title: <T _str="Changed by" />,
  },
  {
    dataIndex: 'note',
    title: <T _str="Note" />,
  },
]

const ChangesHistory = () => {
  const { data: { data } = {}, loading } = useGetAll({ first: 10, offset: 0 })

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={
        data?.map(({ changedBy, date, note, requestedBy }) => ({
          changedBy: changedBy.fullName,
          date: moment(date).format('YYYY-MM-DD HH:mm'),
          note,
          requestedBy: requestedBy.fullName,
        })) || []
      }
      size="small"
    />
  )
}

export default ChangesHistory
