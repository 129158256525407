import React from 'react'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { EventLogTableMode, useEventLogTable } from './table'
import { FiltersProiver, FilterByDateRange } from './filters.elements'
import { TFilters } from './filters.context'
import { ColumnKey } from './table/ui'
import { LayoutTableWithHideableFilters } from '../../../layouts'

type EventLogTableIntegrationProps = {
  mode: EventLogTableMode
  initialFilters?: TFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
  id: string
}

const EventLogTableIntegration = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  id,
  mode,
}: EventLogTableIntegrationProps) => {
  const {
    jsx: { table, columnsFilter, search },
  } = useEventLogTable({
    mode,
    initialFilters,
    tableColumnsTreeFilterAndOrderProps,
    id,
  })
  return (
    <LayoutTableWithHideableFilters
      layoutOffset={220}
      jsx={{
        table,
        columnsFilter,
        search,
        filters: [],
        dateFilter: <FilterByDateRange />,
      }}
    />
  )
}

const EventLogTable = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  id,
  mode,
}: EventLogTableIntegrationProps) => {
  return (
    <FiltersProiver>
      <EventLogTableIntegration
        mode={mode}
        tableColumnsTreeFilterAndOrderProps={
          tableColumnsTreeFilterAndOrderProps
        }
        initialFilters={initialFilters}
        id={id}
      />
    </FiltersProiver>
  )
}

export default EventLogTable
