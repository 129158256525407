import { gql } from '@apollo/client'

export type GetCallResponseEdgeNode = {
  id: string
  scheduledDay: string
  vehicle: {
    name: string
  }
  driver: {
    user: {
      firstName: string
      lastName: string
    }
  }
}

export type GetCallResponse = {
  allRoutes: {
    edges: {
      node: GetCallResponseEdgeNode
    }[]
  }
}

export type GetCallVariables = {
  activeProjects?: string
  routeSchemes?: string[]
  search?: string
  first?: number
  offset?: number
}

export const GET_CALL = gql`
  query Component_Select_Route_GetAllRoutes(
    $activeProjects: String
    $search: String
    $first: Int
    $offset: Int
    $ids: [ID]
    $routeSchemes: [ID]
  ) {
    allRoutes(
      activeProjects: $activeProjects
      search: $search
      first: $first
      offset: $offset
      ids: $ids
      routeSchemes: $routeSchemes
    ) {
      edges {
        node {
          id
          scheduledDay
          vehicle {
            id
            name
          }
          driver {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
