import { gql } from '@apollo/client'
import { TMetaValuesIcons, TMetaValuesInputTypes } from '../../../api/consts'

export type GetMetaTypesResponse = {
  allCompanyMetaTypes: {
    edges: {
      node: {
        id: string
        name: string
        label: string
        inputType: TMetaValuesInputTypes
        options: string[]
        helpText: string
        icon: TMetaValuesIcons
        userCanEdit: boolean
      }
    }[]
  }
}

export const GET_META_TYPES = gql`
  query GetMetaTypes($entity: String) {
    allCompanyMetaTypes(entity: $entity) {
      edges {
        node {
          id
          name
          label
          inputType
          options
          helpText
          icon
          userCanEdit
        }
      }
    }
  }
`
