import { mockPriorityTypeLabelMap } from './consts'

export type TPriorityType = `low` | `medium` | `high`

export type MockPriorityType = {
  value: TPriorityType
}

export const API_MOCK_PRIORITY_TYPE_DATA: MockPriorityType[] = (
  Object.keys(mockPriorityTypeLabelMap) as TPriorityType[]
).map((k) => ({ value: k }))
