import React, { useEffect } from 'react'
import { T } from '@transifex/react'
import { useQuery } from '@apollo/client'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { Button, Tag } from 'antd'
// import { snakeCase } from 'lodash'
import { MessageFilled } from '@ant-design/icons'
import styled from 'styled-components'
import moment from 'moment'
import { DataCell, useTableUI, ColumnKey, SortKey } from './ui'
import { useFiltersContext, TFilters } from '../filters.context'
import { DebouncedSearch } from '../../../../ui'
import {
  useSavedActiveProjects,
  useTablePaginationAndSorterAPIHelper,
} from '../../../../hooks'
import {
  GET_ALL_CONTAINER_GROUP_LOGS,
  GET_ALL_LOGS,
  GET_ALL_PROPERTY_GROUP_LOGS,
  GET_CONTAINER_LOGS,
} from './api'
import {
  eventTicketEventEventTypeChoicesLabelMap,
  TEventTicketEventEventTypeChoices,
} from '../../../../api/consts'
import DetailView from '../../../../components/main/routes/customer-management/components/EventLog/DetailView'

const { useDebouncedSearchSimple } = DebouncedSearch

const DividerOveride = styled.div`
  .ant-divider {
    display: none;
  }
`

export type EventLogTableMode =
  | `propertyGroup`
  | `containerGroup`
  | `property`
  | `container`

const columnKeyMap: Record<ColumnKey, string> = {
  category: 'category',
  type: 'type',
  ticket: 'ticket',
  container: 'container',
  details: 'details',
  triggeredTime: 'triggeredTime',
}

const getEventType = (
  type: string,
  receiverPropertyUser: $TSFixMe,
  eventType: string
) => {
  if (type === 'TicketEventDef') {
    return eventTicketEventEventTypeChoicesLabelMap[
      eventType as TEventTicketEventEventTypeChoices
    ]
  }

  if (type === 'TicketCommentDef') {
    return <T _str="Internal Note" />
  }

  if (type === 'TicketMessageDef') {
    return receiverPropertyUser ? <T _str="Sent" /> : <T _str="Recieved" />
  }

  return <T _str="Created" />
}

const renderIconFn = (type: $TSFixMe, name: string) =>
  type === 'TicketEventDef' ? (
    <Tag
      color="cyan"
      style={{
        padding: '0px 2px',
        margin: 0,
        fontWeight: 'bold',
        marginLeft: 11,
      }}
    >
      {name?.slice(0, 2)}
    </Tag>
  ) : (
    <Button
      style={{
        marginLeft: 8,
        background: '#F38804',
        border: 'none',
        width: 20,
        height: 20,
      }}
      icon={<MessageFilled style={{ color: 'white', fontSize: 11 }} />}
      size="small"
    />
  )

const formatData = (node: $TSFixMe): DataCell => {
  return {
    _id: node.id,
    // eslint-disable-next-line no-underscore-dangle
    category: renderIconFn(node.__typename, node.ticket?.ticketType?.name),
    type: getEventType(
      // eslint-disable-next-line no-underscore-dangle
      node.__typename,
      node?.recieverPropertyUser,
      node?.eventType
    ),
    ticket: {
      linkProps: {
        to: ({ routingMap }) =>
          routingMap.app.tickets['/:id'](node?.ticket?.id)._,
        children: `${node?.ticket?.ticketType?.name} | ${
          node?.ticket?.container?.location?.name ||
          node?.ticket?.property?.location?.name
        }`,
      },
    },
    container: {
      linkProps: {
        to: ({ routingMap }) =>
          routingMap.app['asset-management'].containers[':containerId'](
            node?.ticket?.container?.id
          )._,
        children: node?.ticket?.container?.containerId,
      },
    },
    details: (
      <DividerOveride>
        <DetailView
          id={node.id}
          /* eslint-disable-next-line no-underscore-dangle */
          type={node.__typename}
          eventType={node?.eventType}
          compact
        />
      </DividerOveride>
    ),
    triggeredTime: moment(node.createdAt).format('YYYY-MM-DD HH:mm'),
  }
}

type useEventLogTableProps = {
  mode: EventLogTableMode
  initialFilters?: TFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
  id: string
}

export const useEventLogTable = ({
  mode,
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  id,
}: useEventLogTableProps) => {
  const [{ dateRange }, set] = useFiltersContext()
  const { search, jsx: jsxSearch } = useDebouncedSearchSimple()

  const savedActiveProjects = useSavedActiveProjects()

  useEffect(() => {
    if (initialFilters) {
      set(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters])

  /* PAGINATION AND SORT */
  const {
    onTableChangeHandler,
    paginationVars,
    state: { pageSize, page, orderBy, order },
  } = useTablePaginationAndSorterAPIHelper<DataCell>()

  const variables = {
    ...paginationVars,
    search,
    activeProjects: savedActiveProjects,
    from: dateRange[0].toISOString(),
    to: dateRange[1].toISOString(),
    pageSize: paginationVars.first,
    orderBy: columnKeyMap[orderBy as ColumnKey],
    id: id || null,
    // eslint-disable-next-line no-nested-ternary
    order: order ? (order === 'ascend' ? 'asc' : 'desc') : undefined,
  }

  const { data, loading } = useQuery(GET_ALL_LOGS, {
    variables,
    skip: mode !== 'property',
  })

  const { data: propertyGroupData, loading: propertyGroupLoading } = useQuery(
    GET_ALL_PROPERTY_GROUP_LOGS,
    {
      variables,
      skip: mode !== 'propertyGroup',
    }
  )

  const { data: containerGroupData, loading: containerGroupLoading } = useQuery(
    GET_ALL_CONTAINER_GROUP_LOGS,
    {
      variables,
      skip: mode !== 'containerGroup',
    }
  )

  const { data: containerData, loading: containerLoading } = useQuery(
    GET_CONTAINER_LOGS,
    {
      variables,
      skip: mode !== 'container',
    }
  )

  const { table, toggler } = useTableUI({
    loading:
      loading ||
      propertyGroupLoading ||
      containerGroupLoading ||
      containerLoading,
    onChange: onTableChangeHandler,
    tableColumnsTreeFilterAndOrderProps,
    dataSource:
      data?.property?.eventLog
        ?.filter(
          // eslint-disable-next-line no-underscore-dangle
          (e: { __typename: string }) => !(e.__typename === 'PickUpOrderType')
        )
        .map((e: $TSFixMe) => formatData({ ...e })) ||
      propertyGroupData?.propertyGroup?.eventLog
        ?.filter(
          // eslint-disable-next-line no-underscore-dangle
          (e: { __typename: string }) => !(e.__typename === 'PickUpOrderType')
        )
        .map((e: $TSFixMe) => formatData({ ...e })) ||
      containerGroupData?.containerGroup?.eventLog
        ?.filter(
          // eslint-disable-next-line no-underscore-dangle
          (e: { __typename: string }) => !(e.__typename === 'PickUpOrderType')
        )
        .map((e: $TSFixMe) => formatData({ ...e })) ||
      containerData?.container?.activeAgreement?.eventLog
        ?.filter(
          // eslint-disable-next-line no-underscore-dangle
          (e: { __typename: string }) => !(e.__typename === 'PickUpOrderType')
        )
        .map((e: $TSFixMe) => formatData({ ...e })) ||
      undefined,
    pagination: {
      pageSize,
      current: page,
      total:
        data?.property.eventLog?.length ||
        propertyGroupData?.propertyGroup.eventLog?.length ||
        containerGroupData?.containerGroup.eventLog?.length,
      style: {
        padding: '0 1rem',
      },
      showSizeChanger: true,
      hideOnSinglePage: true,
    },
    scroll: {
      y: '100%',
      x: 1000,
    },
    extended: {},
    sorting: {
      sortField: orderBy as ColumnKey,
      sortOrder: order as SortKey,
      fields: [],
    },
    on: {
      view: () => {},
      edit: () => {},
    },
  })

  return {
    jsx: {
      columnsFilter: toggler,
      search: jsxSearch,
      table,
    },
  }
}
