import { gql } from '@apollo/client'

/* Fragments */
export const FrgRoute = gql`
  fragment FrgRoute on RouteType {
    id
    stopsCount
    completedStopsCount
    totalDistance
    totalDuration
    scheduledDay
    startedAt
    completedAt
    startTime
    endTime
    project {
      id
      name
    }
    locked
    wasteFraction {
      id
      wasteCategory
      name
      wasteSubstance
    }
    vehicle {
      id
      name
    }
    driver {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
    stops {
      edges {
        node {
          id
          scheduledDriverArrival
          orderType
          completedAt
          stopNumber
          status
          orderId
          container {
            id
            containerId
            containerType {
              id
              name
            }
            location {
              id
              latitude
              longitude
              name
            }
            measurement {
              id
              fillPercentage
            }
            wasteFraction {
              id
              name
              wasteSubstance
              wasteCategory
            }
          }
          depot {
            id
            name
            depotType
            location {
              id
              latitude
              longitude
              placeId
              name
            }
          }
        }
      }
    }
    routeScheme {
      id
      promptDriverForWeightOnContainer
      promptDriverForWeightOnWasteStation
    }

    vehicleTrailer {
      id
      wasteHolds {
        edges {
          node {
            id
            wasteFraction {
              id
              name
            }
          }
        }
      }
    }
  }
`

/* Queries */
export const GET_ALL_ROUTES = gql`
  query GetAllRoutes(
    $search: String
    $first: Int
    $name: String
    $orderBy: String
    $order: String
    $offset: Int
    $startDate: Date
    $endDate: Date
    $routeSettingsId_Isnull: Boolean
    $routeSettings: ID
    $activeProjects: String
    $active: Boolean
    $Type: String
    $filterByDay: Boolean
    $drivers: String
    $vehicles: String
    $wasteFractions: String
    $containers: [ID]
    $routeSchemes: [ID]
    $completedAt_Isnull: Boolean
  ) {
    allRoutes(
      search: $search
      first: $first
      name: $name
      orderBy: $orderBy
      order: $order
      offset: $offset
      scheduledDay_Gte: $startDate
      scheduledDay_Lte: $endDate
      routeSettingsId_Isnull: $routeSettingsId_Isnull
      routeSettings: $routeSettings
      activeProjects: $activeProjects
      active: $active
      Type: $Type
      filterByDay: $filterByDay
      drivers: $drivers
      vehicles: $vehicles
      wasteFractions: $wasteFractions
      containers: $containers
      routeSchemes: $routeSchemes
      completedAt_Isnull: $completedAt_Isnull
    ) {
      totalCount
      edges {
        node {
          id
          stopsCount
          completedStopsCount
          totalDistance
          totalDuration
          scheduledDay
          startedAt
          completedAt
          startTime
          endTime
          locked
          stopsCount
          completedStopsCount
          status
          vehicleTrailer {
            id
            wasteHolds {
              edges {
                node {
                  id
                  wasteFraction {
                    id
                    name
                  }
                }
              }
            }
          }
          project {
            id
            name
          }
          vehicle {
            id
            name
          }
          driver {
            id
            user {
              firstName
              lastName
            }
          }
          wasteFraction {
            id
            wasteCategory
            name
            wasteSubstance
          }
        }
      }
    }
  }
`

/* Mutations */
export const UPDATE_ROUTE_STOP = gql`
  ${FrgRoute}
  mutation UpdateRouteStopGlobal(
    $routeId: ID!
    $fillLevel: Float
    $delete: Boolean!
    $orderIds: [String]!
    $reset: Boolean!
    $weight: Float
  ) {
    routeStopsUpdate(
      routeId: $routeId
      orderIds: $orderIds
      delete: $delete
      weight: $weight
      fillLevel: $fillLevel
      reset: $reset
    ) {
      route {
        ...FrgRoute
      }
    }
  }
`
