import React from 'react'
import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'

import { Opt } from './types'
import { getOptsFromValueMap } from './utils'
import { containerStatusLabelMap } from '../../api/consts/container'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectContainerStatusProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps, 'options' | 'loading'>

const options = getOptsFromValueMap(containerStatusLabelMap)

export const SelectContainerStatus = ({
  render,
  ...selectProps
}: SelectContainerStatusProps) => {
  return (
    render?.({
      options,
      loading: false,
    }) || (
      <Select
        placeholder={<T _str="Status" />}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}
