import React, { useEffect } from 'react'
import { Form } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { usePrevious, useRefCopy } from '../../hooks'

type TUseResetOnFieldChangeArgs = {
  field: NamePath
  dependants: NamePath[]
  /** the value to set when reset.
   * Default to  null
   * */
  resetValue?: $TSFixMe
  /** If namepath is array then concat it like this name.join('.') */
  resetValueMap?: Record<string, $TSFixMe>
}

const NULLED_VALUES = [undefined, '']

export const useResetOnFieldChange = ({
  field,
  dependants,
  resetValue,
  resetValueMap,
}: TUseResetOnFieldChangeArgs) => {
  const value = Form.useWatch(field)
  const form = Form.useFormInstance()
  const fieldRef = useRefCopy(field)
  const dependantsRef = useRefCopy(dependants)
  const resetValueRef = useRefCopy(resetValue)
  const resetValueMapRef = useRefCopy(resetValueMap)
  const previousValue = usePrevious(value)
  const prevValueRef = useRefCopy(previousValue)

  useEffect(() => {
    if (
      form.isFieldTouched(fieldRef()) &&
      !NULLED_VALUES.includes(prevValueRef())
    ) {
      const dependantsNow = dependantsRef()
      const resetValueNow = resetValueRef()
      const resetValueMapNow = resetValueMapRef()
      if (dependantsNow && dependantsNow.length) {
        form.setFields(
          dependantsNow.map((dependant) => {
            const dependantKey = Array.isArray(dependant)
              ? dependant.join('.')
              : dependant
            return {
              name: dependant,
              value: resetValueMapNow?.[dependantKey] || resetValueNow,
            }
          })
        )
      }
    }
    // All other values other then value are updated through refs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
}

export const ResetOnFieldChange = (args: TUseResetOnFieldChangeArgs) => {
  useResetOnFieldChange(args)

  return <></>
}
