import { pathJoin } from '../../../../utils'

export const createAssetManagementRouteMap = (prefix: string) => ({
  'container-types': pathJoin(prefix)('container-types').chain,
  'waste-fractions': pathJoin(prefix)('waste-fractions').chain,
  'sensor-measurement-types': pathJoin(prefix)('sensor-measurement-types')
    .chain,
  'container-meta-types': pathJoin(prefix)('container-meta-types').chain,
  'container-imports': pathJoin(prefix)('container-imports').chain,
  'container-weight-imports': pathJoin(prefix)('container-weight-imports')
    .chain,
})
