import React from 'react'

import { useCustomerContext } from '../../../customer.context'

import UI from './ui'

const TimelineUpcoming = () => {
  const { id: customerId } = useCustomerContext()

  return (
    <UI
      dataSource={[]}
      loading={false}
      getCalendarLink={({ routingMap }) =>
        routingMap.app['customer-management'].property['/:id'](customerId)
          .calendar._
      }
    />
  )
}

export default TimelineUpcoming
