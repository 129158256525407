import React, { useState, memo, lazy, Suspense } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Col, Empty, Row, Space, Skeleton } from 'antd'
import moment, { Moment } from 'moment'
import { T } from '@transifex/react'
import Card from '@wastehero/storybook/lib/components/Card'
import Area, {
  AreaProps,
} from '@wastehero/storybook/lib/components/Charts/Components/Area'
import isEqual from 'react-fast-compare'

import { CardContainer } from './styles'
import ExpandSkeleton from './Skeleton'
import { IExpandContentProps } from './types'

import { getSimpleTheme as Surface3dTheme } from '../../../../../../../../../../ui/Surface3d/themes'

const Surface3d = lazy(
  () => import('../../../../../../../../../../ui/Surface3d')
)

type DataObj = {
  id: string
  date: string
  dateRaw: Moment
  key: string
  value: number
}

const ExpandContent = ({
  query,
  queryVariablesFormatter,
  queryVariables,
  measurementQuery,
}: IExpandContentProps): JSX.Element => {
  const [selectedFillLevelMeasurementId, setSelectedFillLevelMeasurementId] =
    useState(undefined)

  const [
    loadMeasurementQuery,
    { loading: loadingMeasurementQuery, data: dataMeasurementQuery },
  ] = useLazyQuery(measurementQuery, {
    variables: {
      id: selectedFillLevelMeasurementId,
    },
  })

  const { loading, data } = useQuery(query, {
    variables: queryVariablesFormatter
      ? queryVariablesFormatter(queryVariables)
      : queryVariables,
  })

  let containerPlotData = []

  if (data?.container.measurement?.createdAt)
    containerPlotData = data?.container?.deviceToContainerSet?.edges.map(
      (node: $TSFixMe) =>
        node?.node?.filllevelmeasurementSet?.edges
          .filter((measurement: $TSFixMe) => !!measurement.node.createdAt)
          .map((measurement: $TSFixMe) => ({
            key: 'Fill Level',
            value: measurement.node.fillPercentage,
            date: moment(measurement.node.createdAt).format('YYYY-MM-DD HH:mm'),
            dateRaw: moment(measurement.node.createdAt),
            id: measurement.node.id,
          }))
    )

  const map3DData = (graphData: number[]) => {
    const zData = []
    let pushCount = 0
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Math.sqrt(graphData.length) + 2; i++) {
      const yValues = []
      if (i === 0 || i === Math.sqrt(graphData.length) + 1) {
        // eslint-disable-next-line no-plusplus
        for (let z = 0; z < Math.sqrt(graphData.length) + 2; z++) {
          yValues.push(0)
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < Math.sqrt(graphData.length) + 2; j++) {
          if (j === 0 || j === Math.sqrt(graphData.length) + 1) {
            yValues.push(0)
          } else {
            yValues.push(graphData[pushCount])
            // eslint-disable-next-line no-plusplus
            pushCount++
          }
        }
      }
      zData.push(yValues)
    }

    return zData
  }

  const areaChartDataRaw = containerPlotData.flat()
  const areaChartDataSorted = areaChartDataRaw.sort(function sortData(
    { dateRaw: one }: DataObj,
    { dateRaw: two }: DataObj
  ) {
    return one.diff(two)
  })

  const config: AreaProps = {
    data: areaChartDataSorted,
    xField: 'date',
    yField: 'value',
    seriesField: 'key',
    onReady: (plot: $TSFixMe) => {
      plot.chart.on('plot:click', (evt: $TSFixMe) => {
        const { x, y } = evt
        const toolTipItems = plot.chart.getTooltipItems({ x, y })
        if (toolTipItems.length > 0) {
          const { id } = toolTipItems[0].data
          setSelectedFillLevelMeasurementId(id)
          loadMeasurementQuery()
        }
      })
    },
    yAxis: {
      min: 0,
      max: 100,
      tickInterval: 20,
    },
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' }
    },
    height: 250,
  }

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <ExpandSkeleton loading={loading}>
          <Row>
            <Col span={19}>
              {data && (
                <CardContainer>
                  <Card
                    style={{ width: '100%' }}
                    title={<T _str="Fill level" />}
                    bordered={false}
                    size="small"
                  >
                    <div style={{ padding: '1rem 1rem 2rem 1rem' }}>
                      {containerPlotData && containerPlotData.length > 0 ? (
                        <Area {...config} />
                      ) : (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <T _str="No history for selected container." />
                          }
                        />
                      )}
                    </div>
                  </Card>
                </CardContainer>
              )}
            </Col>
            <Col span={5}>
              {data && (
                <CardContainer>
                  <Card
                    style={{ width: '100%' }}
                    title={<T _str="3D Graph" />}
                    bordered={false}
                    size="small"
                  >
                    {loadingMeasurementQuery ? (
                      <Skeleton.Button
                        style={{ width: '100%', height: `250px` }}
                        active
                        className="surface3d-skeleton"
                      />
                    ) : (
                      <Suspense
                        fallback={
                          <Skeleton.Button
                            style={{ width: '100%', height: `250px` }}
                            active
                            className="surface3d-skeleton"
                          />
                        }
                      >
                        <Surface3d
                          plotProps={Surface3dTheme({
                            data: map3DData(
                              dataMeasurementQuery?.fillLevelMeasurements
                                ?.rawMeasurementsPercentage ||
                                data?.container?.measurement
                                  ?.rawMeasurementsPercentage ||
                                []
                            ),
                            height: 250,
                          })}
                        />
                      </Suspense>
                    )}
                  </Card>
                </CardContainer>
              )}
            </Col>
          </Row>
        </ExpandSkeleton>
      </Space>
    </>
  )
}

export default memo(
  ExpandContent,
  ({ queryVariables: newVars }, { queryVariables: oldVars }) =>
    isEqual(newVars, oldVars)
)
