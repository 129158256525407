import React from 'react'
import { useQuery } from '@apollo/client'

import UI from './ui'
import { GET_PROPERY } from './api'

const Header = ({ id }: { id: string }) => {
  const { data, loading } = useQuery(GET_PROPERY, {
    variables: {
      id,
    },
  })

  const { location } = data?.property || {}

  return (
    <UI
      loading={loading}
      data={{
        address: location?.name,
      }}
    />
  )
}

export default Header
