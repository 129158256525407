export const API_MOCK_USER_DATA = [
  { id: 1, firstName: 'Nadège', lastName: 'Cécilia' },
  { id: 2, firstName: 'Athéna', lastName: 'Edmée' },
  { id: 3, firstName: 'Cécile', lastName: 'Garçon' },
  { id: 4, firstName: 'Rébecca', lastName: 'Solène' },
  { id: 5, firstName: 'Méline', lastName: 'Esbjörn' },
  { id: 6, firstName: 'Gaïa', lastName: 'Laurène' },
  { id: 7, firstName: 'Jú', lastName: 'Illustrée' },
  { id: 8, firstName: 'Maïwenn', lastName: 'Bérénice' },
  { id: 9, firstName: 'Pélagie', lastName: 'Adélaïde' },
  { id: 10, firstName: 'Cécile', lastName: 'Adélaïde' },
  { id: 11, firstName: 'Céline', lastName: 'Mélanie' },
  { id: 12, firstName: 'Marie-josée', lastName: 'Gisèle' },
  { id: 13, firstName: 'Mahélie', lastName: 'Valérie' },
  { id: 14, firstName: 'Kuí', lastName: 'Måns' },
  { id: 15, firstName: 'Loïca', lastName: 'Åsa' },
  { id: 16, firstName: 'Maëlle', lastName: 'Dà' },
  { id: 17, firstName: 'Océanne', lastName: 'Chloé' },
  { id: 18, firstName: 'Garçon', lastName: 'Clémentine' },
  { id: 19, firstName: 'Intéressant', lastName: 'Frédérique' },
  { id: 20, firstName: 'Eléa', lastName: 'Océane' },
  { id: 21, firstName: 'Pélagie', lastName: 'Cunégonde' },
  { id: 22, firstName: 'Josée', lastName: 'Françoise' },
  { id: 23, firstName: 'Wá', lastName: 'Léa' },
  { id: 24, firstName: 'Eugénie', lastName: 'Adélaïde' },
  { id: 25, firstName: 'Cécilia', lastName: 'Åsa' },
  { id: 26, firstName: 'Eugénie', lastName: 'Eliès' },
  { id: 27, firstName: 'Pénélope', lastName: 'Adèle' },
  { id: 28, firstName: 'Salomé', lastName: 'Séverine' },
  { id: 29, firstName: 'Anaïs', lastName: 'Noëlla' },
  { id: 30, firstName: 'Zhì', lastName: 'Maïlis' },
  { id: 31, firstName: 'Kuí', lastName: 'Clémence' },
  { id: 32, firstName: 'Mårten', lastName: 'Rachèle' },
  { id: 33, firstName: 'Åke', lastName: 'Léane' },
  { id: 34, firstName: 'Yénora', lastName: 'Eliès' },
  { id: 35, firstName: 'Adèle', lastName: 'Véronique' },
  { id: 36, firstName: 'Lèi', lastName: 'Véronique' },
  { id: 37, firstName: 'Eléa', lastName: 'Michèle' },
  { id: 38, firstName: 'Maéna', lastName: 'Åke' },
  { id: 39, firstName: 'Mélinda', lastName: 'Eloïse' },
  { id: 40, firstName: 'Lèi', lastName: 'Véronique' },
  { id: 41, firstName: 'Cécile', lastName: 'Sòng' },
  { id: 42, firstName: 'Maëly', lastName: 'Anaïs' },
  { id: 43, firstName: 'Yáo', lastName: 'Adélaïde' },
  { id: 44, firstName: 'Clélia', lastName: 'Bérénice' },
  { id: 45, firstName: 'Miléna', lastName: 'Kù' },
  { id: 46, firstName: 'Béatrice', lastName: 'Maïly' },
  { id: 47, firstName: 'Néhémie', lastName: 'Kallisté' },
  { id: 48, firstName: 'Lucrèce', lastName: 'Géraldine' },
  { id: 49, firstName: 'Stéphanie', lastName: 'Eliès' },
  { id: 50, firstName: 'Félicie', lastName: 'Dù' },
  { id: 51, firstName: 'Örjan', lastName: 'Aimée' },
  { id: 52, firstName: 'Béatrice', lastName: 'Maëlys' },
  { id: 53, firstName: 'Lài', lastName: 'Mélodie' },
  { id: 54, firstName: 'Bénédicte', lastName: 'Chloé' },
  { id: 55, firstName: 'Geneviève', lastName: 'Östen' },
  { id: 56, firstName: 'Loïc', lastName: 'Solène' },
  { id: 57, firstName: 'Maëlla', lastName: 'Desirée' },
  { id: 58, firstName: 'Estève', lastName: 'Stévina' },
  { id: 59, firstName: 'Yóu', lastName: 'Adélie' },
  { id: 60, firstName: 'Pélagie', lastName: 'Annotée' },
  { id: 61, firstName: 'Eugénie', lastName: 'Dorothée' },
  { id: 62, firstName: 'Gaétane', lastName: 'Nélie' },
  { id: 63, firstName: 'Vénus', lastName: 'Gisèle' },
  { id: 64, firstName: 'Estève', lastName: 'Lài' },
  { id: 65, firstName: 'Gaétane', lastName: 'Vérane' },
  { id: 66, firstName: 'Michèle', lastName: 'Françoise' },
  { id: 67, firstName: 'Clélia', lastName: 'Táng' },
  { id: 68, firstName: 'Nélie', lastName: 'Örjan' },
  { id: 69, firstName: 'Thérèse', lastName: 'Daphnée' },
  { id: 70, firstName: 'Maïté', lastName: 'Måns' },
  { id: 71, firstName: 'Åsa', lastName: 'Dà' },
  { id: 72, firstName: 'Marie-josée', lastName: 'Eugénie' },
  { id: 73, firstName: 'Cécile', lastName: 'Torbjörn' },
  { id: 74, firstName: 'Esbjörn', lastName: 'Styrbjörn' },
  { id: 75, firstName: 'Almérinda', lastName: 'Cléa' },
  { id: 76, firstName: 'Kallisté', lastName: 'Ruò' },
  { id: 77, firstName: 'Marie-ève', lastName: 'Clélia' },
  { id: 78, firstName: 'Lóng', lastName: 'Camélia' },
  { id: 79, firstName: 'Thérèse', lastName: 'Liè' },
  { id: 80, firstName: 'Annotée', lastName: 'Laurélie' },
  { id: 81, firstName: 'Séverine', lastName: 'Mylène' },
  { id: 82, firstName: 'Uò', lastName: 'Eloïse' },
  { id: 83, firstName: 'Maëly', lastName: 'Maéna' },
  { id: 84, firstName: 'Gösta', lastName: 'Maëlle' },
  { id: 85, firstName: 'Audréanne', lastName: 'Léonore' },
  { id: 86, firstName: 'Léandre', lastName: 'Måns' },
  { id: 87, firstName: 'Léane', lastName: 'Audréanne' },
  { id: 88, firstName: 'Personnalisée', lastName: 'Åke' },
  { id: 89, firstName: 'Eléa', lastName: 'Maëline' },
  { id: 90, firstName: 'Mélinda', lastName: 'Sòng' },
  { id: 91, firstName: 'Loïs', lastName: 'Réjane' },
  { id: 92, firstName: 'Zhì', lastName: 'Mà' },
  { id: 93, firstName: 'Inès', lastName: 'Marie-thérèse' },
  { id: 94, firstName: 'Médiamass', lastName: 'Dà' },
  { id: 95, firstName: 'Andréa', lastName: 'Lài' },
  { id: 96, firstName: 'Réjane', lastName: 'Cunégonde' },
  { id: 97, firstName: 'Méthode', lastName: 'Josée' },
  { id: 98, firstName: 'Aurélie', lastName: 'Yénora' },
  { id: 99, firstName: 'Almérinda', lastName: 'Laurène' },
  { id: 100, firstName: 'Léone', lastName: 'Gaëlle' },
  { id: 101, firstName: 'Angélique', lastName: 'Thérèse' },
  { id: 102, firstName: 'Gisèle', lastName: 'Sélène' },
  { id: 103, firstName: 'Stévina', lastName: 'Marie-françoise' },
  { id: 104, firstName: 'Maëlla', lastName: 'Thérèse' },
  { id: 105, firstName: 'Agnès', lastName: 'Loïc' },
  { id: 106, firstName: 'Eléa', lastName: 'Salomé' },
  { id: 107, firstName: 'Eléa', lastName: 'Stévina' },
  { id: 108, firstName: 'Véronique', lastName: 'Dorothée' },
  { id: 109, firstName: 'Andréanne', lastName: 'Marie-noël' },
  { id: 110, firstName: 'Pål', lastName: 'Céline' },
  { id: 111, firstName: 'Célia', lastName: 'Félicie' },
  { id: 112, firstName: 'Almérinda', lastName: 'Estève' },
  { id: 113, firstName: 'Séréna', lastName: 'Gaétane' },
  { id: 114, firstName: 'Thérèse', lastName: 'Léana' },
  { id: 115, firstName: 'Nadège', lastName: 'Håkan' },
  { id: 116, firstName: 'Mén', lastName: 'Vénus' },
  { id: 117, firstName: 'Michèle', lastName: 'Maï' },
  { id: 118, firstName: 'Liè', lastName: 'Adélaïde' },
  { id: 119, firstName: 'Géraldine', lastName: 'Célestine' },
  { id: 120, firstName: 'Céline', lastName: 'Torbjörn' },
  { id: 121, firstName: 'Nadège', lastName: 'Anaïs' },
  { id: 122, firstName: 'Clémentine', lastName: 'Maëline' },
  { id: 123, firstName: 'Loïc', lastName: 'Mélys' },
  { id: 124, firstName: 'Chloé', lastName: 'Erwéi' },
  { id: 125, firstName: 'Yóu', lastName: 'Lóng' },
  { id: 126, firstName: 'Joséphine', lastName: 'Gösta' },
  { id: 127, firstName: 'Angèle', lastName: 'Noëlla' },
  { id: 128, firstName: 'Réjane', lastName: 'Cloé' },
  { id: 129, firstName: 'Maëlla', lastName: 'Anaïs' },
  { id: 130, firstName: 'Edmée', lastName: 'Athéna' },
  { id: 131, firstName: 'Gérald', lastName: 'Pénélope' },
  { id: 132, firstName: 'Cléa', lastName: 'Intéressant' },
  { id: 133, firstName: 'Stévina', lastName: 'Maïlis' },
  { id: 134, firstName: 'Kù', lastName: 'Mà' },
  { id: 135, firstName: 'Kallisté', lastName: 'Östen' },
  { id: 136, firstName: 'Geneviève', lastName: 'Gösta' },
  { id: 137, firstName: 'Célia', lastName: 'Mårten' },
  { id: 138, firstName: 'Aimée', lastName: 'Ruì' },
  { id: 139, firstName: 'Félicie', lastName: 'Gaëlle' },
  { id: 140, firstName: 'Gérald', lastName: 'Crééz' },
  { id: 141, firstName: 'Garçon', lastName: 'Aurélie' },
  { id: 142, firstName: 'Intéressant', lastName: 'Danièle' },
  { id: 143, firstName: 'Östen', lastName: 'Vérane' },
  { id: 144, firstName: 'Östen', lastName: 'Mélina' },
  { id: 145, firstName: 'Athéna', lastName: 'Gwenaëlle' },
  { id: 146, firstName: 'Yú', lastName: 'Hélèna' },
  { id: 147, firstName: 'Marylène', lastName: 'Agnès' },
  { id: 148, firstName: 'Lài', lastName: 'Dà' },
  { id: 149, firstName: 'Clémence', lastName: 'Cléa' },
  { id: 150, firstName: 'Sélène', lastName: 'Médiamass' },
  { id: 151, firstName: 'Nélie', lastName: 'Naëlle' },
  { id: 152, firstName: 'Mégane', lastName: 'Maïly' },
  { id: 153, firstName: 'Gaïa', lastName: 'Bérengère' },
  { id: 154, firstName: 'Françoise', lastName: 'Adélie' },
  { id: 155, firstName: 'Léone', lastName: 'Judicaël' },
  { id: 156, firstName: 'Annotés', lastName: 'Valérie' },
  { id: 157, firstName: 'Estève', lastName: 'Clémentine' },
  { id: 158, firstName: 'Mélinda', lastName: 'Anaé' },
  { id: 159, firstName: 'Märta', lastName: 'Josée' },
  { id: 160, firstName: 'Océane', lastName: 'Thérèse' },
  { id: 161, firstName: 'Léone', lastName: 'Adèle' },
  { id: 162, firstName: 'Stéphanie', lastName: 'Görel' },
  { id: 163, firstName: 'Mà', lastName: 'Cléopatre' },
  { id: 164, firstName: 'Danièle', lastName: 'Lauréna' },
  { id: 165, firstName: 'Valérie', lastName: 'Léa' },
  { id: 166, firstName: 'Lorène', lastName: 'Irène' },
  { id: 167, firstName: 'Léandre', lastName: 'Yè' },
  { id: 168, firstName: 'Estée', lastName: 'Méryl' },
  { id: 169, firstName: 'Cléa', lastName: 'Cléa' },
  { id: 170, firstName: 'Athéna', lastName: 'Kù' },
  { id: 171, firstName: 'Célia', lastName: 'Danièle' },
  { id: 172, firstName: 'Françoise', lastName: 'Angèle' },
  { id: 173, firstName: 'Simplifiés', lastName: 'Inès' },
  { id: 174, firstName: 'Mélodie', lastName: 'Magdalène' },
  { id: 175, firstName: 'Erwéi', lastName: 'Joséphine' },
  { id: 176, firstName: 'Mélanie', lastName: 'Nadège' },
  { id: 177, firstName: 'Séverine', lastName: 'Mélissandre' },
  { id: 178, firstName: 'Chloé', lastName: 'Judicaël' },
  { id: 179, firstName: 'Marylène', lastName: 'Zoé' },
  { id: 180, firstName: 'Loïca', lastName: 'Inès' },
  { id: 181, firstName: 'Naéva', lastName: 'Tú' },
  { id: 182, firstName: 'Anaël', lastName: 'Naëlle' },
  { id: 183, firstName: 'Eléa', lastName: 'Audréanne' },
  { id: 184, firstName: 'Pélagie', lastName: 'Aurélie' },
  { id: 185, firstName: 'Eugénie', lastName: 'Lucrèce' },
  { id: 186, firstName: 'Sòng', lastName: 'Wá' },
  { id: 187, firstName: 'Céline', lastName: 'Géraldine' },
  { id: 188, firstName: 'Adélaïde', lastName: 'Méline' },
  { id: 189, firstName: 'Ruì', lastName: 'Kù' },
  { id: 190, firstName: 'Göran', lastName: 'Publicité' },
  { id: 191, firstName: 'Lóng', lastName: 'Michèle' },
  { id: 192, firstName: 'Mén', lastName: 'Séréna' },
  { id: 193, firstName: 'Garçon', lastName: 'Anaëlle' },
  { id: 194, firstName: 'Célestine', lastName: 'Méthode' },
  { id: 195, firstName: 'Pélagie', lastName: 'Danièle' },
  { id: 196, firstName: 'Michèle', lastName: 'Crééz' },
  { id: 197, firstName: 'Nadège', lastName: 'Mélodie' },
  { id: 198, firstName: 'Esbjörn', lastName: 'Björn' },
  { id: 199, firstName: 'Marie-ève', lastName: 'Danièle' },
  { id: 200, firstName: 'Eliès', lastName: 'Märta' },
].map(({ firstName, lastName, id }) => ({
  id: id.toString(),
  firstName,
  lastName,
  fullName: `${firstName} ${lastName}`,
}))
