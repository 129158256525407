import React from 'react'
import { T } from '@transifex/react'
import { LinkProps } from '../../reducers/routing/child-router-factory'

import { TGetLinkPropsFn, defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'

export const getLinkPropsVehicleNode: TGetLinkPropsFn =
  getLinkPropsMakeNullable(
    (
      /** Should be VehicleType!
       */
      value,
      { includeLabel } = defaultConfig
    ): LinkProps => ({
      to: ({ routingMap }) =>
        routingMap.app['fleet-management'].vehicles[':vehicleId'](value.id)._,
      children: includeLabel ? (
        <T _str="Vehicle: {name}" name={value.name} />
      ) : (
        value.name
      ),
    })
  )
