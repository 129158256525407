/* eslint-disable no-underscore-dangle */
import React from 'react'
import {
  DragDropContext,
  Droppable,
  DropResult,
  DroppableProvided,
} from 'react-beautiful-dnd'
import { Row, Col, Collapse } from 'antd'

import {
  DragAndDropMultipleVerticalListsSectionsProps,
  Item,
  ItemRender,
  ItemSource,
  Section,
  UIProps,
  OnDrop,
} from './types'

import { decodeDroppableId, encodeDroppableId } from './utils'

import { ListMemo } from './elements'
import { StlyedWrapper, classes } from './styles'

import { useParseSource } from './useParseSource'

export type {
  DragAndDropMultipleVerticalListsSectionsProps,
  Item,
  ItemRender,
  ItemSource,
  Section,
  UIProps,
  OnDrop,
}

const { Panel } = Collapse

export const DragAndDropMultipleVerticalListsSections = <
  TSection extends string,
  TList extends string,
  TItem extends $TSFixMe
>(
  props: DragAndDropMultipleVerticalListsSectionsProps<TSection, TList, TItem>
) => {
  const [nodes, setNodes] = useParseSource(props)
  const {
    ui: {
      // list: listStyles = {},
      // listItem: listItemStyles = {},
      section: sectionStyles = {},
      wrapper: wrapperStyles = {},
    } = {},
    sections,
    lists,
    renderItem,
    renderListTitle,
    disabled,
    on: { onDrop } = {},
    colors,
  } = props

  /* handlers */
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) return // it was not dropped.

    // we will update the internal state and then ping the parent that this action happeneded.
    const [sourceSection, sourceList] = decodeDroppableId(
      source.droppableId
    ) as [TSection, TList]
    const [destinationSection] = decodeDroppableId(destination.droppableId) as [
      TSection,
      TList
    ]
    const destinationList = sourceList

    const sourceNodeList = nodes?.[sourceSection]?.[sourceList] || []
    if (
      sourceList === destinationList &&
      sourceSection === destinationSection
    ) {
      // drag/drop happen in same list in section.
      // just need to update the index.
      const item = sourceNodeList[source.index]
      const sameList = [...sourceNodeList]
      // first remove it from the index;
      sameList.splice(source.index, 1)
      // now add it to new index;
      sameList.splice(destination.index, 0, item)

      const newNodesMap = {
        ...nodes,
        [sourceSection]: {
          ...nodes?.[sourceSection],
          [sourceList]: sameList,
        },
      }

      setNodes(newNodesMap)
      onDrop?.({
        destination: {
          list: sourceList,
          section: sourceSection,
        },
        item,
        sameList: true,
        source: {
          list: sourceList,
          section: sourceSection,
        },
      })

      return
    }
    const destinationNodeList =
      nodes?.[destinationSection]?.[destinationList] || []

    // remove item from source list.
    const item = sourceNodeList[source.index]
    const newSourceList = [...sourceNodeList]
    newSourceList.splice(source.index, 1)
    const newDestinationList = [...destinationNodeList]
    newDestinationList.splice(destination.index, 0, item)

    // update the new state;
    const newNodesMap = {
      ...nodes,
      [sourceSection]: {
        ...nodes?.[sourceSection],
        [sourceList]: newSourceList,
      },
      [destinationSection]: {
        ...nodes?.[destinationSection],
        [destinationList]: newDestinationList,
      },
    }
    setNodes(newNodesMap)
    onDrop?.({
      destination: {
        list: destinationList,
        section: destinationSection,
      },
      item,
      sameList: false,
      source: {
        list: sourceList,
        section: sourceSection,
      },
    })
  }

  const dndDisabled = disabled

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StlyedWrapper
        className={classes.wrapper}
        wrap={false}
        {...sectionStyles}
      >
        {sections.map(({ _id: sectionId, title: sectionTitle }) => (
          <Col
            xs={24 / sections.length}
            {...wrapperStyles}
            key={sectionId}
            className={classes.section}
          >
            <Row>
              <Col xs={24}>{sectionTitle} </Col>
              <Col xs={24}>
                {lists.map(({ _id: listId, title: listTitle }) => (
                  <Droppable
                    droppableId={encodeDroppableId(sectionId, listId)}
                    type={listId}
                  >
                    {(provided: DroppableProvided, { isDraggingOver }) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          background: isDraggingOver
                            ? colors[sectionId]
                            : 'white',
                        }}
                      >
                        <Collapse ghost>
                          <Panel
                            header={
                              renderListTitle?.({
                                _section: sectionId,
                                list: {
                                  _id: listId,
                                  title: listTitle,
                                },
                                _childs:
                                  nodes?.[sectionId]?.[listId]?.map(
                                    (v) => v._id
                                  ) || [],
                              }) || listTitle
                            }
                            key={listId}
                          >
                            <ListMemo
                              items={nodes?.[sectionId]?.[listId] || []}
                              renderItem={renderItem as $TSFixMe}
                              key={listId}
                              isDragDisabled={dndDisabled}
                            />
                            {provided.placeholder}
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  </Droppable>
                ))}
              </Col>
            </Row>
          </Col>
        ))}
      </StlyedWrapper>
    </DragDropContext>
  )
}

export { classes }
