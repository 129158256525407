/* eslint-disable no-underscore-dangle */
import React, { memo } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { ItemRender, Item, ListItemProps } from './types'

import { encodeDraggableId } from './utils'

export const ListItem = ({
  id,
  index,
  isDragDisabled,
  children,
}: ListItemProps) => (
  <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...(typeof children === 'function' ? {} : provided.dragHandleProps)}
      >
        {children?.(provided.dragHandleProps) || children}
      </div>
    )}
  </Draggable>
)

export type ListProps<
  TSection extends string,
  TList extends string,
  TItem extends $TSFixMe
> = {
  items: Item<TSection, TList, TItem>[]
  renderItem: ItemRender<TSection, TList, TItem>
}

export const List = <
  TSection extends string,
  TList extends string,
  TItem extends $TSFixMe
>({
  items,
  renderItem,
  isDragDisabled,
}: ListProps<TSection, TList, TItem> & { isDragDisabled?: boolean }) => (
  <>
    {items.map((item, index) => (
      <ListItem
        key={item._id}
        index={index}
        id={encodeDraggableId(item._id, item._section, item._list)}
        isDragDisabled={isDragDisabled}
      >
        {renderItem(item)}
      </ListItem>
    ))}
  </>
)

export const ListMemo = memo(List)
