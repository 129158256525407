import React from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row, Skeleton } from 'antd'
import { T } from '@transifex/react'

import {
  GET_PROPERTY,
  GetPropertyVariables,
  GetPropertyResponse,
} from '../../../api'

import { useCustomerContext } from '../../../customer.context'
import { CRMInfoCard } from '../../../../../ui'
import { useRoutingHistory } from '../../../../../../../../../reducers/routing/child-router-factory'
import useMetaTypeView from '../../../../../../../../../new-components/forms/meta-type/View'

const PropertyInfoCard = () => {
  const { id: propertyId } = useCustomerContext()
  const [history, { routingMap }] = useRoutingHistory()
  const { data, loading } = useQuery<GetPropertyResponse, GetPropertyVariables>(
    GET_PROPERTY,
    {
      variables: {
        id: propertyId,
      },
    }
  )

  const {
    location: { name: locationName = '' } = {},
    externalPropertyNumber = '',
    note,
    metaData = '{}',
  } = data?.property || {}

  const metaDataObject = JSON.parse(metaData)

  const { metaDataNodes, loading: loadingMetaTypeView } = useMetaTypeView({
    metaDataObject,
    entity: 'PROPERTY',
  })

  return (
    <>
      <CRMInfoCard
        content={{
          address: locationName,
          description: note,
          name: externalPropertyNumber,
        }}
        onEdit={() => {
          history.push(
            routingMap.app['customer-management'].property['edit/:id'](
              propertyId
            )._
          )
        }}
        title={<T _str="Property information" />}
        loading={loading}
      />
      <Row
        gutter={[0, 8]}
        style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
      >
        {!loadingMetaTypeView &&
          metaDataNodes.map((metaDataNode) => {
            return (
              <Col xs={24}>
                <Row gutter={21}>
                  <Col>
                    {metaDataNode.icon &&
                      React.cloneElement(metaDataNode.icon as JSX.Element, {
                        style: {
                          color: '#909DA9',
                        },
                      })}
                  </Col>
                  <Col>{metaDataNode.node}</Col>
                </Row>
              </Col>
            )
          })}
        {loadingMetaTypeView && (
          <Col xs={24}>
            <Skeleton loading />
          </Col>
        )}
      </Row>
    </>
  )
}

export default PropertyInfoCard
