import React from 'react'
// import moment from 'moment'

import { GET_ALL_LOGS } from './api'
import { useCustomerContext } from '../../../customer.context'
import { CRMEventLog } from '../../../../../components/EventLog/index'

const EventLog = () => {
  const { id } = useCustomerContext()
  return (
    <CRMEventLog
      query={GET_ALL_LOGS}
      queryVariableFormatter={() => ({
        id,
        // fromDate: moment().subtract(days, 'day').format('YYYY-MM-DD'),
      })}
      queryVariableFormatterDeps={[id]}
      queryResultFormatter={(data: $TSFixMe) => data?.property?.eventLog}
      mode="compact"
    />
  )
}

export default EventLog
