import { gql } from '@apollo/client'

export const GET_ALL_COMPANIES = gql`
  query GetAllCompanies($withUsers: Boolean = false) {
    allCompanies {
      edges {
        node {
          id
          name
          dashboardLocation {
            id
            latitude
            longitude
            placeId
            name
          }
          projectSet {
            edges {
              node {
                id
                name
                logo
                userAccessLevel
                settings {
                  id
                  dashboardLocation {
                    id
                    latitude
                    longitude
                    placeId
                    name
                  }
                }
                userprojectSet @include(if: $withUsers) {
                  edges {
                    node {
                      id
                      isSuper
                      user {
                        id
                        email
                        firstName
                        lastName
                        phoneNumber
                        logo
                        isMaster
                        # isAdmin
                        isReseller
                        isActive
                        jobtitle {
                          id
                          title
                          pendingTitle
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/* Mutations */
