import moment, { Moment } from 'moment'
import React, { ReactNode } from 'react'
import {
  Timeline,
  TimelineItemProps,
  Skeleton,
  Row,
  Col,
  Button,
  Collapse,
  CollapseProps,
  Empty,
} from 'antd'
import { T } from '@transifex/react'
import { CaretUpOutlined, CalendarOutlined } from '@ant-design/icons'
import {
  Link,
  Routing,
} from '../../../../../../../../../reducers/routing/child-router-factory'

import { StyledRow } from './styles'
import { SubHeader16 } from '../../../../../../../../../ui'

export const ExpandIcon: CollapseProps['expandIcon'] = ({ isActive }) => (
  <CaretUpOutlined rotate={isActive ? 180 : 0} style={{ color: 'gray' }} />
)

const { Item: TimelineItem } = Timeline

export type Datum = {
  title: ReactNode
  time: Moment
  id: string
  timelineItemProps?: TimelineItemProps
  expandContent?: ReactNode
}

export type TProps = {
  dataSource: Datum[]
  /** default to false. */
  loading?: boolean
  getCalendarLink: (routing: Routing) => string
}

const UI = ({ dataSource, loading = false, getCalendarLink }: TProps) => {
  return (
    <StyledRow gutter={[0, 16]}>
      <Col xs={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <SubHeader16>
              <T _str="Upcoming" />
            </SubHeader16>
          </Col>
          <Col>
            <Link to={getCalendarLink}>
              <Button icon={<CalendarOutlined />} />
            </Link>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Skeleton active loading={loading}>
          {dataSource.length ? (
            <Timeline style={{ marginTop: `8px` }}>
              {dataSource.map(
                ({
                  time,
                  title,
                  id,
                  timelineItemProps = {},
                  expandContent,
                }) => (
                  <TimelineItem {...timelineItemProps}>
                    <Collapse
                      ghost
                      expandIconPosition="right"
                      expandIcon={ExpandIcon}
                    >
                      <Collapse.Panel
                        header={
                          <Row
                            justify="space-between"
                            wrap={false}
                            gutter={16}
                            style={{ width: '100%' }}
                          >
                            <Col>{title}</Col>
                            <Col>{moment(time).format('DD-MM-YYYY')}</Col>
                          </Row>
                        }
                        key={id}
                      >
                        {expandContent}
                      </Collapse.Panel>
                    </Collapse>
                  </TimelineItem>
                )
              )}
            </Timeline>
          ) : (
            <Empty />
          )}
        </Skeleton>
      </Col>
      <Col xs={24} style={{ marginTop: !dataSource.length ? '0px' : '-16px' }}>
        <Link to={getCalendarLink}>
          <Button type="link" style={{ paddingLeft: 0 }}>
            <T _str="View all in calendar" />
          </Button>
        </Link>
      </Col>
    </StyledRow>
  )
}

export default UI
