/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react'

import { DragAndDropMultipleVerticalListsSectionsProps, Item } from './types'

export type Nodes<
  TSection extends string,
  TList extends string,
  TItem extends $TSFixMe
> = {
  [key: string]: {
    [key2: string]: Item<TSection, TList, TItem>[]
  }
}

export const useParseSource = <
  TSection extends string,
  TList extends string,
  TItem extends $TSFixMe
>(
  props: DragAndDropMultipleVerticalListsSectionsProps<TSection, TList, TItem>
): [
  Nodes<TSection, TList, TItem> | undefined,
  React.Dispatch<
    React.SetStateAction<Nodes<TSection, TList, TItem> | undefined>
  >
] => {
  const [parsedSource, setParsedSource] =
    useState<Nodes<TSection, TList, TItem>>()
  const { lists, sections, source, render } = props

  useEffect(() => {
    /* loop once through the source */
    const baseListNodes = lists.reduce(
      (acc, { _id }) => ({ ...acc, [_id]: [] }),
      {}
    )
    const baseSectionNodes = sections.reduce(
      (acc, { _id }) => ({ ...acc, [_id]: baseListNodes }),
      {}
    )
    const nodes = source.reduce<Nodes<TSection, TList, TItem>>(
      (acc: $TSFixMe, { _id, _list, _section, data }) => {
        return {
          ...acc,
          [_section]: {
            ...(acc?.[_section] || []),
            [_list]: [
              ...(acc?.[_section]?.[_list] || []),
              // will implement insertion sort here.
              {
                _id,
                _list,
                _section,
                data,
              },
            ],
          },
        }
      },
      baseSectionNodes
    )

    setParsedSource(nodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render])

  return [parsedSource, setParsedSource]
}
