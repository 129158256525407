import React from 'react'
import { T } from '@transifex/react'
import { GET, Variables, Response } from './api'

import {
  AsyncSelectWithFetchMore,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'
import { formatSelectOptionsFromEdgesNodeLocationId } from '../factories/response-to-options/getEdgesNodeNameId'
import { useSavedActiveProjects } from '../../../hooks'

export type SelectContainerGroupProps = {
  variables: Variables
} & AsyncSelectWithFetchMoreProps['selectProps'] &
  Pick<AsyncSelectWithFetchMoreProps, 'render'>

export const SelectContainerGroup = ({
  variables,
  ...props
}: SelectContainerGroupProps) => {
  const activeProjects = useSavedActiveProjects()
  const queryVariables = {
    activeProjects,
    ...variables,
  }

  return (
    <AsyncSelectWithFetchMore<Response, Variables>
      formatResponseToOptions={(data) =>
        formatSelectOptionsFromEdgesNodeLocationId<Response>(
          data,
          'allContainerGroups'
        )
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      selectProps={{
        placeholder: <T _str="Container group" />,
        allowClear: true,
        ...props,
      }}
      value={props.value}
    />
  )
}
