export const droppableIdSeparater = '|'
export const encodeDroppableId = (sectionId: string, listId: string) =>
  sectionId.concat(droppableIdSeparater).concat(listId)
export const decodeDroppableId = (id: string): string[] =>
  id.split(droppableIdSeparater)

export const encodeDraggableId = (
  id: string,
  sectionId: string,
  listId: string
) => [id, sectionId, listId].join(droppableIdSeparater)
export const decodeDraggableId = (id: string) => id.split(droppableIdSeparater)
