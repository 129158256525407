import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { FormikConfig, FormikHelpers } from 'formik'
import { ApolloError } from '@apollo/client'
import { Modal, Row, Col, Typography, message } from 'antd'

const { Text } = Typography

type OnSubmit<T> = FormikConfig<T>['onSubmit']
type RecordAny = Record<string, $TSFixMe>

type FormikErrorHandlerArgs<T> = {
  /** fn:
   * The onSubmit function required in the formikConfig.
   *  Note: If you catch the error in this function to handle some logic
   *  then remember to throw it again so that this handler can catch it again.
   */
  fn: OnSubmit<T>
  /** onError
   * This will be called once this handlers process the error and related UI.
   */
  onError?: (e: unknown, handlers: FormikHelpers<T>) => void
  /** fieldMapping.
   * Note: If you use fieldMappingFn then fieldMapping will be ignored.
   * If your formik fields are different that the fields that are returned in errors,
   * then you can map them there.
   *
   * Example:
   * In formik you name a field A and in api this field error will have a
   * name of B
   * then you will pass
   * ```
   * {
   *   A: B
   * }
   * ```
   * So that this handler can map it accorindly.
   */
  fieldMapping?: Record<string, keyof T>
  /** fieldMappingFn.
   * If your formik fields are different that the fields that are returned in errors,
   * then you can also use a function to map them there.
   *
   * Example:
   * In formik you name a field A and in api this field error will have a
   * name of B
   * then you will pass
   * ```
   * const map = {
   *   A: B
   * }
   * (k) => map[k]
   * ```
   * So that this handler can map it accorindly.
   */
  fieldMappingFn?: (k: string) => keyof T
}

export const formikErrorHandler =
  <T extends RecordAny = RecordAny>({
    fn,
    onError,
    fieldMapping = {},
    fieldMappingFn,
  }: FormikErrorHandlerArgs<T>): OnSubmit<T> =>
  async (values, formikHelpers) => {
    try {
      await fn(values, formikHelpers)
    } catch (e: unknown) {
      const errors = e as ApolloError
      const otherErrors: ReactNode[] = []
      /* 
      Todo:
      1. Populate formik with errors that are of field errors.
      2. Show other errors as antd.messages -- not auto closable.
      */
      errors?.graphQLErrors?.forEach((graphQLError: $TSFixMe) => {
        if (graphQLError?.context?.field) {
          formikHelpers.setFieldError(
            (fieldMappingFn?.(graphQLError.context.field) ||
              fieldMapping[graphQLError.context.field] ||
              graphQLError.context.field) as string,
            graphQLError.message
          )
        } else {
          // show error as antd message.
          otherErrors.push(graphQLError.message)
        }
      })
      if (errors.networkError) {
        otherErrors.push(
          <T
            _str="Network problem: {error}"
            error={errors.networkError.message}
          />
        )
      }
      if (otherErrors.length) {
        Modal.error({
          title: <T _str="Error occured." />,
          content: (
            <Row>
              {otherErrors.map((error) => (
                <Col span={24}>
                  <Text type="secondary">{error}</Text>
                </Col>
              ))}
            </Row>
          ),
        })
      } else {
        // otherwise show the message with error.
        message.error((e as Error)?.message ?? <T _str="Error occured." />)
        // message.error(<T _str="Error occured." />)
      }
      /*
      3. call the onError if provided.
      */
      onError?.(e, formikHelpers)
    }
  }
