import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'
import React from 'react'

export const timezones = [
  {
    value: 'Africa/Abidjan',
    label: <T _str="(GMT -12:00) Eniwetok, Kwajalein" />,
  },
  {
    value: 'Pacific/Samoa',
    label: <T _str="(GMT -11:00) Midway Island, Samoa" />,
  },
  { value: 'US/Hawaii', label: <T _str="(GMT -10:00) Hawaii" /> },
  { value: 'Pacific/Marquesas', label: <T _str="(GMT -09:30) Taiohae" /> },
  { value: 'US/Alaska', label: <T _str="(GMT -09:00) Alaska" /> },
  {
    value: 'Canada/Pacific',
    label: <T _str="(GMT -08:00) Pacific Time, USA, Canada" />,
  },
  {
    value: 'Canada/Mountain',
    label: <T _str="(GMT -07:00) Mountain Time, USA, Canada" />,
  },
  {
    value: 'America/Mexico_City',
    label: <T _str="(GMT -06:00) Central Time, USA, Canada, Mexico City" />,
  },
  {
    value: 'America/Bogota',
    label: <T _str="(GMT -05:00) Eastern Time, USA, Canada, Bogota, Lima" />,
  },
  {
    value: 'America/Caracas',
    label: <T _str="(GMT -04:00) Atlantic Time (Canada), Caracas, La Paz" />,
  },
  {
    value: 'Canada/Newfoundland',
    label: <T _str="(GMT -03:30) Newfoundland" />,
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: <T _str="(GMT -03:00) Brazil, Buenos Aires, Georgetown" />,
  },
  { value: 'America/Noronha', label: <T _str="(GMT -02:00) Mid-Atlantic" /> },
  {
    value: 'Atlantic/Azores',
    label: <T _str="(GMT -01:00) Azores, Cape Verde Islands" />,
  },
  {
    value: 'Europe/London',
    label: <T _str="(GMT +00:00) Western Europe Time, London, Lisbon" />,
  },
  {
    value: 'Europe/Paris',
    label: <T _str="(GMT +01:00) Copenhagen, Paris, Madrid, Brussels" />,
  },
  {
    value: 'Europe/Istanbul',
    label: <T _str="(GMT +02:00) Istanbul, Jerusalem" />,
  },
  {
    value: 'Europe/Moscow',
    label: <T _str="(GMT +03:00) Baghdad, Riyadh, Moscow, St. Petersburg" />,
  },
  { value: 'Asia/Tehran', label: <T _str="(GMT +03:30) Tehran" /> },
  {
    value: 'Asia/Muscat',
    label: <T _str="(GMT +04:00) Abu Dhabi, Muscat, Baku, Tbilisi" />,
  },
  { value: 'Asia/Kabul', label: <T _str="(GMT +04:30) Kabul" /> },
  {
    value: 'Asia/Karachi',
    label: <T _str="(GMT +05:00) Ekaterinburg, Islamabad, Karachi, Tashkent" />,
  },
  {
    value: 'Asia/Calcutta',
    label: <T _str="(GMT +05:30) Bombay, Calcutta, Madras, New Delhi" />,
  },
  {
    value: 'Asia/Almaty',
    label: <T _str="(GMT +06:00) Almaty, Dhaka, Colombo" />,
  },
  { value: 'Asia/Rangoon', label: <T _str="(GMT +06:30) Yangon, Mandalay" /> },
  {
    value: 'Asia/Bangkok',
    label: <T _str="(GMT +07:00) Bangkok, Hanoi, Jakarta" />,
  },
  {
    value: 'Asia/Singapore',
    label: <T _str="(GMT +08:00) Beijing, Perth, Singapore, Hong Kong" />,
  },
  {
    value: 'Asia/Yakutsk',
    label: <T _str="(GMT +09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk" />,
  },
  {
    value: 'Australia/Darwin',
    label: <T _str="(GMT +09:30) Adelaide, Darwin" />,
  },
  {
    value: 'Asia/Vladivostok',
    label: <T _str="(GMT +10:00) Eastern Australia, Guam, Vladivostok" />,
  },
  {
    value: 'Australia/Lord_Howe',
    label: <T _str="(GMT +10:30) Lord Howe Island" />,
  },
  {
    value: 'Asia/Magadan',
    label: <T _str="(GMT +11:00) Magadan, Solomon Islands, New Caledonia" />,
  },
  { value: 'Pacific/Norfolk', label: <T _str="(GMT +11:30) Norfolk Island" /> },
  {
    value: 'Pacific/Fiji',
    label: <T _str="(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka" />,
  },
  {
    value: 'Pacific/Chatham',
    label: <T _str="(GMT +12:45) Chatham Islands" />,
  },
  { value: 'Pacific/Apia', label: <T _str="(GMT +13:00) Apia, Nukualofa" /> },
  {
    value: 'Pacific/Tongatapu',
    label: <T _str="(GMT +14:00) Line Islands, Tokelau" />,
  },
]

const SelectTimezone = (props: SelectProps) => {
  return <Select options={timezones} {...props} />
}

export default SelectTimezone
