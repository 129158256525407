import { gql } from '@apollo/client'

export const FrgTicketInfo = gql`
  fragment FrgTicketInfo on TicketDef {
    id
    ticketType {
      id
      name
    }
    container {
      id
      containerId
    }
  }
`

export const GET_ALL_LOGS = gql`
  ${FrgTicketInfo}
  query GetPropertyLogs($id: ID!, $fromDate: Date, $first: Int) {
    property(id: $id) {
      eventLog(fromDate: $fromDate, first: $first) {
        ... on TicketEventDef {
          id
          ticket {
            ...FrgTicketInfo
          }
          eventType
          createdAt
          fromStatus
          toStatus
          fromAssignee {
            id
          }
          toAssignee {
            id
          }
          fromAssignedTeam {
            id
            name
          }
          toAssignedTeam {
            id
            name
          }
          fromPriority
          toPriority
          __typename
        }
        ... on TicketCommentDef {
          id
          createdAt
          body
          __typename
          ticket {
            ...FrgTicketInfo
          }
        }
        ... on TicketMessageDef {
          id
          createdAt
          body
          receiverPropertyUser {
            id
          }
          __typename
          viaSms
          viaEmail
          ticket {
            ...FrgTicketInfo
          }
        }
      }
    }
  }
`
