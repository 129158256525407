import { pathJoin } from '../../../utils'
import { createCompanyRoutesMap } from './company'
import { createProjectRouteMap } from './project'
import { createProjectsDetailView } from './projects'

export const createSettingsPageRouteMap = (prefix: string) => ({
  'company/:id': (id: string) =>
    pathJoin(prefix)('company')(id).chainMapAuto(createCompanyRoutesMap),
  'project/:id': (id: string) =>
    pathJoin(prefix)('project')(id).chainMap(
      createProjectRouteMap(pathJoin(prefix)('project')(id).value.slice(1))
    ),
  projects: pathJoin(prefix)('projects').chainMapAuto(createProjectsDetailView),
})
