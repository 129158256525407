import { gql } from '@apollo/client'

/* Fragments */

export const FrgMeasurementSettings = gql`
  fragment FrgMeasurementSettings on MeasurementSettingsType {
    id
    name
    hours
    measureAt
    measurementsPerHour
    excludeDays
    excludePeriodStart
    excludePeriodEnd
    excludePeriodEveryYear
    project {
      id
      name
    }
  }
`

/* Queries */
export const GET_ALL_MEASUREMENT_SETTINGS = gql`
  ${FrgMeasurementSettings}
  query GetAllMeasurementSettingsGlobal($projectId: ID) {
    allMeasurementSettings(projectId: $projectId) {
      edges {
        node {
          ...FrgMeasurementSettings
        }
      }
    }
  }
`
