import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { GET, TVariables } from './api'
import LayersContainer from './Layer'

import { TSourceRenderFn, TPoint, TCluster, TSourceConfig } from '../../types'

type TAvailableVariables = Omit<TVariables, 'bbox' | 'zoomLevel'>

export type TSourceContainerConfig = TSourceConfig<TAvailableVariables>

export { getContainerSourceClusterPoints } from './utils'

const SourceContainer: TSourceRenderFn<TVariables> = ({
  queryOptions,
  _bbox,
  _zoomLevel,
  _queryOptions,
  _getMap,
  _onLoadingChange,
  refreshCount,
}) => {
  const { loading, data, refetch } = useQuery<$TSFixMe, TVariables>(GET, {
    ...queryOptions?.({ baseVariables: { _bbox, _zoomLevel } }),
    ..._queryOptions(),
    variables: {
      ...queryOptions?.({ baseVariables: { _bbox, _zoomLevel } }).variables,
      ..._queryOptions().variables,
    },
  })

  const points: TPoint[] = useMemo(
    () => data?.containerMap?.points || [],
    [data?.containerMap?.points]
  )
  const clusters: TCluster[] = useMemo(
    () => data?.containerMap?.clusters || [],
    [data?.containerMap?.clusters]
  )

  useEffect(() => {
    _onLoadingChange(loading)
  }, [_onLoadingChange, loading])

  useEffect(() => {
    refetch()
  }, [refreshCount, refetch])

  return (
    <LayersContainer clusters={clusters} points={points} getMap={_getMap} />
  )
}

export default SourceContainer
