import { gql } from '@apollo/client'

export type GetCallResponseEdgeNode = {
  id: string
  name: string
}

export type GetCallResponse = {
  allWasteFractions: {
    edges: {
      node: GetCallResponseEdgeNode
    }[]
  }
}

export type GetCallVariables = {
  projectId?: string
  activeProjects?: string
  search?: string
  vehicles?: string
}

export const GET_CALL = gql`
  query Component_Select_WasteFraction_GetAllWasteFractions(
    $projectId: ID
    $activeProjects: String
    $search: String
    $offset: Int
    $first: Int
    $ids: [ID]
    $vehicles: String
  ) {
    allWasteFractions(
      projectId: $projectId
      activeProjects: $activeProjects
      search: $search
      offset: $offset
      first: $first
      ids: $ids
      vehicles: $vehicles
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
