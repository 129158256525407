import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useLocalStorageState from 'use-local-storage-state'
import { getSavedActiveProjects } from '../../shared/utils'

const compare = (array1: $TSFixMe[], array2: $TSFixMe[]) =>
  array1.length === array2.length &&
  array1.every((value, index) => value === array2[index])

type UseLastSavedActiveProjectsSnapshot = (
  localStorageKey: string
) => [boolean, () => void]

/**
* Use case:
* This hook is made specially for the following case:
* 1. When you save something in localStorage based on the current saved projects, 
* and then you want to reset them if the activeProjects are changed.
*
* How it works:
* When you run this hook inside your page, it saves the current projects in the localStorage too,
* and so in theory it keeps the last savedProject when your component was rendered, and it will let you
* know if they are changed or not.

* Usage:
* You need to reset the state back to false after doing your action. 
 */
export const useLastSavedActiveProjectsSnapshot: UseLastSavedActiveProjectsSnapshot =
  (localStorageKey) => {
    const [isChanged, setIsChanged] = useState(false)

    const savedActiveProjects = useSelector((state) =>
      getSavedActiveProjects(state)
    )

    const [lastSavedActiveProjects, setLastSavedActiveProjects] =
      useLocalStorageState(localStorageKey, ``)

    // effects;
    useEffect(() => {
      setLastSavedActiveProjects(savedActiveProjects)
    }, [savedActiveProjects, setLastSavedActiveProjects])

    useEffect(() => {
      /* RESET FILTERS IF THE LAST_SAVED_ACTIVE_PROJECTS ARE NOT SAME AS THE SAVED ACTIVE PROJECTS */
      if (
        !compare(
          savedActiveProjects.split(','),
          lastSavedActiveProjects.split(',')
        )
      ) {
        setIsChanged(true)
      }
    }, [lastSavedActiveProjects, savedActiveProjects])

    return [isChanged, () => setIsChanged(false)]
  }

type UseLastSavedActiveProjectsSnapshotFnType = (
  key: string,
  cb: () => void,
  dep: $TSFixMe[]
) => void

/**
* Use case:
* This hook is made specially for the following case:
* 1. When you save something in localStorage based on the current saved projects, 
* and then you want to reset them if the activeProjects are changed.
*
* How it works:
* When you run this hook inside your page, it saves the current projects in the localStorage too,
* and so in theory it keeps the last savedProject when your component was rendered, and it will let you
* know if they are changed or not.

* Usage:
* You need to reset the state back to false after doing your action. 
*/
export const useLastSavedActiveProjectsSnapshotFn: UseLastSavedActiveProjectsSnapshotFnType =
  (key, cb, dep) => {
    const [needReset, afterReset] = useLastSavedActiveProjectsSnapshot(key)

    useEffect(() => {
      if (needReset) {
        cb()
        afterReset()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [afterReset, needReset, ...dep])
  }
