/*
Todo:
1. Currently this component only works for the selects with multiple values. 
Make it to work with single value as well.
*/

import React, { useState, useEffect } from 'react'
import {
  useLazyQuery,
  LazyQueryHookOptions,
  DocumentNode,
} from '@apollo/client'
import { Select as AntdSelect, SelectProps } from 'antd'
import { debounce } from 'lodash'
import isEqual from 'react-fast-compare'

import { useRefEnhanced } from '../../hooks'
import { Opt } from './types'

export type { Opt }

type Config = {
  pageLimit: number
  initialValuesFetchKey: string
  searchVariableName: string
}

const defaultConfig: Config = {
  pageLimit: 100,
  initialValuesFetchKey: 'ids',
  searchVariableName: 'search',
}

type RenderFnProps<TOpt> = {
  filterOptions: boolean
  options: TOpt[]
  showSearch: boolean
  onSearch: $TSFixMeFunction
  loading: boolean
  onPopupScroll: $TSFixMeFunction
  onMore: $TSFixMeFunction
  loadingMore?: boolean
}

export type AsyncSelectWithFetchMoreProps<
  GetCallResponse = $TSFixMe,
  GetCallVariables = $TSFixMe,
  TOpt = Opt
> = {
  queryOptions?: LazyQueryHookOptions<GetCallResponse, GetCallVariables>
  query: DocumentNode
  queryVariables: GetCallVariables
  refreshDependencies: unknown[]
  formatResponseToOptions: (data: GetCallResponse) => TOpt[]
  config?: Partial<Config>
  render?: (props: RenderFnProps<TOpt>) => JSX.Element
  selectProps?: Partial<SelectProps<$TSFixMe>>
  /**
   * In milliseconds.
   * default to 600 i.e 0.6 seconds  */
  debounceTime?: number
  /**
   * initial search keyword
   * default to empty string.
   */
  initialSearchValue?: string
  /** current value */
  value: SelectProps['value']
}

/**
 * AsyncSelectWithFetchMore
 * render ant-design select component with fetching options from API.
 * You can use the render prop to use custom Select component.
 *
 * - Fetches limited values on first mount and then fetch more when onPopupScroll hits the end.
 * - Fetches the initial set options on mount.
 *   Note: By default the initial fetch key is `ids`
 *   You can change it using config.initialValuesFetchKey
 */
export const AsyncSelectWithFetchMore = <
  GetCallResponse,
  GetCallVariables,
  TOpt extends Opt = Opt
>({
  query,
  queryOptions,
  queryVariables,
  refreshDependencies,
  formatResponseToOptions,
  config,
  render,
  selectProps,
  debounceTime = 600,
  initialSearchValue = ``,
  value,
}: AsyncSelectWithFetchMoreProps<GetCallResponse, GetCallVariables, TOpt>) => {
  const [getInitLoading, setInitLoading] = useRefEnhanced(true)
  const mergedConfig = { ...defaultConfig, ...config }
  const [getMergeRef, setMergeRef] = useRefEnhanced<boolean>(false)
  const [getSearchRef, setSearchRef] = useRefEnhanced<string>(``)
  const [valuesMap, setValuesMap] = useState<Map<string, TOpt>>(new Map())
  const options = Array.from(valuesMap.values())
  const [getDepencyRef, setDependencyRef] =
    useRefEnhanced<unknown[]>(refreshDependencies)
  const [getSelectValue, setSelectValue] =
    useRefEnhanced<SelectProps['value']>(undefined)

  const [fetchOptions, { loading, data }] = useLazyQuery<
    GetCallResponse,
    GetCallVariables
  >(query, queryOptions)

  const [fetchInitialOptions, { data: dataInitial }] = useLazyQuery<
    GetCallResponse,
    GetCallVariables
  >(query, queryOptions)

  const onSearch: SelectProps<TOpt>['onSearch'] = debounce((search) => {
    setSearchRef(search)
    setMergeRef(false)
    fetchOptions({
      variables: {
        [mergedConfig.searchVariableName]: search,
        first: mergedConfig.pageLimit, // page size.
        offset: 0, // 0 because search is changed.
        ...queryVariables,
      },
    })
  }, debounceTime)

  const onMore = () => {
    if (!loading) {
      fetchOptions({
        variables: {
          [mergedConfig.searchVariableName]: getSearchRef(),
          first: mergedConfig.pageLimit, // page size.
          offset: valuesMap.size,
          ...queryVariables,
        },
      })
      setMergeRef(true)
    }
  }
  useEffect(() => {
    if (data) {
      const mergeIncoming = getMergeRef()
      const newOpts = formatResponseToOptions(data)
      if (mergeIncoming) {
        // merge the results
        const valuesMap1 = new Map(valuesMap)
        newOpts.forEach((x) => valuesMap1.set(x.value, x))
        setValuesMap(valuesMap1)
      } else {
        // overwrite the options.
        const valuesMap1 = new Map()
        newOpts.forEach((x) => valuesMap1.set(x.value, x))
        setValuesMap(valuesMap1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (dataInitial) {
      let initalOpts = formatResponseToOptions(dataInitial)
      initalOpts = initalOpts.filter((x) => !valuesMap?.get(x.value))
      const intialMap = new Map(valuesMap)
      initalOpts.forEach((x) => intialMap.set(x.value, x))
      setValuesMap(intialMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInitial])

  useEffect(() => {
    fetchOptions({
      variables: {
        [mergedConfig.searchVariableName]: initialSearchValue,
        first: mergedConfig.pageLimit, // page size.
        offset: 0, // 0 because search is changed.
        ...queryVariables,
      },
    })
    setInitLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    /** fetching options value that are currently selected but not in the options. */
    if (!!value?.length && !isEqual({ v: value }, { v: getSelectValue() })) {
      setSelectValue(value)
      const doFetch = Array.isArray(value) ? !!value.length : value

      if (doFetch) {
        setMergeRef(true)
        // eslint-disable-next-line no-console
        fetchInitialOptions({
          variables: {
            [mergedConfig.initialValuesFetchKey]: value,
          } as $TSFixMe,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    const dep = getDepencyRef()
    if (!isEqual(dep, refreshDependencies)) {
      setMergeRef(false)
      fetchOptions({
        variables: {
          [mergedConfig.searchVariableName]: getSearchRef(),
          first: mergedConfig.pageLimit, // page size.
          offset: 0, // 0 because external variables changed like activeProjects.
          ...queryVariables,
        },
      })
      setDependencyRef(refreshDependencies)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDependencies])

  const onPopupScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    e.persist()
    const { target } = e
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'scrollTop' does not exist on type 'Event... Remove this comment to see the full error message
    if (target.scrollTop + target.offsetHeight + 10 >= target.scrollHeight) {
      // dynamic add options...
      onMore()
    }
  }

  const loadingNew = getInitLoading() || (loading && !getMergeRef())

  return (
    render?.({
      filterOptions: false,
      options,
      showSearch: true,
      onSearch,
      loading: loadingNew,
      onPopupScroll,
      onMore,
      loadingMore: loading && getMergeRef(),
    }) || (
      <AntdSelect
        filterOption={false}
        showSearch
        options={options}
        onSearch={onSearch}
        autoClearSearchValue={false}
        loading={loading}
        onPopupScroll={onPopupScroll}
        {...selectProps}
        style={{
          ...selectProps?.style,
        }}
      />
    )
  )
}
