import styled from 'styled-components'

const ExpandContentSkeleton = styled.div`
  .ant-skeleton-header {
    width: 100% !important;
    height: 323px !important;
    .ant-skeleton-avatar-lg {
      width: 100% !important;
      height: 323px !important;
    }
  }
`

export default ExpandContentSkeleton
