import React, { FC } from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'

import { Opt } from './types'

import {
  AsyncSelectOnMount,
  AsyncSelectOnMountProps,
} from './AsyncSelectOnMount'
import { useSavedActiveProjects } from '../../hooks'

type GetCallVariables = {
  activeProjects?: string
  companyId?: string
}

type GetCallResponse = $TSFixMe

const GET_QUERY = gql`
  query Component_Select_PropertyType($activeProjects: String, $companyId: ID) {
    allPropertyTypes(activeProjects: $activeProjects, companyId: $companyId) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const formatResponse = ({ node: { id, name } }: $TSFixMe): Opt<string> => ({
  label: name,
  value: id,
})

type SelectPropertyTypeProps = {
  variables?: GetCallVariables
} & AsyncSelectOnMountProps['selectProps'] &
  Pick<AsyncSelectOnMountProps, 'queryOptions'>

const formatResponseToOptions: AsyncSelectOnMountProps['formatResponseToOptions'] =
  (data: GetCallResponse) =>
    data.allPropertyTypes.edges?.map((val: $TSFixMe) => formatResponse(val))

type ExtendedProps = {
  formatResponseToOptions: typeof formatResponseToOptions
}

const SelectPropertyType: FC<SelectPropertyTypeProps> & ExtendedProps = ({
  variables,
  queryOptions = {},
  ...props
}) => {
  const activeProjects = useSavedActiveProjects()

  return (
    <AsyncSelectOnMount<GetCallResponse, GetCallVariables>
      formatResponseToOptions={formatResponseToOptions}
      query={GET_QUERY}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
        ...queryOptions,
        variables: {
          activeProjects,
          ...queryOptions?.variables,
          ...variables,
        },
      }}
      selectProps={{
        placeholder: <T _str="Propery type" />,
        allowClear: true,
        ...props,
      }}
    />
  )
}

SelectPropertyType.formatResponseToOptions = formatResponseToOptions

export { SelectPropertyType }
