/* eslint-disable no-bitwise */
import React, { useCallback, useEffect, useMemo } from 'react'
import { groupBy } from 'lodash'
import { useQuery } from '@apollo/client'
import { Layer, Source } from 'react-mapbox-gl'
import produce from 'immer'

import { GET, TVariables } from './api'

import { TSourceRenderFn, TSourceConfig } from '../../types'

import {
  getRouteStopContainerCircleProperties,
  pickupOrderColors,
} from './utils'
import { array } from '../../../../utils/array'
import { apiHelperPickupOrder, apiHelperRoute } from '../../../../api/helpers'
import { fixIfNotInt } from '../../../../utils/math'
import { GROUP_CIRCLE_CONFIG } from '../../consts'
import { addonZoomCluster } from '../../utils'
import { TPopupPickupOrderDetailProps } from '../../Popup/PopupPickupOrderDetail'

type TSourceRoutePickupOrdersExtendedVars = TVariables & {
  /**
   * Will navigate to current active stop
   */
  navigateToActiveStop?: boolean
}
export type TSourceRoutePickupOrdersConfig = TSourceConfig<
  $TSFixMe,
  TSourceRoutePickupOrdersExtendedVars
>

const textSize = [
  'step',
  ['get', 'groupPointCount'],
  12,
  9,
  13,
  99,
  15,
  999,
  18,
  9999,
  20,
]

const concatLocationName = (location: $TSFixMe) =>
  `${location.latitude}-${location.longitude}`

const randomDe = () => (Math.random() < 0.5 ? -1 : 1)

function randomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min
}

const SourceRoutePickupOrders: TSourceRenderFn<
  $TSFixMe,
  TSourceRoutePickupOrdersExtendedVars
> = ({
  _getMap,
  routeId,
  search,
  _onLoadingChange,
  navigateToActiveStop,
  refreshCount,
}) => {
  const navigateToCurrentStop = useCallback(
    (route: $TSFixMe) => {
      if (route) {
        const nextStop = apiHelperRoute.getNextStop(route)
        if (nextStop) {
          const { latitude, longitude } =
            apiHelperPickupOrder.getLocation(nextStop)
          const currentZoom = _getMap().getZoom()
          _getMap().easeTo({
            center: [longitude, latitude],
            zoom: Math.min(currentZoom, 16),
          })
        }
      }
    },
    [_getMap]
  )

  const { loading, data, refetch } = useQuery<$TSFixMe, TVariables>(GET, {
    variables: {
      routeId,
      search,
    },
  })

  useEffect(() => {
    refetch()
  }, [refreshCount, refetch])

  useEffect(() => {
    // Effect on data change: Will navigate to current active stop.
    // if (navigateToActiveStop) navigateToCurrentStop(data?.route)
  }, [data, navigateToCurrentStop, navigateToActiveStop])

  useEffect(() => {
    _onLoadingChange(loading)
  }, [_onLoadingChange, loading])

  const stopsUnGrouped = useMemo(
    () =>
      produce((draft: $TSFixMe) => {
        data?.route?.stops?.edges
          ?.map((e: $TSFixMe) => ({
            ...e,
            index: e.node.stopNumber,
          }))
          ?.forEach((edge: $TSFixMe) => {
            const groupKey = apiHelperPickupOrder.getGroupKey(edge.node, true)
            if (groupKey) {
              // check if the last group is same
              if (draft.at(-1).group?.id === groupKey) {
                draft.at(-1).group.nodes.push(edge)
              } else {
                // get the last group is see if the it's the same.
                draft.push({
                  group: {
                    id: groupKey,
                    nodes: [edge],
                  },
                  index: edge.node.stopNumber,
                })
              }
            } else {
              // do the location grouping.
              draft.push({
                node: edge.node,
                location: apiHelperPickupOrder.getLocation(edge.node),
              })
            }
          })
      }, [])()?.map((n: $TSFixMe) => {
        if (n.group) {
          return {
            ...n,
            group: {
              ...n.group,
              id: n.group.id.concat(n.index),
            },
            location: apiHelperPickupOrder.getLocation(n.group.nodes[0].node),
          }
        }
        return n
      }),
    [data?.route?.stops?.edges]
  )

  /* 
  Todo: Same stop grouping i.e group stop that occurs more then once.
  1. groupby their id.
  2. separate again if a group has length === 1. converting them back to normal stop.
  3. for length > 1, convert them into same-stop-group.
  */

  const stopsGrouped2: $TSFixMe[] = useMemo(() => {
    const groupedById = groupBy(stopsUnGrouped, ({ node }: $TSFixMe) => {
      // if group then take first node location.
      // Todo: Use container group location.
      if (node?.depot) {
        return node.depot.id
      }
      if (node?.container) {
        return node.container.id
      }
      if (node?.ticket) {
        return node.ticket.id
      }
      return 'same'
    })

    return Object.keys(groupedById).reduce((acc: $TSFixMe, k) => {
      const v = groupedById[k] as $TSFixMe
      if (v.length > 1 && k !== 'same') {
        return [
          ...acc,
          {
            sameStopGroup: {
              nodes: v,
            },
            id: k,
            location: apiHelperPickupOrder.getLocation(v[0].node),
          },
        ]
      }
      return [...acc, ...v]
    }, [])
  }, [stopsUnGrouped])

  /*
  Todo: Grouping exact location stops.
  1. groupby their location lat-lng.
  2. separate again if a group has length === 1. converting them back to normal stop.
  3. for length > 1, convert them into stop-group.
  */

  const stops: $TSFixMe[] = useMemo(() => {
    const groupedByLocation = groupBy(
      stopsGrouped2,
      ({ location }: $TSFixMe) => {
        return concatLocationName(location)
      }
    )

    return Object.keys(groupedByLocation).reduce((acc: $TSFixMe, k) => {
      const v = groupedByLocation[k]
      if (v.length > 1) {
        const {
          plain = [],
          containerGroup = [],
          sameStopGroup = [],
        } = groupBy(
          v,
          (n) =>
            array(
              n.group && 'containerGroup',
              n.sameStopGroup && 'sameStopGroup',
              'plain'
            )[0]
        )
        // scatter stop grops in a better way.
        const scatteredCoord = [...containerGroup, ...sameStopGroup].map(
          (nn) => {
            const platitude = nn.location.latitude
            const plongitude = nn.location.longitude
            const randomOffset = randomNumber(1.5, 3.5) * 0.00001
            return {
              ...nn,
              location: {
                latitude: randomDe()
                  ? platitude + randomOffset
                  : platitude - randomOffset,
                longitude: -randomDe()
                  ? plongitude + randomOffset
                  : plongitude - randomOffset,
              },
            }
          }
        )

        return array(
          acc,
          scatteredCoord,
          plain.length > 1 && {
            stopGroup: { nodes: plain, id: k },
            location: plain.at(0).location,
          },
          plain.length === 1 && plain
        )
      }
      return [...acc, ...v]
    }, [])
  }, [stopsGrouped2])

  useEffect(() => {
    addonZoomCluster('route-pickup-orders-cluster', _getMap())
  }, [_getMap])

  const nextStop = data?.route
    ? apiHelperRoute.getNextStopIndex(data?.route)
    : undefined

  const features = useMemo(
    () =>
      stops?.map(
        (
          {
            node: inNode,
            group,
            stopGroup,
            sameStopGroup,
            location: { latitude, longitude },
          },
          i
        ) => {
          const zIndex = i

          let isNextStopIcon = false
          const isNextStop = (node: $TSFixMe) => {
            if (nextStop === node.stopNumber) isNextStopIcon = true
            return nextStop === node.stopNumber
          }

          let properties
          let coordinates

          if (sameStopGroup) {
            const { nodes } = sameStopGroup

            let hasNextStop = false
            let nextStopId

            const groupStyles = () => {
              let hasCompletedStop = false
              let hasUnCompletedStop = false
              let hasPendingStop = false

              nodes.forEach(({ node: groupStopNode }: $TSFixMe) => {
                // Todo: Handle the container-grouping case here.
                // What will happen if container-group is also at same position as another stop
                if (groupStopNode) {
                  const p = isNextStop(groupStopNode)
                  if (p) nextStopId = groupStopNode.id
                  hasNextStop = hasNextStop || p
                  hasCompletedStop =
                    hasCompletedStop ||
                    (apiHelperPickupOrder.isCompleted(groupStopNode) &&
                      !apiHelperPickupOrder.isUnCompleted(groupStopNode))
                  hasUnCompletedStop =
                    hasUnCompletedStop ||
                    apiHelperPickupOrder.isUnCompleted(groupStopNode)
                  hasPendingStop =
                    hasPendingStop ||
                    apiHelperPickupOrder.isPending(groupStopNode)
                }
              })

              return {
                color: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.color,
                  hasPendingStop && pickupOrderColors.pending.color,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.color,
                  hasCompletedStop && pickupOrderColors.completed.color
                )[0],
                strokeColor: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.stroke,
                  hasPendingStop && pickupOrderColors.pending.stroke,
                  hasCompletedStop && pickupOrderColors.completed.stroke,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.stroke
                )[0],
                textColor: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.textColor,
                  hasPendingStop && pickupOrderColors.pending.textColor,
                  hasCompletedStop && pickupOrderColors.completed.textColor,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.textColor
                )[0],
              }
            }
            properties = {
              type: 'same-stop-group',
              groupPointCount: nodes.length,
              ...groupStyles(),
              popupCustom: {
                type: 'same-stop-group',
                props: {
                  ids: nodes.map((n: $TSFixMe) => n.node.orderId),
                  isNext: hasNextStop,
                  nextStopId,
                },
              } as TPopupPickupOrderDetailProps,
            }
            coordinates = [longitude, latitude]
          }

          if (group) {
            let hasNextStop = false
            let nextStopId

            // this is a container-group.
            const groupStyles = () => {
              if (!group) return {}

              let hasCompletedStop = false
              let hasUnCompletedStop = false
              let hasPendingStop = false

              group.nodes.forEach(({ node: groupStopNode }: $TSFixMe) => {
                const p = isNextStop(groupStopNode)
                if (p) nextStopId = groupStopNode.id
                hasNextStop = hasNextStop || p
                hasCompletedStop =
                  hasCompletedStop ||
                  (apiHelperPickupOrder.isCompleted(groupStopNode) &&
                    !apiHelperPickupOrder.isUnCompleted(groupStopNode))
                hasUnCompletedStop =
                  hasUnCompletedStop ||
                  apiHelperPickupOrder.isUnCompleted(groupStopNode)
                hasPendingStop =
                  hasPendingStop ||
                  apiHelperPickupOrder.isPending(groupStopNode)
              })

              return {
                colorPrimary: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.color,
                  hasPendingStop && pickupOrderColors.pending.color,
                  hasCompletedStop && pickupOrderColors.completed.color,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.color
                )[0],
                strokeColorPrimary: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.stroke,
                  hasPendingStop && pickupOrderColors.pending.stroke,
                  hasCompletedStop && pickupOrderColors.completed.stroke,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.stroke
                )[0],
                textColorPrimary: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.textColor,
                  hasPendingStop && pickupOrderColors.pending.textColor,
                  hasCompletedStop && pickupOrderColors.completed.textColor,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.textColor
                )[0],
                colorSecondary: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.color,
                  hasCompletedStop && pickupOrderColors.completed.color,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.color,
                  hasPendingStop && pickupOrderColors.pending.color
                )[0],
                strokeColorSecondary: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.stroke,
                  hasCompletedStop && pickupOrderColors.completed.stroke,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.stroke,
                  hasPendingStop && pickupOrderColors.pending.stroke
                )[0],
                colorTertiary: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.color,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.color,
                  hasCompletedStop && pickupOrderColors.completed.color,
                  hasPendingStop && pickupOrderColors.pending.color
                )[0],
                strokeColorTertiary: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.stroke,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.stroke,
                  hasCompletedStop && pickupOrderColors.completed.stroke,
                  hasPendingStop && pickupOrderColors.pending.stroke
                )[0],
              }
            }

            properties = {
              type: 'container-group',
              groupPointCount: group.nodes.length,
              dataIds: group.nodes.map((e: $TSFixMe) => e.node.orderId),
              ...groupStyles(),
              popupCustom: {
                type: 'container-group',
                props: {
                  ids: group.nodes.map((e: $TSFixMe) => e.node.orderId),
                  isNext: hasNextStop,
                  groupId: group.id,
                  // ids: group.nodes.map((e: $TSFixMe) => e.node.id),
                  nextStopId,
                },
              } as TPopupPickupOrderDetailProps,
            }
            coordinates = [longitude, latitude]
          }
          if (stopGroup) {
            const { nodes } = stopGroup as $TSFixMe
            // const {
            //   node,
            //   group: containerGroupInStopGroup,
            //   sameStopGroup: sameStopGroupInStopGroup,
            // } = nodes.at(0)
            // const { longitude, latitude } = apiHelperPickupOrder.getLocation(
            //   array(
            //     containerGroupInStopGroup &&
            //       containerGroupInStopGroup.nodes.at(0).node,
            //     sameStopGroupInStopGroup &&
            //       sameStopGroupInStopGroup.nodes.at(0).node,
            //     node
            //   )[0]
            // )

            let hasNextStop = false

            const groupStyles = () => {
              let hasCompletedStop = false
              let hasUnCompletedStop = false
              let hasPendingStop = false

              const evalNode = (inp: $TSFixMe) => {
                hasNextStop = hasNextStop || isNextStop(inp)
                hasCompletedStop =
                  hasCompletedStop ||
                  (apiHelperPickupOrder.isCompleted(inp) &&
                    !apiHelperPickupOrder.isUnCompleted(inp))
                hasUnCompletedStop =
                  hasUnCompletedStop || apiHelperPickupOrder.isUnCompleted(inp)
                hasPendingStop =
                  hasPendingStop || apiHelperPickupOrder.isPending(inp)
              }

              nodes.forEach(
                ({
                  node: groupStopNode,
                  sameStopGroup: sameStopGroupInStopGroupNodes,
                }: $TSFixMe) => {
                  // Todo: Handle the container-grouping case here.
                  // What will happen if container-group is also at same position as another stop
                  if (groupStopNode) {
                    evalNode(groupStopNode)
                  }
                  if (sameStopGroupInStopGroupNodes) {
                    sameStopGroupInStopGroupNodes.nodes.forEach(
                      ({
                        node: sameStopGroupInStopGroupNodesNode,
                      }: $TSFixMe) => {
                        evalNode(sameStopGroupInStopGroupNodesNode)
                      }
                    )
                  }
                }
              )

              return {
                color: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.color,
                  hasPendingStop && pickupOrderColors.pending.color,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.color,
                  hasCompletedStop && pickupOrderColors.completed.color
                )[0],
                strokeColor: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.stroke,
                  hasPendingStop && pickupOrderColors.pending.stroke,
                  hasCompletedStop && pickupOrderColors.completed.stroke,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.stroke
                )[0],
                textColor: array<string>(
                  hasNextStop && pickupOrderColors.nextStop.textColor,
                  hasPendingStop && pickupOrderColors.pending.textColor,
                  hasCompletedStop && pickupOrderColors.completed.textColor,
                  hasUnCompletedStop && pickupOrderColors.unCompleted.textColor
                )[0],
              }
            }
            properties = {
              type: 'stop-group',
              groupPointCount: nodes.length,
              ...groupStyles(),
              popupCustom: {
                type: 'stop-group',
                props: {
                  ids: nodes.map((n: $TSFixMe) => n.node.orderId),
                  isNext: hasNextStop,
                },
              } as TPopupPickupOrderDetailProps,
            }
            coordinates = [longitude, latitude]
          }

          if (inNode) {
            const node = inNode
            const { id, container, depot, ticket } = node
            const isNextStopThis = isNextStop(node)
            const { color, strokeColor, textColor } =
              getRouteStopContainerCircleProperties(node, isNextStopThis)
            const fillLevel = container?.measurement?.fillPercentage
              ? fixIfNotInt(
                  container?.measurement?.fillPercentage,
                  0
                ).toString()
              : ``
            // single stop.
            properties = {
              dataId: id,
              color,
              strokeColor,
              textColor,
              label: fillLevel,
              labelLength: fillLevel.length,
              depotLabel: array<'D' | 'W' | 'C'>(
                depot?.depotType === 'waste_fraction' && 'W',
                depot?.depotType === 'container_storage' && 'C',
                'D'
              )[0],
              type: array<'container' | 'depot' | 'ticket'>(
                !!container && 'container',
                !!ticket && 'ticket',
                !!depot && 'depot'
              )[0],
              popupCustom: {
                type: 'stop',
                props: {
                  id,
                  isNext: isNextStopThis,
                },
              } as TPopupPickupOrderDetailProps,
            }
            coordinates = [longitude, latitude]
          }

          return {
            type: 'Feature',
            properties: {
              zIndex: isNextStopIcon ? 9999999 : zIndex,
              ...properties,
            },
            geometry: {
              type: 'Point',
              coordinates,
            },
          }
        }
      ),
    [nextStop, stops]
  )

  return (
    <>
      <Source
        id="source-route-pickup-orders"
        geoJsonSource={{
          type: 'geojson',
          generateId: true,
          data: {
            type: 'FeatureCollection',
            features,
          },
        }}
        layout={{
          'symbol-z-order': 'source',
        }}
      />
      {/* RoutePickupOrders Layers. */}
      <>
        <>
          {/* Container stop */}
          <Layer
            id="route-pickup-orders-containers"
            sourceId="source-route-pickup-orders"
            type="circle"
            filter={['==', 'container', ['get', 'type']]}
            paint={{
              'circle-radius': [
                'step',
                ['get', 'labelLength'],
                10,
                1,
                11,
                2,
                12,
              ],
              'circle-color': ['get', 'color'],
              'circle-opacity': 1,
              'circle-stroke-color': ['get', 'strokeColor'],
              'circle-stroke-width': 2,
            }}
            layout={{
              'circle-sort-key': ['get', 'zIndex'],
            }}
          />
          <Layer
            id="route-pickup-orders-containers-label"
            sourceId="source-route-pickup-orders"
            type="symbol"
            filter={['==', 'container', ['get', 'type']]}
            layout={{
              'text-field': [
                'case',
                ['>', ['length', ['get', 'label']], 0],
                [
                  'format',
                  ['get', 'label'],
                  { 'font-scale': 1.2 },
                  '\n%',
                  { 'font-scale': 0.8 },
                ],
                '',
              ],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 10,
              'text-line-height': 0.9,
              'symbol-sort-key': ['*', ['get', 'zIndex'], -1],
              'text-padding': 6,
            }}
            paint={{
              'text-color': ['get', 'textColor'],
            }}
          />
        </>
        {/* Container-group stop */}
        <>
          <Layer
            id="route-pickup-orders-container-groups-dept1"
            sourceId="source-route-pickup-orders"
            type="circle"
            filter={['==', 'container-group', ['get', 'type']]}
            paint={{
              'circle-radius': [
                'step',
                ['get', 'groupPointCount'],
                10,
                2,
                13,
                10,
                15,
                99999,
                20,
              ],
              'circle-color': ['get', 'colorTertiary'],
              'circle-opacity': 1,
              'circle-stroke-color': ['get', 'strokeColorTertiary'],
              'circle-stroke-width': 3,
            }}
            layout={{
              'circle-sort-key': ['get', 'zIndex'],
            }}
          />
          <Layer
            id="route-pickup-orders-container-groups-dept2"
            sourceId="source-route-pickup-orders"
            type="circle"
            filter={['==', 'container-group', ['get', 'type']]}
            paint={{
              'circle-radius': [
                'step',
                ['get', 'groupPointCount'],
                10,
                2,
                13,
                10,
                15,
                99999,
                20,
              ],
              'circle-color': ['get', 'colorSecondary'],
              'circle-opacity': 1,
              'circle-stroke-color': ['get', 'strokeColorSecondary'],
              'circle-stroke-width': 3,
              'circle-translate': [
                -GROUP_CIRCLE_CONFIG.translate.x,
                GROUP_CIRCLE_CONFIG.translate.y,
              ],
            }}
            layout={{
              'circle-sort-key': ['get', 'zIndex'],
            }}
          />
          <Layer
            id="route-pickup-orders-container-groups"
            sourceId="source-route-pickup-orders"
            type="circle"
            filter={['==', 'container-group', ['get', 'type']]}
            paint={{
              'circle-radius': [
                'step',
                ['get', 'groupPointCount'],
                10,
                2,
                13,
                10,
                15,
                99999,
                20,
              ],
              'circle-color': ['get', 'colorPrimary'],
              'circle-opacity': 1,
              'circle-stroke-color': ['get', 'strokeColorPrimary'],
              'circle-stroke-width': 3,
              'circle-translate': [
                -GROUP_CIRCLE_CONFIG.translate.x * 2,
                GROUP_CIRCLE_CONFIG.translate.y * 2,
              ],
            }}
            layout={{
              'circle-sort-key': ['get', 'zIndex'],
            }}
          />
          <Layer
            id="route-pickup-orders-container-groups-point-count"
            sourceId="source-route-pickup-orders"
            type="symbol"
            filter={['==', 'container-group', ['get', 'type']]}
            layout={{
              'text-field': ['get', 'groupPointCount'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': textSize,
              'symbol-sort-key': ['*', ['get', 'zIndex'], -1],
            }}
            paint={{
              'text-color': ['get', 'textColorPrimary'],
              'text-translate': [
                -GROUP_CIRCLE_CONFIG.translate.x * 2,
                GROUP_CIRCLE_CONFIG.translate.y * 2,
              ],
            }}
          />
        </>
        {/* Same-stop group */}
        <Layer
          id="route-pickup-orders-same-stops-group"
          sourceId="source-route-pickup-orders"
          type="circle"
          filter={['==', 'same-stop-group', ['get', 'type']]}
          paint={{
            'circle-radius': [
              'step',
              ['get', 'groupPointCount'],
              12,
              99,
              15,
              99999,
              20,
              999999,
              25,
            ],
            'circle-color': ['get', 'color'],
            'circle-opacity': 1,
            'circle-stroke-color': ['get', 'strokeColor'],
            'circle-stroke-width': 2,
          }}
          layout={{
            'circle-sort-key': ['get', 'zIndex'],
          }}
        />
        <Layer
          id="route-pickup-orders-same-stops-group-label"
          sourceId="source-route-pickup-orders"
          type="symbol"
          filter={['==', 'same-stop-group', ['get', 'type']]}
          layout={{
            'text-field': ['get', 'groupPointCount'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': textSize,
            'text-line-height': 0.9,
            'symbol-sort-key': ['*', ['get', 'zIndex'], -1],
          }}
          paint={{
            'text-color': ['get', 'textColor'],
          }}
        />
        {/* Stop-group  */}
        <Layer
          id="route-pickup-orders-stops-group"
          sourceId="source-route-pickup-orders"
          type="circle"
          filter={['==', 'stop-group', ['get', 'type']]}
          paint={{
            'circle-radius': [
              'step',
              ['get', 'groupPointCount'],
              12,
              99,
              15,
              99999,
              20,
              999999,
              25,
            ],
            'circle-color': ['get', 'color'],
            'circle-opacity': 1,
            'circle-stroke-color': ['get', 'strokeColor'],
            'circle-stroke-width': 2,
          }}
          layout={{
            'circle-sort-key': ['get', 'zIndex'],
          }}
        />
        <Layer
          id="route-pickup-orders-stops-group-label"
          sourceId="source-route-pickup-orders"
          type="symbol"
          filter={['==', 'stop-group', ['get', 'type']]}
          layout={{
            'text-field': ['get', 'groupPointCount'],
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': textSize,
            'text-line-height': 0.9,
            'symbol-sort-key': ['*', ['get', 'zIndex'], -1],
          }}
          paint={{
            'text-color': ['get', 'textColor'],
          }}
        />
        {/* Depot stop */}
        <>
          <Layer
            id="route-pickup-orders-depots"
            sourceId="source-route-pickup-orders"
            type="circle"
            filter={['==', 'depot', ['get', 'type']]}
            paint={{
              'circle-radius': 12,
              'circle-color': ['get', 'color'],
              'circle-opacity': 1,
              'circle-stroke-color': ['get', 'strokeColor'],
              'circle-stroke-width': 2,
            }}
            layout={{
              'circle-sort-key': ['get', 'zIndex'],
            }}
          />
          <Layer
            id="route-pickup-orders-depots-label"
            sourceId="source-route-pickup-orders"
            type="symbol"
            filter={['==', 'depot', ['get', 'type']]}
            layout={{
              'text-field': ['get', 'depotLabel'],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 10,
              'text-line-height': 0.9,
              'symbol-sort-key': ['*', ['get', 'zIndex'], -1],
            }}
            paint={{
              'text-color': ['get', 'textColor'],
            }}
          />
        </>
        {/* Ticket stop */}
        <Layer
          id="route-pickup-orders-tickets"
          sourceId="source-route-pickup-orders"
          type="circle"
          filter={['==', 'ticket', ['get', 'type']]}
          paint={{
            'circle-radius': 10,
            'circle-color': ['get', 'color'],
            'circle-opacity': 1,
            'circle-stroke-color': ['get', 'strokeColor'],
            'circle-stroke-width': 2,
          }}
          layout={{
            'circle-sort-key': ['get', 'zIndex'],
          }}
        />
      </>
    </>
  )
}

export default SourceRoutePickupOrders
