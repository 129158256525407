import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,
})

export const sendSentryError = (error: $TSFixMe, errorInfo: $TSFixMe) => {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key])
    })
    Sentry.captureException(error)
  })
}

export default Sentry
