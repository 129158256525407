import React, { ReactNode } from 'react'
import { Skeleton, Space, Empty, Divider, Tag } from 'antd'
import { T } from '@transifex/react'
import {
  InfoList,
  InfoListProps,
} from '@wastehero/storybook/lib/layouts/cards/LayoutInfoCardSimple'
import { FromTo } from '@wastehero/storybook/lib/components'

import { useQuery } from '@apollo/client'

import { GET } from './api'
import CRMInfoList from '../../../ui/CRMInfoList'

const fieldsLabelMap: Record<string, ReactNode> = {
  description: <T _str="Description" />,
  ignore_service_fee: <T _str="Remove service fee" />,
  preferred_date: <T _str="Prefered date" />,
}

export type TModifiedLogDetailProps = {
  id: string
  compact?: boolean
}

type TChangeDataType = {
  after: Record<string, unknown>
  before: Record<string, unknown>
}

const Booleanize = (val: $TSFixMe) => {
  switch (val) {
    case false:
      return (
        <Tag>
          <T _str="False" />
        </Tag>
      )
    case true:
      return (
        <Tag>
          <T _str="True" />
        </Tag>
      )
    case null:
    case '':
      return (
        <Tag>
          <T _str="None" />
        </Tag>
      )
    default:
      return val
  }
}

const ModifiedLogDetail = ({ id, compact }: TModifiedLogDetailProps) => {
  const { data, loading } = useQuery(GET, {
    variables: {
      id,
    },
  })

  if (loading) return <Skeleton loading />
  if (!loading && !data) return <Empty description={<T _str="No data" />} />

  const { modifiedData } = data?.ticketEventLog || {}

  const {
    location,
    container,
    property,
    assignee,
    ticketType,
    id: ticketId,
  } = data?.ticketEventLog?.ticket || {}

  const changeData: TChangeDataType = JSON.parse(modifiedData)

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <InfoList
        style={{ margin: 0 }}
        list={Object.keys(changeData?.after).map(
          (fieldK): InfoListProps['list'][0] => {
            return {
              data: (
                <FromTo
                  from={Booleanize(changeData.before[fieldK] as boolean)}
                  to={Booleanize(changeData.after[fieldK] as boolean)}
                />
              ),
              label: fieldsLabelMap[fieldK] || fieldK,
              labelColProps: { style: { flexShrink: 0, alignSelf: 'start' } },
            }
          }
        )}
      />
      <Divider style={{ margin: 0 }} />
      <CRMInfoList
        isContainerLevel={ticketType?.isContainerLevel}
        compact={compact}
        loading={loading}
        content={{
          address:
            location?.name ||
            container?.location?.name ||
            property?.location?.name,
          assigne: assignee && {
            id: assignee?.id,
            name: assignee?.firstName
              ?.concat(` `)
              .concat(assignee?.lastName || ``),
          },
          container: container && {
            id: container.id,
            name: container.containerId,
          },
          property: property && {
            id: property.id,
            name: property.location?.name,
          },
          ticket: ticketId && {
            id: ticketId,
            name: ticketType.name,
          },
        }}
      />
    </Space>
  )
}

export default ModifiedLogDetail
