import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TTicketCreatedBy =
  | 'reporterByDriver'
  | 'requestedByPortal'
  | 'reportedByPlatform'

export const ticketCreatedByLabelMap: Record<TTicketCreatedBy, ReactNode> = {
  reporterByDriver: <T _str="Created by driver" />,
  requestedByPortal: <T _str="Created by crm user" />,
  reportedByPlatform: <T _str="Created by user" />,
}

export const TICKET_CREATED_BY_OPTIONS = createOptionsFromLabelMap(
  ticketCreatedByLabelMap
)
