/* eslint-disable no-plusplus */
/* eslint-disable no-template-curly-in-string */
import React from 'react'
import moment from 'moment'
import { T } from '@transifex/react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import client from '../../graphql/apollo'
import Logo from '../../../images/logo.svg'
import { getWasteCategory, getWasteType } from '../analyticsComponents/utils'

export const getDate = (date: $TSFixMe) =>
  date ? moment(date).format('DD/MM/YYYY') : ''

export const getLocalDate = (date: $TSFixMe) =>
  date ? moment(date).local().format('DD/MM/YYYY') : ''

export const checkUserIsDemo = (state: $TSFixMe) => !!state.settings.user.isDemo

export const checkIfMomentDateIsValid = (date: $TSFixMe) =>
  moment(date).isValid() ? moment(date) : null

export const getSavedActiveProjects = (state: $TSFixMe) =>
  state.settings.activeProjects.map((project: $TSFixMe) => project.id).join(',')

export const getSavedActiveCompanies = (state: $TSFixMe) =>
  state.settings.user.activeProjects.edges.map(
    ({ node }: $TSFixMe) => node.company.id
  )

export const capitalizeFirstLetter = (string: $TSFixMe) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const getProjectSettings = (state: $TSFixMe) => {
  if (state.settings.user.activeProjects.edges.length) {
    const { node: project } = state.settings.user.activeProjects.edges[0]
    return project.settings
  }
  return {}
}

export const calcWeight = (projectSettings: $TSFixMe, value: $TSFixMe) => {
  switch (projectSettings.weight) {
    case 'KG':
      return value
    case 'T':
      return value * 0.001
    case 'LBS':
      return value * 2.2046
    default:
      return value
  }
}

export const calcVolume = (projectSettings: $TSFixMe, value: $TSFixMe) => {
  switch (projectSettings.volume) {
    case 'M3':
      return value
    case 'L':
      return value * 1000
    case 'YD3':
      return value * 1.308
    case 'FT3':
      return value * 28.316847
    default:
      return value
  }
}

export const calcDrivingDistance = (
  projectSettings: $TSFixMe,
  value: $TSFixMe
) => {
  switch (projectSettings.drivingDistance) {
    case 'M':
      return value
    case 'KM':
      return value * 0.001
    case 'MI':
      return value * 0.00062137
    case 'YD':
      return value * 1.093613
    case 'FT':
      return value * 3.28084
    default:
      return value
  }
}

export const getUsersAvailableProjects = (state: $TSFixMe) =>
  state.settings.user.activeProjects.edges

export const getWasteFractionFromContainer = (container: $TSFixMe) => {
  const { wasteFraction = {} } = container || {}

  return (
    wasteFraction &&
    `${getWasteType(wasteFraction)} ${getWasteCategory(wasteFraction)}`.trim()
  )
}

export const getDeviceFromContainer = (container = {}) =>
  // Return first active sensor from the container
  // deviceToContainerSet has a history of all sensor attached to it
  // container can has more than one active sensor, we are returning only first active
  (container as $TSFixMe).deviceToContainerSet?.edges?.find(
    (i: $TSFixMe) => !i.node.endDate
  )?.node

export const getMeasurementsFromContainer = (container: $TSFixMe) => {
  const { deviceToContainerSet = {} } = container || {}
  const { edges: device = [] } = deviceToContainerSet || {}
  const fillLevelMeasurementSets: $TSFixMe = []
  let result: $TSFixMe = []

  device.forEach(({ node }: $TSFixMe) => {
    // Join all measurement from different devices
    if (
      node.filllevelmeasurementSet &&
      node.filllevelmeasurementSet.edges.length
    ) {
      fillLevelMeasurementSets.push(node.filllevelmeasurementSet.edges || [])
    }
  })

  result = result.concat(...fillLevelMeasurementSets)

  // Sort by createdAt
  result = result.sort(
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'a' implicitly has an 'any' type.
    ({ node: { createdAt: a } }, { node: { createdAt: b } }) =>
      // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      moment(a).format('X') - moment(b).format('X')
  )

  return [result, device]
}

export const logger =
  (debug: $TSFixMe) =>
  (...messages: $TSFixMe[]) => {
    if (debug) {
      window.console.debug('[DEBUG]', ...messages)
    }
  }

export const saveCacheRead = async (query: $TSFixMe, debug = false) => {
  const queryLogger = logger(debug)
  try {
    const cachedQuery = await client.readQuery(query)
    queryLogger('cachedQuery - ', cachedQuery)
    return cachedQuery ? { data: cachedQuery } : await client.query(query)
  } catch (e) {
    queryLogger('get cache error - ', e)
    const apiQuery = await client.query(query)
    queryLogger('api query - ', e)
    return apiQuery
  }
}

export const IsJson = (str: $TSFixMe) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const toastifyError = (error: $TSFixMe) => {
  if (error.graphQLErrors && error.graphQLErrors.length) {
    error.graphQLErrors?.forEach((graphQLError: $TSFixMe) => {
      const message = graphQLError?.message?.replace(/'/gi, '"')
      if (IsJson(message)) {
        const jsonMessage = JSON.parse(message)
        Reflect.ownKeys(jsonMessage).forEach((field) => {
          toast.error(jsonMessage[field].join(', '))
        })
      } else {
        toast.error(message)
      }
    })
  } else if (error?.networkError?.result?.errors) {
    error.networkError.result.errors.forEach((networkError: $TSFixMe) =>
      toast.error(networkError.message)
    )
  } else {
    toast.error(error.message)
  }
}

export const getMainLogo = (user: $TSFixMe) => {
  if (user && user.isAdmin) {
    return Logo
  }

  if (user && user.activeProjects && user.activeProjects.edges[0]) {
    if (user.activeProjects.edges.length > 1) {
      return user?.company?.logo ? user.company.logo : Logo
    }
    return (
      user.activeProjects.edges[0]?.node?.logo || user?.company?.logo || Logo
    )
  }

  return user?.company?.logo || Logo
}

export const getJobTitle = (state: $TSFixMe) => {
  const {
    // @ts-expect-error ts-migrate(2525) FIXME: Initializer provides no value for this binding ele... Remove this comment to see the full error message
    settings: { user: { jobtitle: { title: jobTitle = '' } } = {} } = {},
  } = state

  return jobTitle
}

export const CurrentUserContext = React.createContext(null)

export const getFieldsErrors = (e: $TSFixMe) =>
  (e?.graphQLErrors || []).reduce((acc: $TSFixMe, graphQLError: $TSFixMe) => {
    if (graphQLError?.context?.field) {
      acc.push({
        field: graphQLError.context.field,
        message: graphQLError.message,
      })
    }
    return acc
  }, [])

export const sleep = (ms: $TSFixMe, ...args: $TSFixMe[]) =>
  // @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 0 or more.
  new Promise((resolve) => setTimeout(() => resolve(...args), ms))

export const validationMessages = {
  mixed: {
    default: <T _str="${path} is invalid" />,
    required: <T _str="This field is required" />,
    oneOf: <T _str="${path} must be one of the following values: ${values}" />,
    notOneOf: (
      <T _str="${path} must not be one of the following values: ${values}" />
    ),
    defined: <T _str="${path} must be defined" />,
  },
  string: {
    length: <T _str="${path} must be exactly ${length} characters" />,
    min: <T _str="${path} must be at least ${min} characters" />,
    max: <T _str="${path} must be at most ${max} characters" />,
    matches: <T _str="${path} must match the following: '${regex}'" />,
    email: <T _str="${path} must be a valid email" />,
    url: <T _str="${path} must be a valid URL" />,
    trim: <T _str="${path} must be a trimmed string" />,
    lowercase: <T _str="${path} must be a lowercase string" />,
    uppercase: <T _str="${path} must be a upper case string" />,
  },
  number: {
    min: <T _str="${path} must be greater than or equal to ${min}" />,
    max: <T _str="${path} must be less than or equal to ${max}" />,
    lessThan: <T _str="${path} must be less than ${less}" />,
    moreThan: <T _str="${path} must be greater than ${more}" />,
    notEqual: <T _str="${path} must be not equal to ${notEqual}" />,
    positive: <T _str="${path} must be a positive number" />,
    negative: <T _str="${path} must be a negative number" />,
    integer: <T _str="${path} must be an integer" />,
  },
  date: {
    min: <T _str="${path} field must be later than ${min}" />,
    max: <T _str="${path} field must be at earlier than ${max}" />,
  },
  boolean: {},
  object: {
    noUnknown: <T _str="${path} field has unspecified keys: ${unknown}" />,
  },
  array: {
    min: <T _str="${path} field must have at least ${min} items" />,
    max: (
      <T _str="${path} field must have less than or equal to ${max} items" />
    ),
  },
}

export const addValidationMethods = () => {
  Yup.addMethod(Yup.mixed, 'time', function testValueIsTime(this: $TSFixMe) {
    return this.test({
      name: 'time',
      message: (
        <T _str="Not valid time string" description="Validate min value" />
      ),
      test(value: $TSFixMe) {
        if (!value) {
          return true
        }
        return value.isValid()
      },
    })
  })

  Yup.addMethod(
    Yup.mixed,
    'timeGreaterThen',
    function testValueIsGreaterThen(
      this: $TSFixMe,
      compareWith: $TSFixMe,
      message = ''
    ) {
      return this.test({
        name: 'is-time-from-greater',
        message: message || (
          <T
            _str="Should be more then value in previous input"
            description="Validate min value"
          />
        ),
        test(currentValue: $TSFixMe) {
          const compareWithValue = this.parent[compareWith]
          if (compareWithValue && currentValue) {
            return currentValue.isAfter(compareWithValue)
          }
          return true
        },
      })
    }
  )

  Yup.addMethod(
    Yup.mixed,
    'timeLesserThen',
    function testValueIsGreaterThen(
      this: $TSFixMe,
      compareWith: $TSFixMe,
      message = ''
    ) {
      return this.test({
        name: 'is-time-from-greater',
        message: message || (
          <T
            _str="Should be less then value in previous input"
            description="Validate min value"
          />
        ),
        test(currentValue: $TSFixMe) {
          const compareWithValue = this.parent[compareWith]
          if (compareWithValue && currentValue) {
            return compareWithValue.isAfter(currentValue)
          }
          return true
        },
      })
    }
  )
}

export const maxLengthStringMessage = (maxLength: $TSFixMe) => (
  <T _str="Exceeded maximum length of {maxLength}" maxLength={maxLength} />
)

export const maxNumberMessage = (maxNumber: $TSFixMe) => (
  <T _str="Should be less than or equal to {maxNumber}" maxNumber={maxNumber} />
)

export const messageNoNegativeValues = (
  <T _str="Only positive numbers are allowed!" />
)

export const messageWrongCoords = (coordsValue: $TSFixMe) => (
  <T _str="Should be in range from -{value} to {value}" value={coordsValue} />
)

export const messageRequiredField = <T _str="This field is required" />

export const uniqueWasteFractions = (arr: $TSFixMe) => {
  let wFractions: $TSFixMe = []
  const fractions = arr.map((item: $TSFixMe) => item.node || item)

  fractions.forEach((item: $TSFixMe) => {
    const foundWFraction = wFractions.find(
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'wFraction' implicitly has an 'any' type... Remove this comment to see the full error message
      (wFraction) =>
        `${wFraction.wasteCategory} ${wFraction?.wasteType?.join(',')}` ===
        `${item.wasteCategory} ${item?.wasteType?.join(',')}`
    )

    if (foundWFraction) {
      wFractions = wFractions.filter(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'wFraction' implicitly has an 'any' type... Remove this comment to see the full error message
        (wFraction) =>
          `${wFraction.wasteCategory} ${wFraction?.wasteType?.join(',')}` !==
          `${item.wasteCategory} ${item?.wasteType?.join(',')}`
      )

      wFractions.push({
        ...foundWFraction,
        id: foundWFraction.id.concat(`,${item.id}`),
      })
    } else {
      wFractions.push(item)
    }
  })
  return wFractions
}

type TimeRange = {
  start: moment.Moment | null
  end: moment.Moment | null
}

const HOUR_IN_MINUTES = Array.from(Array(60).keys())
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24

export const getDisabledHours = (timeRange: TimeRange) => {
  // disabled hours
  const hours = []
  const { start, end } = timeRange
  const startHour = start?.hour() ?? 0
  const endHour = end?.hour() ?? 0
  for (let curr = 0; curr < HOURS_IN_DAY; curr++) {
    if (curr < startHour || curr > endHour) {
      hours.push(curr)
    }
  }

  return hours
}

export const getDisabledMinutes = (
  selectedHour: number,
  timeRange: TimeRange
) => {
  // disabled minutes
  const minutes = []
  const { start, end } = timeRange

  const startHour = start?.hour() ?? 0
  const startMinute = start?.minute() ?? 0

  const endHour = end?.hour() ?? 0
  const endMinute = end?.minute() ?? 0

  if (selectedHour === -1) {
    // disable all minutes
    return HOUR_IN_MINUTES
  }

  if (selectedHour <= startHour) {
    for (let i = 0; i < MINUTES_IN_HOUR; i++) {
      // disable only if less than start time
      if (i < startMinute) {
        minutes.push(i)
      }
    }
    return minutes
  }

  if (selectedHour >= endHour) {
    for (let i = 0; i < MINUTES_IN_HOUR; i++) {
      // disable only for greater than end time
      if (i > endMinute) {
        minutes.push(i)
      }
    }
  }
  return minutes
}
