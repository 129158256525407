import React from 'react'
import { T } from '@transifex/react'

type Property = {
  externalPropertyNumber: string
  location: {
    name?: string
  }
}

const getCellPropertyName = (
  /**
   * Graph API type: PropertyDef!
   * Schema: {
   *  externalPropertyNumber
   *   location {
   *     name
   *   }
   * }
   */
  property: Property
) => {
  return property?.location?.name
    ? `${property?.location?.name} | ${property?.externalPropertyNumber}`
    : property?.externalPropertyNumber
}

const withTitle = (
  /**
   * Graph API type: PropertyDef!
   * Schema: {
   *  externalPropertyNumber
   *   location {
   *     name
   *   }
   * }
   */
  property: Property
) => <T _str="Property: {label}" label={getCellPropertyName(property)} />

getCellPropertyName.withTitle = withTitle

export { getCellPropertyName }
