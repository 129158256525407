import React from 'react'
import { Select, SelectProps } from 'antd'
import { T } from '@transifex/react'

import { Opt } from './types'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectCurrencyProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps<Opt>, 'options' | 'loading'>

const options = [
  { value: 'DKK', label: <T _str="Danish krone (DKK)" /> },
  { value: 'USD', symbol: '$', label: <T _str="US dollar (USD)" /> },
  { value: 'EUR', symbol: '€', label: <T _str="Euro (EUR)" /> },
  { value: 'AED', label: <T _str="United Arab Emirates dirham (AED)" /> },
  { value: 'AFN', label: <T _str="Afghan afghani (AFN)" /> },
  { value: 'ALL', label: <T _str="Albanian lek (ALL)" /> },
  { value: 'AMD', label: <T _str="Armenian dram (AMD)" /> },
  { value: 'ANG', label: <T _str="Netherlands Antillean guilder (ANG)" /> },
  { value: 'AOA', label: <T _str="Angolan kwanza (AOA)" /> },
  { value: 'ARS', label: <T _str="Argentine peso (ARS)" /> },
  { value: 'AUD', label: <T _str="Australian dollar (AUD)" /> },
  { value: 'AWG', label: <T _str="Aruban florin (AWG)" /> },
  { value: 'AZN', label: <T _str="Azerbaijani manat (AZN)" /> },
  {
    value: 'BAM',
    label: <T _str="Bosnia and Herzegovina convertible mark (BAM)" />,
  },
  { value: 'BBD', label: <T _str="Barbadian dollar (BBD)" /> },
  { value: 'BDT', label: <T _str="Bangladeshi taka (BDT)" /> },
  { value: 'BGN', label: <T _str="Bulgarian lev (BGN)" /> },
  { value: 'BHD', label: <T _str="Bahraini dinar (BHD)" /> },
  { value: 'BIF', label: <T _str="Burundian franc (BIF)" /> },
  { value: 'BMD', label: <T _str="Bermudian dollar (BMD)" /> },
  { value: 'BND', label: <T _str="Brunei dollar (BND)" /> },
  { value: 'BOB', label: <T _str="Bolivian boliviano (BOB)" /> },
  { value: 'BRL', label: <T _str="Brazilian real (BRL)" /> },
  { value: 'BSD', label: <T _str="Bahamian dollar (BSD)" /> },
  { value: 'BTN', label: <T _str="Bhutanese ngultrum (BTN)" /> },
  { value: 'BWP', label: <T _str="Botswana pula (BWP)" /> },
  { value: 'BYN', label: <T _str="Belarusian ruble (BYN)" /> },
  { value: 'BZD', label: <T _str="Belize dollar (BZD)" /> },
  { value: 'CAD', label: <T _str="Canadian dollar (CAD)" /> },
  { value: 'CDF', label: <T _str="Congolese franc (CDF)" /> },
  { value: 'CHF', label: <T _str="Swiss franc (CHF)" /> },
  { value: 'CLP', label: <T _str="Chilean peso (CLP)" /> },
  { value: 'CNY', symbol: '¥', label: <T _str="Chinese yuan (CNY)" /> },
  { value: 'COP', label: <T _str="Colombian peso (COP)" /> },
  { value: 'CRC', label: <T _str="Costa Rican colón (CRC)" /> },
  { value: 'CUC', label: <T _str="Cuban convertible peso (CUC)" /> },
  { value: 'CUP', label: <T _str="Cuban peso (CUP)" /> },
  { value: 'CVE', label: <T _str="Cape Verdean escudo (CVE)" /> },
  { value: 'CZK', label: <T _str="Czech koruna (CZK)" /> },
  { value: 'DJF', label: <T _str="Djiboutian franc (DJF)" /> },
  { value: 'DOP', label: <T _str="Dominican peso (DOP)" /> },
  { value: 'DZD', label: <T _str="Algerian dinar (DZD)" /> },
  { value: 'EGP', label: <T _str="Egyptian pound (EGP)" /> },
  { value: 'ERN', label: <T _str="Eritrean nakfa (ERN)" /> },
  { value: 'ETB', label: <T _str="Ethiopian birr (ETB)" /> },
  { value: 'FJD', label: <T _str="Fijian dollar (FJD)" /> },
  { value: 'FKP', label: <T _str="Falkland Islands pound (FKP)" /> },
  { value: 'GBP', symbol: '£', label: <T _str="British pound (GBP)" /> },
  { value: 'GEL', label: <T _str="Georgian lari (GEL)" /> },
  { value: 'GGP', label: <T _str="Guernsey pound (GGP)" /> },
  { value: 'GHS', label: <T _str="Ghanaian cedi (GHS)" /> },
  { value: 'GIP', label: <T _str="Gibraltar pound (GIP)" /> },
  { value: 'GMD', label: <T _str="Gambian dalasi (GMD)" /> },
  { value: 'GNF', label: <T _str="Guinean franc (GNF)" /> },
  { value: 'GTQ', label: <T _str="Guatemalan quetzal (GTQ)" /> },
  { value: 'GYD', label: <T _str="Guyanese dollar (GYD)" /> },
  { value: 'HNL', label: <T _str="Honduran lempira (HNL)" /> },
  { value: 'HKD', label: <T _str="Hong Kong dollar (HKD)" /> },
  { value: 'HRK', label: <T _str="Croatian kuna (HRK)" /> },
  { value: 'HTG', label: <T _str="Haitian gourde (HTG)" /> },
  { value: 'HUF', label: <T _str="Hungarian forint (HUF)" /> },
  { value: 'IDR', label: <T _str="Indonesian rupiah (IDR)" /> },
  { value: 'ILS', label: <T _str="Israeli new shekel (ILS)" /> },
  { value: 'IMP', label: <T _str="Manx pound (IMP)" /> },
  { value: 'INR', label: <T _str="Indian rupee (INR)" /> },
  { value: 'IQD', label: <T _str="Iraqi dinar (IQD)" /> },
  { value: 'IRR', label: <T _str="Iranian rial (IRR)" /> },
  { value: 'ISK', label: <T _str="Icelandic króna (ISK)" /> },
  { value: 'JEP', label: <T _str="Jersey pound (JEP)" /> },
  { value: 'JMD', label: <T _str="Jamaican dollar (JMD)" /> },
  { value: 'JOD', label: <T _str="Jordanian dinar (JOD)" /> },
  { value: 'JPY', symbol: '¥', label: <T _str="Japanese yen (JPY)" /> },
  { value: 'KES', label: <T _str="Kenyan shilling (KES)" /> },
  { value: 'KGS', label: <T _str="Kyrgyzstani som (KGS)" /> },
  { value: 'KHR', label: <T _str="Cambodian riel (KHR)" /> },
  { value: 'KID', label: <T _str="Kiribati dollar (KID)" /> },
  { value: 'KMF', label: <T _str="Comorian franc (KMF)" /> },
  { value: 'KPW', label: <T _str="North Korean won (KPW)" /> },
  { value: 'KRW', label: <T _str="South Korean won (KRW)" /> },
  { value: 'KWD', label: <T _str="Kuwaiti dinar (KWD)" /> },
  { value: 'KYD', label: <T _str="Cayman Islands dollar (KYD)" /> },
  { value: 'KZT', label: <T _str="Kazakhstani tenge (KZT)" /> },
  { value: 'LAK', label: <T _str="Lao kip (LAK)" /> },
  { value: 'LBP', label: <T _str="Lebanese pound (LBP)" /> },
  { value: 'LKR', label: <T _str="Sri Lankan rupee (LKR)" /> },
  { value: 'LRD', label: <T _str="Liberian dollar (LRD)" /> },
  { value: 'LSL', label: <T _str="Lesotho loti (LSL)" /> },
  { value: 'LYD', label: <T _str="Libyan dinar (LYD)" /> },
  { value: 'MAD', label: <T _str="Moroccan dirham (MAD)" /> },
  { value: 'MDL', label: <T _str="Moldovan leu (MDL)" /> },
  { value: 'MGA', label: <T _str="Malagasy ariary (MGA)" /> },
  { value: 'MKD', label: <T _str="Macedonian denar (MKD)" /> },
  { value: 'MMK', label: <T _str="Burmese kyat (MMK)" /> },
  { value: 'MNT', label: <T _str="Mongolian tögrög (MNT)" /> },
  { value: 'MOP', label: <T _str="Macanese pataca (MOP)" /> },
  { value: 'MRU', label: <T _str="Mauritanian ouguiya (MRU)" /> },
  { value: 'MUR', label: <T _str="Mauritian rupee (MUR)" /> },
  { value: 'MVR', label: <T _str="Maldivian rufiyaa (MVR)" /> },
  { value: 'MWK', label: <T _str="Malawian kwacha (MWK)" /> },
  { value: 'MXN', label: <T _str="Mexican peso (MXN)" /> },
  { value: 'MYR', label: <T _str="Malaysian ringgit (MYR)" /> },
  { value: 'MZN', label: <T _str="Mozambican metical (MZN)" /> },
  { value: 'NAD', label: <T _str="Namibian dollar (NAD)" /> },
  { value: 'NGN', label: <T _str="Nigerian naira (NGN)" /> },
  { value: 'NIO', label: <T _str="Nicaraguan córdoba (NIO)" /> },
  { value: 'NOK', label: <T _str="Norwegian krone (NOK)" /> },
  { value: 'NPR', label: <T _str="Nepalese rupee (NPR)" /> },
  { value: 'NZD', label: <T _str="New Zealand dollar (NZD)" /> },
  { value: 'OMR', label: <T _str="Omani rial (OMR)" /> },
  { value: 'PAB', label: <T _str="Panamanian balboa (PAB)" /> },
  { value: 'PEN', label: <T _str="Peruvian sol (PEN)" /> },
  { value: 'PGK', label: <T _str="Papua New Guinean kina (PGK)" /> },
  { value: 'PHP', label: <T _str="Philippine peso (PHP)" /> },
  { value: 'PKR', label: <T _str="Pakistani rupee (PKR)" /> },
  { value: 'PLN', label: <T _str="Polish złoty (PLN)" /> },
  { value: 'PRB', symbol: '₽', label: <T _str="Transnistrian ruble (PRB)" /> },
  { value: 'PYG', label: <T _str="Paraguayan guaraní (PYG)" /> },
  { value: 'QAR', label: <T _str="Qatari riyal (QAR)" /> },
  { value: 'RON', label: <T _str="Romanian leu (RON)" /> },
  { value: 'RSD', label: <T _str="Serbian dinar (RSD)" /> },
  { value: 'RUB', label: <T _str="Russian ruble (RUB)" /> },
  { value: 'RWF', label: <T _str="Rwandan franc (RWF)" /> },
  { value: 'SAR', label: <T _str="Saudi riyal (SAR)" /> },
  { value: 'SEK', label: <T _str="Swedish krona (SEK)" /> },
  { value: 'SGD', label: <T _str="Singapore dollar (SGD)" /> },
  { value: 'SHP', label: <T _str="Saint Helena pound (SHP)" /> },
  { value: 'SLL', label: <T _str="Sierra Leonean leone (SLL)" /> },
  { value: 'SLS', label: <T _str="Somaliland shilling (SLS)" /> },
  { value: 'SOS', label: <T _str="Somali shilling (SOS)" /> },
  { value: 'SRD', label: <T _str="Surinamese dollar (SRD)" /> },
  { value: 'SSP', label: <T _str="South Sudanese pound (SSP)" /> },
  { value: 'STN', label: <T _str="São Tomé and Príncipe dobra (STN)" /> },
  { value: 'SYP', label: <T _str="Syrian pound (SYP)" /> },
  { value: 'SZL', label: <T _str="Swazi lilangeni (SZL)" /> },
  { value: 'THB', label: <T _str="Thai baht (THB)" /> },
  { value: 'TJS', label: <T _str="Tajikistani somoni (TJS)" /> },
  { value: 'TMT', label: <T _str="Turkmenistan manat (TMT)" /> },
  { value: 'TND', label: <T _str="Tunisian dinar (TND)" /> },
  { value: 'TOP', label: <T _str="Tongan paʻanga (TOP)" /> },
  { value: 'TRY', label: <T _str="Turkish lira (TRY)" /> },
  { value: 'TTD', label: <T _str="Trinidad and Tobago dollar (TTD)" /> },
  { value: 'TVD', label: <T _str="Tuvaluan dollar (TVD)" /> },
  { value: 'TWD', label: <T _str="New Taiwan dollar (TWD)" /> },
  { value: 'TZS', label: <T _str="Tanzanian shilling (TZS)" /> },
  { value: 'UAH', symbol: '₴', label: <T _str="Ukrainian hryvnia (UAH)" /> },
  { value: 'UGX', label: <T _str="Ugandan shilling (UGX)" /> },
  { value: 'UYU', label: <T _str="Uruguayan peso (UYU)" /> },
  { value: 'UZS', label: <T _str="Uzbekistani soʻm (UZS)" /> },
  { value: 'VES', label: <T _str="Venezuelan bolívar soberano (VES)" /> },
  { value: 'VND', label: <T _str="Vietnamese đồng (VND)" /> },
  { value: 'VUV', label: <T _str="Vanuatu vatu (VUV)" /> },
  { value: 'WST', label: <T _str="Samoan tālā (WST)" /> },
  { value: 'XAF', label: <T _str="Central African CFA franc (XAF)" /> },
  { value: 'XCD', label: <T _str="Eastern Caribbean dollar (XCD)" /> },
  { value: 'XOF', label: <T _str="West African CFA franc (XOF)" /> },
  { value: 'XPF', label: <T _str="CFP franc (XPF)" /> },
  { value: 'ZAR', label: <T _str="South African rand (ZAR)" /> },
  { value: 'ZMW', label: <T _str="Zambian kwacha (ZMW)" /> },
  { value: 'ZWB', label: <T _str="Zimbabwean bonds (ZWB)" /> },
]

export const SelectCurrency = ({
  render,
  ...selectProps
}: SelectCurrencyProps) => {
  return (
    render?.({
      options,
      loading: false,
    }) || (
      <Select
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}
