import { gql } from '@apollo/client'
/* MUTATIONS */
export const MUTATION_CHECK_DEVICE_ID = gql`
  mutation MutationCheckDeviceId($deviceId: ID!, $projectId: ID!) {
    checkDeviceId(deviceId: $deviceId, projectId: $projectId) {
      status
    }
  }
`

export const CREATE_OR_UPDATE_CONTAINER = gql`
  mutation CreateOrUpdateContainer(
    $containerId: String!
    $containerTypeId: ID!
    $description: String
    $locationLatitude: Float!
    $locationLongitude: Float!
    $locationName: String!
    $overflowingSettings: OverflowingSettingsObject
    $photo: Upload
    $pickupSettingId: ID
    $projectId: ID!
    $selfId: ID
    $showOnRoute: Boolean
    $status: String!
    $wasteCollectorId: ID
    $wasteFractionId: ID!
    $devicesToContainer: [SensorInputObject]
    $allowedHoursStart: String
    $allowedHoursEnd: String
    $sensorFillLevel: Int
    $sensorFillLevelPeriodHours: Int
    $pickupMethod: String!
    $prioritizeInStartOfRoute: Boolean
    $containerMetaValues: [ContainerMetaValueInputObject]
  ) {
    updateOrCreateContainer(
      containerId: $containerId
      containerTypeId: $containerTypeId
      description: $description
      locationLatitude: $locationLatitude
      locationLongitude: $locationLongitude
      locationName: $locationName
      overflowingSettings: $overflowingSettings
      photo: $photo
      pickupSettingId: $pickupSettingId
      projectId: $projectId
      selfId: $selfId
      showOnRoute: $showOnRoute
      status: $status
      wasteCollectorId: $wasteCollectorId
      wasteFractionId: $wasteFractionId
      devicesToContainer: $devicesToContainer
      allowedHoursStart: $allowedHoursStart
      allowedHoursEnd: $allowedHoursEnd
      sensorFillLevel: $sensorFillLevel
      sensorFillLevelPeriodHours: $sensorFillLevelPeriodHours
      pickupMethod: $pickupMethod
      prioritizeInStartOfRoute: $prioritizeInStartOfRoute
      containerMetaValues: $containerMetaValues
    ) {
      container {
        id
        allowedHoursEnd
        allowedHoursStart
        description
        pickupMethod
        prioritizeInStartOfRoute
        sensorFillLevel
        pickupSetting {
          id
          name
        }
        containerId
        photo
        photoUrl
        containerType {
          height
        }
        project {
          id
        }
        metaValues {
          value
          companyContainerMetaType {
            id
          }
        }
        location {
          latitude
          longitude
          name
        }
        deviceToContainerSet {
          edges {
            node {
              id
              objectId
              internalHeight
              offset
              deviceAngle
              topLid
              sideLid
              endDate
              device {
                id
                devId
              }
              measurementSettings {
                id
              }
            }
          }
        }
        wasteFraction {
          id
          wasteType
        }
        containerType {
          id
          name
          height
        }
        status
      }
    }
  }
`

export const GET_CONTAINER_INIT = gql`
  query GetContainer($id: ID!) {
    container(id: $id) {
      id
      allowedHoursEnd
      allowedHoursStart
      description
      pickupMethod
      prioritizeInStartOfRoute
      sensorFillLevel
      pickupSetting {
        id
        name
      }
      containerId
      photo
      photoUrl
      containerType {
        height
      }
      project {
        id
      }
      metaValues {
        value
        companyContainerMetaType {
          id
        }
      }
      location {
        latitude
        longitude
        name
      }
      deviceToContainerSet {
        edges {
          node {
            id
            objectId
            internalHeight
            offset
            deviceAngle
            topLid
            sideLid
            endDate
            device {
              id
              devId
            }
            measurementSettings {
              id
            }
          }
        }
      }
      wasteFraction {
        id
        wasteType
      }
      containerType {
        id
        name
        height
      }
      status
      measurement {
        fillPercentage
      }
    }
  }
`
