import { pathJoin } from '../../../../utils'
import { createContainerCreateRouteMap } from './container-create'
import { createContainerRouteMap } from './container-detailed-view'

export const createRoutesMapContainers = (prefix: string) => ({
  create: pathJoin(prefix)('create').chainMapAuto(
    createContainerCreateRouteMap
  ),
  'add-sensor': pathJoin(prefix)('add-sensor').chain,
  ':containerId': (containerId: string) =>
    pathJoin(prefix)(containerId).chainMapAuto(createContainerRouteMap),
})
