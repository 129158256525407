import React from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'
import {
  createAsyncSelectOnMount,
  AsyncSelectOnMountPropsExtended,
  THookCreateAsyncSelectOnMountResponse,
} from './factories/createAsyncSelectOnMount'
import { useSavedActiveProjects } from '../../hooks/useSavedActiveProjects'

import { DataType } from './factories/response-to-options/getEdgesNodeNameId'

const GET_QUERY = gql`
  query (
    $companyId: ID
    $activeProjects: String
    $isPropertyLevel: Boolean
    $isContainerLevel: Boolean
  ) {
    allTicketTypes(
      companyId: $companyId
      activeProjects: $activeProjects
      isPropertyLevel: $isPropertyLevel
      isContainerLevel: $isContainerLevel
    ) {
      edges {
        node {
          id
          name
          service {
            id
            serviceType
          }
        }
      }
    }
  }
`

type TVariables = {
  companyId?: string
  activeProjects?: string
  isPropertyLevel?: boolean
  isContainerLevel?: boolean
}

type TResponse = DataType<
  'allTicketTypes',
  {
    id: string
    name: string
    service: {
      serviceType: string
    }
  }
>

/* @ts-expect-error We will add it before we export. */
const SelectTicketType: THookCreateAsyncSelectOnMountResponse<TVariables> & {
  /**
   * @deprecated
   * Use the main select.
   */
  WithActiveProjects: THookCreateAsyncSelectOnMountResponse<TVariables>
} = createAsyncSelectOnMount<TResponse, TVariables>({
  formatResponse: (data) =>
    data?.allTicketTypes?.edges?.map(({ node }) => {
      return {
        label: node.name,
        value: node.id,
        serviceType: node.service?.serviceType,
      }
    }),
  defaultSelectProps: {
    filterOption: true,
    optionFilterProp: 'label',
    placeholder: <T _str="Ticket type" />,
    allowClear: true,
    showSearch: true,
  },
  query: GET_QUERY,
  usesActiveProjects: true,
})

const UseActiveProjects = ({
  variables,
  ...props
}: AsyncSelectOnMountPropsExtended<TVariables>) => {
  const activeProjects = useSavedActiveProjects()

  return (
    <SelectTicketType {...props} variables={{ activeProjects, ...variables }} />
  )
}

SelectTicketType.WithActiveProjects = UseActiveProjects

export { SelectTicketType }
