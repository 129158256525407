import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'
import React from 'react'
import { Opt } from '../options'

export type TSelectUnitMassValue = 'KG' | 'LBS'

const options: Opt<TSelectUnitMassValue>[] = [
  { value: 'KG', label: <T _str="Kilogram (KG)" /> },
  { value: 'LBS', label: <T _str="Pound (LBS)" /> },
]

const SelectUnitMass = (props: SelectProps) => {
  return <Select options={options} {...props} />
}

export default SelectUnitMass
