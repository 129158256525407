import { gql } from '@apollo/client'

export type GetCallResponseNode = {
  id: string
  scheduledDay: string
  startTime: string
  endTime: string
  completedStopsCount: number
  stopsCount: number
  wasteFraction?: {
    name: string
  }
  status: string
  vehicle: {
    id: string
    name: string
  }
  driver: {
    id: string
    user: {
      id: string
      firstName: string
      lastName: string
    }
  }
}

export type GetCallResponse = {
  ticket: {
    id: string
    availableRoutes: {
      totalCount: number
      edges: { node: GetCallResponseNode }[]
    }
  }
}

export type GetCallVariables = {
  ticketId: string
  drivers?: string[]
  vehicles?: string[]
  search?: string
  orderBy?: string
  order?: 'asc' | 'desc'
  activeProjects?: string
}

export const GET_TICKET_ROUTES = gql`
  query Component_Select_TicketRoute_AvailableRoutes(
    $ticketId: ID!
    $drivers: String
    $vehicles: String
    $search: String
    $first: Int
    $orderBy: String
    $order: String
    $offset: Int
    $scheduledDay_Gte: Date
    $scheduledDay_Lte: Date
    $activeProjects: String
  ) {
    ticket(id: $ticketId) {
      id
      availableRoutes(
        drivers: $drivers
        vehicles: $vehicles
        search: $search
        first: $first
        orderBy: $orderBy
        order: $order
        offset: $offset
        scheduledDay_Gte: $scheduledDay_Gte
        scheduledDay_Lte: $scheduledDay_Lte
        activeProjects: $activeProjects
      ) {
        totalCount
        edges {
          node {
            id
            wasteFraction {
              name
            }
            status
            completedStopsCount
            stopsCount
            scheduledDay
            startTime
            endTime
            vehicle {
              id
              name
            }
            driver {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`
