import React from 'react'
import { FillViewHeight } from '@wastehero/storybook/lib/layouts'

import {
  pathJoin,
  CreateConfigFn,
} from '../../../../../../reducers/routing/utils'
import Invoice from './invoice'
import Overview from './overview'
import EventLog from './events-logs'
import Containers from './containers/table'
import TicketTable from './tickets'
import Collections from './collections'

export type Keys =
  | 'containers'
  | 'collection-log'
  | 'event-log'
  | 'tickets'
  | `invoices`
  | 'overview'
  | 'calendar'

export const createRouteMap = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chain,
  'collection-log': pathJoin(prefix)('collection-log').chain,
  'event-log': pathJoin(prefix)('event-log').chain,
  tickets: pathJoin(prefix)('tickets').chain,
  invoices: pathJoin(prefix)('invoices').chain,
  overview: pathJoin(prefix)('overview').chain,
  calendar: pathJoin(prefix)('calendar').chain,
})

export const createConfig: CreateConfigFn<Keys> = (map) => {
  // eslint-disable-next-line react/destructuring-assignment
  const p = map.app['customer-management'].property['/:id'](':customerId')

  return [
    {
      key: 'overview',
      path: p.overview._,
      children: (
        <FillViewHeight offset="30px" useHeight>
          <Overview />
        </FillViewHeight>
      ),
      fallback: () => `/login`,
      roles: {
        and: ['authenticated'],
      },
    },
    {
      key: 'containers',
      path: p.containers._,
      children: (
        <FillViewHeight offset="30px">
          <Containers />
        </FillViewHeight>
      ),
      fallback: () => ``,
      roles: {
        and: ['authenticated'],
      },
    },
    {
      key: 'collection-log',
      path: p['collection-log']._,
      children: (
        <FillViewHeight offset="30px">
          <Collections />
        </FillViewHeight>
      ),
      fallback: () => ``,
      roles: {
        and: ['authenticated'],
      },
    },
    {
      key: 'event-log',
      path: p['event-log']._,
      children: (
        <FillViewHeight offset="30px">
          <EventLog />
        </FillViewHeight>
      ),
      fallback: () => ``,
      roles: {
        and: ['authenticated'],
      },
    },
    {
      key: 'tickets',
      path: p.tickets._,
      children: (
        <FillViewHeight offset="30px">
          <TicketTable />
        </FillViewHeight>
      ),
      fallback: () => ``,
      roles: {
        and: ['authenticated'],
      },
    },
    {
      key: 'invoices',
      path: p.invoices._,
      children: (
        <FillViewHeight offset="30px">
          <Invoice />
        </FillViewHeight>
      ),
      fallback: () => ``,
      roles: {
        and: ['authenticated'],
      },
    },
  ]
}
