import React from 'react'
import { useQuery } from '@apollo/client'
import { T } from '@transifex/react'

import {
  InfoCardSimple,
  InfoList,
  InfoListProps,
} from '@wastehero/storybook/lib/layouts/cards/LayoutInfoCardSimple'
import { UserOutlined } from '@ant-design/icons'
import { omit } from 'lodash'
import { Typography } from '@wastehero/storybook/lib/components'
import {
  GET_PROPERTY_INFO,
  GetPropertyInfoVariables,
  GetPropertyInfoResponse,
} from './api'

import { useCustomerContext } from '../../../customer.context'

const PropertyMasterDataCard = () => {
  const { id: propertyId } = useCustomerContext()
  const { data, loading } = useQuery<
    GetPropertyInfoResponse,
    GetPropertyInfoVariables
  >(GET_PROPERTY_INFO, {
    variables: {
      propertyId,
    },
  })

  let labelList: InfoListProps['list'] = []
  if (!loading && data?.propertyInfo) {
    const { owners, administrators } = data.propertyInfo

    const ownersList = owners.map((o) => ({
      label: (
        <>
          <UserOutlined />
        </>
      ),
      data: (
        <>
          {o.name}&nbsp;
          <Typography variant="body-12" style={{ color: '#909DA9' }}>
            <T _str="(owner)" />
          </Typography>
        </>
      ),
    }))
    const administratorsList = administrators.map((o) => ({
      label: (
        <>
          <UserOutlined />
        </>
      ),
      data: (
        <>
          {o.name}&nbsp;
          <Typography variant="body-12" style={{ color: '#909DA9' }}>
            <T _str="(administrator)" />
          </Typography>
        </>
      ),
    }))
    const labelListRaw: (InfoListProps['list'][0] & { exclude?: boolean })[] = [
      ...ownersList,
      ...administratorsList,
    ]

    labelList = labelListRaw
      .filter(({ exclude }) => !exclude)
      .map<InfoListProps['list'][0]>((p) => omit(p, 'exclude'))
  }

  if (!loading && !data?.propertyInfo) {
    return <></>
  }
  return (
    <InfoCardSimple title={<T _str="Master data" />} loading={loading}>
      <InfoList loading={loading} list={labelList} />
    </InfoCardSimple>
  )
}

export default PropertyMasterDataCard
