import React from 'react'
import { Select, SelectProps } from 'antd'
import { T } from '@transifex/react'
import { Opt } from '../../../../../../new-components'

export type Opts = 1 | 10 | 30 | 180 | 0

const options: Opt<Opts>[] = [
  {
    value: 1,
    label: <T _str="Today" />,
  },
  {
    value: 10,
    label: <T _str="Last 10 days" />,
  },
  {
    value: 30,
    label: <T _str="Last 30 days" />,
  },
  {
    value: 180,
    label: <T _str="Last 6 months" />,
  },
  {
    value: 0,
    label: <T _str="All time" />,
  },
]

export type TDaysFilterProps = SelectProps<Opts>

const DaysFilter = (props: TDaysFilterProps) => {
  return (
    <Select
      options={options}
      style={{ width: '200px' }}
      size="small"
      {...props}
    />
  )
}

export default DaysFilter
