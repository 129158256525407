import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { Row, Col, Divider, ColProps, RowProps } from 'antd'

export type LayoutWithDividerv2Props = {
  left: ReactNode
  right: ReactNode
  footer?: ReactNode
  style?: CSSProperties
  wrapperProps?: RowProps
  dividerWrapperProps?: RowProps
  footerProps?: ColProps
  dividerMargin?: string
  dividerWidth?: string
  /**
   * - A number between 0 to 100. It's the percentage the left column will cover over the entire width.
   * - The right column will then cover (100 - leftRightRatio)% width.
   */
  leftRightRatio?: number
}

export const LayoutWithDividerv2 = ({
  footer,
  left,
  right,
  style = {},
  footerProps = {},
  wrapperProps = {},
  dividerWrapperProps = {},
  dividerMargin = `1rem`,
  dividerWidth = `1px`,
  leftRightRatio = 50,
  children,
}: PropsWithChildren<LayoutWithDividerv2Props>) => {
  return (
    <Row style={{ ...style, ...(wrapperProps.style || {}) }} {...wrapperProps}>
      <Col xs={24}>
        <Row wrap={false} {...dividerWrapperProps}>
          <Col
            flex={1}
            style={{
              width: `calc(${leftRightRatio}% - ${dividerMargin} - ${dividerWidth})`,
            }}
          >
            {left}
          </Col>
          <Col flex="none">
            <Divider
              type="vertical"
              style={{
                height: '100%',
                margin: `0 ${dividerMargin}`,
                borderLeftWidth: dividerWidth,
              }}
            />
          </Col>
          <Col
            flex={1}
            style={{
              width: `calc(${
                100 - leftRightRatio
              }% - ${dividerMargin} - ${dividerWidth})`,
            }}
          >
            {right}
          </Col>
        </Row>
      </Col>
      {footer && (
        <Col xs={24} {...footerProps}>
          {footer}
        </Col>
      )}
      {children}
    </Row>
  )
}
