import React from 'react'
import { TTypeNames } from './types'

import EventLogDetail from './EventLogDetail'
import MessageLogDetail from './MessageLogDetail'
import CommentLogDetail from './CommentLogDetail'
import ModifiedLogDetail from './ModifiedLogDetail'
import { TEventTicketEventEventTypeChoices } from '../../../../../../api/consts'

type TDetailViewProps = {
  id: string
  type: TTypeNames
  eventType?: TEventTicketEventEventTypeChoices
  compact?: boolean
}

const DetailView = ({ id, type, eventType, compact }: TDetailViewProps) => {
  return (
    <>
      {type === 'TicketEventDef' && eventType !== 'A_10' && (
        <EventLogDetail id={id} compact={compact} />
      )}
      {type === 'TicketEventDef' && eventType === 'A_10' && (
        <ModifiedLogDetail id={id} compact={compact} />
      )}
      {type === 'TicketMessageDef' && (
        <MessageLogDetail id={id} compact={compact} />
      )}
      {type === 'TicketCommentDef' && (
        <CommentLogDetail id={id} compact={compact} />
      )}
    </>
  )
}

export default DetailView
