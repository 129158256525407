import { gql } from '@apollo/client'
import apiUtilGetKey from '../../../../../../../../api/apiUtilCreatePrefixer'

export const GET_ALL_CONTAINERS = gql`
  query GetContainersNewAllContainers(
    $last: Int
    $first: Int
    $before: String
    $after: String
    $q: String
    $order: String
    $orderBy: String
    $measurement_FillPercentage: String
    $activeProjects: String
    $offset: Int
    $containerTypes: [ID]
    $pickupSetting: [ID]
    $sensor_Isnull: Boolean
    $name: String
    $containerType_Name_In: [String]
    $search: String
    $wasteFractionIds: String
    $propertyIds: [ID]
    $propertyGroup: [ID]
    $status: [String]
    $from: DateTime
    $to: DateTime
    $containerGroupIds: [ID]
  ) {
    allContainers(
      last: $last
      first: $first
      after: $after
      before: $before
      q: $q
      order: $order
      orderBy: $orderBy
      measurement_FillPercentage: $measurement_FillPercentage
      activeProjects: $activeProjects
      offset: $offset
      containerTypes: $containerTypes
      pickupSetting: $pickupSetting
      sensor_Isnull: $sensor_Isnull
      name: $name
      containerType_Name_In: $containerType_Name_In
      search: $search
      propertyIds: $propertyIds
      wasteFractionIds: $wasteFractionIds
      status: $status
      From: $from
      To: $to
      propertyGroupIds: $propertyGroup
      containerGroupIds: $containerGroupIds
    ) {
      totalCount
      edges {
        node {
          id
          containerId
          description
          status
          totalOverflowings
          scheduledPickup
          showOnRoute
          pickupMethod
          latestPickUp
          nextPickUp
          pickupSetting {
            id
            name
          }
          wasteFraction {
            id
            name
            wasteSubstance
            wasteCategory
          }
          project {
            id
            name
          }
          location {
            id
            name
          }
          containerType {
            id
            name
          }
          measurement {
            id
            fillLevel
            fillPercentage
            batteryPercentage
            rsi
            createdAt
          }
          deviceToContainerSet {
            edges {
              node {
                device {
                  id
                  devId
                }
                measurementSettings {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
export const K_GET_ALL_CONTAINERS = apiUtilGetKey(GET_ALL_CONTAINERS)

export const GET_ALL_CONTAINERS_FOR_CONTAINER_SELECT = gql`
  query GetAllContainersForContainerSelect(
    $activeProjects: String
    $search: String
    $wasteFractionIds: String
    $sensor_Isnull: Boolean
    $containerTypes: [ID]
  ) {
    allContainers(
      activeProjects: $activeProjects
      search: $search
      wasteFractionIds: $wasteFractionIds
      sensor_Isnull: $sensor_Isnull
      containerTypes: $containerTypes
    ) {
      edges {
        node {
          id
          containerId
          containerType {
            id
            name
          }
          location {
            id
            latitude
            longitude
            name
          }
          measurement {
            id
            fillPercentage
          }
          wasteFraction {
            id
            name
            wasteSubstance
            wasteCategory
          }
        }
      }
    }
  }
`

export const GET_EDIT_CONTAINER_INITIAL_VALUES = gql`
  query GetEditContainerInitialValues($id: ID!) {
    container(id: $id) {
      id
      containerId
      description
      photoUrl
      showOnRoute
      allowedHoursStart
      allowedHoursEnd
      status
      sensorFillLevel
      sensorFillLevelPeriodHours
      prioritizeInStartOfRoute
      pickupSetting {
        id
      }
      project {
        id
      }
      location {
        id
        latitude
        longitude
        name
      }
      containerType {
        id
        name
        volume
        height
        length
        width
        diameter
        isCylindrical
      }
      wasteFraction {
        id
        name
        wasteCategory
        wasteSubstance
      }
      pickupMethod
      deviceToContainerSet {
        edges {
          node {
            id
            objectId
            internalHeight
            offset
            deviceAngle
            topLid
            sideLid
            endDate
            measurementSettings {
              id
              custom
              name
            }
            device {
              id
              devId
              deviceType {
                id
                name
              }
              connectionType
              ... on LorawanDeviceType {
                appKey
              }
              ... on MobileDeviceType {
                simcard {
                  id
                  iccid
                  imsi
                  apn
                  isActive
                }
              }
              ... on SigFoxDeviceType {
                sigFoxPac {
                  id
                  pac
                }
              }
            }
          }
        }
      }
    }
  }
`

export const DELETE_CONTAINER = gql`
  mutation DeleteContainer($containerId: ID!) {
    deleteContainer(containerId: $containerId) {
      status
    }
  }
`
export const GET_FILL_LEVEL_MEASUREMENTS_BY_ID = gql`
  query GetFillLevelMeasurementsById($id: ID!) {
    fillLevelMeasurements(id: $id) {
      id
      rawMeasurements
      rawMeasurementsPercentage
    }
  }
`

export { MUTATION_CHECK_DEVICE_ID } from '../api'

export const MUTATION_DETACH_SENSOR = gql`
  mutation MutationDetachSensor($deviceId: ID!) {
    detachSensor(deviceId: $deviceId) {
      status
    }
  }
`
