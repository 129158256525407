import { TCrmServiceServiceTypeChoices } from '../../../../../api/consts'

export const getSummaryDataConfig = (
  ticketType: TCrmServiceServiceTypeChoices
) => {
  switch (ticketType) {
    case 'ADD_CONTAINER':
      return {
        detail: true,
        address: true,
        pickup: true,
        containerType: true,
        wasteFraction: true,
        preferredDate: true,
        priority: true,
        creator: true,
        assignee: true,
        reporter: true,
        serviceFee: true,
      }
    case 'BULK_WASTE_PICKUP':
      return {
        detail: true,
        address: true,
        preferredDate: true,
        wasteFraction: true,
        priority: true,
        creator: true,
        assignee: true,
        reporter: true,
        serviceFee: true,
      }
    case 'CHANGE_CONTAINER_PICKUP_SETTING':
      return {
        containerId: true,
        pickup: true,
        creator: true,
        assignee: true,
        reporter: true,
        serviceFee: true,
      }
    case 'CHANGE_CONTAINER_TYPE':
      return {
        containerId: true,
        containerType: true,
        preferredDate: true,
        priority: true,
        creator: true,
        assignee: true,
        reporter: true,
        serviceFee: true,
      }
    case 'EXTRA_EMPTYING':
      return {
        containerId: true,
        wasteFraction: true,
        preferredDate: true,
        priority: true,
        creator: true,
        assignee: true,
        reporter: true,
        serviceFee: true,
      }
    case 'REMOVE_CONTAINER':
      return {
        containerId: true,
        containerType: true,
        preferredDate: true,
        priority: true,
        creator: true,
        assignee: true,
        reporter: true,
        serviceFee: true,
      }
    default:
      return {}
  }
}
