import React, { useCallback, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Layer, Source } from 'react-mapbox-gl'

import { GET, TVariables } from './api'

import { TSourceRenderFn, TSourceConfig } from '../../types'

type TSourceRouteOneExtendedProps = TVariables & {
  /**
   * - Will set the mapCenter to the starting point of the route.
   * - default to false.
   */
  navigateToStarting?: boolean
}

export type TSourceRouteOneConfig = TSourceConfig<
  $TSFixMe,
  TSourceRouteOneExtendedProps
>

const SourceRouteOne: TSourceRenderFn<$TSFixMe, TSourceRouteOneExtendedProps> =
  ({
    _getMap,
    routeId,
    _onLoadingChange,
    navigateToStarting,
    refreshCount,
  }) => {
    const handleNavigateToStarting = useCallback(
      (routeGeojson) => {
        if (routeGeojson) {
          const [lng, lat] =
            routeGeojson?.features?.[0].geometry.coordinates?.[0]
          const map = _getMap()
          map.easeTo({ center: [lng, lat] })
        }
      },
      [_getMap]
    )

    const { loading, data, refetch } = useQuery<$TSFixMe, TVariables>(GET, {
      variables: {
        routeId,
      },
    })

    useEffect(() => {
      refetch()
    }, [refreshCount, refetch])

    useEffect(() => {
      if (navigateToStarting) {
        handleNavigateToStarting(data?.routeGeojson)
      }
    }, [data, handleNavigateToStarting, navigateToStarting])

    const stops =
      data?.routeGeojson?.features.map(
        (feature: $TSFixMe) => feature.geometry?.coordinates
      )?.[0] || []

    useEffect(() => {
      _onLoadingChange(loading)
    }, [_onLoadingChange, loading])

    return (
      <>
        <Source
          id="route-one"
          geoJsonSource={{
            type: 'geojson',
            generateId: true,
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {
                    id: routeId,
                  },
                  geometry: {
                    coordinates: stops,
                    type: 'LineString',
                  },
                },
              ],
            },
          }}
        />
        {/* RouteOne Layers. */}
        <Layer
          id="route-line"
          sourceId="route-one"
          type="line"
          paint={{
            'line-color': `black`,
            'line-width': 2,
          }}
        />
      </>
    )
  }

export default SourceRouteOne
