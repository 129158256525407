import React, { FC } from 'react'
import { Typography } from '@wastehero/storybook/lib/components'
import { TextProps } from 'antd/es/typography/Text'

/**
 * @deprecated - will be removed.
 * Use Typography from "@wastehero/storybook/lib/components" with variant="subHeader-16" weight="semi-bold"
 */
export const SubHeader16: FC<TextProps> = (props) => (
  <Typography {...props} variant="subHeader-16" weight="semi-bold" />
)
