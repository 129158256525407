import * as turf from '@turf/turf'
import { Map as TMapbox, GeoJSONSource } from 'mapbox-gl'
import { useEffect } from 'react'
import { useRefEnhanced } from '../../hooks'

export type TGetFeaturesInPolygonArgs = {
  map: TMapbox
  opts: {
    layers: string[]
  }
  /** default to data */
  format?: (feature: $TSFixMe) => $TSFixMe
  /** handleServerClusterSelection
   * return false to proceed with the default selection
   */
  handleServerClusterSelection?: (
    feature: $TSFixMe,
    polygonId: string
  ) => Promise<Map<string, $TSFixMe> | `DEFAULT`>
}

export const getFeaturesInPolygon = async (
  polygons: $TSFixMe,
  {
    map,
    opts,
    format = (v) => v,
    handleServerClusterSelection,
  }: TGetFeaturesInPolygonArgs
): Promise<{
  allResult: $TSFixMe
  allClusterResults: $TSFixMe
  allServerClusterResults: $TSFixMe
}> => {
  const allResult: $TSFixMe = {}
  const allClusterResults: Record<
    string,
    Record<string, Map<string, $TSFixMe>>
  > = {}
  const allServerClusterResults: Record<
    string,
    Record<string, Map<string, $TSFixMe>>
  > = {}
  await Promise.all(
    polygons.map(async (polygon: $TSFixMe) => {
      const result: Record<string, Map<string, $TSFixMe>> = {}
      const clusterResult: Record<string, Map<string, $TSFixMe>> = {}
      const serverClusterResult: Record<string, Map<string, $TSFixMe>> = {}
      const [p1, p2, p3, p4] = turf.bbox(polygon)
      const allFeatures = map.queryRenderedFeatures(
        [map.project([p1, p2]), map.project([p3, p4])],
        opts
      )
      const handlePointFeature = (feature: $TSFixMe, source?: string) => {
        const mySource = source || feature.source
        if (!result[mySource]) result[mySource] = new Map()
        const formattedFeature = format(feature)
        result[mySource].set(formattedFeature.id, formattedFeature)
      }

      const handleClusterFeature = (feature: $TSFixMe) => {
        const clusterSource = feature.source
        const clusterId = feature.properties.cluster_id
        return new Promise((resolve) => {
          ;(map.getSource(clusterSource) as GeoJSONSource).getClusterLeaves(
            clusterId,
            Infinity,
            0,
            (err, subFeatures) => {
              if (!clusterResult[clusterSource])
                clusterResult[clusterSource] = new Map()
              clusterResult[clusterSource].set(clusterId, {
                id: clusterId,
              })
              subFeatures.map(async (subFeat: $TSFixMe) => {
                handlePointFeature(subFeat, clusterSource)
              })
              resolve(true)
            }
          )
        })
      }
      await Promise.all(
        allFeatures.map(async (feature: $TSFixMe) => {
          if (turf.booleanPointInPolygon(feature, polygon)) {
            const handledCustom = await handleServerClusterSelection?.(
              feature,
              polygon.id
            )
            if (handledCustom !== 'DEFAULT') {
              // then it's handled custom
              const customFeatureSource = feature.properties.source
              if (handledCustom && Array.from(handledCustom?.keys()).length) {
                if (!result[customFeatureSource])
                  result[customFeatureSource] = new Map()
                Array.from(handledCustom?.keys()).forEach((k) => {
                  result[customFeatureSource].set(k, handledCustom.get(k))
                })
                if (!serverClusterResult[customFeatureSource])
                  serverClusterResult[customFeatureSource] = new Map()
                serverClusterResult[customFeatureSource].set(
                  feature.properties.dataId,
                  {
                    id: feature.properties.dataId,
                  }
                )
              }
            }
            // It's default selection
            // only add the property, if the feature intersects with the polygon drawn by the user
            else if (feature.properties.cluster) {
              // then it's cluster.
              await handleClusterFeature(feature)
            } else {
              handlePointFeature(feature)
            }
          }
        })
      )
      allResult[polygon.id] = result
      allClusterResults[polygon.id] = clusterResult
      allServerClusterResults[polygon.id] = serverClusterResult
    })
  )
  return { allResult, allClusterResults, allServerClusterResults }
}

export const useRefCopy = <T extends $TSFixMe>(state: T) => {
  const [getRef, setRef] = useRefEnhanced<T>(state)

  useEffect(() => {
    setRef(state)
  }, [setRef, state])

  return getRef
}

export const addonZoomCluster = (layerName: string, map: TMapbox) => {
  map.on('click', layerName, (e: $TSFixMe) => {
    const feature = map.queryRenderedFeatures(e.point, {
      layers: [layerName],
    })[0]

    const source = map.getSource(feature.source) as GeoJSONSource
    if (source && feature?.properties?.cluster_id) {
      const clusterId = feature.properties.cluster_id

      // Ease the camera to the next cluster expansion
      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (!err) {
          if (zoom < 26) {
            // the zoom in
            map.easeTo({
              // @ts-expect-error WIP
              center: feature.geometry.coordinates,
              zoom,
            })
          }
        }
      })
    }
  })
}
