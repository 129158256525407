import React from 'react'
import { T } from '@transifex/react'
import { SelectProps, Select } from 'antd'

import {
  createFilter,
  FiltersProiver as Provider,
  initialFilters,
} from './filters.context'
import { ticketTicketStatusChoicesSelectOptions } from '../../../api/consts/TicketTicketStatusChoices'
import {
  SelectContainer,
  SelectProperty,
  SelectPropertyGroup,
  SelectTicketType,
  SelectRoute,
} from '../../select'

export const FiltersProiver: React.FC = (props) => (
  <Provider {...props} initialValue={initialFilters} />
)

const defaultProps: Partial<SelectProps> = {
  style: {
    width: '100%',
  },
}

export const FilterByStatus = createFilter({
  key: 'status',
  Component: Select,
  componentProps: {
    ...defaultProps,
    placeholder: <T _str="Status" />,
    allowClear: true,
    options: ticketTicketStatusChoicesSelectOptions,
  },
})

export const FilterByTicketType = createFilter({
  key: 'ticketType',
  Component: SelectTicketType.WithActiveProjects,
  componentProps: {
    ...defaultProps,
  },
})

export const FilterByProperty = createFilter({
  key: 'property',
  Component: SelectProperty,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    maxTagCount: 'responsive',
  },
})

export const FilterByPropertyGroup = createFilter({
  key: 'propertyGroup',
  Component: SelectPropertyGroup,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    maxTagCount: 'responsive' as const,
    placeholder: <T _str="Property group" />,
  },
})

export const FilterByContainers = createFilter({
  key: 'containerIds',
  Component: SelectContainer,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    maxTagCount: 'responsive' as const,
    placeholder: <T _str="Container" />,
  },
})
export const FilterByRoutes = createFilter({
  key: 'routeIds',
  Component: SelectRoute,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    maxTagCount: 'responsive' as const,
    placeholder: <T _str="Route" />,
  },
})
