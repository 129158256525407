import { pathJoin } from '../../../../utils'
import { createContainersRouteMap } from './container'
import { createPropertiesRouteMap } from './properties'

export const createCreateBulkRoutesMap = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chainMapAuto(
    createContainersRouteMap
  ),
  properties: pathJoin(prefix)('properties').chainMapAuto(
    createPropertiesRouteMap
  ),
})
