import { gql } from '@apollo/client'

export const GET_TICKET_TYPES = gql`
  query GetAllTicketTypes(
    $companyId: ID
    $activeProjects: String
    $isPropertyLevel: Boolean
    $isContainerLevel: Boolean
  ) {
    allTicketTypes(
      companyId: $companyId
      activeProjects: $activeProjects
      isPropertyLevel: $isPropertyLevel
      isContainerLevel: $isContainerLevel
    ) {
      edges {
        node {
          id
          name
          service {
            id
            serviceType
          }
        }
      }
    }
  }
`
