import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

/* Fragments */
export const FrgPickupSettings = gql`
  fragment FrgPickupSettings on PickupSettingsType {
    id
    allowedHoursFrom
    name
    allowedHoursTo
    amountDaysBetweenPickups
    containerStatus
    emptyingIntervalFrom
    emptyingIntervalTo
    excludeDays
    fixedPickupsPeriod
    minimumDaysBetweenPickup
    pickupDay {
      edges {
        node {
          id
          weekday
          timeFrom
          timeTo
        }
      }
    }
    project {
      id
      name
    }
    pickupInterval
    pickupMethod
    pickupRepeatPeriod
    collectionPerWeek
    firstCollection
    containersCount
  }
`

export const GET_ALL_PICK_UP_SETTINGS = gql`
  ${FrgPickupSettings}
  query GetAllPickUpSettings(
    $activeProjects: String
    $project: ID
    $containerIsNull: Boolean = false
    $search: String
    $orderBy: String
    $order: String
    $offset: Int
  ) {
    allPickupSettings(
      activeProjects: $activeProjects
      project: $project
      project_Isnull: false
      container_Isnull: $containerIsNull
      name_Isnull: false
      search: $search
      orderBy: $orderBy
      order: $order
      offset: $offset
    ) {
      totalCount
      edges {
        node {
          ...FrgPickupSettings
        }
      }
    }
  }
`
export const K_GET_ALL_PICK_UP_SETTINGS = apiUtilGetKey(
  GET_ALL_PICK_UP_SETTINGS
)
