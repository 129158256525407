import React from 'react'
import { T } from '@transifex/react'
import { SelectProps, Select, Button } from 'antd'
import { RangeSelectorWithRadio } from '@wastehero/storybook/lib/components'
import moment from 'moment'

import {
  createFilter,
  useFiltersContext,
  FiltersProiver as Provider,
  initialFilters,
} from './filters.context'
import { SelectProperty } from '../../select/Property'
import { crmInvoiceStatusChoicesOptions } from '../../../api/consts'
import { SelectContainer, SelectPropertyGroup } from '../../select'

export const FiltersProiver: React.FC = (props) => (
  <Provider {...props} initialValue={initialFilters} />
)

const defaultProps: Partial<SelectProps> = {
  style: {
    width: `100%`,
  },
  allowClear: true,
  maxTagCount: 'responsive',
}

export const FilterByStatus = createFilter({
  key: 'status',
  Component: Select,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    placeholder: <T _str="Status" />,
    options: crmInvoiceStatusChoicesOptions,
  },
})

export const FilterByProperty = createFilter({
  key: 'property',
  Component: SelectProperty,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    placeholder: <T _str="Property" />,
  },
})

export const FilterByPropertyGroup = createFilter({
  key: 'propertyGroup',
  Component: SelectPropertyGroup,
  componentProps: {
    ...defaultProps,
    maxTagCount: 'responsive' as const,
    mode: 'multiple',
    placeholder: <T _str="Property group" />,
  },
})

export const FilterByContainers = createFilter({
  key: 'containerIds',
  Component: SelectContainer,
  componentProps: {
    ...defaultProps,
    maxTagCount: 'responsive' as const,
    mode: 'multiple',
    placeholder: <T _str="Containers" />,
  },
})

export const FilterByDateRange = () => {
  const [{ createdAtFrom, createdAtTo }, set] = useFiltersContext()

  return (
    <RangeSelectorWithRadio
      value={[moment(createdAtFrom), moment(createdAtTo)]}
      onChange={([a, b]) =>
        set({ createdAtFrom: a.toISOString(), createdAtTo: b.toISOString() })
      }
    />
  )
}

export const ResetFilters = () => {
  const [, , reset] = useFiltersContext()
  return (
    <Button onClick={() => reset(initialFilters)}>
      <T _str="Reset" />
    </Button>
  )
}
