import React, { ReactNode } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Tabs, TabsProps } from 'antd'

export type TabNavigationProps<Keys extends string> = {
  routeKey: string
  on: {
    change: (key: Keys, history: $TSFixMe) => void
  }
  children: ReactNode
  antd?: Omit<TabsProps, 'onChange' | 'activeKey'>
}

export const TabNavigation = <Keys extends string>({
  routeKey,
  children,
  on: { change: onChange },
  antd = {},
}: TabNavigationProps<Keys>): JSX.Element => {
  const history = useHistory()
  const params = useParams<$TSFixMe>()
  return (
    <Tabs
      {...antd}
      onChange={(key) => onChange(key as Keys, history)}
      activeKey={params[routeKey]}
    >
      {children}
    </Tabs>
  )
}
