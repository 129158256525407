import React, { FC } from 'react'
import { Select, SelectProps } from 'antd'
import { T } from '@transifex/react'
import {
  Select as SelectFormik,
  SelectProps as SelectFormikProps,
} from 'formik-antd'

import { Opt } from './types'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectIntegrationGpsProviderProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps<Opt>, 'options' | 'loading'>

const options: Opt[] = [
  {
    label: <T _str="Water monitoring" />,
    value: 'water_monitoring',
  },
  {
    label: <T _str="RFID Systems" />,
    value: 'rfit_systems',
  },
  {
    label: <T _str="GPS tracking" />,
    value: 'gps_tracking',
  },
  {
    label: <T _str="CAN bus" />,
    value: 'can_bus',
  },
]

const SelectIntegrationGpsProvider = ({
  render,
  ...selectProps
}: SelectIntegrationGpsProviderProps) => {
  return (
    render?.({
      options,
      loading: false,
    }) || (
      <Select
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}

SelectIntegrationGpsProvider.options = options

export { SelectIntegrationGpsProvider }

export const SelectFormikIntegrationGpsProvider: FC<SelectFormikProps> = ({
  name,
  ...rest
}) => (
  <SelectIntegrationGpsProvider
    render={({ loading, options: opts }) => (
      <SelectFormik
        name={name}
        loading={loading}
        options={opts}
        showSearch
        filterOption
        optionFilterProp="label"
        {...rest}
      />
    )}
  />
)
