import React, { ReactNode } from 'react'
import { TableColumnProps, Table, TableProps } from 'antd'
import { T } from '@transifex/react'
import { TableVariantsCombos } from '../../../ui/table-variants'
import { formatPayment } from '../../../utils/currency'

const { TableSimpleBordered } = TableVariantsCombos

export type Column = {
  item: ReactNode
  note: ReactNode
  total: ReactNode
}

export type TItemsTableProps = {
  data: Column[]
} & Pick<TableProps<Column>, 'summary'>

export const renderPayment = (price: number, curr: string) =>
  formatPayment(+price.toFixed(2), curr)

const columns: TableColumnProps<Column>[] = [
  {
    title: <T _str="Item" />,
    dataIndex: 'item',
  },
  {
    title: <T _str="Note" />,
    dataIndex: 'note',
    width: 150,
  },
  {
    title: <T _str="Total" />,
    align: 'right',
    width: 100,
    dataIndex: 'total',
  },
]

const ItemsTable = ({ data, summary }: TItemsTableProps) => (
  <TableSimpleBordered
    styles={`
          &&& {
            tfoot {
              td {
                border: none;
              }
            }
          }
        `}
  >
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      summary={summary}
    />
  </TableSimpleBordered>
)

export default ItemsTable
