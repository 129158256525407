import React from 'react'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { getSavedActiveProjects } from '../../../components/shared/utils'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'
import { AsyncSelectOnMountProps } from '../AsyncSelectOnMount'

const formatResponse = ({ node }: { node: GetCallResponseEdgeNode }): Opt => ({
  label: node?.name,
  value: node?.id,
  key: node.id,
})

const formatResponseToOptions: AsyncSelectOnMountProps['formatResponseToOptions'] =
  (data: GetCallResponse) =>
    data.allWasteFractions.edges?.map((val: $TSFixMe) => formatResponse(val))

export const SelectWasteFraction = ({
  variables = {},
  queryOptions = {},
  render,
  ...props
}: AsyncSelectWithFetchMoreProps['selectProps'] & {
  variables?: Partial<GetCallVariables>
} & Pick<AsyncSelectWithFetchMoreProps, 'render' | 'queryOptions'>) => {
  const activeProjects = useSelector((state) => getSavedActiveProjects(state))
  const queryVariables = {
    activeProjects,
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allWasteFractions.edges.map((val) => formatResponse(val))
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
        ...queryOptions,
      }}
      selectProps={{
        placeholder: <T _str="Waste Fraction" />,
        allowClear: true,
        ...props,
      }}
      render={render}
      value={props.value}
    />
  )
}

SelectWasteFraction.formatResponseToOptions = formatResponseToOptions
