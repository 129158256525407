import { gql } from '@apollo/client'

export const MUTATION_ADD_CARD = gql`
  mutation MutationAddCard(
    $billingInformationId: ID!
    $source: String!
    $default: Boolean!
  ) {
    addCard(
      billingInformationId: $billingInformationId
      source: $source
      default: $default
    ) {
      billingInformation {
        id
      }
    }
  }
`
