import { LinkProps } from '../../reducers/routing/child-router-factory'

import { TGetLinkPropsFn, defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'
import { getCellPropertyName } from '../cells/getters'

export const getLinkPropsPropertyNode: TGetLinkPropsFn =
  getLinkPropsMakeNullable(
    (
      /** Should be PropertyType!
       */
      value,
      { includeLabel } = defaultConfig
    ): LinkProps => ({
      to: ({ routingMap }) =>
        routingMap.app['customer-management'].property['/:id'](value.id)._,
      children: includeLabel
        ? getCellPropertyName.withTitle(value)
        : getCellPropertyName(value),
    })
  )
