import { useQuery } from '@apollo/client'
import { GET_META_TYPES, GetMetaTypesResponse } from '../api'
import {
  metaValuesIconsIconMap,
  TMetaValuesEntityTypes,
  TMetaValuesIcons,
  TMetaValuesInputTypes,
} from '../../../../api/consts'
import metaValuInputViewComponentMap from '../components'

type UseMetaTypeViewProps = {
  entity: TMetaValuesEntityTypes
  metaDataObject: Record<string, $TSFixMe>
}

const useMetaTypeView = ({ entity, metaDataObject }: UseMetaTypeViewProps) => {
  const { data, loading } = useQuery<GetMetaTypesResponse>(GET_META_TYPES, {
    variables: {
      entity,
    },
  })

  const inputDataMap =
    data?.allCompanyMetaTypes?.edges.reduce(
      (prevValues, currentValue) => ({
        ...prevValues,
        [currentValue.node.name]: {
          icon: currentValue.node.icon,
          inputType: currentValue.node.inputType,
          label: currentValue.node.label,
        },
      }),
      {} as Record<
        string,
        {
          icon: TMetaValuesIcons
          inputType: TMetaValuesInputTypes
          label: string
        }
      >
    ) || {}

  const metaDataNodes = Object.keys(metaDataObject)
    .filter(
      (input) =>
        metaDataObject[input] !== '' && metaDataObject[input]?.length !== 0
    )
    .map((input) => ({
      label: inputDataMap[input]?.label,
      node: metaValuInputViewComponentMap[inputDataMap[input]?.inputType]?.view(
        {
          value: metaDataObject[input],
        }
      ),
      icon: metaValuesIconsIconMap[inputDataMap[input]?.icon],
    }))

  return {
    loading,
    metaDataNodes,
  }
}

export default useMetaTypeView
