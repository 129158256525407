import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TPaymentIntervals = 'month' | 'year'

export const convertFromMonthlyNumeric: Record<number, TPaymentIntervals> = {
  1: 'month',
  12: 'year',
}

export const paymentIntervalsLabelMap: Record<TPaymentIntervals, ReactNode> = {
  year: <T _str="Annual" />,
  month: <T _str="Monthly" />,
}

export const paymentIntervalsLabelMapPerInterval: Record<
  TPaymentIntervals,
  ReactNode
> = {
  year: <T _str="year" />,
  month: <T _str="month" />,
}

export const paymentIntervalsLabelMapEvery: Record<
  TPaymentIntervals,
  ReactNode
> = {
  year: <T _str="every 12 months" />,
  month: <T _str="every month" />,
}

export const paymentIntervalsLabelMapShowIntervalPrices: Record<
  TPaymentIntervals,
  ReactNode
> = {
  year: <T _str="Show yearly prices" />,
  month: <T _str="Show monthly prices" />,
}
