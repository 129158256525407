import React, { createContext, useContext, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

export type Value<P, S> = [
  [P, React.Dispatch<React.SetStateAction<P>>],
  [S, React.Dispatch<React.SetStateAction<S>>]
]

export const createStatefulContextSomePersistent = <
  State extends $TSFixMe,
  NonPersistentState extends $TSFixMe
>(
  persistentKey: string
) => {
  type iStatefulSimpleContext = Value<State, NonPersistentState>
  const StatefulSimpleContext = createContext<iStatefulSimpleContext>(
    null as unknown as iStatefulSimpleContext
  )

  const useStatefulSimpleContext = () => {
    const store = useContext(StatefulSimpleContext)

    if (!store) {
      throw new Error(
        'Cannot use `useStatefulSimpleContext` outside of a StatefulSimpleProvider'
      )
    }

    return store
  }

  const StatefulSimpleStore = (
    initialState: State,
    initialNonPersistent: NonPersistentState
  ): Value<State, NonPersistentState> => {
    const [c, d] = useLocalStorageState<State>(persistentKey, initialState)
    const [e, f] = useState<NonPersistentState>(initialNonPersistent)
    return [
      [c, d],
      [e, f],
    ]
  }

  const Provider: React.FC<{
    initialValue: State
    initialNonPersistent: NonPersistentState
  }> = ({ children, initialValue, initialNonPersistent }) => {
    return (
      <StatefulSimpleContext.Provider
        value={StatefulSimpleStore(initialValue, initialNonPersistent)}
      >
        {children}
      </StatefulSimpleContext.Provider>
    )
  }

  return {
    Provider,
    Context: StatefulSimpleContext,
    useContext: useStatefulSimpleContext,
  }
}
