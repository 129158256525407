import React from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'
import {
  createAsyncSelectOnMount,
  THookCreateAsyncSelectOnMountResponse,
} from './factories/createAsyncSelectOnMount'

import {
  formatSelectOptionsFromEdgesNodeNameId,
  DataType,
} from './factories/response-to-options/getEdgesNodeNameId'

const GET_QUERY = gql`
  query {
    allCompanies {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

type TVariables = {
  companyId?: string
  activeProjects?: string
}

type TResponse = DataType<'allCompanies'>

/* @ts-expect-error We will add it before we export. */
const SelectCompany: THookCreateAsyncSelectOnMountResponse<TVariables> & {
  WithActiveProjects: THookCreateAsyncSelectOnMountResponse<TVariables>
} = createAsyncSelectOnMount<TResponse, TVariables>({
  formatResponse: (data) =>
    formatSelectOptionsFromEdgesNodeNameId<TResponse>(data, 'allCompanies'),
  defaultSelectProps: {
    placeholder: <T _str="Company" />,
    allowClear: true,
  },
  query: GET_QUERY,
})

export { SelectCompany }
