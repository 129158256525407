import React from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'
import {
  createAsyncSelectOnMount,
  AsyncSelectOnMountPropsExtended,
  THookCreateAsyncSelectOnMountResponse,
} from './factories/createAsyncSelectOnMount'
import { useSavedActiveProjects } from '../../hooks/useSavedActiveProjects'

import {
  formatSelectOptionsFromEdgesNodeNameId,
  DataType,
} from './factories/response-to-options/getEdgesNodeNameId'

const GET_QUERY = gql`
  query GetAllServices($company: ID, $activeProjects: String) {
    allServices(activeProjects: $activeProjects, company: $company) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

type TVariables = {
  activeProjects?: string
  company?: string
}

type TResponse = DataType<'allServices'>

/* @ts-expect-error We will add it before we export. */
const SelectService: THookCreateAsyncSelectOnMountResponse<TVariables> & {
  /**
   * @deprecated
   * Use the main select.
   */
  WithActiveProjects: THookCreateAsyncSelectOnMountResponse<TVariables>
} = createAsyncSelectOnMount<TResponse, TVariables>({
  formatResponse: (data) =>
    formatSelectOptionsFromEdgesNodeNameId<TResponse>(data, 'allServices'),
  defaultSelectProps: {
    filterOption: true,
    optionFilterProp: 'label',
    placeholder: <T _str="Service" />,
    allowClear: true,
    showSearch: true,
  },
  query: GET_QUERY,
  usesActiveProjects: true,
})

const UseActiveProjects = ({
  variables,
  ...props
}: AsyncSelectOnMountPropsExtended<TVariables>) => {
  const activeProjects = useSavedActiveProjects()

  return (
    <SelectService {...props} variables={{ activeProjects, ...variables }} />
  )
}

SelectService.WithActiveProjects = UseActiveProjects

export { SelectService }
