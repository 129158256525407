import { T } from '@transifex/react'
import React, { ReactNode } from 'react'

export const MsgUpdateSuccess = () => <T _str="Changes submitted!" />
export const MsgCreateSuccess = ({ name }: { name: ReactNode }) => (
  <T _str="{name} created!" name={name} />
)
export const MsgCompletedSuccess = ({ name }: { name: ReactNode }) => (
  <T _str="{name} completed!" name={name} />
)

export const MsgSentSuccess = ({ name }: { name: ReactNode }) => (
  <T _str="{name} sent!" name={name} />
)
