import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'
import React from 'react'
import { Opt } from '../options'

export type TSelectUnitLengthValue = 'KM' | 'MI'

const options: Opt<TSelectUnitLengthValue>[] = [
  { value: 'KM', label: <T _str="Kilometer (KM)" /> },
  { value: 'MI', label: <T _str="Mile (MI)" /> },
]

const SelectUnitLength = (props: SelectProps) => {
  return <Select options={options} {...props} />
}

export default SelectUnitLength
