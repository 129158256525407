import React from 'react'
import { FillViewHeightV2 } from '@wastehero/storybook/lib/layouts'
import {
  CreateConfigFn,
  pathJoin,
} from '../../../../../../../reducers/routing/utils'

import { ProjectGeneralSettingsView } from './General'

export type Keys = `general`

export const createRouteMap = (prefix: string) => ({
  general: pathJoin(prefix)('general').chain,
})

export const createConfig: CreateConfigFn<Keys> = (map) => {
  const p = map.app.settings.projects.create

  return [
    {
      key: 'general',
      path: p.general._,
      children: (
        <FillViewHeightV2 childSelector=".ant-col" offset="90px">
          <ProjectGeneralSettingsView />
        </FillViewHeightV2>
      ),
      fallback: () => `/login`,
      roles: {
        and: ['authenticated'],
      },
    },
  ]
}
