import React, { ReactNode, useCallback } from 'react'
import { Radio, RadioGroupProps } from 'antd'
import { useHistory } from 'react-router-dom'

type TRouteRadioSwitchOption = {
  label: ReactNode
  route: string
}

export type TRouteRadioSwitchProps = {
  options: TRouteRadioSwitchOption[]
} & Omit<RadioGroupProps, 'options' | 'value' | 'children'>

export const RouteRadioSwitch = ({
  options,
  ...rest
}: TRouteRadioSwitchProps) => {
  const history = useHistory()

  const handleOnChange: RadioGroupProps['onChange'] = useCallback(
    (e) => {
      history.push(e.target.value)
    },
    [history]
  )

  return (
    <Radio.Group
      buttonStyle="solid"
      optionType="button"
      {...rest}
      value={history.location.pathname}
      onChange={handleOnChange}
      options={options.map(({ label, route }) => ({ value: route, label }))}
    />
  )
}
