import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { CellAvatarUserSet1 } from '../../cells'

const GET = gql`
  query SelectAssigneeGetOneUser($id: ID!) {
    user(id: $id) {
      id
      logo
      firstName
      lastName
    }
  }
`

type TValueProps = {
  id: string
}

const Value = ({ id }: TValueProps) => {
  const { data, loading } = useQuery(GET, { variables: { id } })

  return (
    <CellAvatarUserSet1
      loading={loading}
      value={data?.user || ({} as $TSFixMe)}
      avatarProps={{ size: 'small' }}
      wrapperProps={{ width: '100%' }}
      typographyProps={{ style: { width: '100%' } }}
    />
  )
}

export default Value
