import React from 'react'
import { T } from '@transifex/react'
import { Checkbox, Row, Col } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const DAY_OPTIONS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const

const COL_1_DAYS = DAY_OPTIONS.slice(0, 3)
const COL_2_DAYS = DAY_OPTIONS.slice(3, 5)
const COL_3_DAYS = DAY_OPTIONS.slice(5)

type DayType = typeof DAY_OPTIONS[number]

type DayCheckboxGroupProps = {
  checkedDays: string[]
  setter: (val: string[]) => void
  disableAll?: boolean
  disableSpecific?: DayType[]
}

const DayCheckboxGroup: React.FC<DayCheckboxGroupProps> = ({
  checkedDays,
  setter,
  disableAll = false,
  disableSpecific = [],
}) => {
  const onChange = (list: $TSFixMe) => {
    setter(list)
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'readonly ["Monday", "Tuesday", "... Remove this comment to see the full error message
    setter(e.target.checked ? DAY_OPTIONS : [])
  }

  return (
    <Row>
      <Col className="selectAllDaysCheckboxCol">
        <Checkbox
          indeterminate={checkedDays?.length > 0 && checkedDays?.length < 7}
          onChange={onCheckAllChange}
          checked={checkedDays?.length === 7}
          disabled={disableAll || !!disableSpecific?.length}
        >
          <T _str="Select All" />
        </Checkbox>
      </Col>
      <Checkbox.Group
        disabled={disableAll}
        value={checkedDays}
        onChange={onChange}
        style={{ marginLeft: '1rem' }}
      >
        <Row gutter={12}>
          <Col>
            {COL_1_DAYS.map((day) => (
              <Col key={day}>
                <Checkbox
                  disabled={disableSpecific.indexOf(day) !== -1}
                  value={day}
                >
                  {day}
                </Checkbox>
              </Col>
            ))}
          </Col>
          <Col>
            {COL_2_DAYS.map((day) => (
              <Col key={day}>
                <Checkbox
                  disabled={disableSpecific.indexOf(day) !== -1}
                  value={day}
                >
                  {day}
                </Checkbox>
              </Col>
            ))}
          </Col>
          <Col>
            {COL_3_DAYS.map((day) => (
              <Col key={day}>
                <Checkbox
                  disabled={disableSpecific.indexOf(day) !== -1}
                  value={day}
                >
                  {day}
                </Checkbox>
              </Col>
            ))}
          </Col>
        </Row>
      </Checkbox.Group>
    </Row>
  )
}

export default DayCheckboxGroup
