import { gql } from '@apollo/client'

export const GET_PROPERY = gql`
  query GetProperty($id: ID!) {
    property(id: $id) {
      id
      externalPropertyNumber
      location {
        name
      }
    }
  }
`
