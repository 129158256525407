export const getFillLevelColour = (fl: number) => {
  let fillLevel = 0
  let color = '#000000'

  switch (true) {
    case fl > 100:
      fillLevel = 100
      break
    case fl < 0:
      fillLevel = 100
      break
    default:
      fillLevel = Math.round(fl)
      break
  }
  switch (true) {
    case fillLevel <= 15 && fillLevel >= 0:
      color = '#9BD383'
      break
    case fillLevel <= 75 && fillLevel >= 16:
      color = '#FDD569'
      break
    case fillLevel <= 95 && fillLevel >= 76:
      color = '#FF7B75'
      break
    case fillLevel <= 100 && fillLevel >= 96:
      color = '#E72100'
      break
    default:
      color = '#000000'
      break
  }
  return color
}

export const getBatteryLevel = (bl: number) => {
  if (bl > 100) return 100
  return bl
}

export const withLength = (val: $TSFixMe) => (val?.length ? val : undefined)
