import React from 'react'
import { List } from 'antd'
import { useQuery, gql } from '@apollo/client'
import { InfoList } from '@wastehero/storybook/lib/layouts/cards/LayoutInfoCardSimple'
import { T } from '@transifex/react'
import { CssScrollerDiv } from '@wastehero/storybook/lib/components'
import { Link } from '../../../reducers/routing/child-router-factory'
import { getLinkPropsPropertyNode } from '../../extract-links-props'
import { array } from '../../../utils/array'

export type TPopupPropertyDetailProps = {
  id: string
}

const PropertyNode = ({
  value = {},
  loading,
}: {
  value: $TSFixMe
  loading?: boolean
}) => {
  const { location } = value

  return (
    <InfoList
      style={{ margin: 0 }}
      loading={loading}
      list={[
        {
          label: <T _str="Property" />,
          data: (
            <Link
              {...getLinkPropsPropertyNode(value, { includeLabel: false })}
            />
          ),
        },
        { label: <T _str="Address" />, data: location?.name },
      ]}
    />
  )
}

export const PopupPropertyDetail = ({ id }: TPopupPropertyDetailProps) => {
  const { data, loading } = useQuery(
    gql`
      query MapPopupSinglePropertyGet($id: ID!) {
        property(id: $id) {
          id
          externalPropertyNumber
          location {
            id
            name
          }
        }
      }
    `,
    {
      variables: { id },
      skip: !id,
    }
  )

  return <PropertyNode loading={loading} value={data?.property} />
}

export type TPopupPropertysDetailProps = {
  ids: string[]
}

export const PopupPropertysDetail = ({ ids }: TPopupPropertysDetailProps) => {
  const { data, loading } = useQuery(
    gql`
      query MapPopupSinglePropertyGet($ids: [ID]) {
        allProperties(ids: $ids) {
          edges {
            node {
              id
              location {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      variables: { ids },
      skip: !ids?.length,
    }
  )

  return (
    <CssScrollerDiv
      style={{ maxHeight: 400, minWidth: 300 }}
      defaultYScroller
      offset={10}
    >
      <List
        loading={loading}
        dataSource={array(
          data?.allProperties?.edges?.map((e: $TSFixMe) => e.node),
          loading && Array.from({ length: ids.length }).fill({ loading: true })
        )}
        renderItem={(item: $TSFixMe) => (
          <List.Item>
            <PropertyNode loading={item.loading} value={item} />
          </List.Item>
        )}
      />
    </CssScrollerDiv>
  )
}
