import { mockServiceTypeLabelMap } from './consts'

export type TServiceType = `pick_up` | `request` | `inquiry` | `message`

export type MockServiceType = {
  value: TServiceType
}

export const API_MOCK_SERVICE_TYPE_DATA: MockServiceType[] = (
  Object.keys(mockServiceTypeLabelMap) as TServiceType[]
).map((k) => ({ value: k }))
