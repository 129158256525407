import { gql } from '@apollo/client'

export type GetAllContainerTypesFragmentNameIdResponseEdgeNode = {
  id: string
  name: string
}

export type GetAllContainerTypesFragmentNameIdResponse = {
  allContainerTypes: {
    edges: {
      node: GetAllContainerTypesFragmentNameIdResponseEdgeNode
    }[]
  }
}

export type GetAllContainerTypesFragmentNameIdVariables = {
  activeProjects?: string
  search?: string
  first?: number
  offset?: number
  companyId?: string
}

export const GET_ALL_CONTAINER_TYPES_FRAGMENT_NAME_ID = gql`
  query Component_Select_ContainerType_GetAllContainerTypes(
    $activeProjects: String
    $search: String
    $first: Int
    $offset: Int
    $ids: [ID]
    $companyId: ID
  ) {
    allContainerTypes(
      activeProjects: $activeProjects
      search: $search
      first: $first
      offset: $offset
      ids: $ids
      companyId: $companyId
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
