import { Typography } from '@wastehero/storybook/lib/components'
import {
  Col,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  Row,
  Select,
  SelectProps,
  Tag,
} from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import React from 'react'
import { TMetaValuesInputTypes } from '../../../api/consts'

export type MetaValueInputViewData = {
  label: string
  options: string[]
  selectedOptions: string[]
  selectedOption: string
  helpText: string
  number: number
}

type InputTypeFormProps = InputProps &
  SelectProps &
  TextAreaProps &
  InputNumberProps

const LabelWrapper: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <Row gutter={[0, 6]}>
      <Col xs={24}>
        <Typography>{label}</Typography>
      </Col>
      <Col xs={24}>{children}</Col>
    </Row>
  )
}

const metaValuInputViewComponentMap: Record<
  TMetaValuesInputTypes,
  {
    view: (props: { value: $TSFixMe }) => JSX.Element
    edit: (
      props: MetaValueInputViewData & {
        set: React.Dispatch<React.SetStateAction<MetaValueInputViewData>>
      }
    ) => JSX.Element
    form: (props: InputTypeFormProps) => JSX.Element
  }
> = {
  SELECT: {
    view: ({ value }) => <Typography>{value}</Typography>,
    edit: ({ label, options, selectedOption, set }) => (
      <LabelWrapper label={label}>
        <Select
          value={selectedOption}
          onChange={(v) => {
            set((prev) => ({ ...prev, selectedOption: v }))
          }}
          style={{ width: '100%' }}
          options={options.map((opt) => ({ label: opt, value: opt }))}
        />
      </LabelWrapper>
    ),
    form: ({ options, ...props }) => <Select {...props} options={options} />,
  },
  MULTI_SELECT: {
    view: ({ value }) => (
      <Row gutter={4}>
        {value.map((opt: $TSFixMe) => (
          <Col key={opt}>
            <Tag style={{ margin: 0 }}>{opt}</Tag>
          </Col>
        ))}
      </Row>
    ),
    edit: ({ label, options, selectedOptions, set }) => (
      <LabelWrapper label={label}>
        <Select
          mode="multiple"
          value={selectedOptions}
          onChange={(v) => {
            set((prev) => ({ ...prev, selectedOptions: v }))
          }}
          style={{ width: '100%' }}
          options={options.map((opt) => ({ label: opt, value: opt }))}
        />
      </LabelWrapper>
    ),
    form: ({ options, ...props }) => (
      <Select {...props} mode="multiple" options={options} />
    ),
  },
  TEXT_AREA: {
    view: ({ value }) => <Typography>{value}</Typography>,
    edit: ({ helpText, set, label }) => (
      <LabelWrapper label={label}>
        <Input.TextArea
          value={helpText}
          onChange={(e) =>
            set((prev) => ({
              ...prev,
              helpText: e.target.value,
            }))
          }
          rows={1}
        />
      </LabelWrapper>
    ),
    form: (props) => <Input.TextArea {...props} rows={2} />,
  },
  NUMBER: {
    view: ({ value }) => <Typography>{value}</Typography>,
    edit: ({ number, label, set }) => (
      <LabelWrapper label={label}>
        <Input
          value={number}
          onChange={(e) =>
            set((prev) => ({
              ...prev,
              number: +e.target.value,
            }))
          }
          type="number"
        />
      </LabelWrapper>
    ),
    form: (props) => <InputNumber style={{ width: '100%' }} {...props} />,
  },
}

export default metaValuInputViewComponentMap
