import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { getTagsColumn } from '@wastehero/storybook/lib/components/table-kit'

export type RouteStatus = 'on-route' | 'off-route'

export const routeStatusLabelMap: Record<RouteStatus, ReactNode> = {
  'on-route': <T _str="On route" />,
  'off-route': <T _str="Off route" />,
}

export const routeStatusColorMap: Record<RouteStatus, string> = {
  'on-route': 'green',
  'off-route': 'red',
}

export const routeStatusTagColumn = getTagsColumn(
  routeStatusColorMap,
  (val) => routeStatusLabelMap[val as RouteStatus]
)
