import * as Yup from 'yup'
import { jsxFieldRequried, jsxNumberOnly } from '../../../utils/yup-validation'

export default Yup.object().shape({
  name: Yup.string().required(() => jsxFieldRequried),
  address: Yup.string().required(() => jsxFieldRequried),
  zipCode: Yup.number()
    .typeError(() => jsxNumberOnly)
    .required(() => jsxFieldRequried),
  cityName: Yup.string().required(() => jsxFieldRequried),
  country: Yup.string().required(() => jsxFieldRequried),
})
