/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ReactNode,
  FC,
  useCallback,
  useState,
  CSSProperties,
} from 'react'
import { T } from '@transifex/react'
import { Row, Col, Divider, ColProps } from 'antd'
import styled from 'styled-components'
import { RangeSelectorWithRadio } from '@wastehero/storybook/lib/components'
import { createPrefixer } from '../utils/createPrefixer'

export type TLayoutTableWithHideableFiltersProps = {
  layoutOffset?: number
  height?: CSSProperties['height']
  showHiddenFilters?: boolean
  hideFilterBar?: boolean
  hideTopDivider?: boolean
  /** default to false */
  hideFiltersBottomDivider?: boolean
  jsx: {
    filters: ReactNode[]
    columnsFilter?: ReactNode
    dateFilter?: ReactNode
    search?: ReactNode
    table: ReactNode
    addBtn?: ReactNode
    bottomBar?: ReactNode
  }
  style?: CSSProperties
  className?: string
  /** render table section */
  renderTableSection?: (args: { table: ReactNode }) => ReactNode
}

const p = createPrefixer(`wh-Layouts-LayoutTableWithHidableFilters-`)

const classes = {
  padded: p`padded`,
  paddedLeft: p`paddedLeft`,
  paddedRight: p`paddedRight`,
  toggler: p`toggler`,
  pb_0: p`pb_0`,
  pb_1: p`pb_1`,
  divider: p`antd-divider`,
  'w-100': p`w-100`,
}

const Wrapper = styled.div`
  --bg-color: rgb(235, 239, 243);
  & {
    .${classes.padded} {
      padding: 1rem;
      width: 100%;
    }
    .${classes.toggler} {
      color: #1890ff;
      margin: auto 0;
      padding-left: 2px;
      cursor: pointer;
      user-select: none;
    }
    .${classes.pb_0} {
      padding-bottom: 0;
    }
    .${classes.pb_1} {
      padding-bottom: 1rem;
    }
    .${RangeSelectorWithRadio.classes.wrapper} {
      flex-wrap: nowrap;
    }
    .${classes.divider} {
      border-color: var(--bg-color);
    }
    .${classes['w-100']} {
      width: 100%;
    }
  }
`

const FilterCol: React.FC<ColProps> = ({ children, ...props }) => (
  // Hoc to make sure that the length of all col are same.
  <Col
    className={classes.pb_1}
    xl={12}
    lg={18}
    md={18}
    sm={18}
    xs={18}
    {...props}
  >
    {children}
  </Col>
)

const LayoutTableWithHideableFilters: FC<TLayoutTableWithHideableFiltersProps> & {
  classes: typeof classes
} = ({
  children,
  jsx,
  showHiddenFilters: showHiddenFiltersDefault = false,
  hideFilterBar,
  hideTopDivider,
  hideFiltersBottomDivider,
  layoutOffset,
  className,
  style,
  height,
  renderTableSection = ({ table }) => table,
}) => {
  const [showHiddenFilters, setShowHiddenFilters] = useState(
    showHiddenFiltersDefault
  )

  const showToggle = jsx.filters.length > 2

  /** first two filters up */
  const topFilters = jsx.filters.slice(0, 2)
  /** rest always down, hidden by default */
  const bottomFilters = jsx.filters.slice(2, jsx.filters.length)

  const toggleText = (
    <T
      _str="{showOrHide} filters"
      showOrHide={showHiddenFilters ? <T _str="Hide" /> : <T _str="Show" />}
    />
  )

  const toggleShowFilters = useCallback(() => {
    setShowHiddenFilters((prev) => !prev)
  }, [])

  return (
    <Wrapper
      style={{
        height: height ?? `calc(100vh - ${layoutOffset ?? 0}px)`,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      className={className}
    >
      <div className="wh-vertical-scroller-container">
        {!hideTopDivider && (
          <Divider
            className={classes.divider}
            style={{ margin: 0, borderTopWidth: '4px' }}
          />
        )}
        {(!!jsx.filters.length || !!jsx.dateFilter) && !hideFilterBar && (
          <>
            <Row
              className={[classes.padded, classes.pb_0].join(' ')}
              justify="space-between"
            >
              <FilterCol>
                <Row gutter={[5, 0]} align="middle">
                  {topFilters.map((filter, i) => (
                    <Col key={i} span={8}>
                      {filter}
                    </Col>
                  ))}
                  {showToggle && (
                    <Col span={8}>
                      <span
                        onClick={toggleShowFilters}
                        className={classes.toggler}
                      >
                        {toggleText}
                      </span>
                    </Col>
                  )}
                </Row>
              </FilterCol>
              <FilterCol lg={19} md={19} sm={19} xs={19}>
                <Row justify="end">{jsx.dateFilter}</Row>
              </FilterCol>
              {showHiddenFilters && (
                <>
                  <Col span={24}>
                    <Divider
                      type="horizontal"
                      style={{ marginBottom: '1rem', marginTop: 0 }}
                    />
                  </Col>
                  <FilterCol>
                    <Row gutter={[5, 8]}>
                      {bottomFilters.map((filter, i) => (
                        <Col key={i} span={8}>
                          {filter}
                        </Col>
                      ))}
                    </Row>
                  </FilterCol>
                </>
              )}
            </Row>
            {!hideFiltersBottomDivider && (
              <Divider
                className={classes.divider}
                style={{
                  margin: 0,
                  borderTopWidth: '4px',
                  borderColor: 'var(--bg-color)',
                }}
              />
            )}
          </>
        )}
        <div className="wh-vertical-scroller-container-scroll">
          {renderTableSection?.({
            table: (
              <div className="wh-vertical-scroller-container">
                {(!!jsx.search || !!jsx.columnsFilter) && (
                  <Row
                    gutter={[0, 8]}
                    align="middle"
                    justify="space-between"
                    className={classes.padded}
                  >
                    <Col>{jsx.search}</Col>
                    <Col>
                      <Row gutter={8}>
                        <Col>{jsx.addBtn}</Col>
                        <Col>{jsx.columnsFilter}</Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <div className="wh-vertical-scroller-container-scroll wh-ant-fill-view-table">
                  {jsx.table}
                </div>
              </div>
            ),
          })}
        </div>
        {children}
        {jsx.bottomBar && (
          <>
            <div className={classes['w-100']}>
              <Divider
                style={{ marginBottom: 4, marginTop: 0, borderTopWidth: '4px' }}
              />
            </div>
            <Row style={{ padding: '1rem' }}>
              <Col style={{ height: '100%' }} xs={24}>
                {jsx.bottomBar}
              </Col>
            </Row>
          </>
        )}
      </div>
    </Wrapper>
  )
}

LayoutTableWithHideableFilters.classes = classes

// eslint-disable-next-line import/prefer-default-export
export { LayoutTableWithHideableFilters }
