import React, { FC, ReactNode } from 'react'
import { T } from '@transifex/react'
import { useQuery } from '@apollo/client'
import { Button, Divider, Space, Table, Row, Col, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  CssScrollerDiv,
  StyledSkeleton,
  Typography,
  FromTo,
} from '@wastehero/storybook/lib/components'
import { CardWithHeader } from '@wastehero/storybook/lib/layouts'
import moment from 'moment'

import { GET } from './api'
import { LayoutWithDividerv3 } from '../../../layouts'
import { formatPayment } from '../../../utils/currency'
import {
  crmInvoiceStatusChoicesTag,
  TCrmInvoiceStatusChoices,
} from '../../../api/consts'
import ItemsTable, { Column as Item } from './Items.ui'
import { array } from '../../../utils/array'
import CRMInfoList from '../../../components/main/routes/customer-management/ui/CRMInfoList'

const Cell: FC<{ label: ReactNode }> = ({ label, children }) => (
  <Space direction="vertical" size={4}>
    <Typography type="secondary" variant="body-14">
      {label}
    </Typography>
    <Typography weight="semi-bold" variant="body-14">
      {children}
    </Typography>
  </Space>
)

type TInvoiceMode = 'invoice' | 'reciept'

type TInvoiceProps = {
  mode?: TInvoiceMode
  id: string
  setIds: $TSFixMe
}

const modeLabel: Record<TInvoiceMode, ReactNode> = {
  invoice: <T _str="Invoice" />,
  reciept: <T _str="Reciept" />,
}

export const CardInvoice = ({
  mode = 'invoice',
  id: selectedInvoice,
  setIds,
}: TInvoiceProps) => {
  const { loading, data } = useQuery(GET, {
    variables: {
      id: selectedInvoice,
    },
    skip: !selectedInvoice,
  })

  const {
    id,
    accountAddress,
    invoiceNumber,
    issuedAt,
    dueDate,
    status,
    invoiceItems,
    accountName,
    subTotal,
    taxAmount,
    total,
    periodStart,
    periodEnd,
    ticket,
    paidAt,
    paymentMethod,
  } = data?.invoice || {}

  if (!loading && !data?.invoice) {
    message.error(<T _str="{mode} not found" mode={modeLabel[mode]} />)
  }

  const isRecurring = periodStart && periodEnd
  const isPaid = !!paidAt
  const showReciept = isPaid && mode === 'reciept'

  return (
    <CardWithHeader
      style={{ height: '100%' }}
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          {!showReciept && <T _str="Invoice {number}" number={id} />}
          {showReciept && <T _str="Reciept {number}" number={id} />}
          <CloseOutlined style={{ color: 'white' }} onClick={() => setIds()} />
        </Space>
      }
      loading={loading}
    >
      {loading && (
        <StyledSkeleton
          style={{ padding: '2rem' }}
          active
          loading={loading}
          size="small"
        />
      )}
      {!loading && (
        <div
          className="wh-vertical-scroller-container"
          style={{ padding: '2rem', height: '100%', rowGap: 24 }}
        >
          <div className="wh-vertical-scroller-container-scroll">
            <CssScrollerDiv defaultYScroller offset={32}>
              <Space direction="vertical" size={24} style={{ width: '100%' }}>
                <Space direction="vertical" size={5} style={{ width: '100%' }}>
                  <Typography weight="semi-bold" variant="body-14">
                    <Space size={4}>
                      {isRecurring && <T _str="Recurring payment" />}
                      {ticket && <T _str="Ticket payment" />}
                      {(ticket || isRecurring) && <>-</>}
                      {!showReciept && (
                        <T _str="Invoice {number}" number={invoiceNumber} />
                      )}
                      {showReciept && <T _str="Reciept {number}" number={id} />}
                    </Space>
                  </Typography>
                  {isRecurring && (
                    <FromTo
                      from={
                        <Typography weight="semi-bold" variant="body-14">
                          {moment(periodStart).format('DD-MM-YYYY')}
                        </Typography>
                      }
                      to={
                        <Typography weight="semi-bold" variant="body-14">
                          {moment(periodEnd).format('DD-MM-YYYY')}
                        </Typography>
                      }
                    />
                  )}
                </Space>
                <Row>
                  <Col xs={12}>
                    <Space direction="vertical">
                      <Typography type="secondary" variant="body-14">
                        <T _str="Bill to" />
                      </Typography>
                      <Space direction="vertical" size={4}>
                        <Typography weight="semi-bold" variant="body-14">
                          {accountName}
                        </Typography>
                        <Typography variant="body-14">
                          {accountAddress}
                        </Typography>
                      </Space>
                    </Space>
                  </Col>
                  {showReciept && (
                    <Col xs={12}>
                      <Cell label={<T _str="Payment method" />}>
                        {paymentMethod?.details?.last4}
                      </Cell>
                    </Col>
                  )}
                </Row>
                <Divider style={{ margin: 0 }} />
                <LayoutWithDividerv3
                  content={array<[ReactNode, $TSFixMe]>(
                    !showReciept && [
                      [
                        <T _str="Issue date" />,
                        moment(issuedAt).format('DD-MM-YYYY'),
                      ],
                      [
                        <T _str="Due date" />,
                        moment(dueDate).format('DD-MM-YYYY'),
                      ],
                      [
                        <T _str="Total amount due" />,
                        formatPayment(total / 100, 'dkk'),
                      ],
                    ],
                    showReciept && [
                      [<T _str="Invoice ID" />, invoiceNumber],
                      [
                        <T _str="Date paid" />,
                        moment(paidAt).format('DD-MM-YYYY'),
                      ],
                      [
                        <T _str="Amount paid" />,
                        formatPayment(total / 100, 'dkk'),
                      ],
                    ],
                    [
                      [
                        <T _str="Status" />,
                        crmInvoiceStatusChoicesTag(
                          status as TCrmInvoiceStatusChoices
                        ),
                      ],
                    ]
                  ).map(([l, c]) => (
                    <Cell label={l}>{c}</Cell>
                  ))}
                  colsRatio={[100 / 4, 100 / 4, 100 / 4, 100 / 4]}
                />
                <Divider style={{ margin: 0 }} />
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Typography weight="semi-bold" variant="body-14">
                    <T _str="Items" />
                  </Typography>
                  <ItemsTable
                    data={invoiceItems?.edges?.map(
                      ({
                        node: { name, amount, ticket: t, agreement },
                      }: $TSFixMe): Item => ({
                        item: name,
                        note: (
                          <CRMInfoList
                            isContainerLevel={
                              ticket?.ticketType?.isContainerLevel
                            }
                            content={{
                              ticket: { id: t?.id, name: t?.ticketType.name },
                              container: {
                                id: agreement?.container?.id,
                                name: agreement?.container?.containerId,
                              },
                            }}
                          />
                        ),
                        total: formatPayment(amount / 100, 'dkk'),
                      })
                    )}
                    summary={() =>
                      React.Children.toArray(
                        [
                          [
                            <T _str="Subtotal" />,
                            subTotal && formatPayment(subTotal / 100, 'dkk'),
                            !!subTotal,
                          ],
                          [
                            <T _str="VAT" />,
                            taxAmount && formatPayment(taxAmount / 100, 'dkk'),
                            !!taxAmount,
                          ],
                          [
                            <T _str="Total" />,
                            total && formatPayment(total / 100, 'dkk'),
                            !!total,
                          ],
                        ]
                          .filter(([, , c = true]) => !!c)
                          .map(([l, v]) => (
                            <Table.Summary.Row>
                              {l && (
                                <Table.Summary.Cell index={1} colSpan={2}>
                                  <div>{l}</div>
                                </Table.Summary.Cell>
                              )}
                              <Table.Summary.Cell
                                index={2}
                                align="right"
                                colSpan={l ? 1 : 3}
                              >
                                <div>{v}</div>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          ))
                      )
                    }
                  />
                </Space>
              </Space>
            </CssScrollerDiv>
          </div>
          <Space style={{ width: `100%`, justifyContent: 'flex-end' }}>
            <Button type="primary">
              <T _str="Download" />
            </Button>
            <Button>
              <T _str="Send to" />
            </Button>
          </Space>
        </div>
      )}
    </CardWithHeader>
  )
}
