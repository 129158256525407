import { pathJoin } from '../../../utils'
import { createRoutesMapVehicleManagementRouter } from './vehicles'
import { createRoutesMapLocationMangementRouter } from './locations'
import { createDriverManagementRouter } from './drivers'

export const createRoutesMapFleetManagement = (prefix: string) => ({
  vehicles: pathJoin(prefix)('vehicles').chainMap(
    createRoutesMapVehicleManagementRouter(
      pathJoin(prefix)('vehicles').value.slice(1)
    )
  ),
  'street-sweeper-vehicle': pathJoin(prefix)('street-sweeper-vehicle').chain,
  locations: pathJoin(prefix)('locations').chainMap(
    createRoutesMapLocationMangementRouter(
      pathJoin(prefix)('locations').value.slice(1)
    )
  ),
  drivers: pathJoin(prefix)('drivers').chainMap(
    createDriverManagementRouter(pathJoin(prefix)('drivers').value.slice(1))
  ),
  'vehicle-planner': pathJoin(prefix)('vehicle-planner').chain,
})
