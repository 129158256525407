/* eslint-disable   */
import { SimpleTheme } from './types'
import colorScales from './colorScales'

const { greenToRed } = colorScales

export const getSimpleTheme: SimpleTheme = ({
  data,
  xAxis = {},
  yAxis = {},
  zAxis = {},
  width,
  height,
}) => [
  [
    {
      showscale: false,
      colorscale: greenToRed,
      showlegend: false,
      z: data,
      type: 'surface',
    },
  ],
  {
    grid: {
      rows: 1,
      columns: 1,
      pattern: 'independent',
    },
    scene: {
      aspectmode: 'cube',
      domain: { row: 0, column: 0 },
      zaxis: {
        title: 'Fill Level',
        range: [0, 100],
        ...zAxis,
      },
      yaxis: { showgrid: false, zeroline: false, visible: false },
      xaxis: { showgrid: false, zeroline: false, visible: false },
    },
    paper_bgcolor: 'transparent',
    showlegend: false,
    width: width,
    height: height,
    margin: {
      b: 0,
      l: 0,
      r: 0,
      t: 0,
      pad: 0,
    },
  },
  {
    displayModeBar: false,
  },
]
