import React, { ReactNode, useState, FC } from 'react'
import { Select, SelectProps } from 'antd'
import { SelectValue } from 'antd/es/select'

type THookUseSelectArgs<T> = Omit<SelectProps<T>, 'value'> & {
  init: T
  Component?: FC<SelectProps<T>>
}
type THookUseSelectResult<T> = [
  T,
  ReactNode,
  React.Dispatch<React.SetStateAction<T>>
]

export const useSelect = <T extends SelectValue = SelectValue>({
  init,
  Component = Select,
  ...props
}: THookUseSelectArgs<T>): THookUseSelectResult<T> => {
  const [value, setValue] = useState<T>(init)

  return [
    value,
    <Component
      {...props}
      value={value}
      onChange={(...args) => {
        setValue(args[0])
        props?.onChange?.(...args)
      }}
    />,
    setValue,
  ]
}
