import { TableProps } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import { useState } from 'react'
import { DEFAULT_PAGE_SIZE } from './pagination.config'

const sortDirectionsMap: Record<string, string> = {
  ascend: 'asc',
  descend: 'desc',
}

type PersistentState = {
  pageSize: number
  orderBy: string
  order: string
}

const defaultPersistentInit: PersistentState = {
  order: '',
  orderBy: '',
  pageSize: 10,
}

export const useTablePaginationAndSorterAPIHelperPersistent = <RecordType>(
  persistentKeyPrefix: string,
  persistentInit: PersistentState = defaultPersistentInit
) => {
  const [page, setPage] = useState(1)
  const [{ order, orderBy, pageSize }, setState] =
    useLocalStorageState<PersistentState>(persistentKeyPrefix, persistentInit)

  const onTableChangeHandler: TableProps<RecordType>['onChange'] = (
    pagination,
    _,
    sorter
  ) => {
    if (!Array.isArray(sorter)) {
      setState((p) => ({
        ...p,
        orderBy: `${sorter.field || ''}`,
        order: sorter.order || ``,
      }))
      if (!sorter.order) {
        // the reset case.

        setState((p) => ({
          ...p,
          orderBy: ``,
        }))
      }
    }
    setState((p) => ({
      ...p,
      pageSize: pagination.pageSize || DEFAULT_PAGE_SIZE,
    }))
    setPage(pagination.current || 1)
  }

  return {
    paginationVars: {
      first: pageSize,
      offset: pageSize * (page - 1),
      order: sortDirectionsMap[order] || undefined,
      orderBy,
    },
    onTableChangeHandler,
    state: {
      order,
      orderBy,
      page,
      pageSize,
    },
  }
}
