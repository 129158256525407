import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { SelectProps } from 'antd'
import {
  TStatusType,
  statusTypeData,
} from '@wastehero/storybook/lib/components/SelectFilledStatus/data'
import { invert } from 'lodash'
import {
  SelectFilledStatus,
  TSelectFilledStatusProps,
} from '@wastehero/storybook/lib/components'

import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TicketTicketNotCreated = 'A_null'

export type TicketTicketStatusChoices =
  | `A_1`
  | `A_2`
  | 'A_3'
  | 'A_4'
  | `A_5`
  | `A_6`
  | `A_7`

export type TicketTicketStatusChoicesTuple = [
  TicketTicketStatusChoices | TicketTicketNotCreated,
  TicketTicketStatusChoices
]

export const ticketTicketStatusChoicesLabelMap: Record<
  TicketTicketStatusChoices,
  ReactNode
> = {
  A_1: <T _str="Created" />,
  A_2: <T _str="Open" />,
  A_3: <T _str="Pending" />,
  A_4: <T _str="On hold" />,
  A_5: <T _str="In Progress" />,
  A_6: <T _str="Completed" />,
  A_7: <T _str="Rejected" />,
}

export const ticketTicketStatusChoicesTagColorMap: Record<
  TicketTicketStatusChoices,
  string
> = {
  A_1: 'blue',
  A_2: 'red',
  A_3: 'orange',
  A_4: 'yellow',
  A_5: 'blue',
  A_6: 'green',
  A_7: 'red',
}

/**
 * Use this map when you are sending data to server.
 */
export const ticketTicketStatusChoicesMutationValueMap: Record<
  TicketTicketStatusChoices,
  number
> = {
  A_1: 1,
  A_2: 2,
  A_3: 3,
  A_4: 4,
  A_5: 5,
  A_6: 6,
  A_7: 7,
}

export const ticketTicketStatusChoicesSelectOptions = createOptionsFromLabelMap(
  ticketTicketStatusChoicesLabelMap
)

// eslint-disable-next-line no-underscore-dangle
export const ticketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus: Record<
  TicketTicketStatusChoices,
  TStatusType
> = {
  A_1: 'created',
  A_2: 'open',
  A_3: 'pending',
  A_4: 'on_hold',
  A_5: 'in_progress',
  A_6: 'completed',
  A_7: 'rejected',
}

export const invertTicketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus: Record<
  TStatusType,
  TicketTicketStatusChoices
> = invert(
  ticketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus
) as Record<TStatusType, TicketTicketStatusChoices>

type TColor = {
  primary: string
  secondary: string
}

export const ticketTicketStatusChoicesColorMap1: Partial<
  Record<TicketTicketStatusChoices, TColor>
> = {
  A_1: {
    primary: '#D46B08',
    secondary: '#FFF7E6',
  },
  A_2: {
    primary: '#F5222D',
    secondary: '#FFF1F0',
  },
  A_5: {
    primary: '#096DD9',
    secondary: '#E6F7FF',
  },
  A_6: {
    primary: '#739C00',
    secondary: '#F6FFED',
  },
}

export const ticketTicketStatusChoicesPossibleOpts: Record<
  TicketTicketStatusChoices,
  TicketTicketStatusChoices[]
> = {
  A_1: ['A_2'],
  A_2: ['A_3', 'A_4', 'A_5', 'A_6'],
  A_3: ['A_2'],
  A_4: ['A_2', 'A_5'],
  A_5: ['A_6', 'A_7'],
  A_6: [],
  A_7: ['A_6'],
}

const possibleAutomationStatusFlow: Record<
  TicketTicketStatusChoices | TicketTicketNotCreated,
  TicketTicketStatusChoices[]
> = {
  A_null: ['A_1'],
  A_1: ['A_2'],
  A_2: ['A_5'],
  A_3: [],
  A_4: [],
  A_5: ['A_6', 'A_7'],
  A_6: [],
  A_7: [],
}

export const possibleAutomationStatusFlowTuples = Object.keys(
  possibleAutomationStatusFlow
).reduce((prevValue, currentValue) => {
  return [
    ...prevValue,
    ...possibleAutomationStatusFlow[
      currentValue as TicketTicketStatusChoices
    ].reduce((prevVal, currentVal) => {
      return [...prevVal, [currentValue, currentVal]]
    }, [] as $TSFixMe),
  ]
}, [] as TicketTicketStatusChoicesTuple[])

export const SelectTicketTicketStatusFilled = ({
  value,
  onChange,
  ...rest
}: SelectProps<TicketTicketStatusChoices> & {
  omitOptions?: TSelectFilledStatusProps['omitOptions']
}) => (
  <SelectFilledStatus
    {...rest}
    dropdownClassName={`wh-select-hide-selected-option ${
      rest.dropdownClassName || ``
    }`}
    omitOptions={[
      'solved',
      'new',
      ...((Object.keys(statusTypeData) as TStatusType[]).filter((k) => {
        const allowed =
          ticketTicketStatusChoicesPossibleOpts[
            value as TicketTicketStatusChoices
          ] || []
        const v =
          invertTicketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus[
            k as TStatusType
          ]
        return ![...allowed, value].includes(v as TicketTicketStatusChoices)
      }) || []),
    ]}
    {...rest}
    value={
      value &&
      ticketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus[value]
    }
    onChange={(val: $TSFixMe, opt) =>
      onChange?.(
        invertTicketTicketStatusChoicesSelectOptionsValueMapForSelectFilledStatus[
          val as TStatusType
        ],
        opt
      )
    }
  />
)
