import React from 'react'
import { Skeleton, Space, Empty, Divider } from 'antd'
import { T } from '@transifex/react'
import parse from 'html-react-parser'

import { useQuery } from '@apollo/client'

import { GET, TResponse } from './api'
import CRMInfoList from '../../../ui/CRMInfoList'

export type TCommentLogDetailProps = {
  id: string
  compact?: boolean
}

const CommentLogDetail = ({ id, compact }: TCommentLogDetailProps) => {
  const { data, loading } = useQuery<TResponse>(GET, {
    variables: {
      id,
    },
  })

  if (loading) return <Skeleton loading />
  if (!loading && !data) return <Empty description={<T _str="No data" />} />

  const { body } = data?.ticketEventLog || {}

  const {
    location,
    container,
    property,
    assignee,
    ticketType,
    id: ticketId,
  } = data?.ticketEventLog?.ticket || ({} as $TSFixMe)

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ marginBottom: 16 }}>{body && parse(body)}</Space>
      <Divider style={{ margin: 0 }} />
      <CRMInfoList
        isContainerLevel={ticketType?.isContainerLevel}
        compact={compact}
        loading={loading}
        content={{
          address:
            location?.name ||
            container?.location?.name ||
            property?.location?.name,
          assigne: assignee && {
            id: assignee?.id,
            name: assignee?.firstName?.concat(` `).concat(assignee?.lastName),
          },
          container: container && {
            id: container.id,
            name: container.containerId,
          },
          property: property && {
            id: property.id,
            name: property.location?.name,
          },
          ticket: ticketId && {
            id: ticketId,
            name: ticketType?.name,
          },
        }}
      />
    </Space>
  )
}

export default CommentLogDetail
