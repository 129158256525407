import { LeftOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'
import { CssScrollerDiv } from '@wastehero/storybook/lib/components'
import React, { FC, useState } from 'react'
import { TPopupPickupOrderDetailProps, TSameStopGroup } from '..'
import PopupLayout1 from '../../Layout'
import StopView from '../Stop/StopView'
import SameStopGroupDetail from './SameStopGroupDetail'

type SameStopGroupBaseProps = TPopupPickupOrderDetailProps & TSameStopGroup

const SameStopGroup: FC<
  SameStopGroupBaseProps & { hideDetailHeader?: boolean }
> & {
  WithoutHeader: FC<SameStopGroupBaseProps>
} = ({ onClose = () => {}, props: { ids, nextStopId }, hideDetailHeader }) => {
  const [selectedPickupOrder, setSelectedPickupOrder] = useState('')

  return (
    <PopupLayout1
      onClose={onClose}
      addonBeforeTitle={
        selectedPickupOrder ? (
          <LeftOutlined
            style={{ fontSize: '1rem' }}
            onClick={() => setSelectedPickupOrder('')}
          />
        ) : undefined
      }
      title={<T _str="Stops" />}
    >
      <CssScrollerDiv
        style={{ height: 300, width: 380 }}
        defaultYScroller
        offset={10}
      >
        {selectedPickupOrder ? (
          <StopView
            isNext={nextStopId === selectedPickupOrder}
            id={selectedPickupOrder}
          />
        ) : (
          <SameStopGroupDetail
            hideHeader={hideDetailHeader}
            onSelect={setSelectedPickupOrder}
            nextStopId={nextStopId}
            orderIds={ids}
          />
        )}
      </CssScrollerDiv>
    </PopupLayout1>
  )
}

const WithoutHeader = (props: SameStopGroupBaseProps) => {
  return <SameStopGroup {...props} hideDetailHeader />
}

SameStopGroup.WithoutHeader = WithoutHeader

export default SameStopGroup
