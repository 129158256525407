import { LinkProps } from '../../reducers/routing/child-router-factory'

import { defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'
import {
  getCellRouteName,
  getCellRouteNameWithTitle,
  TCellRoute,
} from '../cells'

export const getLinkPropsRouteNode = getLinkPropsMakeNullable<
  TCellRoute & { id: string }
>(
  (
    /** Should be RouteType!
     */
    value,
    { includeLabel } = defaultConfig
  ): LinkProps => ({
    to: ({ routingMap }) =>
      routingMap.app['operation-management'].routes['/:id'](value.id)._,
    children: includeLabel
      ? getCellRouteNameWithTitle(value)
      : getCellRouteName(value),
  })
)
