import { mockContainerTypeLabelMap } from './consts'

export type TContainerType = `120L` | `240L` | `340L`

export type MockContainerType = {
  value: TContainerType
}

export const API_MOCK_CONTAINER_TYPE_DATA: MockContainerType[] = (
  Object.keys(mockContainerTypeLabelMap) as TContainerType[]
).map((k) => ({ value: k }))
