import { gql } from '@apollo/client'

export type GetCallResponseEdgeNode = {
  id: string
  name: string
}

export type GetCallResponse = {
  allVehicleTypes: {
    edges: {
      node: GetCallResponseEdgeNode
    }[]
  }
}

export type GetCallVariables = {
  activeProjects?: string
  search?: string
  first?: number
  offset?: number
}

export const GET_CALL = gql`
  query Component_Select_VehicleType_GetAllVehicleTypes(
    $activeProjects: String
    $search: String
    $first: Int
    $offset: Int
    $ids: [ID]
  ) {
    allVehicleTypes(
      activeProjects: $activeProjects
      search: $search
      first: $first
      offset: $offset
      ids: $ids
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
