import { random } from 'lodash'
import moment from 'moment'
import { API_MOCK_USER_DATA } from '../../../../../../../api/mock'

import randomNotes from './notes'

export const USER_SET_1_REQUESTED_BY = API_MOCK_USER_DATA.slice(0, 15)
export const USER_SET_2_CHANGED_BY = API_MOCK_USER_DATA.slice(15, 30)

const data = Array(1000)
  .fill(0)
  .map((_, index) => ({
    date: moment().subtract(random(0, 525600, false), 'minutes').toISOString(),
    requestedBy: USER_SET_1_REQUESTED_BY[random(0, 14, false)],
    changedBy: USER_SET_2_CHANGED_BY[random(0, 14, false)],
    note: randomNotes[random(0, randomNotes.length - 1, false)],
    id: index.toString(),
  }))

export type Data = typeof data[0]

export default data
