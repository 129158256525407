import { Form } from 'antd'
import React from 'react'
import { T } from '@transifex/react'
import { useQuery } from '@apollo/client'
import { baseSelectionFieldNames } from './utils'
import {
  IgnoreFormStyling,
  SummaryTitleWrapper,
  SummaryWrapper,
} from './components/wrappers'
import { SummaryData } from './components/summary-data'
import { SendSummaryToForm } from './components/send-summary-to-form'

import { GET_TICKET_TYPE } from './api'

export type TFormSummarySchema = {
  assignee?: {
    assignee?: string
    assignedTeam?: string
  }
  sendSummaryTo?: {
    users?: string
    sms: boolean
    email: boolean
  }
  reporter?: string
  reporterOther?: string
}

export type TicketSummaryType = {
  containerId: string | undefined
  formRequiredFields: string[]
  ticketTypeId?: string
  propertyId: string | undefined
}

export const TicketSummary = ({
  containerId,
  formRequiredFields,
  ticketTypeId,
  propertyId,
}: TicketSummaryType) => {
  const { data, loading } = useQuery(GET_TICKET_TYPE, {
    variables: { id: ticketTypeId },
    skip: !ticketTypeId,
  })

  return (
    <IgnoreFormStyling>
      {!loading && (
        <Form.Item shouldUpdate style={{ height: '100%' }}>
          {({ getFieldValue, getFieldsError }) => {
            const formErrors = getFieldsError([
              ...baseSelectionFieldNames,
              ...formRequiredFields,
            ])?.filter((fieldError) => fieldError.errors.length > 0)

            const formHasErrors = formErrors.length > 0
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <SummaryTitleWrapper style={{ flex: '0 1' }}>
                  <T _str="Summary" />
                </SummaryTitleWrapper>
                <SummaryWrapper>
                  <SummaryData
                    ticketType={data?.ticketType?.service?.serviceType}
                    formIsEmpty={formHasErrors}
                    containerId={containerId}
                    getFieldValue={getFieldValue}
                    ticketTypeName={data?.ticketType?.name}
                    propertyId={propertyId}
                  />
                  <SendSummaryToForm />
                </SummaryWrapper>
              </div>
            )
          }}
        </Form.Item>
      )}
    </IgnoreFormStyling>
  )
}
