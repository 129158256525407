import React from 'react'
import { T } from '@transifex/react'
import { number, object } from 'yup'
import { FormSchema } from './types'

type Key = keyof FormSchema
const requiredFields: Key[] = ['weight']
export const isRequired = (key: Key) => requiredFields.includes(key)

export const validationSchema = object().shape({
  weight: number().required(() => <T _str="Weight is required" />),
})
