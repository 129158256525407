import React from 'react'
import { TimePicker } from 'antd'
import { Field, RangePickerProps } from 'formik-antd'
import { FieldProps } from 'formik'

const { RangePicker } = TimePicker

/**
 * Extends the TimePicker by `antd` in a similar
 * fashion that `formik-antd` extends `DatePicker`
 * with `DatePicker.RangePicker`.
 * [Example](https://github.com/jannikbuschke/formik-antd/blob/f412642cfb6a6997ac501cfd0491716d90e2b2b0/src/date-picker/index.tsx#L76)
 */
const TimeRangePicker = ({
  name,
  validate,
  onChange,
  ...restProps
}: RangePickerProps) => {
  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <RangePicker
          name={name}
          value={value}
          onChange={(dates, dateStrings) => {
            /**
             * Default:
             *  expect time to be stored in form schema like this.
             *  Any customization can be done in change handler
             */
            const updatedTime = {
              start: dates?.[0] ?? null,
              end: dates?.[1] ?? null,
            }
            setFieldValue(name, updatedTime)
            setFieldTouched(name, true, false)
            onChange?.(dates, dateStrings)
          }}
          {...restProps}
        />
      )}
    </Field>
  )
}

export default TimeRangePicker
