import { createStatefulContextSimple } from '../../../contexts/factories/createStatefulContextSimple'
import { createEnhancedFiltersSimpleStateWithCreateFilter } from '../../filters-utils/createEnhancedFiltersSimpleState'

export type TFilters = {
  status?: string
  ticketType?: string
  propertyGroup?: string[]
  property?: string[]
  containerGroupIds?: string[]
  containerIds?: string[]
  routeIds?: string[]
}

export const initialFilters: TFilters = {}

const { Provider, useContext } = createStatefulContextSimple<TFilters>()

const { createFilter, useFiltersContext } =
  createEnhancedFiltersSimpleStateWithCreateFilter(useContext)

export { Provider as FiltersProiver, useFiltersContext, createFilter }
