import React, { useEffect, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Form } from 'antd'
import { uniqBy } from 'lodash'

import { TBaseFormSchema } from '../form-ticket'
import { TPricingHandlerProps } from './types'
import {
  convertFromMonthlyNumeric,
  TPaymentIntervals,
} from '../../../../../consts'

const GET = gql`
  query FormTicketSinglePricingOptionsChangePickupSetting(
    $containerIds: [ID!]!
  ) {
    ticketPricing {
      changePickupSetting(containerIds: $containerIds) {
        containerPrice {
          id
          amount
          recurringInterval
          containerType {
            id
            name
          }
          wasteFraction {
            id
            name
          }
          pickupSetting {
            id
            name
          }
        }
        servicePrice {
          id
          amount
        }
      }
    }
  }
`

export const TicketPricingOptionsChangePickupSetting = ({
  containerInfo,
  containerId,
}: TPricingHandlerProps) => {
  if (!containerInfo) {
    throw new Error(
      'Container Info required for PriceHandler: TicketPricingOptionsChangePickupSetting '
    )
  }
  const { wasteFraction, containerType } = containerInfo
  const form = Form.useFormInstance<TBaseFormSchema>()
  const pickupSetting = Form.useWatch('pickupSetting')
  /* listening to form values */

  const { data } = useQuery(GET, { variables: { containerIds: [containerId] } })

  const allOptions = useMemo(
    () =>
      data?.ticketPricing?.changePickupSetting?.map((d: $TSFixMe) => {
        const {
          amount,
          recurringInterval,
          containerType: ct,
          wasteFraction: wf,
          pickupSetting: ps,
        } = d.containerPrice

        return [
          { label: ct.name, value: ct.id },
          { label: wf.name, value: wf.id },
          { label: ps.name, value: ps.id },
          [
            amount / 100,
            convertFromMonthlyNumeric[recurringInterval] as TPaymentIntervals,
          ],
          d.servicePrice?.amount / 100,
        ]
      }) || [],
    [data?.ticketPricing?.changePickupSetting]
  )

  const pickupSettingOptions = useMemo(
    () =>
      uniqBy(
        allOptions
          ?.filter(([ct, wf]: $TSFixMe) => {
            return wf.value === wasteFraction && ct.value === containerType
          })
          ?.map(([, , ps]: $TSFixMe) => ps),
        (v: $TSFixMe) => v.value
      ),
    [allOptions, containerType, wasteFraction]
  )

  const serviceFee = useMemo(() => {
    const [, , , , serviceFeeAmount] =
      allOptions.find(
        ([ct, wf, ps]: $TSFixMe) =>
          containerType === ct.value &&
          wf.value === wasteFraction &&
          ps.value === pickupSetting
      ) || []
    return {
      amount: serviceFeeAmount,
      currency: 'dkk',
    }
  }, [allOptions, containerType, pickupSetting, wasteFraction])

  useEffect(() => {
    form.setFieldsValue({
      data: {
        containerTypeOptions: [],
        wasteFractionOptions: [],
        pickupSettingOptions,
        wasteFractionsOptions: [],
        serviceFee,
      },
    })
  }, [pickupSettingOptions, form, serviceFee])

  return <></>
}
