import { gql } from '@apollo/client'

export const GET = gql`
  query GetOneInvoice($id: ID!) {
    invoice(id: $id) {
      id
      invoiceNumber
      periodStart
      periodEnd
      customerName
      customerEmail
      customerAddress
      issuedAt
      dueDate
      total
      status
      createdAt
      subtotal
      taxAmount
      accountName
      accountAddress
      accountTaxId
      ticket {
        id
      }
      paidAt
      paymentMethod {
        details {
          ... on CardPaymentMethodDef {
            brand
            expMonth
            expYear
            last4
          }
        }
      }
      invoiceItems {
        edges {
          node {
            name
            amount
            quantity
            taxAmount
            ticket {
              id
              ticketType {
                isContainerLevel
              }
            }
            agreement {
              container {
                id
                containerId
              }
            }
            periodStart
            periodEnd
            note
          }
        }
      }
    }
  }
`
