import { mockInquiryTypeLabelMap } from './consts'

export type TInquiryType = `missorted_waste`

export type MockInquiryType = {
  value: TInquiryType
}

export const API_MOCK_INQUIRY_TYPE_DATA: MockInquiryType[] = (
  Object.keys(mockInquiryTypeLabelMap) as TInquiryType[]
).map((k) => ({ value: k }))
