import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: (theme as $TSFixMe).variables.VHBlue,
    background: 'rgba(255,255,255,0.5)',
    zIndex: 1200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

type OwnProps = {
  isLoading?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LoadingLayout.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'LoadingLayout' implicitly has type 'any' because ... Remove this comment to see the full error message
const LoadingLayout = ({ isLoading }: Props) => {
  const classes = useStyles()
  return isLoading ? (
    <div className={classes.root}>
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: '46px', color: '#001529' }}
            spin
          />
        }
      />
    </div>
  ) : null
}

LoadingLayout.defaultProps = {
  isLoading: false,
}

export default LoadingLayout
