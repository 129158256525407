/* eslint-disable no-param-reassign */
import create from 'zustand'
import React, { FC, ReactNode, useEffect, useCallback } from 'react'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import {
  Form,
  FormProps,
  ButtonProps,
  Button,
  FormInstance,
  message,
  Space,
} from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import produce from 'immer'
import isEqual from 'react-fast-compare'
import { T } from '@transifex/react'
import styled from 'styled-components'
import classnames from 'classnames'
import {
  antdFormErrorHandler,
  FormErrorHandlerArgs,
} from '../antdform-utils/errorHandler'
import { usePrevious } from '../../hooks'
import { StyledSkeleton } from '../../ui/Skeleton'
import { MsgCreateSuccess, MsgUpdateSuccess } from './consts'

const StyledForm = styled(Form)`
  &.fill-height {
    height: 100%;
  }

  &.isSubmitting {
    opacity: 0.3;
    user-select: none;
  }
`
export type TMode = 'edit' | 'view'

type TFormModeState = {
  mode: TMode
}

type TFormState = {
  touched: boolean
  initialValues: $TSFixMe
  loading?: boolean
  isSubmitting?: boolean
  values: $TSFixMe
  errors: {
    hasError: boolean
    errors: $TSFixMe[]
  }
  form: {
    INTERNAL_SET_FIELDS_VALUE_TRIGGER: $TSFixMe
    INTERNAL_SUBMIT_TRIGGER: boolean
    INTERNAL_VALIDATE_FIELDS_TRIGGER: NamePath[] | 'all' | undefined
    setInitialValues: (key: string, values: $TSFixMe) => void
    setInitialValuesPartial: (key: string, values: $TSFixMe) => void
    setFieldsValue: (key: string, values: $TSFixMe) => void
    submit: (key: string) => void
    validateFields: (key: string, fields?: NamePath[] | 'all') => void
  }
} & TFormModeState

type TStore = Record<string, TFormState>

export type FormRequiredProps = {
  /** formid */
  formId: string
}

export type TCreateAntdFormElementsFormProps = Omit<FormProps, 'form'> & {
  antdFormErrorHandlerArgs?: Pick<
    FormErrorHandlerArgs<$TSFixMe>,
    'fieldMappingFn' | 'onError'
  >
  loading?: boolean
  /** runs when the isSubmitting status is changed */
  onIsSubmittingChange?: (val: boolean | undefined) => void
  /** success message */
  successMsg?: ReactNode
  /** touch listener compare fn  */
  compareFn?: (initialValues: $TSFixMe, values: $TSFixMe) => boolean
  createMode?: boolean
  /** required but only used in create mode - pass empty if you only want to use the form form for update. */
  msgSuccessName: ReactNode
  /** default to view.
   * if createMode is true then it's edit by default
   *
   * passing a value will overwrite the default
   */
  initMode?: TMode
  onSuccess?: () => void
  onError?: () => void
  disableDefaultSuccessMessage?: boolean
  /** createMode success message overwrite */
  successMessageCreateMode?: ReactNode
} & FormRequiredProps

export const useStore = create<TStore>(() => ({}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('wh-antdform-kit-v2', useStore)
}

export const initForm = (formId: string) => {
  const set = useStore.setState
  const get = useStore.getState
  useStore.setState({
    [formId]: {
      initialValues: {},
      touched: false,
      isSubmitting: false,
      loading: false,
      values: {},
      errors: { hasError: false, errors: [] },
      form: {
        setFieldsValue: (key, values) =>
          set(
            produce((draft: TStore) => {
              // eslint-disable-next-line no-param-reassign
              draft[key].form.INTERNAL_SET_FIELDS_VALUE_TRIGGER = values
            })
          ),
        INTERNAL_SET_FIELDS_VALUE_TRIGGER: false,
        INTERNAL_SUBMIT_TRIGGER: false,
        submit: (key) =>
          set(
            produce((draft: TStore) => {
              // eslint-disable-next-line no-param-reassign
              draft[key].form.INTERNAL_SUBMIT_TRIGGER = true
            })
          ),
        setInitialValues: (key, initialValues) => {
          set(
            produce((draft: TStore) => {
              // eslint-disable-next-line no-param-reassign
              draft[key].initialValues = initialValues
            })
          )
          get()[key].form.setFieldsValue(key, initialValues)
        },
        setInitialValuesPartial: (key, initialValues) => {
          set(
            produce((draft: TStore) => {
              // eslint-disable-next-line no-param-reassign
              draft[key].initialValues = {
                ...draft.initialValues,
                ...initialValues,
              }
            })
          )
          get()[key].form.setFieldsValue(key, initialValues)
        },
        INTERNAL_VALIDATE_FIELDS_TRIGGER: undefined,
        validateFields: (key, namePath) => {
          set(
            produce(
              // eslint-disable-next-line no-return-assign
              (draft: TStore) => {
                draft[key].form.INTERNAL_VALIDATE_FIELDS_TRIGGER = namePath
              }
            )
          )
        },
      },
      mode: 'view',
    },
  })
}

export const removeForm = (formId: string) => {
  useStore.setState({ [formId]: undefined as unknown as TFormState })
}

export const setFormState: (
  key: string,
  state:
    | Partial<Omit<TFormState, 'form'>>
    | ((args: TFormState) => Partial<Omit<TFormState, 'form'>>)
) => void = (formId, state) => {
  const current = useStore.getState()[formId]
  if (!current) return // if there is no form instance then don't set state.
  let newState
  if (typeof state === 'function') {
    newState = state(current)
  } else {
    newState = state
  }
  useStore.setState({
    [formId]: {
      ...current,
      ...newState,
    },
  })
}

const FormTouchListener = ({
  compareFn = isEqual,
  formId,
}: Pick<TCreateAntdFormElementsFormProps, 'compareFn' | 'formId'>) => {
  const values = useStore((store) => store[formId].values)
  const initialValues = useStore((store) => store[formId].initialValues)

  useEffect(() => {
    setFormState(formId, { touched: !compareFn(initialValues, values) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, values])

  return <></>
}

const ErrorSetter = ({
  getFieldsError,
  formId,
}: FormInstance & Pick<TCreateAntdFormElementsFormProps, 'formId'>) => {
  const values = useStore((store) => store[formId].values)

  useEffect(() => {
    const [hasError, errors] = getFieldsError().reduce(
      ([acc1, acc2]: $TSFixMe, field: $TSFixMe) => [
        acc1 || !!field?.errors?.length,
        field?.errors?.length ? [...acc2, field] : acc2,
      ],
      [false, []]
    )
    setFormState(formId, {
      errors: {
        errors,
        hasError,
      },
    })
  }, [formId, getFieldsError, values])

  return <></>
}

const FormSetter = ({
  setFieldsValue: formSetFieldsValue,
  submit: formSubmit,
  formId,
  validateFields,
}: FormInstance & Pick<TCreateAntdFormElementsFormProps, 'formId'>) => {
  const {
    INTERNAL_SET_FIELDS_VALUE_TRIGGER,
    setFieldsValue,
    submit,
    INTERNAL_SUBMIT_TRIGGER,
    INTERNAL_VALIDATE_FIELDS_TRIGGER,
  } = useStore((store) => store[formId].form)

  useEffect(() => {
    if (INTERNAL_SET_FIELDS_VALUE_TRIGGER) {
      formSetFieldsValue(INTERNAL_SET_FIELDS_VALUE_TRIGGER)
      setFormState(
        formId,
        produce((draft: TFormState) => {
          draft.values = {
            ...draft.values,
            ...INTERNAL_SET_FIELDS_VALUE_TRIGGER,
          }
          draft.form.INTERNAL_SET_FIELDS_VALUE_TRIGGER = false
        })
      )
    }
    if (INTERNAL_SUBMIT_TRIGGER) {
      formSubmit()
      setFormState(
        formId,
        produce((draft: TFormState) => {
          // eslint-disable-next-line no-param-reassign
          draft.form.INTERNAL_SUBMIT_TRIGGER = false
        })
      )
    }
    if (INTERNAL_VALIDATE_FIELDS_TRIGGER) {
      validateFields(
        INTERNAL_VALIDATE_FIELDS_TRIGGER === 'all'
          ? undefined
          : INTERNAL_VALIDATE_FIELDS_TRIGGER
      ).catch((reason) => {
        if (!reason.outOfDate) {
          const [hasError, errors] = reason.errorFields?.reduce(
            ([acc1, acc2]: $TSFixMe, field: $TSFixMe) => [
              acc1 || !!field?.errors?.length,
              field?.errors?.length ? [...acc2, field] : acc2,
            ],
            [false, []]
          )
          setFormState(formId, {
            errors: {
              errors,
              hasError,
            },
          })
        }
      })
      setFormState(
        formId,
        produce((draft: TFormState) => {
          // eslint-disable-next-line no-param-reassign
          draft.form.INTERNAL_VALIDATE_FIELDS_TRIGGER = undefined
        })
      )
    }
  }, [
    formSetFieldsValue,
    INTERNAL_SET_FIELDS_VALUE_TRIGGER,
    INTERNAL_VALIDATE_FIELDS_TRIGGER,
    setFieldsValue,
    INTERNAL_SUBMIT_TRIGGER,
    submit,
    formSubmit,
    formId,
    validateFields,
  ])

  return <></>
}

export const WithFormInitializer =
  <T extends { formId: string }>(Component: FC<T>): FC<T> =>
  ({ formId, ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const initialized = useStore(
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback((state) => !!state[formId]?.form, [formId])
    )

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!initialized) {
        initForm(formId)
      }
    }, [formId, initialized])

    if (initialized) return <Component {...(rest as T)} formId={formId} />

    return <></>
  }

export const WithFormInitializerStatic =
  <T extends Record<string, unknown>>(
    Component: FC<T>,
    formId: string
  ): FC<T> =>
  ({ ...rest }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const initialized = useStore(
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback((state) => !!state[formId]?.form, [])
    )

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!initialized) {
        initForm(formId)
      }
    }, [initialized])

    if (initialized) return <Component {...(rest as T)} formId={formId} />

    return <></>
  }

export const FormFn: FC<TCreateAntdFormElementsFormProps> = WithFormInitializer(
  ({
    initialValues,
    onFinish,
    antdFormErrorHandlerArgs = {},
    loading = false,
    onIsSubmittingChange,
    successMsg,
    compareFn,
    formId,
    className,
    createMode,
    msgSuccessName,
    initMode,
    onSuccess,
    onError,
    disableDefaultSuccessMessage,
    successMessageCreateMode,
    ...props
  }) => {
    const [form] = Form.useForm<$TSFixMe>()
    const isSubmitting = useStore((store) => store[formId].isSubmitting)
    const setInitialValues = useStore(
      (store) => store[formId].form.setInitialValues
    )
    const formMode = useStore((store) => store[formId].mode)
    const initVal = usePrevious(initialValues, !isSubmitting)

    useEffect(() => {
      onIsSubmittingChange?.(isSubmitting)
    }, [isSubmitting, onIsSubmittingChange])

    useEffect(() => {
      if (!isSubmitting && !isEqual(initVal, initialValues)) {
        setInitialValues(formId, initialValues)
      }
    }, [initialValues, form, isSubmitting, initVal, setInitialValues, formId])

    useEffect(() => {
      setFormState(formId, { loading })
    }, [formId, loading])

    useEffect(() => {
      if (initMode) {
        setFormState(formId, { mode: initMode })
      } else if (createMode) {
        setFormState(formId, { mode: 'edit' })
      } else {
        setFormState(formId, { mode: 'view' })
      }
    }, [createMode, initMode, formId])

    const onFinishedBase = antdFormErrorHandler<$TSFixMe>({
      fn: async (...a) => {
        setFormState(formId, { isSubmitting: true })
        await onFinish?.(...a)
        setFormState(formId, { isSubmitting: false })
        if (!disableDefaultSuccessMessage) {
          message.success(
            createMode ? (
              successMessageCreateMode || (
                <MsgCreateSuccess name={msgSuccessName} />
              )
            ) : (
              <MsgUpdateSuccess />
            )
          )
        }
        if (successMsg) {
          message.success(successMsg)
        }
        onSuccess?.()
      },
      handlers: form,
      onError: (e) => {
        setFormState(formId, { isSubmitting: false })
        antdFormErrorHandlerArgs?.onError?.(e)
        onError?.()
      },
      ...antdFormErrorHandlerArgs,
    })

    return (
      <StyledForm
        layout="vertical"
        {...props}
        form={form}
        onValuesChange={(_, values) => {
          setFormState(formId, { values })
          props.onValuesChange?.(_, values)
        }}
        className={classnames(className, 'wh-antd-form', {
          disabled: formMode === 'view',
          isSubmitting,
        })}
        onFinish={async (values) => {
          await onFinishedBase?.(values)
          if (!createMode) {
            setFormState(formId, { mode: 'view' })
          }
        }}
      >
        <StyledSkeleton loading={loading} size="small">
          {props.children}
        </StyledSkeleton>
        <Form.Item shouldUpdate noStyle>
          {(f) => <FormSetter formId={formId} {...(f as FormInstance)} />}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {(f) => <ErrorSetter formId={formId} {...(f as FormInstance)} />}
        </Form.Item>
        <FormTouchListener formId={formId} compareFn={compareFn} />
      </StyledForm>
    )
  }
)

export const SubmitButton: FC<
  Omit<ButtonProps, 'htmlType'> & {
    /** default to true. */
    disableOnUnTouched?: boolean
  } & Pick<TCreateAntdFormElementsFormProps, 'formId'>
> = WithFormInitializer(
  ({ formId, disabled, disableOnUnTouched = true, ...props }) => {
    const isSubmitting = useStore((store) => store[formId].isSubmitting)
    const loading = useStore((store) => store[formId].loading)
    const form = useStore((store) => store[formId].form)
    const touched = useStore((store) => store[formId].touched)

    return (
      <Button
        type="primary"
        {...props}
        disabled={disabled || (disableOnUnTouched && !touched) || loading}
        loading={isSubmitting}
        onClick={(...args) => {
          form.submit(formId)
          props.onClick?.(...args)
        }}
      >
        {props?.children || (
          <>{isSubmitting ? <T _str="Submitting" /> : <T _str="Submit" />}</>
        )}
      </Button>
    )
  }
)

export const ResetButton: FC<
  ButtonProps & Pick<TCreateAntdFormElementsFormProps, 'formId'>
> = WithFormInitializer(({ formId, onClick, ...props }) => {
  const initialValues = useStore((store) => store[formId].initialValues)
  useStore((store) => store[formId].values)
  const setFieldsValue = useStore((store) => store[formId].form.setFieldsValue)

  return (
    <Button
      {...props}
      onClick={(...e) => {
        setFieldsValue(formId, initialValues)
        onClick?.(...e)
      }}
    />
  )
})

export const SetEditModeButton: FC<
  ButtonProps & Pick<TCreateAntdFormElementsFormProps, 'formId'>
> = WithFormInitializer(({ onClick, formId, ...props }) => {
  return (
    <Button
      type="primary"
      {...props}
      onClick={(...a) => {
        setFormState(formId, { mode: 'edit' })
        onClick?.(...a)
      }}
    >
      {props.children || <T _str="Edit" />}
    </Button>
  )
})

export const SetViewModeButton: FC<
  ButtonProps & Pick<TCreateAntdFormElementsFormProps, 'formId'>
> = WithFormInitializer(({ onClick, formId, ...props }) => {
  return (
    <Button
      {...props}
      onClick={(...a) => {
        setFormState(formId, { mode: 'view' })
        onClick?.(...a)
      }}
    >
      {props.children || <T _str="Cancel" />}
    </Button>
  )
})

export type TFooterProps = {
  createMode: boolean
  addonPrefix?: ReactNode
  addonSuffix?: ReactNode
  submitBtnProps?: ButtonProps
  editBtnProps?: ButtonProps
  createEditBtnProps?: ButtonProps
}

export const Footer: FC<
  TFooterProps & Pick<TCreateAntdFormElementsFormProps, 'formId'>
> = WithFormInitializer(
  ({
    createMode,
    addonPrefix,
    addonSuffix,
    submitBtnProps,
    editBtnProps,
    createEditBtnProps,
    formId,
  }) => {
    const mode = useStore((store) => store[formId].mode)
    const setFieldsValue = useStore(
      (store) => store[formId].form.setFieldsValue
    )
    const initialValues = useStore((store) => store[formId].initialValues)

    return (
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        {addonPrefix}
        {mode === 'view' && (
          <SetEditModeButton formId={formId} {...editBtnProps} />
        )}
        {mode === 'edit' && (
          <>
            {!createMode && (
              <SetViewModeButton
                formId={formId}
                onClick={() => setFieldsValue(formId, initialValues)}
              />
            )}
            {createMode && createEditBtnProps?.onClick && (
              <Button {...createEditBtnProps}>
                {createEditBtnProps.children || <T _str="Cancel" />}
              </Button>
            )}
            <SubmitButton
              formId={formId}
              {...submitBtnProps}
              disableOnUnTouched={!createMode}
            />
          </>
        )}
        {addonSuffix}
      </Space>
    )
  }
)
