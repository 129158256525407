import React, { useEffect, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Form } from 'antd'
import { uniqBy } from 'lodash'

import { TBaseFormSchema } from '../form-ticket'
import { TPricingHandlerProps } from './types'

const GET = gql`
  query FormTicketSinglePricingOptionsBulkWastePickup($propertyIds: [ID!]!) {
    ticketPricing {
      bulkWastePickup(propertyIds: $propertyIds) {
        wasteFraction {
          id
          name
        }
        servicePrice {
          id
          amount
        }
      }
    }
  }
`

export const TicketPricingOptionsBulkWastePickup = ({
  propertyId,
}: TPricingHandlerProps) => {
  const form = Form.useFormInstance<TBaseFormSchema>()
  const wasteFractions = Form.useWatch('wasteFractions')
  /* listening to form values */

  const { data } = useQuery(GET, { variables: { propertyIds: [propertyId] } })

  const allOptions = useMemo(
    () =>
      data?.ticketPricing?.bulkWastePickup?.map((d: $TSFixMe) => {
        const { id, name } = d.wasteFraction

        return [{ label: name, value: id }, d.servicePrice?.amount / 100]
      }) || [],
    [data?.ticketPricing?.bulkWastePickup]
  )

  const allWasteFractionsOptions = useMemo(
    () =>
      uniqBy(
        allOptions?.map(([wf]: $TSFixMe) => wf),
        (v: $TSFixMe) => v.value
      ),
    [allOptions]
  )

  const serviceFee = useMemo(
    () => ({
      currency: 'dkk',
      amount: allOptions?.reduce(
        (acc: number, [wf, serviceFeeAmount]: $TSFixMe) => {
          if (wasteFractions?.includes(wf.value)) return acc + serviceFeeAmount
          return acc
        },
        0
      ),
    }),
    [allOptions, wasteFractions]
  )

  useEffect(() => {
    form.setFieldsValue({
      data: {
        containerTypeOptions: [],
        wasteFractionOptions: [],
        pickupSettingOptions: [],
        wasteFractionsOptions: allWasteFractionsOptions,
        serviceFee,
      },
    })
  }, [allOptions, allWasteFractionsOptions, form, serviceFee])

  return <></>
}
