import React from 'react'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { getSavedActiveProjects } from '../../../components/shared/utils'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'

const formatResponse = ({
  node: {
    id,
    user: { firstName, lastName },
  },
}: {
  node: GetCallResponseEdgeNode
}): Opt => ({
  label: `${firstName} ${lastName}`,
  value: id,
  key: id,
})

type SelectDriverProps = {
  variables?: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps']

export const SelectDriver = ({ variables, ...props }: SelectDriverProps) => {
  const activeProjects = useSelector((state) => getSavedActiveProjects(state))
  const queryVariables = {
    activeProjects,
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allDrivers.edges.map((val: $TSFixMe) => formatResponse(val))
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      selectProps={{
        placeholder: <T _str="Driver" />,
        allowClear: true,
        maxTagCount: 'responsive',
        ...props,
      }}
      value={props.value}
    />
  )
}
