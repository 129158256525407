import React from 'react'
import { T } from '@transifex/react'
import { useMutation } from '@apollo/client'
import { asyncAlertAction } from '@wastehero/storybook/lib/components'
import { message } from 'antd'

import { K_GET_COMPANY_BILL_CARDS, MUTATION_SET_DEFAULT_CARD } from './api'

export const useChangeDefaultCard = (throwError: boolean) => {
  const [apiCall] = useMutation(MUTATION_SET_DEFAULT_CARD, {
    refetchQueries: [K_GET_COMPANY_BILL_CARDS],
  })

  return async (cardId: string, billId: string) => {
    try {
      await apiCall({
        variables: {
          billingInformationId: billId,
          cardId,
        },
      })
      message.success(<T _str="Default card successfully changed." />)
    } catch (err: $TSFixMe) {
      message.error(err?.message || 'Something went wrong')
      if (throwError) {
        throw new Error(err)
      }
    }
  }
}

export const useChangeDefaultCardModal = () => {
  const fnChangeCard = useChangeDefaultCard(true)

  return (cardId: string, billId: string) =>
    asyncAlertAction({
      modalProps: {
        title: <T _str="Change default card" />,
      },
      alertProps: {
        message: <T _str="Change default card" />,
        description: <T _str="You are about to change the default card." />,
        type: 'info',
        showIcon: true,
      },
      asyncCall: async ({ closeModal, setLoading }) => {
        try {
          await fnChangeCard(cardId, billId)
          closeModal()
        } catch (err: $TSFixMe) {
          setLoading(false)
        }
      },
    })
}
