import React from 'react'
import { T } from '@transifex/react'

/**
 * @deprecated
 * Use `jsxFieldRequried` instead.
 */
export const getFieldRequiredSimple = (field: string) => () =>
  <T _str="{field} is required" field={field} />

export const jsxFieldRequried = <T _str="This field is required" />
export const jsxNumberOnly = <T _str="Only numbers." />
export const messageNoNegativeValues = (
  <T _str="Only positive numbers are allowed!" />
)

export const maxLengthStringMessage = (maxLength: number) => (
  <T _str="Exceeded maximum length of {maxLength}" maxLength={maxLength} />
)

export const messageWrongCoords = (coordsValue: string | number) => (
  <T _str="Should be in range from -{value} to {value}" value={coordsValue} />
)
