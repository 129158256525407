import { gql } from '@apollo/client'

/* Fragments */
export const FrgWasteFraction = gql`
  fragment BaseWasteFraction on WasteFractionType {
    id
    name
    wasteSubstance
    wasteCategory
    weightFactor
    isUsed
    project {
      id
      name
    }
  }
`
/* Queries */
export const GET_ALL_WASTE_FRACTIONS = gql`
  query GetAllWasteFractionsGlobal(
    $projectId: ID
    $activeProjects: String
    $vehicles: String
    $withProject: Boolean = true
    $search: String
  ) {
    allWasteFractions(
      projectId: $projectId
      activeProjects: $activeProjects
      search: $search
      vehicles: $vehicles
    ) {
      edges {
        node {
          id
          name
          wasteSubstance
          wasteCategory
          weightFactor
          isUsed
          project @include(if: $withProject) {
            id
            name
          }
        }
      }
    }
  }
`
/* Mutations */
