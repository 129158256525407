import React from 'react'
import { Select, SelectProps } from 'antd'

import { Opt } from './types'
import { TICKET_CREATED_BY_OPTIONS } from '../../api/consts/TicketCreatedBy'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectTicketCreatedByProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps<Opt>, 'options' | 'loading'>

export const SelectTicketCreatedBy = ({
  render,
  ...selectProps
}: SelectTicketCreatedByProps) => {
  return (
    render?.({
      options: TICKET_CREATED_BY_OPTIONS,
      loading: false,
    }) || (
      <Select
        options={TICKET_CREATED_BY_OPTIONS}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}
