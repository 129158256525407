import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { T, useT } from '@transifex/react'
import { FormikProps, FormikValues } from 'formik'
import { Col, Row, Space } from 'antd'
import { Select, FormItem } from 'formik-antd'
import Button from '@wastehero/storybook/lib/components/Form/Elements/Button'
import Form from '@wastehero/storybook/lib/components/Form/index'
import FormikTextInput from '@wastehero/storybook/lib/components/Form/Elements/TextInput/Formik'
import { SelectCountry } from '@wastehero/storybook/lib/components'
import FormCompanyShippingInformationScheme from './scheme'
import {
  GET_BILLING_INFORMATION_QUERY,
  UPDATE_BILLING_INFORMATION,
  K_GET_BILLING_INFORMATION_QUERY,
} from './api'
import { LayoutWithDividerv3 } from '../../../layouts'
import { TFormEventsProps } from '../types'

type TFormCompanyShippingInformationProps = {
  companyId: string
} & TFormEventsProps

type TFormCompanyShippingInformation = FC<TFormCompanyShippingInformationProps>

type TGraphqlProperties = {
  getQueryKey: string
}

type TFormCompanyShippingInformationWithGraphql =
  TFormCompanyShippingInformation & TGraphqlProperties

const FormCompanyShippingInformation: TFormCompanyShippingInformationWithGraphql =
  ({ companyId, on: { success: onSuccess } = {} }) => {
    const t = useT()

    const translationNameOfTheCard = t('Name of the company')
    const translationAddress = t('Main address')
    const translationAddress2 = t('Optional secondary Address')
    const translationZip = t('The zip code')
    const translationCity = t('The city which the company is located in')
    const translationCountry = t('Name of the country')

    /* Fetching initial values */
    const { data: { company } = {}, refetch } = useQuery(
      GET_BILLING_INFORMATION_QUERY,
      {
        variables: {
          id: companyId,
        },
      }
    )

    const billingInformationMutationVariablesFormatter = (values: $TSFixMe) => {
      const variables = {
        shippingName: values.name,
        shippingAddress: values.address,
        shippingAddress2: values.addressTwo,
        shippingCity: values.cityName,
        companyId,
        shippingCountry: values.country,
        shippingInvoiceEmail: values.invoiceEmail,
        shippingZipCode: values.zipCode,
        shippingInvoicePhone: values.phone,
      }

      return variables
    }

    return (
      <Form
        mutation={UPDATE_BILLING_INFORMATION}
        mutationVariablesFormatter={
          billingInformationMutationVariablesFormatter
        }
        onSuccess={() => {
          refetch()
          onSuccess?.()
        }}
        formikProps={{
          initialValues: {
            name: company?.billinginformation?.shippingName,
            address: company?.billinginformation?.shippingAddress,
            addressTwo: company?.billinginformation?.shippingAddress2,
            cityName: company?.billinginformation?.shippingCity,
            country: company?.billinginformation?.shippingCountry,
            invoiceEmail: company?.billinginformation?.shippingInvoiceEmail,
            zipCode: company?.billinginformation?.shippingZipCode,
            phone: company?.billinginformation?.shippingInvoicePhone,
          },
          validationSchema: FormCompanyShippingInformationScheme,
          enableReinitialize: true,
        }}
      >
        {({ isSubmitting, submitForm }: FormikProps<FormikValues>) => (
          <LayoutWithDividerv3
            style={{
              padding: '1rem',
            }}
            dividerMargin={16}
            content={[
              <>
                <FormikTextInput
                  name="name"
                  placeholder={translationNameOfTheCard}
                  antd={{
                    required: true,
                    label: <T _str="Name" />,
                  }}
                />

                <FormikTextInput
                  name="address"
                  placeholder={translationAddress}
                  antd={{
                    required: true,
                    label: <T _str="Address" />,
                  }}
                />
                <FormikTextInput
                  name="addressTwo"
                  placeholder={translationAddress2}
                  antd={{
                    label: <T _str="Address 2" />,
                  }}
                />
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <FormikTextInput
                      name="zipCode"
                      placeholder={translationZip}
                      antd={{
                        required: true,
                        label: <T _str="Zip code" />,
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <FormikTextInput
                      name="cityName"
                      placeholder={translationCity}
                      antd={{
                        required: true,
                        label: <T _str="City" />,
                      }}
                    />
                  </Col>
                </Row>
              </>,
              <>
                <FormItem name="country" required label={<T _str="Country" />}>
                  <Select
                    name="country"
                    options={SelectCountry.options}
                    placeholder={translationCountry}
                    showSearch
                    filterOption
                    optionFilterProp="label"
                  />
                </FormItem>
              </>,
            ]}
            footer={
              <Space style={{ width: '100%', justifyContent: 'end' }} size={16}>
                <Button
                  type="primary"
                  disabled={isSubmitting}
                  title={<T _str="Submit" />}
                  onClick={submitForm}
                />
              </Space>
            }
          />
        )}
      </Form>
    )
  }

FormCompanyShippingInformation.getQueryKey = K_GET_BILLING_INFORMATION_QUERY

export { FormCompanyShippingInformation }
