import styled, { css } from 'styled-components'

type TSimpleRoundedBorderedProps = {
  innerPadding?: number
}

const simpleRoundedBordered = css<TSimpleRoundedBorderedProps>`
  table {
    padding: 0 1rem;
  }
  .ant-table-content {
    border: solid 1px #e3e3e3;
    border-radius: 5px;
  }

  .ant-table-thead > tr > th {
    background: none;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 12px 12px;
  }

  &&& {
    tr {
      td {
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }

  thead {
    th {
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
    }
  }
`

const headerSecondary = css`
  .ant-table-thead > tr > th {
    font-weight: 400;
    color: #aaafb4;
  }
`

export const headerNoDividers = css`
  &&& {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      content: none;
    }
  }
`

export const TableVariants = {
  simpleRoundedBordered,
  headerSecondary,
  headerNoDividers,
}

const TableSimpleBordered = styled.div<{ styles?: string }>`
  ${simpleRoundedBordered}
  ${headerSecondary}
  ${headerNoDividers}

  ${({ styles = `` }) => styles}
`

export const TableVariantsCombos = {
  TableSimpleBordered,
}
