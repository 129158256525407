import { mockRequestTypeLabelMap } from './consts'

export type TRequestType =
  | `cleaning`
  | `change_container_type`
  | `change_pickup_schee`

export type MockRequestType = {
  value: TRequestType
}

export const API_MOCK_REQUEST_TYPE_DATA: MockRequestType[] = (
  Object.keys(mockRequestTypeLabelMap) as TRequestType[]
).map((k) => ({ value: k }))
