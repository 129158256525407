import { lowerCase } from 'lodash'
import moment, { Moment } from 'moment'
import { createGetAll } from '../../../../../../../new-components/mock-utils/createMockHelpers'

import data, { Data } from './data'

export * from './data'

type Response = {
  data: Data[]
  total: number
}

type PaginationArgs = {
  first: number
  offset: number
}

type SortableFields = keyof Pick<Data, 'date' | 'changedBy' | 'requestedBy'>

type SortArgs = {
  sortField?: SortableFields
  sortOrder?: 1 | -1
}

const sortFieldsMap: Record<SortableFields, (a: Data, b: Data) => number> = {
  changedBy: (a, b) =>
    a.changedBy.firstName.localeCompare(b.changedBy.firstName),
  requestedBy: (a, b) =>
    a.requestedBy.firstName.localeCompare(b.requestedBy.firstName),
  date: (a, b) => moment(a.date).diff(b.date),
}

type Args = {
  changedBy?: string[]
  requestedBy?: string[]
  dateRange?: [Moment, Moment]
  search?: string
} & PaginationArgs &
  SortArgs

const ifFilter = (val: unknown, filter: boolean | undefined) =>
  !val ? true : filter

export const useGetAll = createGetAll<Args, Response>(
  ({
    changedBy: fchangedBy,
    first,
    offset,
    requestedBy: frequestedBy,
    search: fsearch = '',
    dateRange: fdateRange,
    sortField,
    sortOrder,
  }) => {
    const query = lowerCase(fsearch)
    const allResponse = data.filter(({ changedBy, requestedBy, date }) => {
      return (
        ifFilter(fchangedBy?.length, fchangedBy?.includes(changedBy.id)) &&
        ifFilter(
          frequestedBy?.length,
          frequestedBy?.includes(requestedBy.id)
        ) &&
        ifFilter(
          fsearch,
          lowerCase(changedBy.fullName).includes(query) ||
            lowerCase(requestedBy.fullName).includes(query)
        ) &&
        ifFilter(
          fdateRange,
          moment(date).isBetween(fdateRange?.[0], fdateRange?.[1])
        )
      )
    })

    if (sortField && sortOrder) {
      allResponse.sort((a, b) => sortFieldsMap[sortField](a, b) * sortOrder)
    }

    const off = offset / first

    return {
      data: allResponse.slice(off * first, (off + 1) * first),
      total: allResponse.length,
    }
  }
)
