import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type AutomationActionTypes =
  | `send_message_to_related_customer`
  | 'schedule_route'
  | 'assign_to_user'
  | 'assign_to_team'
  | 'create_ticket'
  | 'change_priority'

export const automationActionTypesLabelMap: Record<
  AutomationActionTypes,
  ReactNode
> = {
  send_message_to_related_customer: (
    <T _str="Send message to related customers" />
  ),
  schedule_route: <T _str="Schedule in route" />,
  assign_to_user: <T _str="Assign to User" />,
  assign_to_team: <T _str="Assign to Team" />,
  create_ticket: <T _str="Create a ticket" />,
  change_priority: <T _str="Change priority" />,
}

export const automationActionTypesSelectOptions = createOptionsFromLabelMap(
  automationActionTypesLabelMap
)

export const automationActionTypesAsArray = Object.keys(
  automationActionTypesLabelMap
) as AutomationActionTypes[]

export const automationActionTypeDefMap: Record<string, AutomationActionTypes> =
  {
    SendMessageToPropertyUserActionDef: 'send_message_to_related_customer',
    ScheduleOnRouteActionDef: 'schedule_route',
    AssignToUserActionDef: 'assign_to_user',
    AssignToTeamActionDef: 'assign_to_team',
    CreateTicketActionDef: 'create_ticket',
    ChangePriorityActionDef: 'change_priority',
  }

export const automationActionTypeMutationKeys: Record<
  AutomationActionTypes,
  string
> = {
  send_message_to_related_customer: 'sendMessageToPropertyUserAction',
  schedule_route: 'scheduleOnRouteAction',
  assign_to_user: 'assignToUserAction',
  assign_to_team: 'assignToTeamAction',
  create_ticket: 'createTicketAction',
  change_priority: 'changePriorityAction',
}
