import React, { useEffect, useState } from 'react'
import { Row, Col, Divider } from 'antd'
import styled from 'styled-components'
import MapCard from '@wastehero/storybook/lib/components/Card/Cards/Map'
import { useQuery } from '@apollo/client'

import PropertyInfo from './components/info-card'
import TableAgreements from './components/table-agreements'
import TimelineUpcoming from './components/timeline-upcoming'
import EventLog from './components/event-log'
import { useCustomerContext } from '../customer.context'
import {
  GET_PROPERTY_AGREEMENTS,
  TResponse,
  TVariables,
} from './components/table-agreements/table/api'
import PropertyMasterData from './components/master-data-card'

const Layout = styled(Row)`
  --bgColor: rgb(235, 239, 243);
  background: var(--bgColor);
  padding-top: 4px;

  .white {
    background: white;
  }
`

const OverviewPage = () => {
  const { location, id: propertyId } = useCustomerContext()
  const [containers, setContainers] = useState<$TSFixMe>([])

  const { data: { property } = {}, loading } = useQuery<TResponse, TVariables>(
    GET_PROPERTY_AGREEMENTS,
    {
      variables: {
        propertyId,
      },
    }
  )

  useEffect(() => {
    setContainers(
      property?.agreements.edges
        ?.filter((agreement) => !!agreement?.node?.container)
        ?.map((agreement) => ({
          id: agreement.node?.container?.id,
          fillData: {
            id: agreement.node?.container?.id,
            containerId: agreement.node?.container?.containerId,
            fillLevel: agreement.node?.container?.measurement?.fillPercentage,
            location: {
              id: agreement.node?.container?.location?.id,
              latitude: agreement.node?.container?.location.latitude,
              longitude: agreement.node?.container?.location.longitude,
              name: agreement.node?.container?.location?.name,
            },
            wasteFraction: {
              id: agreement.node?.container?.wasteFraction?.id,
              name: agreement.node?.container?.wasteFraction?.name,
              wasteSubstance:
                agreement.node?.container?.wasteFraction?.wasteSubstance,
              wasteCategory:
                agreement.node?.container?.wasteFraction?.wasteCategory,
            },
            sensor: !!agreement.node?.container?.measurement?.fillPercentage,
          },
        })) || []
    )
  }, [property?.agreements])

  return (
    <Layout gutter={[4, 4]} style={{ height: '100%' }}>
      <Col xs={5} style={{ height: '100%' }}>
        <div className="wh-vertical-scroller-container white">
          <PropertyInfo />
          <PropertyMasterData />
          {/* <Divider
            style={{
              margin: 0,
              borderWidth: 5,
              borderColor: 'var(--bgColor)',
            }}
          /> */}
          {/* <InfoSearch className="wh-vertical-scroller-container-scroll" /> */}
        </div>
      </Col>
      <Col xs={14} style={{ height: '100%' }}>
        <div className="white wh-vertical-scroller-container">
          <div className="wh-vertical-scroller-container-scroll">
            <TableAgreements />
          </div>
        </div>
      </Col>
      <Col xs={5} style={{ height: '100%' }}>
        <div className="wh-vertical-scroller-container white">
          <TimelineUpcoming />
          <Divider
            style={{
              margin: 0,
              borderWidth: 5,
              borderColor: 'var(--bgColor)',
            }}
          />
          <div className="wh-vertical-scroller-container-scroll">
            <EventLog />
          </div>
          <Divider
            style={{
              margin: 0,
              borderWidth: 5,
              borderColor: 'var(--bgColor)',
            }}
          />
          {location.latitude && location.longitude && (
            <MapCard
              loading={loading || !location}
              containers={containers}
              center={{
                latitude: location.latitude,
                longitude: location.longitude,
              }}
            />
          )}
        </div>
      </Col>
    </Layout>
  )
}

export default OverviewPage
