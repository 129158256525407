import { TMockWasteFractionEnum, mockWasteFractioLabelMap } from './consts'

export type TMockWasteFraction = {
  value: TMockWasteFractionEnum
  id: string
}

export const API_MOCK_WASTE_FRACTION_DATA: TMockWasteFraction[] = (
  Object.keys(mockWasteFractioLabelMap) as TMockWasteFractionEnum[]
).map((k) => ({ value: k, id: k }))
