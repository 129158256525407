import React from 'react'
import { T } from '@transifex/react'
import { LinkProps } from '../../reducers/routing/child-router-factory'
import { getCellTicketLocation } from '../cells'

import { TGetLinkPropsFn, defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'

export const getLinkPropsTicketNode: TGetLinkPropsFn = getLinkPropsMakeNullable(
  (ticket: $TSFixMe, { includeLabel } = defaultConfig): LinkProps => ({
    to: ({ routingMap }) => routingMap.app.tickets['/:id'](ticket.id)._,
    children: includeLabel ? (
      <T
        _str="Ticket: {name}"
        name={getCellTicketLocation({ value: ticket })}
      />
    ) : (
      getCellTicketLocation({ value: ticket })
    ),
  })
)
