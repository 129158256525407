import React from 'react'
import TicketTable from '../../../../../../../new-components/tables/tickets'
import { useCustomerContext } from '../customer.context'

const Tickets = () => {
  const { id: property } = useCustomerContext()

  return (
    <TicketTable
      tableColumnsTreeFilterAndOrderProps={{
        key: 'TicketTable.Property',
      }}
      initialFilters={{ property: [property] }}
    />
  )
}

export default Tickets
