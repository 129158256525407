import React from 'react'
import { useLocation } from 'react-router-dom'
import InvoiceTable from '../../../../../../../new-components/tables/invoice'
import { useCustomerContext } from '../customer.context'

const Invoices = () => {
  const { state } = useLocation<$TSFixMe>()
  const { id: property } = useCustomerContext()

  return (
    <InvoiceTable
      tableColumnsTreeFilterAndOrderProps={{
        key: 'InvoiceTable.Property',
      }}
      initialFilters={{ property: [property] }}
      initialInvoice={state?.initialInvoice}
      initialReciept={state?.initialReciept}
    />
  )
}

export default Invoices
