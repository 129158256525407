import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { AlertProps, Tag, TagProps } from 'antd'
import { createOptionsFromLabelMap } from '../../utils/create-options'

export type TCrmInvoiceStatusChoices = 'A_1' | 'A_2' | 'A_3' | 'A_4'

export const crmInvoiceStatusChoicesLabelMap: Record<
  TCrmInvoiceStatusChoices,
  ReactNode
> = {
  A_1: <T _str="Draft" />,
  A_2: <T _str="Open" />,
  A_3: <T _str="Paid" />,
  A_4: <T _str="Void" />,
}

export const crmInvoiceStatusChoicesTagColorMap: Record<
  TCrmInvoiceStatusChoices,
  TagProps['color']
> = {
  A_1: '',
  A_2: 'orange',
  A_3: 'green',
  A_4: 'red',
}

export const crmInvoiceStatusChoicesAlertTypeMap: Record<
  TCrmInvoiceStatusChoices,
  AlertProps['type']
> = {
  A_1: 'info',
  A_2: 'info',
  A_3: 'success',
  A_4: 'error',
}

export const crmInvoiceStatusChoicesOptions = createOptionsFromLabelMap(
  crmInvoiceStatusChoicesLabelMap
)

export const crmInvoiceStatusChoicesMutationValueMap: Record<
  TCrmInvoiceStatusChoices,
  number
> = {
  A_1: 1,
  A_2: 2,
  A_3: 3,
  A_4: 4,
}

export const crmInvoiceStatusChoicesTag = (val: TCrmInvoiceStatusChoices) => (
  <Tag color={crmInvoiceStatusChoicesTagColorMap[val]}>
    {crmInvoiceStatusChoicesLabelMap[val]}
  </Tag>
)
