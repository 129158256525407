import { gql } from '@apollo/client'
import { message } from 'antd'
import apolloClient from '../components/graphql/apollo'

const MUTATION_IMPERSONATE_USER = gql`
  mutation ($impersonatedId: ID!) {
    impersonate(impersonatedId: $impersonatedId) {
      token
    }
  }
`

const impersonatorTokenKey = 'impersonatorToken'

const removeImpersonatorKey = () => {
  localStorage.removeItem(impersonatorTokenKey)
}

export const impersonateUser = (impersonatedId: string) =>
  apolloClient
    .mutate({
      mutation: MUTATION_IMPERSONATE_USER,
      variables: {
        impersonatedId,
      },
    })
    .then(({ data }) => {
      const token = data?.impersonate?.token
      // set this token to the new reactQLToken and current token to impersonator token
      const currentToken = localStorage.getItem('reactQLJWT')
      if (currentToken) {
        localStorage.setItem(impersonatorTokenKey, currentToken)
        localStorage.setItem('reactQLJWT', token)

        window.location.reload() // reload the whole app.
      }
    })
    .catch((e) => {
      message.error(e?.message)
    })

export const endImpersonatingUser = () => {
  const impersonatorToken = localStorage.getItem(impersonatorTokenKey)
  if (impersonatorToken) {
    localStorage.setItem('reactQLJWT', impersonatorToken)
    removeImpersonatorKey()

    window.location.reload() // reload the whole app.
  }
}

export const endImpersonatingBeforeLogout = () => {
  removeImpersonatorKey()
}
