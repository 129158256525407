import React from 'react'
import { Skeleton } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { SummaryDataEntryWrapper } from './wrappers'

export type DataEntryTypes = {
  label: React.ReactNode
  data: string | React.ReactNode
  changeData?: string | React.ReactNode
  loading?: boolean
}
export const DataEntry = ({
  loading,
  label,
  data,
  changeData,
}: DataEntryTypes) => {
  if (loading) {
    return (
      <SummaryDataEntryWrapper>
        <Skeleton active paragraph={false} title style={{ height: '22px' }} />
      </SummaryDataEntryWrapper>
    )
  }

  return (
    <SummaryDataEntryWrapper>
      {label}
      <div
        className="data-entry-text"
        id={typeof data === 'string' ? 'ellipsis-field' : ''}
      >
        {data}
        {changeData && (
          <ArrowRightOutlined
            style={{
              color: '#8c8c8c',
              marginLeft: '0.25rem',
              marginRight: '0.25rem',
            }}
          />
        )}
        {changeData}
      </div>
    </SummaryDataEntryWrapper>
  )
}
