import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

export const FrgPropertyFacilityRequestType = gql`
  fragment FrgPropertyFacilityRequestType on PropertyFacilityRequestTypeDef {
    id
    name
  }
`

export const GET_ALL_PROPERTY_FACILITY_REQUEST_TYPES = gql`
  ${FrgPropertyFacilityRequestType}
  query GetAllPropertyFacilityRequestTypes(
    $activeProjects: String
    $search: String
    $companyId: ID
  ) {
    allPropertyFacilityRequestTypes(
      activeProjects: $activeProjects
      search: $search
      companyId: $companyId
    ) {
      totalCount
      edges {
        node {
          ...FrgPropertyFacilityRequestType
        }
      }
    }
  }
`

export const K_GET_ALL_PROPERTY_FACILITY_REQUEST_TYPES = apiUtilGetKey(
  GET_ALL_PROPERTY_FACILITY_REQUEST_TYPES
)
