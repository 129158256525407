import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TPaymentType = 'recurring' | 'one-time'

export const paymentTypeLabelMap: Record<TPaymentType, ReactNode> = {
  'one-time': <T _str="One time" />,
  recurring: <T _str="Recurring" />,
}

export const paymentTypeLabelMapPurchaseOrLease: Record<
  TPaymentType,
  ReactNode
> = {
  'one-time': <T _str="Purchase" />,
  recurring: <T _str="Lease" />,
}
