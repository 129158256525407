import React from 'react'
import { useQuery } from '@apollo/client'
import { T, useT } from '@transifex/react'
import { FormikProps, FormikValues } from 'formik'
import { Col, Row, Space } from 'antd'
import { Select, FormItem } from 'formik-antd'
import Button from '@wastehero/storybook/lib/components/Form/Elements/Button'
import Form from '@wastehero/storybook/lib/components/Form/index'
import FormikTextInput from '@wastehero/storybook/lib/components/Form/Elements/TextInput/Formik'
import { PhoneInputFormItem } from '@wastehero/storybook/lib/components/Form/Elements/PhoneInput'
import { SelectCountry } from '@wastehero/storybook/lib/components'
import FormCompanyBillingInformationScheme from './scheme'
import {
  UPDATE_BILLING_INFORMATION,
  GET_BILLING_INFORMATION_QUERY,
} from './api'
import { TFormEventsProps } from '../types'
import { LayoutWithDividerv3 } from '../../../layouts'

type TFormCompanyBillingInformationProps = {
  companyId: string
} & TFormEventsProps

export const FormCompanyBillingInformation = ({
  companyId,
  on: { success: onSuccess } = {},
}: TFormCompanyBillingInformationProps) => {
  const t = useT()

  const translationNameOfTheCard = t('Name of the company')
  const translationAddress = t('Main address')
  const translationAddress2 = t('Optional secondary Address')
  const translationZip = t('The zip code')
  const translationCity = t('The city which the company is located in')
  const translationCountry = t('Name of the country')
  const translationVat = t('The company VAT number')
  const translationInvoice = t('E-mail which invoice e-mails should be sent to')

  /* Fetching initial values */
  const { data: { company } = {}, refetch } = useQuery(
    GET_BILLING_INFORMATION_QUERY,
    {
      variables: {
        id: companyId,
      },
    }
  )

  const billingInformationMutationVariablesFormatter = (values: $TSFixMe) => {
    const variables = {
      name: values.companyName,
      address: values.address,
      address2: values.addressTwo,
      city: values.cityName,
      companyId,
      country: values.country,
      invoiceEmail: values.invoiceEmail,
      vatNumber: values.vatNumber,
      zipCode: values.zipCode,
      invoicePhone: values.phone,
    }

    return variables
  }

  return (
    <Form
      mutation={UPDATE_BILLING_INFORMATION}
      mutationVariablesFormatter={billingInformationMutationVariablesFormatter}
      onSuccess={() => {
        refetch()
        onSuccess?.()
      }}
      formikProps={{
        initialValues: {
          companyName: company?.billinginformation?.name,
          address: company?.billinginformation?.address,
          addressTwo: company?.billinginformation?.address2,
          cityName: company?.billinginformation?.city,
          country: company?.billinginformation?.country,
          invoiceEmail: company?.billinginformation?.invoiceEmail,
          vatNumber: company?.billinginformation?.vatNumber,
          zipCode: company?.billinginformation?.zipCode,
          phone: company?.billinginformation?.invoicePhone,
        },
        validationSchema: FormCompanyBillingInformationScheme,
        enableReinitialize: true,
      }}
    >
      {({
        isSubmitting,
        submitForm,
        isValid,
        dirty,
      }: FormikProps<FormikValues>) => (
        <LayoutWithDividerv3
          style={{
            padding: '1rem',
          }}
          dividerMargin={16}
          content={[
            <>
              <FormikTextInput
                name="companyName"
                placeholder={translationNameOfTheCard}
                antd={{
                  required: true,
                  label: <T _str="Name" />,
                }}
              />

              <FormikTextInput
                name="address"
                placeholder={translationAddress}
                antd={{
                  required: true,
                  label: <T _str="Address" />,
                }}
              />
              <FormikTextInput
                name="addressTwo"
                placeholder={translationAddress2}
                antd={{
                  label: <T _str="Address 2" />,
                }}
              />
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <FormikTextInput
                    name="zipCode"
                    placeholder={translationZip}
                    antd={{
                      required: true,
                      label: <T _str="Zip code" />,
                    }}
                  />
                </Col>
                <Col span={12}>
                  <FormikTextInput
                    name="cityName"
                    placeholder={translationCity}
                    antd={{
                      required: true,
                      label: <T _str="City" />,
                    }}
                  />
                </Col>
              </Row>
            </>,
            <>
              <FormItem name="country" required label={<T _str="Country" />}>
                <Select
                  name="country"
                  options={SelectCountry.options}
                  placeholder={translationCountry}
                  showSearch
                  filterOption
                  optionFilterProp="label"
                />
              </FormItem>

              <FormikTextInput
                name="vatNumber"
                placeholder={translationVat}
                antd={{
                  required: true,
                  label: <T _str="VAT number" />,
                }}
              />
              <FormikTextInput
                name="invoiceEmail"
                placeholder={translationInvoice}
                antd={{
                  required: true,
                  label: <T _str="Invoice e-mail" />,
                }}
              />
              <PhoneInputFormItem
                field={{
                  name: 'phone',
                  label: <T _str="Phone" />,
                  required: true,
                }}
                phoneInput={{ name: 'phone' }}
              />
            </>,
          ]}
          footer={
            <Space style={{ width: '100%', justifyContent: 'end' }} size={16}>
              <Button
                type="primary"
                disabled={isSubmitting || !dirty || !isValid}
                title={<T _str="Submit" />}
                onClick={submitForm}
              />
            </Space>
          }
        />
      )}
    </Form>
  )
}
