import { gql } from '@apollo/client'

export type ResponseEdgeNode = {
  id: string
  location: {
    id: string
    name: string
  }
}

export type Response = {
  allProperties: {
    edges: {
      node: ResponseEdgeNode
    }[]
  }
}

export type Variables = {
  activeProjects?: string
  company?: string
  ids?: string
  search?: string
}

export const GET = gql`
  query GetSelectProperty(
    $company: ID
    $activeProjects: String
    $search: String
    $ids: [ID]
    $first: Int
    $offset: Int
  ) {
    allProperties(
      company: $company
      activeProjects: $activeProjects
      search: $search
      ids: $ids
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          id
          location {
            id
            name
          }
        }
      }
    }
  }
`
