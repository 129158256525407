import { gql } from '@apollo/client'

export const GET_EXPORT = gql`
  query GetExport($id: ID!) {
    export(id: $id) {
      createdAt
      completedAt
      exportType
      status
      file
    }
  }
`

export const CREATE_EXPORT = gql`
  mutation CreateExport(
    $projectId: ID!
    $exportType: String!
    $fields: [String]!
    $startDate: DateTime
    $endDate: DateTime
    $excelFormat: Boolean
  ) {
    createExport(
      projectId: $projectId
      exportType: $exportType
      fields: $fields
      startDate: $startDate
      endDate: $endDate
      excelFormat: $excelFormat
    ) {
      export {
        id
      }
    }
  }
`

export const GET_ALL_EXPORTS = gql`
  query GetAllExports(
    $activeProjects: String!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: String
    $order: String
    $offset: Int
  ) {
    allExports(
      activeProjects: $activeProjects
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      order: $order
      offset: $offset
    ) {
      totalCount
      edges {
        node {
          id
          createdAt
          completedAt
          exportType
          status
          file
        }
      }
    }
  }
`
