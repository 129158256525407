import React from 'react'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { getSavedActiveProjects } from '../../../components/shared/utils'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'

import { SelectVehicleWithDrivers } from './WithDrivers'

const formatResponse = ({ node }: { node: GetCallResponseEdgeNode }): Opt => ({
  label: node?.name,
  value: node?.id,
  key: node.id,
})

export type SelectVehicleProps = {
  variables: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps'] &
  Pick<AsyncSelectWithFetchMoreProps, 'render'>

export type SelectVehicleWithVariants = React.FC<SelectVehicleProps> & {
  WithDrivers: typeof SelectVehicleWithDrivers
}

const SelectVehicle: SelectVehicleWithVariants = ({
  variables,
  render,
  ...props
}) => {
  const activeProjects = useSelector((state) => getSavedActiveProjects(state))
  const queryVariables = {
    activeProjects,
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allVehicles.edges.map((val: $TSFixMe) => formatResponse(val))
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      selectProps={{
        placeholder: <T _str="Vehicle" />,
        allowClear: true,
        ...props,
      }}
      render={render}
      value={props.value}
    />
  )
}

SelectVehicle.WithDrivers = SelectVehicleWithDrivers

export { SelectVehicle }
