import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'
import React from 'react'
import { Opt } from '../options'

export type TSelectUnitVolumeValue = 'FT3' | 'L'

const options: Opt<TSelectUnitVolumeValue>[] = [
  { value: 'L', label: <T _str="Liter (L)" /> },
  {
    value: 'FT3',
    label: (
      <T
        _str="Cubic foot (FT{superscriptThree})"
        superscriptThree={<>&#xb3;</>}
      />
    ),
  },
]

const SelectUnitVolume = (props: SelectProps) => {
  return <Select options={options} {...props} />
}

export default SelectUnitVolume
