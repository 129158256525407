import {
  UserOutlined,
  EnvironmentOutlined,
  DeleteOutlined,
  HomeOutlined,
  ClusterOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { T } from '@transifex/react'
import {
  InfoList,
  InfoListProps,
} from '@wastehero/storybook/lib/layouts/cards/LayoutInfoCardSimple'
import { Tooltip } from 'antd'
import React, { ReactNode } from 'react'
import { Link } from '../../../../../reducers/routing/child-router-factory'
import { array } from '../../../../../utils/array'

type LinkCell = {
  id: string
  name: ReactNode
}

type TContent = {
  assigneTeam?: LinkCell
  assigne?: LinkCell
  container?: LinkCell
  property?: LinkCell
  address?: string
  ticket?: LinkCell
}

type TCRMInfoListProps = {
  content: TContent
  compact?: boolean
  isContainerLevel?: boolean
} & Pick<InfoListProps, 'loading'>

const CRMInfoList = ({
  content,
  loading,
  compact,
  isContainerLevel,
}: TCRMInfoListProps) => {
  return (
    <InfoList
      style={{ margin: 0 }}
      loading={loading}
      list={array<InfoListProps['list'][0]>(
        !!content.assigne?.id && {
          label: <UserOutlined />,
          data: content.assigne?.id && (
            <Link
              to={({ routingMap }) =>
                routingMap.app['users/:userId'](content.assigne?.id || ``)._
              }
            >
              {content.assigne.name}
            </Link>
          ),
        },
        !!content.assigneTeam?.id && {
          label: <TeamOutlined />,
          data: content.assigneTeam?.id && content.assigneTeam.name,
        },
        !!content.container?.name &&
          !compact && {
            label: <DeleteOutlined />,
            data: content.container && (
              <Link
                to={({ routingMap }) =>
                  routingMap.app['customer-management'].assets['/:id'](
                    content.container?.id || ``
                  )._
                }
              >
                {content.container.name}
              </Link>
            ),
          },
        !!content.address &&
          !compact && {
            label: <EnvironmentOutlined />,
            data: content.address,
          },
        !!content.property?.id &&
          !compact && {
            label: (
              <Tooltip
                overlay={
                  isContainerLevel ? (
                    <T _str="Container" />
                  ) : (
                    <T _str="Property" />
                  )
                }
              >
                <HomeOutlined />
              </Tooltip>
            ),
            data: content.property && (
              <Link
                to={({ routingMap }) =>
                  routingMap.app['customer-management'].property['/:id'](
                    content.property?.id || ``
                  )._
                }
              >
                {content.property.name}
              </Link>
            ),
          },
        !!content.ticket?.id &&
          !compact && {
            label: <ClusterOutlined />,
            data: content.ticket && (
              <Link
                to={({ routingMap }) =>
                  routingMap.app.tickets['/:id'](content.ticket?.id || ``)._
                }
              >
                {content.ticket.name}
              </Link>
            ),
          }
      )}
    />
  )
}

export default CRMInfoList
