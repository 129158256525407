import { mockMessageTypeLabelMap } from './consts'

export type TMessageType = `call`

export type MockMessageType = {
  value: TMessageType
}

export const API_MOCK_MESSAGE_TYPE_DATA: MockMessageType[] = (
  Object.keys(mockMessageTypeLabelMap) as TMessageType[]
).map((k) => ({ value: k }))
