import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TMockWasteFractionEnum =
  | `WASTE_TYPE_FOOD`
  | `WASTE_TYPE_PAPER`
  | `WASTE_TYPE_CARDBOARD`
  | `WASTE_TYPE_METAL`
  | `WASTE_TYPE_GLASS`
  | `WASTE_TYPE_PLASTIC`
  | `WASTE_TYPE_TEXTILE`
  | `WASTE_TYPE_BEVERAGE_FOOD_CARTONS`
  | `WASTE_TYPE_BEVERAGE_RESIDUAL`
  | `WASTE_TYPE_BEVERAGE_HAZARDOUS`

export const mockWasteFractioLabelMap: Record<
  TMockWasteFractionEnum,
  ReactNode
> = {
  WASTE_TYPE_BEVERAGE_FOOD_CARTONS: <T _str="Beverage and food cartons" />,
  WASTE_TYPE_BEVERAGE_HAZARDOUS: <T _str="Hazardous" />,
  WASTE_TYPE_BEVERAGE_RESIDUAL: <T _str="Residual" />,
  WASTE_TYPE_CARDBOARD: <T _str="Cardboard" />,
  WASTE_TYPE_FOOD: <T _str="Food" />,
  WASTE_TYPE_GLASS: <T _str="Glass" />,
  WASTE_TYPE_METAL: <T _str="Metal" />,
  WASTE_TYPE_PAPER: <T _str="Paper" />,
  WASTE_TYPE_PLASTIC: <T _str="Plastic" />,
  WASTE_TYPE_TEXTILE: <T _str="Textile" />,
}
