import { UploadFile, RcFile } from 'antd/lib/upload/interface'
import { partition } from 'lodash'
import { antdUploadtoBase64 } from '../../../utils/upload'

export const attachmentsApiToFormValue = (attachments: $TSFixMe) =>
  attachments?.edges.map(
    ({ node }: $TSFixMe): UploadFile => ({
      type: node.mimeType,
      preview: node.file,
      name: node.file,
      uid: node.id,
      thumbUrl: node.file,
      url: node.file,
    })
  ) || []

export const attachmentsConvertFormValues = async (
  formValue: RcFile[],
  init: $TSFixMe
) => {
  const [newUploadsFiles, existingUploadsFiles] = partition(
    formValue,
    (n: $TSFixMe) => !!n.originFileObj
  )
  const existingUploadsIds = existingUploadsFiles.map((n) => n.uid)
  const addAttachments = await antdUploadtoBase64.array(
    newUploadsFiles.map((n: $TSFixMe) => n.originFileObj)
  )
  const removeAttachmentIds = init?.edges
    .map(({ node }: $TSFixMe): UploadFile => node.id)
    .filter((i: string) => !existingUploadsIds.includes(i))

  return [addAttachments, removeAttachmentIds]
}
