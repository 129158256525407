import React, { FC, ReactNode } from 'react'
import { T } from '@transifex/react'
import {
  EditOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  ContainerOutlined,
  SearchOutlined,
  HomeOutlined,
} from '@ant-design/icons'
import {
  InfoList,
  InfoCardSimple,
  InfoListProps,
} from '@wastehero/storybook/lib/layouts/cards/LayoutInfoCardSimple'
import { Button } from 'antd'
import { Typography } from '@wastehero/storybook/lib/components'
import {
  ModalWithButton,
  ModalWithButtonProps,
} from '@wastehero/storybook/lib/components/ModalWithButton'
import { omit } from 'lodash'

export type CRMInfoCardProps = {
  content: {
    address?: string
    description?: string
    companyName?: string
    name?: string
    propertyName?: ReactNode
  }
  loading?: boolean
  onEdit?: () => void
  viewMore?: {
    content: ModalWithButtonProps<unknown>['children']
    btnText: ReactNode
  }
  title: ReactNode
}

export const CRMInfoCard: FC<CRMInfoCardProps> = ({
  content: { address, companyName, description, name, propertyName },
  loading = false,
  title,
  onEdit,
  viewMore,
}) => {
  const labelListRaw: (InfoListProps['list'][0] & { exclude?: boolean })[] = [
    {
      label: <EnvironmentOutlined />,
      data: address,
      exclude: !address,
    },
    {
      label: <SearchOutlined />,
      data: name,
      exclude: !name,
    },
    {
      label: <HomeOutlined />,
      data: propertyName,
      exclude: !propertyName,
    },
    {
      label: <ContainerOutlined />,
      data: companyName,
      exclude: !companyName,
    },
    {
      label: <FileTextOutlined />,
      data: (
        <div>
          <Typography
            component="Paragraph"
            ellipsis={{
              rows: 2,
            }}
            style={{ margin: 0 }}
          >
            {description || <T _str="No description" />}
          </Typography>
          {viewMore && (
            <ModalWithButton
              buttonProps={{
                children: viewMore.btnText,
                type: 'link',
                style: {
                  padding: 0,
                  display: 'inline',
                  marginLeft: 2,
                  height: 'auto',
                },
              }}
              modelProps={{ footer: null }}
            >
              {viewMore.content}
            </ModalWithButton>
          )}
        </div>
      ),
    },
  ]

  const labelList = labelListRaw
    .filter(({ exclude }) => !exclude)
    .map<InfoListProps['list'][0]>((p) => omit(p, 'exclude'))

  return (
    <InfoCardSimple
      title={title}
      extra={
        onEdit && <Button onClick={() => onEdit()} icon={<EditOutlined />} />
      }
      loading={loading}
    >
      <InfoList
        loading={loading}
        list={labelList.map((d) => omit(d, 'exclude'))}
      />
    </InfoCardSimple>
  )
}
