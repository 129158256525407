import React from 'react'
import EventLogs from '../../../../../../../new-components/tables/event-log'
import { useCustomerContext } from '../customer.context'

const EventLog = () => {
  const { id: property } = useCustomerContext()

  return (
    <EventLogs
      tableColumnsTreeFilterAndOrderProps={{
        key: 'EventLog.Property',
      }}
      id={property}
      mode="property"
    />
  )
}

export default EventLog
