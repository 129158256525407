import React from 'react'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { useServiceLogsTable } from './table'
import {
  FiltersProiver,
  FilterByDateRange,
  FilterByDriver,
  FilterByWasteFraction,
  FilterByVehicle,
  FilterByContainer,
  FilterByContainerType,
  FilterByProperty,
  FilterByPropertyGroup,
} from './filters.elements'
import {
  LayoutTableWithHideableFilters,
  TLayoutTableWithHideableFiltersProps,
} from '../../../layouts/LayoutWithHideableFilters'
import { TFilters } from './filters.context'
import { ColumnKey } from './table/ui'

export type ServiceLogsInitialFilters = Omit<TFilters, 'dateRange'>

type ServiceLogsTableIntegrationProps = {
  layoutProps?: Omit<TLayoutTableWithHideableFiltersProps, 'jsx'>
  initialFilters?: ServiceLogsInitialFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
}

const ServiceLogsTableIntegration = ({
  layoutProps,
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
}: ServiceLogsTableIntegrationProps) => {
  const {
    jsx: { table, columnsFilter, search, modal },
  } = useServiceLogsTable({
    initialFilters,
    tableColumnsTreeFilterAndOrderProps,
  })

  return (
    <LayoutTableWithHideableFilters
      layoutOffset={120}
      {...layoutProps}
      jsx={{
        table,
        filters: [
          <FilterByDriver />,
          <FilterByWasteFraction />,
          <FilterByContainer />,
          <FilterByContainerType />,
          <FilterByVehicle />,
          <FilterByProperty />,
          <FilterByPropertyGroup />,
        ],
        columnsFilter,
        search,
        dateFilter: <FilterByDateRange />,
      }}
    >
      {modal}
    </LayoutTableWithHideableFilters>
  )
}

const ServiceLogsTable = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  layoutProps,
}: ServiceLogsTableIntegrationProps) => {
  return (
    <FiltersProiver>
      <ServiceLogsTableIntegration
        layoutProps={layoutProps}
        tableColumnsTreeFilterAndOrderProps={
          tableColumnsTreeFilterAndOrderProps
        }
        initialFilters={initialFilters}
      />
    </FiltersProiver>
  )
}

export default ServiceLogsTable
