import React from 'react'
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from '@stripe/react-stripe-js'
import { message } from 'antd'
import { loadStripe } from '@stripe/stripe-js/pure'
import { MutationHookOptions, useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import { T } from '@transifex/react'

import { MUTATION_ADD_CARD } from './api'
import FormUI, { TFormSchema } from './Form.ui'
import { FormUtils } from '../..'
import { TFormEventsProps } from '../types'
import { stripeErrorCodeLabelMap, TStripeErrorCode } from '../../../consts'

export type TFormCompanyAddPaymentCard = {
  billingInformationId: string
  mutationOptions?: (pre: MutationHookOptions) => MutationHookOptions
} & TFormEventsProps

const FormCompanyAddPaymentCardForm = ({
  billingInformationId,
  on: { success: onSuccess } = {},
  mutationOptions,
}: TFormCompanyAddPaymentCard) => {
  const stripe = useStripe()
  const elements = useElements()

  const [addCardMutation] = useMutation(MUTATION_ADD_CARD)

  const onSubmit = async (
    values: TFormSchema,
    { resetForm }: FormikHelpers<TFormSchema>
  ): Promise<$TSFixMe> => {
    const { country, name } = values

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return false
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    const stripeData = {
      name,
      address_country: country,
    }

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const { error, token } = await stripe.createToken(cardElement, stripeData)

    // eslint-disable-next-line no-empty
    if (!error) {
      await addCardMutation(
        mutationOptions?.({
          variables: {
            source: token?.id,
            billingInformationId,
            default: false,
          },
        }) || {
          variables: {
            source: token?.id,
            billingInformationId,
            default: false,
          },
        }
      )
      resetForm()
      onSuccess?.()
    } else {
      message.error(
        stripeErrorCodeLabelMap[error.code as TStripeErrorCode] || (
          <T _str="Error occured." />
        )
      )
    }

    return true
  }

  return (
    <FormUI
      formik={{
        initialValues: { country: '', name: '' },
        onSubmit: FormUtils.formikErrorHandler({ fn: onSubmit }),
      }}
    />
  )
}

export const FormCompanyAddPaymentCard = (
  props: TFormCompanyAddPaymentCard
) => {
  if (!process.env.REACT_APP_STRIPE_CLIENT_KEY) return <></>
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY)
  return (
    <Elements stripe={stripePromise}>
      <FormCompanyAddPaymentCardForm {...props} />
    </Elements>
  )
}
