import React from 'react'
import ContainerTable from '../../../../../../../../new-components/tables/containers'
import { useCustomerContext } from '../../customer.context'

const Containers = () => {
  const { id: propertyId } = useCustomerContext()

  return (
    <ContainerTable
      // @ts-expect-error WIP
      initialFilters={{ property: [propertyId] }}
      tableColumnsTreeFilterAndOrderProps={{
        init: {
          checked: [
            'locationName',
            'containerId',
            'wasteFraction',
            'containerType',
            'pickupSetting',
            'latestPickUp',
            'status',
          ],
          order: [
            'projectName',
            'locationName',
            'battery',
            'rsi',
            'fillLevel',
            'fillLevelRaw',
            'sensorId',
            'description',
            'containerId',
            'containerType',
            'pickupSetting',
            'pickupMethod',
            'lastSeenDate',
            'latestPickUp',
            'nextPickUp',
            'status',
            'wasteFraction',
            'measurementSetting',
            'price',
          ],
        },
        key: 'containers.property',
      }}
    />
  )
}

export default Containers
