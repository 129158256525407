import { LinkProps } from '../../reducers/routing/child-router-factory'

export type TConfig = {
  /** default to true.
   * With include label: Label: {name}
   * without label: {name}
   */
  includeLabel?: boolean
}

export type TGetLinkPropsFnArgs = [val: $TSFixMe, config?: TConfig]

export type TGetLinkPropsFn<Value = $TSFixMe> = (
  val: Value,
  config?: TConfig
) => LinkProps

export const defaultConfig: TConfig = {
  includeLabel: true,
}
