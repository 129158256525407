import React from 'react'
import { Skeleton } from 'antd'
import { IExpandSkeletonProps } from './types'
import ExpandContentSkeleton from './styles'

const ExpandSkeleton = ({
  loading,
  active = true,
  avatar = { shape: 'square' },
  paragraph = false,
  title = false,
  children,
  ...restProps
}: IExpandSkeletonProps): JSX.Element => {
  return (
    <ExpandContentSkeleton>
      <Skeleton
        loading={loading}
        active={active}
        avatar={avatar}
        paragraph={paragraph}
        title={title}
        {...restProps}
      >
        {children}
      </Skeleton>
    </ExpandContentSkeleton>
  )
}

export default ExpandSkeleton
