import { pathJoin } from '../../../utils'
import { createRoutesRouteMap } from './routes'
import { createRouteSchemeRouteMap } from './route-schemes'

export const createOperationManagementRouteMap = (prefix: string) => ({
  routes: pathJoin(prefix)(`routes`).chainMapAuto(createRoutesRouteMap),
  'routes/create': pathJoin(prefix)('routes')('create').chain,
  inquiries: pathJoin(prefix)('inquiries').chain,
  'route-schemes': pathJoin(prefix)('route-schemes').chainMapAuto(
    createRouteSchemeRouteMap
  ),
  'pickup-history': pathJoin(prefix)('pickup-history').chain,
  'container-types': pathJoin(prefix)('container-types').chain,
})
