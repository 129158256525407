import React from 'react'
import { Select, SelectProps } from 'antd'
import { T } from '@transifex/react'

import { Opt } from './types'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectLanguageProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps<Opt>, 'options' | 'loading'>

const options = [
  {
    value: 'DA',
    label: <T _str="Danish" />,
  },
  {
    value: 'EN',
    label: <T _str="English" />,
  },
]

export const SelectLanguage = ({
  render,
  ...selectProps
}: SelectLanguageProps) => {
  return (
    render?.({
      options,
      loading: false,
    }) || (
      <Select
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}
