import moment, { Moment } from 'moment'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import {
  Timeline,
  TimelineItemProps,
  Skeleton,
  Row,
  Col,
  Button,
  Collapse,
  CollapseProps,
  Empty,
} from 'antd'
import { T } from '@transifex/react'
import { CaretUpOutlined, CalendarOutlined } from '@ant-design/icons'
import {
  Link,
  Routing,
} from '../../../../../reducers/routing/child-router-factory'

import { SubHeader16 } from '../../../../../ui'

export const StyledRow = styled(Row)`
  padding: 1rem;

  /* Collapse styles overwrite - removing paddings. */

  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-top: -8px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px;
    padding-right: 8px;
    padding-bottom: 16px;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 0;
    top: 25%;
  }

  /* timeline styles overwrite */

  .ant-timeline-item {
    padding-bottom: 0px;
  }

  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: auto;
  }

  .ant-timeline-item-tail,
  .ant-timeline-item-head-custom {
    left: 8px;
  }
`

export const ExpandIcon: CollapseProps['expandIcon'] = ({ isActive }) => (
  <CaretUpOutlined rotate={isActive ? 180 : 0} style={{ color: 'gray' }} />
)

const { Item: TimelineItem } = Timeline

export type TCRMUpcomingLogDatum = {
  title: ReactNode
  time: Moment
  id: string
  timelineItemProps?: TimelineItemProps
  expandContent?: ReactNode
}

export type TCRMUpcomingLogProps = {
  dataSource: TCRMUpcomingLogDatum[]
  /** default to false. */
  loading?: boolean
  getCalendarLink: (routing: Routing) => string
}

export const CRMUpcomingLog = ({
  dataSource,
  loading = false,
  getCalendarLink,
}: TCRMUpcomingLogProps) => {
  return (
    <StyledRow gutter={[0, 16]}>
      <Col xs={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <SubHeader16>
              <T _str="Upcoming" />
            </SubHeader16>
          </Col>
          <Col>
            <Link to={getCalendarLink}>
              <Button icon={<CalendarOutlined />} />
            </Link>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Skeleton active loading={loading}>
          <Timeline style={{ marginTop: `8px` }}>
            {dataSource.length ? (
              dataSource.map(
                ({
                  time,
                  title,
                  id,
                  timelineItemProps = {},
                  expandContent,
                }) => (
                  <TimelineItem {...timelineItemProps}>
                    <Collapse
                      ghost
                      expandIconPosition="right"
                      expandIcon={ExpandIcon}
                    >
                      <Collapse.Panel
                        header={
                          <Row
                            justify="space-between"
                            wrap={false}
                            gutter={16}
                            style={{ width: '100%' }}
                          >
                            <Col>{title}</Col>
                            <Col>{moment(time).format('DD-MM-YYYY')}</Col>
                          </Row>
                        }
                        key={id}
                      >
                        {expandContent}
                      </Collapse.Panel>
                    </Collapse>
                  </TimelineItem>
                )
              )
            ) : (
              <Empty />
            )}
          </Timeline>
        </Skeleton>
      </Col>
      <Col xs={24} style={{ marginTop: !dataSource.length ? '0px' : '-16px' }}>
        <Link to={getCalendarLink}>
          <Button type="link" style={{ paddingLeft: 0 }}>
            <T _str="View all in calendar" />
          </Button>
        </Link>
      </Col>
    </StyledRow>
  )
}
