import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TPaymentStatusType = 'draft' | 'unpaid' | 'paid'

export const paymentStatusTypeLabelMap: Record<TPaymentStatusType, ReactNode> =
  {
    draft: <T _str="Draft" />,
    unpaid: <T _str="Unpaid" />,
    paid: <T _str="Paid" />,
  }

export const paymentStatusTypeTagColorMap: Record<TPaymentStatusType, string> =
  {
    paid: 'green',
    unpaid: 'yellow',
    draft: '',
  }
