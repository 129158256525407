import React from 'react'
import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'

import { Opt } from './types'
import { jobTitleLabelMap } from '../../api/consts'
import { getOptsFromValueMap } from './utils'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectJobTitleProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps<Opt>, 'options' | 'loading'>

const options = getOptsFromValueMap(jobTitleLabelMap)

export const SelectJobTitle = ({
  render,
  ...selectProps
}: SelectJobTitleProps) => {
  return (
    render?.({
      options,
      loading: false,
    }) || (
      <Select
        placeholder={<T _str="Job Title" />}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}
