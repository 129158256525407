import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'

import { Opt } from './types'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectSensorProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps<Opt>, 'options' | 'loading'>

export type ContainerSensorFilter = 'with' | 'without' | 'all'

const options: { label: ReactNode; value: ContainerSensorFilter }[] = [
  {
    label: <T _str="Without sensor" />,
    value: 'without',
  },
  {
    label: <T _str="With sensor" />,
    value: 'with',
  },
  {
    label: <T _str="Show All" />,
    value: 'all',
  },
]

export const SelectSensor = ({ render, ...selectProps }: SelectSensorProps) => {
  return (
    render?.({
      options,
      loading: false,
    }) || (
      <Select
        placeholder={<T _str="Status" />}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}
