import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { ApolloError } from '@apollo/client'
import {
  Modal,
  Row,
  Col,
  Typography,
  message,
  FormProps,
  FormInstance,
} from 'antd'
import { NamePath } from 'antd/lib/form/interface'

const { Text } = Typography

type OnSubmit<T> = FormProps<T>['onFinish']
type RecordAny = Record<string, $TSFixMe>

export type FormErrorHandlerArgs<T> = {
  /** fn:
   * The onSubmit function required in the formConfig.
   *  Note: If you catch the error in this function to handle some logic
   *  then remember to throw it again so that this handler can catch it again.
   */
  fn: OnSubmit<T>
  /** onError
   * This will be called once this handlers process the error and related UI.
   */
  onError?: (e: unknown) => void
  /** fieldMapping.
   * If your form fields are different that the fields that are returned in errors,
   * then you can map them there.
   *
   * Example:
   * In form you name a field A and in api this field error will have a
   * name of B
   * then you will pass
   * ```
   * {
   *   A: B
   * }
   * ```
   * So that this handler can map it accorindly.
   */
  fieldMappingFn?: (k: string) => NamePath
  handlers: FormInstance<T>
}

export const antdFormErrorHandler =
  <T extends RecordAny = RecordAny>({
    fn,
    onError,
    fieldMappingFn,
    handlers: { setFields },
  }: FormErrorHandlerArgs<T>): OnSubmit<T> =>
  async (values) => {
    try {
      await fn?.(values)
    } catch (e: unknown) {
      const errors = e as ApolloError
      let otherErrors: ReactNode[] = []
      /* 
      Todo:
      1. Populate form with errors that are of field errors.
      2. Show other errors as antd.messages -- not auto closable.
      */
      errors?.graphQLErrors?.forEach((graphQLError: $TSFixMe) => {
        if (graphQLError?.context?.field) {
          setFields([
            {
              name: (fieldMappingFn?.(graphQLError.context.field) ||
                graphQLError.context.field) as string,
              errors: [graphQLError.message],
            },
          ])
        } else {
          // show error as antd message.
          otherErrors.push(graphQLError.message)
        }
      })
      if (errors.networkError) {
        // @ts-expect-error Todo; Add result on the networkError on ApolloError type.
        const formValidationError = errors.networkError.result?.errors
        if (formValidationError?.length) {
          // Todo: Add better error handling here. Possibly parsing back-end error for more cleaner Ux.
          otherErrors = [
            ...otherErrors,
            formValidationError
              .filter((a: $TSFixMe) => !!a?.message)
              .map((a: $TSFixMe) => a.message),
          ]
        } else {
          otherErrors.push(
            <T
              _str="Network problem: {error}"
              error={errors.networkError.message}
            />
          )
        }
      }
      if (errors.extraInfo) {
        otherErrors.push(errors.extraInfo)
      }
      if (otherErrors.length) {
        Modal.error({
          title: <T _str="Error occured." />,
          content: (
            <Row>
              {otherErrors.map((error) => (
                <Col span={24}>
                  <Text type="secondary">{error}</Text>
                </Col>
              ))}
            </Row>
          ),
        })
      } else {
        // otherwise show the message with error.
        message.error(errors?.message || <T _str="Error occured." />)
      }

      /*
      3. call the onError if provided.
      */
      onError?.(e)
    }
  }
