import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TPaymentStatusType } from './data'

export const mockPaymentStatusTypeLabelMap: Record<
  TPaymentStatusType,
  ReactNode
> = {
  draft: <T _str="Draft" />,
  unpaid: <T _str="Unpaid" />,
  paid: <T _str="Paid" />,
}

export const mockPaymentStatusTypeTagColorMap: Record<
  TPaymentStatusType,
  string
> = {
  paid: 'green',
  unpaid: 'yellow',
  draft: '',
}
