import { TableProps } from 'antd'
import { DEFAULT_PAGE_SIZE } from './pagination.config'
import { useTableState, Init } from './useTableState'

const sortDirectionsMap: Record<string, string> = {
  ascend: 'asc',
  descend: 'desc',
}

export const useTablePaginationAndSorterAPIHelper = <RecordType>(
  init?: Init
) => {
  const [
    { order, orderBy, page, pageSize },
    { setOrder, setOrderBy, setPage, setPageSize },
  ] = useTableState(init)

  const onTableChangeHandler: TableProps<RecordType>['onChange'] = (
    pagination,
    _,
    sorter
  ) => {
    if (!Array.isArray(sorter)) {
      setOrderBy(`${sorter.field || ''}`)
      setOrder(sorter.order || ``)
      if (!sorter.order) {
        // the reset case.
        setOrderBy('')
      }
    }
    setPage(pagination.current || 1)
    setPageSize(pagination.pageSize || DEFAULT_PAGE_SIZE)
  }

  return {
    paginationVars: {
      first: pageSize,
      offset: pageSize * (page - 1),
      order: sortDirectionsMap[order] || undefined,
      orderBy,
    },
    onTableChangeHandler,
    state: {
      order,
      orderBy,
      page,
      pageSize,
    },
  }
}
