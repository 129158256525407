import {
  AvatarWithName,
  TAvatarWithNameProps,
} from '@wastehero/storybook/lib/components'
import React from 'react'

export type TCellAvatarUserSet1Props = {
  value: {
    firstName: string
    lastName: string
    logo?: string
  }
  loading?: boolean
} & TAvatarWithNameProps

export const CellAvatarUserSet1 = ({
  value: { firstName = '', lastName = '', logo },
  ...rest
}: TCellAvatarUserSet1Props) => {
  return (
    <AvatarWithName
      {...rest}
      avatarProps={{
        ...rest?.avatarProps,
        src: logo,
        children: !logo ? firstName.slice(0, 1) : undefined,
      }}
    >
      {firstName} {lastName}
    </AvatarWithName>
  )
}
