import React from 'react'
import { Checkbox, Col, Form, Row } from 'antd'
import { T } from '@transifex/react'
import { SummaryFormWrapper } from './wrappers'
import { SelectUser } from '../../../../select'

export const SendSummaryToForm = () => {
  return (
    <SummaryFormWrapper>
      <Form.Item
        name={['sendSummaryTo', 'users']}
        label={<T _str="Send summary to" />}
        style={{ marginBottom: '0.5rem' }}
      >
        <SelectUser
          style={{ width: '100%' }}
          mode="multiple"
          maxTagCount={'responsive' as const}
        />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col>
          <Form.Item
            style={{ marginBottom: '0rem' }}
            name={['sendSummaryTo', 'sms']}
            valuePropName="checked"
          >
            <Checkbox style={{ marginRight: '0.25rem' }} />
            <T _str="SMS" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            style={{ marginBottom: '0rem' }}
            name={['sendSummaryTo', 'email']}
            valuePropName="checked"
          >
            <Checkbox style={{ marginRight: '0.25rem' }} />
            <T _str="E-mail" />
          </Form.Item>
        </Col>
      </Row>
    </SummaryFormWrapper>
  )
}
