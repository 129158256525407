import React from 'react'
import ServiceLogsTable from '../../../../../../../new-components/tables/service-logs'
import { useCustomerContext } from '../customer.context'

const Collections = () => {
  const { id: property } = useCustomerContext()

  return (
    <ServiceLogsTable
      tableColumnsTreeFilterAndOrderProps={{
        key: 'ServiceLogsTable.Property',
      }}
      initialFilters={{ property: [property] }}
    />
  )
}

export default Collections
