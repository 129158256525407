import styled from 'styled-components'

export const CardContainer = styled.div`
  width: 100%;
  height: 323px;
  background: transparent;

  .ant-card {
    background: transparent !important;
  }

  .ant-card-head {
    height: 35px !important;
    min-height: 0px !important;
  }

  .ant-tabs-nav-wrap {
    height: 35px !important;
  }

  .ant-card-body {
    margin-top: 1px !important;
    padding: 0px !important;
    height: 285px !important;
  }

  .user-select-none.svg-container {
    width: 98% !important;
  }

  .ant-card-head-title {
    padding: 0 !important;
  }

  .surface3d-skeleton {
    width: 100%;
    padding: 1rem;
  }
`
