import { Opt } from '../../types'

type Node = {
  name: string
  id: string
}

export type LocationNode = {
  id: string
  location: {
    id: string
    name: string
  }
}

type EdgeNode<T = Node> = {
  edges: {
    node: T
  }[]
}

export type DataType<TKey extends string, TNode = Node> = Record<
  TKey,
  EdgeNode<TNode>
>

type QueryResponse<T = Node> = {
  [key: string]: EdgeNode<T>
}

export const formatSelectOptionsFromEdgesNodeNameId = <
  TResponse extends QueryResponse
>(
  data: TResponse,
  key: string
): Opt[] => {
  return data[key].edges.map(({ node }) => ({
    label: node.name,
    value: node.id,
    key: node.id,
  }))
}

export const formatSelectOptionsFromEdgesNodeLocationId = <
  TResponse extends QueryResponse<LocationNode>
>(
  data: TResponse,
  key: string
): Opt[] => {
  return data[key].edges.map(({ node }) => ({
    label: node.location.name || node.id,
    value: node.id,
    key: node.id,
  }))
}
