import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import {
  TabsStyledWrapper,
  CardWithHeader,
} from '@wastehero/storybook/lib/layouts'
import { useParams } from 'react-router-dom'
import { useApolloClient, useQuery } from '@apollo/client'
import { message } from 'antd'

import {
  GET_PROPERTY,
  GetPropertyResponse,
  GetPropertyVariables,
  MUTATION_LOG_VIEW_PROPERTY_EVENT,
} from './api'
import { Keys, createConfig } from './route-config'
import { TabsNavigationExtended } from '../../../../../../ui/TabNavigationExtended'
import { useRoutingHistory } from '../../../../../../reducers/routing/child-router-factory'
import { CustomerContext } from './customer.context'

export { createRouteMap } from './route-config'

const tabTitles: Record<Keys, ReactNode> = {
  containers: <T _str="Containers" />,
  'collection-log': <T _str="Collection log" />,
  'event-log': <T _str="Event log" />,
  tickets: <T _str="Tickets" />,
  invoices: <T _str="Invoices" />,
  overview: <T _str="Overview" />,
  calendar: <></>,
}

export default function CustomerDetailTabNavigation() {
  /* Routing */
  const { id: customerId } = useParams<{ id: string }>()
  const [history, { routingMap }] = useRoutingHistory()
  const apollo = useApolloClient()

  const { data, loading } = useQuery<GetPropertyResponse, GetPropertyVariables>(
    GET_PROPERTY,
    {
      variables: {
        id: customerId,
      },
      onCompleted: () => {
        apollo
          .mutate({
            mutation: MUTATION_LOG_VIEW_PROPERTY_EVENT,
            variables: {
              propertyId: customerId,
            },
          })
          .catch((e) =>
            message.error(
              e?.message || <T _str="Log property viewed failed." />
            )
          )
      },
    }
  )

  if (!data?.property && !loading) {
    // this means that we don't have the customer.
    message.warning(<T _str="Customer doesn't exist." />)
    history.push(routingMap.app['customer-management'].property._)

    return <></>
  }

  return (
    <CustomerContext.Provider
      value={{
        id: customerId,
        location: {
          name: data?.property.location?.name || '',
          latitude: data?.property.location?.latitude,
          longitude: data?.property.location?.longitude,
        },
      }}
    >
      <CardWithHeader title={data?.property.location.name} loading={loading}>
        <TabsStyledWrapper>
          <TabsNavigationExtended<Keys, { id: string }>
            defaultLastActiveKey="overview"
            getParentPath={(map) =>
              map.app['customer-management'].property['/:id'](':customerId')._
            }
            getParentPathWithParams={(map) =>
              map.app['customer-management'].property['/:id'](customerId)._
            }
            getRouteConfig={(map) => createConfig(map)}
            tabTitles={tabTitles}
            antd={{
              style: {
                background: `white`,
              },
              /* We need to do it due to performance issue. */
              destroyInactiveTabPane: true,
            }}
          />
        </TabsStyledWrapper>
      </CardWithHeader>
    </CustomerContext.Provider>
  )
}
