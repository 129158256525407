import React from 'react'
import { useQuery } from '@apollo/client'
import { Col, Divider, Row } from 'antd'
import moment from 'moment'
import { T } from '@transifex/react'
import StyledTag from '@wastehero/storybook/lib/components/StyledTag'
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { Typography } from '@wastehero/storybook/lib/components'
import { GET_MAP_PICKUP_ORDERS } from '../api'
import { getStyledTagProps } from '../../../../../api/helpers'
import { getTypeAndData } from '../Stop'

type SameStopGroupDetailProps = {
  hideHeader?: boolean
  orderIds: string[]
  nextStopId?: string
  onSelect: (pickupOrderId: string) => void
}

const SameStopGroupDetail = ({
  orderIds,
  nextStopId,
  onSelect,
  hideHeader,
}: SameStopGroupDetailProps) => {
  const { data, loading } = useQuery(GET_MAP_PICKUP_ORDERS, {
    variables: { orderIds },
  })

  const firstPickupOrder = data?.allPickupOrders?.edges.find(
    ({ node }: $TSFixMe) => node.orderId === orderIds[0]
  )?.node

  const {
    data: dataFirstPickupOrder,
    title,
    href,
  } = getTypeAndData(firstPickupOrder)

  const pickupOrdersSorted = data
    ? [...data?.allPickupOrders?.edges].sort(
        (a: $TSFixMe, b: $TSFixMe) => a.node.stopNumber - b.node.stopNumber
      )
    : []

  return (
    <Row gutter={[0, 8]}>
      {!hideHeader && (
        <>
          <Col xs={24}>
            <Row gutter={0}>
              {firstPickupOrder && (
                <Col>
                  <StyledTag
                    style={{
                      transform: `translate(-3px, 3px)`,
                      boxShadow: '3px -3px 0px 0px #bfbfbf',
                      width: 28,
                      height: 16,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 4,
                    }}
                    {...getStyledTagProps(
                      firstPickupOrder,
                      nextStopId === firstPickupOrder?.id
                    )}
                  >
                    {firstPickupOrder?.stopNumber}
                  </StyledTag>
                </Col>
              )}
              <Col span={21}>
                <Typography
                  ellipsis
                  loading={loading}
                  component="Link"
                  href={href}
                  weight="semi-bold"
                  variant="body-14"
                >
                  {title}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row gutter={8} align="middle">
              <Col>
                <EnvironmentOutlined
                  style={{ fontSize: 14, color: '#BFBFBF' }}
                />
              </Col>
              <Col>
                <Typography loading={loading} variant="body-14">
                  {dataFirstPickupOrder?.location?.name}
                </Typography>
              </Col>
            </Row>
          </Col>
          {firstPickupOrder?.scheduledDriverArrival && (
            <Col xs={24}>
              <Row gutter={8} align="middle">
                <Col>
                  <ClockCircleOutlined
                    style={{ fontSize: 14, color: '#BFBFBF' }}
                  />
                </Col>
                <Col>
                  <Typography loading={loading} variant="body-14">
                    <T
                      _str="Scheduled at {scheduledDriverArrival}"
                      scheduledDriverArrival={moment(
                        dataFirstPickupOrder?.scheduledDriverArrival,
                        'hh:mm:ss'
                      ).format('LLL')}
                    />
                  </Typography>
                </Col>
              </Row>
            </Col>
          )}
          <Col xs={24}>
            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          </Col>
        </>
      )}
      <Col xs={24}>
        <Typography
          style={{ color: '#8C8C8C' }}
          loading={loading}
          variant="body-14"
        >
          <T
            _str="All locations {locationCount}"
            locationCount={`(${orderIds.length})`}
          />
        </Typography>
      </Col>
      <Col xs={24}>
        <Row gutter={[0, 8]}>
          {pickupOrdersSorted.map(({ node }: $TSFixMe, indx) => (
            <>
              <Col
                onClick={() => onSelect(node.id)}
                style={{ cursor: 'pointer' }}
                xs={24}
              >
                <Row align="middle">
                  <Col>
                    <StyledTag
                      style={{
                        width: 28,
                        height: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      {...getStyledTagProps(node, node.id === nextStopId)}
                    >
                      {node.stopNumber}
                    </StyledTag>
                  </Col>
                  <Col>
                    <Typography style={{ color: '#1890FF' }} variant="body-14">
                      {getTypeAndData(node).title}
                    </Typography>
                  </Col>
                  <Col style={{ marginLeft: 'auto' }}>
                    <RightOutlined />
                  </Col>
                </Row>
              </Col>
              {indx !== pickupOrdersSorted?.length - 1 && (
                <Col xs={24}>
                  <Divider style={{ margin: 0 }} />
                </Col>
              )}
            </>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default SameStopGroupDetail
