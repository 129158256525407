import { pathJoin } from '../../../utils'
import { createDetailRoutesMap } from './ticket-details'
import { createEditBulkRoutesMap } from './edit-bulk'
import { createCreateBulkRoutesMap } from './create-bulk'

export const createTicketsRouteMap = (prefix: string) => ({
  '/:id': (id: string) =>
    pathJoin(prefix)(id).chainMapAuto(createDetailRoutesMap),
  kanban: pathJoin(prefix)('kanban').chain,
  'create-bulk': pathJoin(prefix)('create-bulk').chainMapAuto(
    createCreateBulkRoutesMap
  ),
  'edit-bulk': pathJoin(prefix)('edit-bulk').chainMapAuto(
    createEditBulkRoutesMap
  ),
})
