import React, { useState, useEffect, useMemo, FC } from 'react'
import { Row, Col, Empty } from 'antd'
import { T } from '@transifex/react'
import { useQuery } from '@apollo/client'
import { invert } from 'lodash'
import { Typography } from '@wastehero/storybook/lib/components'
import createStore from 'zustand'

import { TRequestType } from './types'

import ContainerHeader from './Header/Conatiner'
import PropertyHeader from './Header/Property'

import TicketTypes from './TicketTypes'
import { CssScrollerDiv } from '../../../../../../ui'

import { GET_TICKET_TYPES } from './api'
import { useSavedActiveProjects } from '../../../../../../hooks/useSavedActiveProjects'
import { StyledSkeleton } from '../../../../../../ui/Skeleton'
import { TicketTypeLevelType } from '../../../../../../api/consts/TicketTypeLevelType'
import { TCrmServiceServiceTypeChoices } from '../../../../../../api/consts'
import {
  FormTicketSingle,
  TFormTicketSingleProps as TFormTicketSinglePropsBase,
} from '../../../../../../new-components/forms/FormTicketSingle'

type TFormTicketSingleProps = Omit<TFormTicketSinglePropsBase, 'formId'> & {
  type: TCrmServiceServiceTypeChoices
}

const typeHeaderMap: Record<
  TicketTypeLevelType,
  ({ id }: { id: string }) => JSX.Element
> = {
  container: ContainerHeader,
  property: PropertyHeader,
}

type TState = {
  isSubmitting?: boolean
}

const useStore = createStore<TState>(() => ({
  isSubmitting: false,
}))

const WhileIsSubmitting: FC = ({ children }) => {
  const { isSubmitting } = useStore()

  return (
    <div style={{ opacity: isSubmitting ? 0.3 : 1 }}>
      {children}
      {isSubmitting && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            userSelect: 'none',
          }}
        />
      )}
    </div>
  )
}

export type { TFormTicketSingleProps }

export type TCreateRequestFormProps = {
  type: TicketTypeLevelType
  form?: TFormTicketSingleProps
  id: string
}

const CreateRequestForm = ({
  form,
  id,
  type,
}: // eslint-disable-next-line @typescript-eslint/no-unused-vars
TCreateRequestFormProps) => {
  const [formType, setFormType] = useState<TCrmServiceServiceTypeChoices>()
  const activeProjects = useSavedActiveProjects()

  const isPropertyLevel = type === 'property' ? true : undefined
  const isContainerLevel = type === 'container' ? true : undefined

  const {
    data: dataTicketTypesWithServices,
    loading: loadingDataTicketTypesWithServices,
  } = useQuery(GET_TICKET_TYPES, {
    variables: {
      activeProjects,
      isPropertyLevel,
      isContainerLevel,
    },
  })

  const ticketTypesServiceMap = useMemo(() => {
    return (
      dataTicketTypesWithServices?.allTicketTypes?.edges?.reduce(
        (acc: $TSFixMe, { node: { id: ticketTypeId, service } }: $TSFixMe) => ({
          ...acc,
          [ticketTypeId]: service?.serviceType,
        }),
        {}
      ) || {}
    )
  }, [dataTicketTypesWithServices])

  const serviceTypeTicketTypesMap = useMemo(
    () => invert(ticketTypesServiceMap),
    [ticketTypesServiceMap]
  )

  const [selectedTicketType, setSelectedTicketType] = useState(``)
  const [fromParentInitialProps, setFromParentInitialProps] = useState(false)

  useEffect(() => {
    if (form && form?.type) {
      setFromParentInitialProps(true)
      setFormType(form?.type)
      // find the ticket type and set that too.
      setSelectedTicketType(serviceTypeTicketTypesMap[form?.type])
    }
  }, [form, form?.type, serviceTypeTicketTypesMap])

  const loadingForm = loadingDataTicketTypesWithServices

  const Header = typeHeaderMap[type]

  return (
    <Row>
      <Col xs={24}>
        <div style={{ padding: '1rem', borderBottom: '1px solid #DEE4EB' }}>
          <Header id={id} />
        </div>
      </Col>
      <Col xs={24}>
        <WhileIsSubmitting>
          <Row>
            <Col xs={4} style={{ background: '#F5F5F5' }}>
              <div className="wh-vertical-scroller-container">
                <Typography type="secondary" style={{ padding: '8px 16px' }}>
                  <T _str="Select ticket type" />
                </Typography>
                <div className="wh-vertical-scroller-container-scroll">
                  <CssScrollerDiv defaultYScroller offset={16}>
                    <TicketTypes
                      isContainerLevel={isContainerLevel || false}
                      isPropertyLevel={isPropertyLevel || null}
                      selected={selectedTicketType}
                      onSelect={(val) => {
                        setSelectedTicketType(val as TRequestType)
                        setFormType(
                          ticketTypesServiceMap[val] ||
                            'FormTicketSimpleTicketType'
                        )
                        setFromParentInitialProps(false)
                      }}
                    />
                  </CssScrollerDiv>
                </div>
              </div>
            </Col>
            <Col xs={20}>
              {loadingForm && (
                <StyledSkeleton
                  size="small"
                  active
                  style={{ padding: '1rem' }}
                />
              )}
              {!loadingForm && (
                <>
                  {formType ? (
                    <div style={{ padding: '1rem' }}>
                      <FormTicketSingle
                        {...form}
                        formId="CreateTicketSingleForm1"
                        ticketTypeId={selectedTicketType}
                        initialValues={
                          fromParentInitialProps ? form?.initialValues : {}
                        }
                        onIsSubmittingChange={(isSubmitting) => {
                          useStore.setState({ isSubmitting })
                        }}
                      />
                    </div>
                  ) : (
                    <Empty description={<T _str="Select a ticket type." />} />
                  )}
                </>
              )}
            </Col>
          </Row>
        </WhileIsSubmitting>
      </Col>
    </Row>
  )
}

export default CreateRequestForm
