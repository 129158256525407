import React from 'react'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import useTable from './table'
import {
  FiltersProiver,
  FilterByProperty,
  FilterByTicketType,
  FilterByStatus,
  FilterByPropertyGroup,
  FilterByContainers,
  FilterByRoutes,
} from './filters.elements'
import { ColumnKey } from './table/ui'
import { TFilters } from './filters.context'
import { LayoutTableWithHideableFilters } from '../../../layouts'

export type TicketInitialFilters = TFilters

type ServiceLogsTableIntegrationProps = {
  initialFilters?: TicketInitialFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
}

const TicketTableIntegration = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
}: ServiceLogsTableIntegrationProps) => {
  const { table, columnsFilterAndOrder, search, bulkActions } = useTable({
    initialFilters,
    tableColumnsTreeFilterAndOrderProps,
  })

  return (
    <LayoutTableWithHideableFilters
      layoutOffset={220}
      jsx={{
        table,
        filters: [
          <FilterByStatus />,
          <FilterByTicketType />,
          <FilterByProperty />,
          <FilterByPropertyGroup />,
          <FilterByContainers />,
          <FilterByRoutes />,
        ],
        addBtn: bulkActions,
        columnsFilter: columnsFilterAndOrder,
        search,
      }}
    />
  )
}

const TicketsTable = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
}: ServiceLogsTableIntegrationProps) => {
  return (
    <FiltersProiver>
      <TicketTableIntegration
        tableColumnsTreeFilterAndOrderProps={
          tableColumnsTreeFilterAndOrderProps
        }
        initialFilters={initialFilters}
      />
    </FiltersProiver>
  )
}

export default TicketsTable
