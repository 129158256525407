/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, useRef, useState, ReactNode } from 'react'
import produce from 'immer'
import { Map as TMapbox } from 'mapbox-gl'
import { pick } from 'lodash'
import { useSelector } from 'react-redux'
import { MapEvent } from 'react-mapbox-gl/lib/map-events'
import { message, Spin } from 'antd'
import styled from 'styled-components'
import { T, useT } from '@transifex/react'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import { useRefCopy } from './utils'
import {
  TSources,
  TSourceRenderFn,
  TSourcesSelectionSupport,
  TGetMap,
  TGetClusterPointsFn,
} from './types'

import { getMapCenter } from '../../components/shared/utils/settings'
import { ReactMapBox } from '../../ui/mapbox-gl'
import SourceContainer, {
  TSourceContainerConfig,
  getContainerSourceClusterPoints,
} from './sources/container'
import SourceProperty, {
  TSourcePropertyConfig,
  getPropertySourceClusterPoints,
} from './sources/property'
import SourceRouteOne, { TSourceRouteOneConfig } from './sources/route'
import SourceRoutePickupOrders, {
  TSourceRoutePickupOrdersConfig,
} from './sources/routePickupOrders'
import AddonPopup from './Popup'
import AddonClusterAndPointSelectionWithPolygons, {
  TPointTypes,
} from './AddonClusterAndPointSelectionWithPolygons'
import { SERVER_CLUSTER_SELECTION_LIMIT } from './consts'

const Wrapper = styled.div`
  &&& {
    &.fill-view {
      height: 100%;
      width: 100%;
      .ant-design-spin {
        width: 100%;
        height: 100%;
        .ant-spin-container {
          width: 100%;
          height: 100%;
          .mapboxgl-map {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`

const sourcesMap: Record<TSources, TSourceRenderFn> = {
  containers: SourceContainer,
  properties: SourceProperty,
  'route-one': SourceRouteOne,
  'source-route-pickup-orders': SourceRoutePickupOrders,
}

const sourcesGetClusterPointMap: Pick<
  Record<TSources, TGetClusterPointsFn<$TSFixMe>>,
  'containers' | 'properties'
> = {
  containers: getContainerSourceClusterPoints,
  properties: getPropertySourceClusterPoints,
}

const getBboxFromMap = (map: TMapbox) => {
  const { lat: minLat, lng: minLng } = map.getBounds().getNorthEast()
  const { lat: maxLat, lng: maxLng } = map.getBounds().getSouthWest()

  return { minLat, maxLat, minLng, maxLng }
}

type TSelectionVaue = Partial<Record<TSources, string[]>>

type TSelectionProps = {
  onChange?: (value: TSelectionVaue) => void
  /** default to all sources that support selection. */
  include?: TSourcesSelectionSupport[]
}

export type TMapEleProps = {
  sources?: Array<
    | { type: 'containers'; config: TSourceContainerConfig }
    | { type: 'properties'; config: TSourcePropertyConfig }
    | { type: 'route-one'; config: TSourceRouteOneConfig }
    | {
        type: 'source-route-pickup-orders'
        config: TSourceRoutePickupOrdersConfig
      }
  >
  selection?: TSelectionProps
  children?: (args: { getMap: TGetMap }) => ReactNode
  loading?: boolean
}

const addonClusterAndPointSelectionWithPolygonsAllSources: Record<
  TSourcesSelectionSupport,
  Partial<Record<TPointTypes, string>>
> = {
  containers: {
    single: 'container-point-single',
    multi: 'container-point-cluster',
    cluster: 'container-cluster-point',
  },
  properties: {
    single: 'property-point-single',
    multi: 'property-point-cluster',
    cluster: 'property-cluster-point',
  },
}

export const Mapv3 = ({
  sources,
  selection,
  children,
  loading: loadingParent,
}: TMapEleProps) => {
  const t = useT()
  const mapRef = useRef() as React.MutableRefObject<TMapbox>
  const [mapInitialized, setMapInitialized] = useState(false)
  const mapInitializedRef = useRefCopy(mapInitialized)
  const [zoomLevel, setZoomLevel] = useState(10)
  const initCenter: $TSFixMe = useSelector(getMapCenter)
  const [loadingOperations, setLoadingOperations] = useState<Array<boolean>>([])

  const setMapLoadingTrue = useCallback(() => {
    setLoadingOperations((p) => [...p, true])
  }, [])

  const setMapLoadingFalse = useCallback(() => {
    setLoadingOperations((p) => p.slice(0, -1))
  }, [])

  const [center] = useState({
    lat: initCenter?.lat,
    lng: initCenter?.lng,
  })
  const [bbox, setBbox] = useState({
    minLat: 0,
    minLng: 0,
    maxLat: 0,
    maxLng: 0,
  })
  const bboxRef = useRefCopy(bbox)
  const [loadingSources, setLoadingState] = useState<Set<TSources>>(new Set())

  const setSourceLoading = useCallback((s: TSources, loading: boolean) => {
    if (loading) {
      setLoadingState(
        produce((p) => {
          p.add(s)
        })
      )
    } else {
      setLoadingState(
        produce((p) => {
          p.delete(s)
        })
      )
    }
  }, [])

  const getMap = useCallback(() => {
    return mapRef.current
  }, [])

  const loading = useMemo(
    () => !!loadingSources.size || loadingParent || !!loadingOperations?.length,
    [loadingSources.size, loadingParent, loadingOperations?.length]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMapMoveEnd: MapEvent = useCallback((map) => {
    if (mapInitializedRef()) {
      setBbox(getBboxFromMap(map))
      setZoomLevel(map.getZoom())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper className="fill-view">
      <Spin
        wrapperClassName="ant-design-spin"
        style={{ width: '100%', height: '100%' }}
        spinning={loading || !mapInitialized}
      >
        <ReactMapBox.Map
          onStyleLoad={(map) => {
            /** init map settings */
            mapRef.current = map
            map.setZoom(zoomLevel)
            map.easeTo({ center: [center.lng, center.lat] })
            setMapInitialized(true)
            // disable map rotation using right click + drag
            map.dragRotate.disable()

            // disable map rotation using touch rotation gesture
            map.touchZoomRotate.disableRotation()
          }}
          containerStyle={{
            width: `100%`,
            height: `100%`,
          }}
          style={ReactMapBox.props.style}
          onMoveEnd={onMapMoveEnd}
        >
          <>
            <AddonPopup
              getMap={getMap}
              layerNameMapping={{
                containers: {
                  single: ['container-point-single'],
                  multi: ['container-point-cluster'],
                },
                properties: {
                  single: ['property-point-single'],
                  multi: ['property-point-cluster'],
                },
                pickupOrders: {
                  custom: [
                    'route-pickup-orders-containers',
                    'route-pickup-orders-depots',
                    'route-pickup-orders-tickets',
                    'route-pickup-orders-container-groups',
                    'route-pickup-orders-stops-group',
                    'route-pickup-orders-same-stops-group',
                  ],
                },
              }}
            />
            {selection && (
              <AddonClusterAndPointSelectionWithPolygons
                getMap={getMap}
                sources={pick(
                  selection.include
                    ? pick(
                        addonClusterAndPointSelectionWithPolygonsAllSources,
                        selection.include
                      )
                    : addonClusterAndPointSelectionWithPolygonsAllSources,
                  sources?.map((s) => s.type) as string[]
                )}
                loadingSources={loading}
                on={{ selection: selection?.onChange }}
                handleServerClusterSelection={async (feature) => {
                  try {
                    if (!feature.properties.serverCluster) return 'DEFAULT'
                    const featureSource = feature.properties.source
                    /* get the selection of the source points. */
                    const currentBbox = bboxRef()
                    if (
                      feature.properties.totalCount >
                      SERVER_CLUSTER_SELECTION_LIMIT
                    ) {
                      throw new Error(
                        t(
                          'Please zoom in furthur - for selection the cluster size should be less than '
                        ).concat(SERVER_CLUSTER_SELECTION_LIMIT)
                      )
                    }
                    setMapLoadingTrue()
                    const getQueryVar =
                      sources
                        ?.find((v) => v === featureSource)
                        ?.config?.queryOptions?.({
                          baseVariables: {
                            _bbox: {
                              minLon: bbox.minLng,
                              maxLon: bbox.maxLng,
                              minLat: bbox.minLat,
                              maxLat: bbox.maxLat,
                            },
                            _zoomLevel: zoomLevel,
                          },
                        }).variables || {}
                    const pointsWithin = await sourcesGetClusterPointMap[
                      featureSource as 'containers'
                    ](
                      feature.properties.dataId,
                      {
                        minLon: currentBbox.minLng,
                        maxLon: currentBbox.maxLng,
                        minLat: currentBbox.minLat,
                        maxLat: currentBbox.maxLat,
                      },
                      getQueryVar
                    )
                    setMapLoadingFalse()
                    return new Map(pointsWithin.map((v: string) => [v, v]))
                  } catch (e: $TSFixMe) {
                    message.error(e?.message || <T _str="Error occured" />)
                    setMapLoadingFalse()
                    return new Map()
                  }
                }}
              />
            )}
            {sources?.map(({ type, config }, i) => {
              const Sourcefn = sourcesMap[type]

              return (
                <Sourcefn
                  key={i}
                  {...config}
                  _getMap={getMap}
                  _onLoadingChange={(loadingState) => {
                    setSourceLoading(type, loadingState)
                  }}
                  _queryOptions={() => ({
                    variables: {
                      bbox: {
                        minLon: bbox.minLng,
                        maxLon: bbox.maxLng,
                        minLat: bbox.minLat,
                        maxLat: bbox.maxLat,
                      },
                      zoomLevel,
                    },
                  })}
                  _bbox={{
                    minLon: bbox.minLng,
                    maxLon: bbox.maxLng,
                    minLat: bbox.minLat,
                    maxLat: bbox.maxLat,
                  }}
                  _zoomLevel={zoomLevel}
                />
              )
            })}
            {children?.({ getMap })}
          </>
        </ReactMapBox.Map>
      </Spin>
    </Wrapper>
  )
}
