import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'antd'
import { createOptionsFromLabelMap } from '../../utils/create-options'

type Map<T extends string> = Record<T, ReactNode>

export type ContainerStatusType =
  | `available`
  | `on_hold`
  | `defect`
  | `in_transit`
  | `in_storage`
export type ContainerPickupMethodType = `static` | `dynamic` | `none`
export type ContainerSensorLidType = `top` | `side`

export const containerStatusLabelMap: Map<ContainerStatusType> = {
  available: <T _str="Available" />,
  on_hold: <T _str="On Hold" />,
  defect: <T _str="Defect" />,
  in_transit: <T _str="In Transit" />,
  in_storage: <T _str="In Storage" />,
}

export const containerStatusTagColorMap: Record<
  ContainerStatusType,
  TagProps['color']
> = {
  available: 'green',
  on_hold: 'orange',
  defect: 'error',
  in_storage: 'blue',
  in_transit: 'yellow',
}

export const containerPickupMethodLabelMap: Map<ContainerPickupMethodType> = {
  static: <T _str="Static" />,
  dynamic: <T _str="Dynamic" />,
  none: <T _str="Disable" />,
}

export const containerSensorLidLabelMap: Map<ContainerSensorLidType> = {
  top: <T _str="Top" />,
  side: <T _str="Side" />,
}

export const CONTAINER_STATUS_OPTIONS = createOptionsFromLabelMap(
  containerStatusLabelMap
)

export const CONTAINER_PICKUP_METHOD_OPTIONS = createOptionsFromLabelMap(
  containerPickupMethodLabelMap
)

export const CONTAINER_SENSOR_LID_OPTIONS = createOptionsFromLabelMap(
  containerSensorLidLabelMap
)
