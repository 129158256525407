import { gql } from '@apollo/client'
import apiUtilGetKey from '../../../api/apiUtilCreatePrefixer'

export const MUTATION_DELETE_CARD = gql`
  mutation MutationDeleteCard($billingInformationId: ID!, $cardId: String!) {
    deleteCard(billingInformationId: $billingInformationId, cardId: $cardId) {
      billingInformation {
        id
      }
    }
  }
`

export const MUTATION_SET_DEFAULT_CARD = gql`
  mutation MutationSetDefaultCard(
    $billingInformationId: ID!
    $cardId: String!
  ) {
    setDefaultCard(
      billingInformationId: $billingInformationId
      cardId: $cardId
    ) {
      billingInformation {
        id
      }
    }
  }
`

export const GET_COMPANY_BILL_CARDS = gql`
  query GetCompanyBillingInformationCards($id: ID!) {
    company(id: $id) {
      billinginformation {
        id
        cards {
          id
          name
          expYear
          expMonth
          last4
          brand
          default
        }
      }
    }
  }
`
export const K_GET_COMPANY_BILL_CARDS = apiUtilGetKey(GET_COMPANY_BILL_CARDS)
