import React from 'react'
import { useQuery } from '@apollo/client'

import UI from './ui'
import { GET_CONTAINER_INFO } from './api'

const Header = ({ id }: { id: string }) => {
  const { data, loading } = useQuery(GET_CONTAINER_INFO, {
    variables: {
      id,
    },
  })

  const { containerId, containerType, wasteFraction, location } =
    data?.container || {}

  return (
    <UI
      loading={loading}
      data={{
        address: location?.name,
        containerId,
        containerType: containerType?.name,
        // currentFee,
        wasteFraction: wasteFraction?.name,
      }}
    />
  )
}

export default Header
