import { gql } from '@apollo/client'

export type GetPropertyInfoVariables = {
  propertyId: string
}

export type GetPropertyInfoResponse = {
  propertyInfo: {
    owners: {
      name: string
      coName: string
      ownerStatus: string
      ownerType: string
      address: string
    }[]
    administrators: {
      name: string
      coName: string
      address: string
    }[]
  }
}

export const GET_PROPERTY_INFO = gql`
  query CRM_CustomerOverview_Customer_GetPropertyInfo($propertyId: ID!) {
    propertyInfo(propertyId: $propertyId) {
      owners {
        name
        coName
        ownerStatus
        ownerType
        address
      }
      administrators {
        name
        coName
        address
      }
    }
  }
`
