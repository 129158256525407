import { gql } from '@apollo/client'

export const GET_CONTAINER_INFO = gql`
  query GetContainerInfo($id: ID) {
    container(id: $id) {
      id
      containerId
      containerType {
        id
        name
      }
      location {
        id
        name
      }
      wasteFraction {
        id
        name
      }
      pickupSetting {
        id
        name
      }
    }
  }
`
