import React from 'react'
import { T } from '@transifex/react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: '10px 0',
  },
  container: {
    margin: '0 10px',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '80px',
    width: '100%',
  },
  icon: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0px 4px',
  },
  status: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  disabledIcon: {
    backgroundColor: (theme as $TSFixMe).variables.cSlate,
  },
  greenIcon: {
    backgroundColor: (theme as $TSFixMe).variables.cGreen,
  },
  yellowIcon: {
    backgroundColor: (theme as $TSFixMe).variables.cLemon,
  },
  orangeIcon: {
    backgroundColor: (theme as $TSFixMe).variables.cOrange,
  },
  redIcon: {
    backgroundColor: (theme as $TSFixMe).variables.cLightRed,
  },
  disabled: {
    color: (theme as $TSFixMe).variables.cSlate,
  },
  green: {
    color: (theme as $TSFixMe).variables.cGreen,
  },
  yellow: {
    color: (theme as $TSFixMe).variables.cLemon,
  },
  orange: {
    color: (theme as $TSFixMe).variables.cOrange,
  },
  red: {
    color: (theme as $TSFixMe).variables.cLightRed,
  },
  value: {
    color: (theme as $TSFixMe).variables.cIndependence,
  },
}))

type OwnHelpBarProps = {
  inverted?: boolean
}

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'HelpBarProps' circularly references it... Remove this comment to see the full error message
type HelpBarProps = OwnHelpBarProps & typeof HelpBar.defaultProps

// @ts-expect-error ts-migrate(7022) FIXME: 'HelpBar' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const HelpBar = ({ inverted }: HelpBarProps) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item className={classes.container}>
        <div
          className={classNames(
            classes.icon,
            inverted ? classes.redIcon : classes.greenIcon
          )}
        />
        <span
          className={classNames(
            classes.status,
            inverted ? classes.red : classes.green
          )}
        >
          0-25%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div
          className={classNames(
            classes.icon,
            inverted ? classes.orangeIcon : classes.yellowIcon
          )}
        />
        <span
          className={classNames(
            classes.status,
            inverted ? classes.orange : classes.yellow
          )}
        >
          25-50%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div
          className={classNames(
            classes.icon,
            inverted ? classes.yellowIcon : classes.orangeIcon
          )}
        />
        <span
          className={classNames(
            classes.status,
            inverted ? classes.yellow : classes.orange
          )}
        >
          50-75%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div
          className={classNames(
            classes.icon,
            inverted ? classes.greenIcon : classes.redIcon
          )}
        />
        <span
          className={classNames(
            classes.status,
            inverted ? classes.green : classes.red
          )}
        >
          75-100%
        </span>
      </Grid>
      <Grid item className={classes.container}>
        <div className={classNames(classes.icon, classes.disabledIcon)} />
        <span className={classNames(classes.status, classes.disabled)}>
          <T _str="Disabled" />
        </span>
      </Grid>
    </Grid>
  )
}

HelpBar.defaultProps = {
  inverted: false,
}

type StyledLabelProps = {
  value: string
  color: string
}

export const StyledLabel = ({ color, value }: StyledLabelProps) => {
  const classes = useStyles()
  return (
    <div className={classes.labelContainer}>
      {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
      <div className={classNames(classes.icon, classes[`${color}Icon`])}> </div>
      {color !== 'disabled' ? (
        <span className={classNames(classes.status, classes.value)}>
          {value}
        </span>
      ) : null}
    </div>
  )
}

export default HelpBar
