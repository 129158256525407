import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

/* Fragments */
/* Queries */
export const GET_PROJECT = gql`
  query GetProject($id: ID!, $withSettings: Boolean = false) {
    project(id: $id) {
      id
      name
      description
      userAccessLevel
      logo
      company {
        id
      }
      settings @include(if: $withSettings) {
        id
        country
        timezone
        emptyingMethod
        pickupTime
        cluster
        measuringDistance
        drivingDistance
        weight
        volume
        temperature
        clockFormat12
        currency
        dashboardLocation {
          id
          latitude
          longitude
          placeId
          name
        }
        crmEnabled
      }
    }
  }
`
export const K_GET_PROJECT = apiUtilGetKey(GET_PROJECT)

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects(
    $isVisibleContainerTypes: Boolean = false
    $isVisibleWasteFractions: Boolean = false
    $isVisibleCompany: Boolean = true
  ) {
    allProjects {
      edges {
        node {
          id
          name
          userAccessLevel
          logo
          company @include(if: $isVisibleCompany) {
            id
            name
          }
          containerTypes @include(if: $isVisibleContainerTypes) {
            edges {
              node {
                id
                name
                length
                width
                height
                diameter
                isCylindrical
                volume
              }
            }
          }
          wasteFractions @include(if: $isVisibleWasteFractions) {
            edges {
              node {
                id
                name
                wasteCategory
                wasteSubstance
                weightFactor
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PROJECT_SETTINGS = gql`
  mutation UpdateProjectSettings($input: UpdateOrCreateProjectSettingsInput!) {
    updateOrCreateProjectSettings(input: $input) {
      project {
        id
        settings {
          id
          country
          timezone
          emptyingMethod
          pickupTime
          cluster
          measuringDistance
          drivingDistance
          weight
          volume
          temperature
          clockFormat12
          currency
          dashboardLocation {
            id
            latitude
            longitude
            placeId
            name
          }
          crmEnabled
        }
      }
    }
  }
`

/* Mutations */
export const UPDATE_OR_CREATE_PROJECT = gql`
  mutation UpdateOrCreateProject($input: UpdateOrCreateProjectInput!) {
    updateOrCreateProject(input: $input) {
      project {
        id
        name
        description
        userAccessLevel
        logo
        company {
          id
          name
        }
        wasteFractions {
          edges {
            node {
              id
              name
              wasteCategory
              wasteSubstance
              weightFactor
            }
          }
        }
        containerTypes {
          edges {
            node {
              id
              name
            }
          }
        }
        settings {
          id
          timezone
          emptyingMethod
          pickupTime
          cluster
          measuringDistance
          drivingDistance
          weight
          volume
          temperature
          clockFormat12
          currency
          dashboardLocation {
            id
            latitude
            longitude
            placeId
            name
          }
          crmEnabled
        }
      }
    }
  }
`
