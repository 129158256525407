import React from 'react'
import { T } from '@transifex/react'
import {
  Select as SelectFormik,
  SelectProps as SelectFormikProps,
} from 'formik-antd'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'
import { useSavedActiveProjects } from '../../../hooks'

const formatResponse = ({ node }: { node: GetCallResponseEdgeNode }): Opt => ({
  label: node?.name,
  value: node?.id,
  key: node.id,
})

export type SelectContainerTypeProps = {
  variables: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps'] &
  Pick<AsyncSelectWithFetchMoreProps, 'render'>

export const SelectContainerType = ({
  variables,
  ...props
}: SelectContainerTypeProps) => {
  const activeProjects = useSavedActiveProjects()
  const queryVariables = {
    activeProjects,
    ...variables,
  }

  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allContainerTypes.edges.map((val: $TSFixMe) => formatResponse(val))
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      selectProps={{
        placeholder: <T _str="Container Type" />,
        allowClear: true,
        ...props,
      }}
      value={props.value}
    />
  )
}

export const SelectFormikContainerType: React.FC<
  SelectFormikProps & { variables: GetCallVariables }
> = ({ name, variables, ...rest }) => (
  <SelectContainerType
    variables={variables}
    render={({ loading, options }) => (
      <SelectFormik
        name={name}
        loading={loading}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...rest}
      />
    )}
  />
)
