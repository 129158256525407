import { string, object } from 'yup'
import { getFieldRequiredSimple } from '../../../utils/yup-validation'

import { TFormSchema } from './types'

type Key = keyof TFormSchema
const requiredFields: Key[] = ['name', 'country']
export const isRequired = (key: Key) => requiredFields.includes(key)

export const validationSchema = object().shape({
  name: string().required(getFieldRequiredSimple('Name')),
  country: string().required(getFieldRequiredSimple('Country')),
})
