import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { SelectableListWithSearch } from '@wastehero/storybook/lib/components'

import { GET_TICKET_TYPES } from './api'
import { useSavedActiveProjects } from '../../../../../../../hooks/useSavedActiveProjects'
import { Opt } from '../../../../../../../new-components'

type TTicketTypesListProps = {
  selected: string
  onSelect: (value: string) => void
  isPropertyLevel?: boolean | null
  isContainerLevel?: boolean
}

const TicketTypesList = ({
  onSelect,
  selected,
  isPropertyLevel,
  isContainerLevel,
}: TTicketTypesListProps) => {
  const activeProjects = useSavedActiveProjects()

  const { data, loading } = useQuery(GET_TICKET_TYPES, {
    variables: {
      activeProjects,
      isPropertyLevel,
      isContainerLevel,
    },
  })

  const options: Opt[] = useMemo(
    () =>
      data?.allTicketTypes?.edges
        ?.filter(({ node }: $TSFixMe) => node?.service?.serviceType)
        .map(({ node }: $TSFixMe) => ({
          label: node.name || node.id,
          value: node.id,
        })) || [],
    [data]
  )

  useEffect(() => {
    if (!selected) {
      onSelect(options?.[0]?.value)
    }
  }, [onSelect, options, selected])

  return (
    <SelectableListWithSearch
      variant="radio"
      value={[selected]}
      onChange={(val) => {
        onSelect(val[0])
      }}
      loading={loading}
    >
      {options.map((p) => (
        <SelectableListWithSearch.Option {...p} />
      ))}
    </SelectableListWithSearch>
  )
}

export default TicketTypesList
