import { gql } from '@apollo/client'
import { FrgPickupSettings } from './pickup-settings'

/* Fragments */
export const FrgBaseContainer = gql`
  ${FrgPickupSettings}
  fragment FrgBaseContainer on ContainerType {
    id
    containerId
    description
    showOnRoute
    photo
    photoUrl
    status
    pickupMethod
    pickupSettings {
      edges {
        node {
          ...FrgPickupSettings
        }
      }
    }
    prioritizeInStartOfRoute
    wasteFraction {
      id
      name
      wasteCategory
      wasteSubstance
      weightFactor
    }
    overflowingSettings {
      id
      duration
      percentage
    }
    project {
      id
      name
      company {
        id
        name
      }
    }
  }
`

/* Queries */

export const GET_CONTAINER = gql`
  ${FrgBaseContainer}
  query GetContainer(
    $id: ID!
    $isFillleveldeviceSet: Boolean = true
    $isLogs: Boolean = true
    $isMeasurement: Boolean = true
    $isCompany: Boolean = true
    $isFillleveldeviceSetNotificationSet: Boolean = true
    $isContainerLog: Boolean = true
    $isDeviceToContainerSetDeviceDeviceType: Boolean = true
    $isFilllevelmeasurementSet: Boolean = true
    $isContainerType: Boolean = true
    $isAvailableMeasurementFilters: Boolean = true
    $isCurrentEffective: Boolean = true
    $fillingFrequency: Boolean = true
    $fillLevelMeasurementSetCreatedAt_Lte: DateTime
    $fillLevelMeasurementSetCreatedAt_Gte: DateTime
    $currentEffectiveFrom: DateTime
    $currentEffectiveTo: DateTime
  ) {
    container(id: $id) {
      ...FrgBaseContainer
      metaValues {
        companyContainerMetaType {
          name
          id
        }
        value
      }
      fillingFrequency @include(if: $fillingFrequency)
      currentEffective(From: $currentEffectiveFrom, To: $currentEffectiveTo)
        @include(if: $isCurrentEffective)
      availableMeasurementFilters @include(if: $isAvailableMeasurementFilters)
      scheduledPickup
      totalCollections
      totalOverflowings
      efficient
      status
      project @include(if: $isCompany) {
        id
        name
      }
      location {
        id
        latitude
        longitude
        placeId
        name
      }
      allowedHoursStart
      allowedHoursEnd
      containerType @include(if: $isContainerType) {
        id
        name
        volume
        height
        length
        width
        diameter
        emplacement
        emptyingTime
        isCylindrical
        vehicleCoupling
      }
      measurement @include(if: $isMeasurement) {
        id
        fillPercentage
        temperature
        batteryLevel
        rsi
        createdAt
        rawMeasurements
        rawMeasurementsPercentage
      }
      logs(last: 100) @include(if: $isLogs) {
        edges {
          node {
            id
            oldFillPercentage
            createdAt
          }
        }
      }
      deviceToContainerSet @include(if: $isFillleveldeviceSet) {
        edges {
          node {
            id
            objectId
            internalHeight
            offset
            deviceAngle
            topLid
            sideLid
            endDate
            measurementSettings {
              id
              custom
              name
            }
            filllevelmeasurementSet(
              createdAt_Lte: $fillLevelMeasurementSetCreatedAt_Lte
              createdAt_Gte: $fillLevelMeasurementSetCreatedAt_Gte
            ) @include(if: $isFilllevelmeasurementSet) {
              edges {
                node {
                  id
                  fillPercentage
                  fillPercentageMasked
                  createdAt
                  rawMeasurements
                }
              }
            }
            notificationSet(last: 10)
              @include(if: $isFillleveldeviceSetNotificationSet) {
              edges {
                node {
                  id
                  status
                  message
                  createdAt
                }
              }
            }
            device {
              id
              devId
              deviceType @include(if: $isDeviceToContainerSetDeviceDeviceType) {
                id
                name
              }
              connectionType
              ... on LorawanDeviceType {
                appKey
              }
              ... on MobileDeviceType {
                simcard {
                  id
                  iccid
                  imsi
                  apn
                  isActive
                }
              }
              ... on SigFoxDeviceType {
                sigFoxPac {
                  id
                  pac
                }
              }
            }
          }
        }
      }
      containerLog @include(if: $isContainerLog) {
        id
        oldFillPercentage
        createdAt
      }
      pickupSetting {
        name
        id
      }
      sensorFillLevel
      sensorFillLevelPeriodHours
    }
  }
`

export const GET_ALL_CONTAINERS = gql`
  query GetAllContainers(
    $last: Int
    $before: String
    $after: String
    $q: String
    $order: String
    $orderBy: String
    $measurement_FillPercentage: String
    $activeProjects: String
    $first: Int
    $offset: Int
    $containerTypes: [ID]
    $pickupSetting: [ID]
    $sensor_Isnull: Boolean
    $name: String
    $containerType_Name_In: [String]
    $hasContainerTypeDetails: Boolean = false
    $search: String
    $wasteFractionIds: String
  ) {
    allContainers(
      last: $last
      first: $first
      after: $after
      before: $before
      q: $q
      order: $order
      orderBy: $orderBy
      measurement_FillPercentage: $measurement_FillPercentage
      activeProjects: $activeProjects
      offset: $offset
      containerTypes: $containerTypes
      pickupSetting: $pickupSetting
      sensor_Isnull: $sensor_Isnull
      name: $name
      containerType_Name_In: $containerType_Name_In
      search: $search
      wasteFractionIds: $wasteFractionIds
    ) {
      totalCount
      edges {
        node {
          id
          containerId
          description
          status
          totalOverflowings
          scheduledPickup
          pickupSetting {
            name
          }
          showOnRoute
          currentEffective
          wasteFraction {
            id
            name
            wasteSubstance
            wasteCategory
          }
          overflowingSettings {
            id
            duration
            percentage
          }
          project {
            id
            name
            company {
              id
              name
            }
          }
          location {
            id
            latitude
            longitude
            placeId
            name
          }
          containerType {
            id
            name
            height @include(if: $hasContainerTypeDetails)
          }
          measurement {
            id
            fillLevel
            fillPercentage
            batteryLevel
            batteryPercentage
            rsi
            createdAt
          }
          logs {
            edges {
              node {
                id
                oldFillPercentage
                createdAt
              }
            }
          }
          deviceToContainerSet {
            edges {
              node {
                id
                endDate
                device {
                  id
                  devId
                }
                measurementSettings {
                  id
                  name
                }
              }
            }
          }
          containerLog {
            id
            oldFillPercentage
            createdAt
          }
        }
      }
    }
  }
`

export const GET_ALL_CONTAINERS_DASHBOARD = gql`
  query GetAllContainersDashboard(
    $activeProjects: String
    $search: String
    $wasteFractionIds: String
    $sensor_Isnull: Boolean
    $containerTypes: [ID]
  ) {
    allContainers(
      activeProjects: $activeProjects
      search: $search
      wasteFractionIds: $wasteFractionIds
      sensor_Isnull: $sensor_Isnull
      containerTypes: $containerTypes
    ) {
      edges {
        node {
          id
          containerId
          containerType {
            id
            name
          }
          location {
            id
            latitude
            longitude
            name
          }
          measurement {
            id
            fillPercentage
          }
          wasteFraction {
            id
            name
            wasteSubstance
            wasteCategory
          }
        }
      }
    }
  }
`

export const GET_CONTAINERS_AUTO_COMPLETE = gql`
  query GetContainersAutoCompelete(
    $last: Int
    $pageSize: Int
    $before: String
    $after: String
    $q: String
    $order: String
    $orderBy: String
    $measurement_FillPercentage: String
    $activeProjects: String
    $offset: Int
    $containerTypes: [ID]
    $sensor_Isnull: Boolean
    $name: String
    $containerType_Name_In: [String]
    $wasteFractionIds: String
    $allowedForRoute: String
    $routeIdsNotCompletedStops: String
    $status: [String!]
  ) {
    allContainers(
      last: $last
      first: $pageSize
      after: $after
      before: $before
      q: $q
      order: $order
      orderBy: $orderBy
      measurement_FillPercentage: $measurement_FillPercentage
      activeProjects: $activeProjects
      offset: $offset
      containerTypes: $containerTypes
      sensor_Isnull: $sensor_Isnull
      name: $name
      containerType_Name_In: $containerType_Name_In
      wasteFractionIds: $wasteFractionIds
      allowedForRoute: $allowedForRoute
      routeIdsNotCompletedStops: $routeIdsNotCompletedStops
      status: $status
    ) {
      totalCount
      edges {
        node {
          id
          containerId
          photoUrl
          location {
            latitude
            longitude
            placeId
            name
            id
          }
          wasteFraction {
            id
            wasteCategory
            name
            wasteSubstance
          }
          deviceToContainerSet {
            edges {
              node {
                id
                endDate
                device {
                  id
                  devId
                }
              }
            }
          }
          measurement {
            fillPercentage
          }
        }
      }
    }
  }
`
