import { DocumentNode, gql } from '@apollo/client'
import { TCrmServiceServiceTypeChoices } from '../../../api/consts'

const FrgFormTicketSingleGetContainerInfo = gql`
  fragment FrgFormTicketSingleGetContainerInfo on ContainerType {
    id
    containerType {
      id
    }
    wasteFraction {
      id
    }
    pickupSetting {
      id
    }
  }
`

export const GET_CONTAINER_INFO = gql`
  ${FrgFormTicketSingleGetContainerInfo}
  query FormTicketSingleGetContainerInfo($id: ID!) {
    container(id: $id) {
      ...FrgFormTicketSingleGetContainerInfo
    }
  }
`

const FrgFormTicketSingleTicketType = gql`
  fragment FrgFormTicketSingleTicketType on TicketTypeDef {
    id
    service {
      id
      serviceType
    }
    containerSource {
      id
      depot {
        id
      }
    }
    containerDestination {
      id
      depot {
        id
      }
    }
    isContainerLevel
    isPropertyLevel
  }
`

export const FrgTicketDetailRouteViewForm = gql`
  ${FrgFormTicketSingleGetContainerInfo}
  ${FrgFormTicketSingleTicketType}
  fragment FrgTicketDetailRouteViewForm on TicketDef {
    id
    status
    preferredDate
    description
    priority
    ignoreServiceFee
    ticketType {
      ...FrgFormTicketSingleTicketType
    }
    property {
      id
    }
    attachments {
      edges {
        node {
          id
          file
          mimeType
        }
      }
    }
    managecontainerticket {
      id
      containerType {
        id
      }
      pickupSetting {
        id
      }
      wasteFraction {
        id
      }
      containerToAdd {
        id
      }
      containerDestination {
        id
        depot {
          id
        }
      }
      containerSource {
        id
        depot {
          id
        }
      }
      newContainerId
    }
    bulkwastepickupticket {
      id
      items {
        edges {
          node {
            id
            wasteFraction {
              id
            }
          }
        }
      }
    }
    container {
      ...FrgFormTicketSingleGetContainerInfo
    }
  }
`

export const MUTATION_COMPLETE_STOP = gql`
  mutation RouteDetailViewMutationRouteStopComplete(
    $orderId: String!
    $routeId: ID!
    $ticketId: ID
    $weight: Float
    $fillLevel: Float
    $ticketIdStopCompleted: Boolean
    $qrCode: String
  ) {
    routeStopComplete(
      orderId: $orderId
      fillLevel: $fillLevel
      weight: $weight
      ticketId: $ticketId
      routeId: $routeId
      ticketIdStopCompleted: $ticketIdStopCompleted
      qrCode: $qrCode
    ) {
      route {
        id
      }
    }
  }
`

const MUTATION_CREATE_TICKET = gql`
  ${FrgTicketDetailRouteViewForm}
  mutation MutationCreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      ticket {
        ...FrgTicketDetailRouteViewForm
      }
    }
  }
`

const MUTATION_CREATE_MANAGED_TICKET = gql`
  ${FrgTicketDetailRouteViewForm}
  mutation CreateManageContainerTicket(
    $input: CreateManageContainerTicketInput!
  ) {
    createManageContainerTicket(input: $input) {
      manageContainerTicket {
        ...FrgTicketDetailRouteViewForm
      }
    }
  }
`

const MUTATION_CREATE_BULK_TICKET = gql`
  ${FrgTicketDetailRouteViewForm}
  mutation CreateBulkWastePickupTicket(
    $input: CreateBulkWastePickupTicketInput!
  ) {
    createBulkWastePickupTicket(input: $input) {
      bulkWastePickupTicket {
        ...FrgTicketDetailRouteViewForm
      }
    }
  }
`

export const mutationKeyMapWithServiceTypeCreate: Record<
  TCrmServiceServiceTypeChoices,
  DocumentNode
> = {
  EXTRA_EMPTYING: MUTATION_CREATE_TICKET,
  ADD_CONTAINER: MUTATION_CREATE_MANAGED_TICKET,
  BULK_WASTE_PICKUP: MUTATION_CREATE_BULK_TICKET,
  CHANGE_CONTAINER_PICKUP_SETTING: MUTATION_CREATE_MANAGED_TICKET,
  CHANGE_CONTAINER_TYPE: MUTATION_CREATE_MANAGED_TICKET,
  REMOVE_CONTAINER: MUTATION_CREATE_MANAGED_TICKET,
}

/* UPDATE MUTATIONS */

const MUTATION_UPDATE_TICKET = gql`
  ${FrgTicketDetailRouteViewForm}
  mutation UpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
      ticket {
        ...FrgTicketDetailRouteViewForm
      }
    }
  }
`
const MUTATION_UPDATE_MANAGED_TICKET = gql`
  ${FrgTicketDetailRouteViewForm}
  mutation UpdateManageContainerTicket(
    $input: UpdateManageContainerTicketInput!
  ) {
    updateManageContainerTicket(input: $input) {
      manageContainerTicket {
        ...FrgTicketDetailRouteViewForm
      }
    }
  }
`

const MUTATION_UPDATE_BULK_WASTE_TICKET = gql`
  ${FrgTicketDetailRouteViewForm}
  mutation UpdateBulkWastePickupTicket(
    $input: UpdateBulkWastePickupTicketInput!
  ) {
    updateBulkWastePickupTicket(input: $input) {
      bulkWastePickupTicket {
        ...FrgTicketDetailRouteViewForm
      }
    }
  }
`

export const mutationKeyMapWithServiceTypeUpdate: Record<
  TCrmServiceServiceTypeChoices,
  DocumentNode
> = {
  EXTRA_EMPTYING: MUTATION_UPDATE_TICKET,
  ADD_CONTAINER: MUTATION_UPDATE_MANAGED_TICKET,
  BULK_WASTE_PICKUP: MUTATION_UPDATE_BULK_WASTE_TICKET,
  CHANGE_CONTAINER_PICKUP_SETTING: MUTATION_UPDATE_MANAGED_TICKET,
  CHANGE_CONTAINER_TYPE: MUTATION_UPDATE_MANAGED_TICKET,
  REMOVE_CONTAINER: MUTATION_UPDATE_MANAGED_TICKET,
}

export const GET = gql`
  ${FrgTicketDetailRouteViewForm}
  query FormTicketSingleGet($id: ID!) {
    ticket(id: $id) {
      ...FrgTicketDetailRouteViewForm
    }
  }
`

export const GET_TICKET_TYPE = gql`
  ${FrgFormTicketSingleTicketType}
  query FormTicketSingleGetTicketType($id: ID!) {
    ticketType(id: $id) {
      ...FrgFormTicketSingleTicketType
    }
  }
`
