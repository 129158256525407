import { gql, useQuery } from '@apollo/client'

const GET_ME_META_VALUES_CAN_EDIT = gql`
  query FormPropertyGetMetaValuesCanEdit {
    me {
      id
      companyRole {
        id
        metaTypesCanEdit {
          id
          name
          entity
        }
      }
    }
  }
`

export const useMeMetaTypesCanEdit = () => {
  const { data, ...r } = useQuery(GET_ME_META_VALUES_CAN_EDIT)

  const meMetaTypesCanEdit = data?.me?.companyRole?.metaTypesCanEdit

  return { data: meMetaTypesCanEdit, ...r }
}
