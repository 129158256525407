import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TDriverWorkStatus = `working` | `unavailable` | `off-work`

export const driverWorkStatusLabelMap: Record<TDriverWorkStatus, ReactNode> = {
  working: <T _str="Working" />,
  'off-work': <T _str="Off work" />,
  unavailable: <T _str="Unavailable" />,
}

export const driverWorkStatusColorMap: Record<TDriverWorkStatus, string> = {
  working: 'blue',
  'off-work': 'red',
  unavailable: 'default',
}
