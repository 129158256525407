import React from 'react'
import { T } from '@transifex/react'
import { LinkProps } from '../../reducers/routing/child-router-factory'

import { TGetLinkPropsFn, defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'

export const getLinkPropsContainerNode: TGetLinkPropsFn =
  getLinkPropsMakeNullable(
    (
      /** Should be ContainerType!
       */
      value,
      { includeLabel } = defaultConfig
    ): LinkProps => ({
      to: ({ routingMap }) =>
        routingMap.app['asset-management'].containers[':containerId'](value.id)
          ._,
      children: includeLabel ? (
        <T _str="Container: {name}" name={value.containerId} />
      ) : (
        value.containerId
      ),
    })
  )
