import React from 'react'
import { useQuery } from '@apollo/client'
import { T } from '@transifex/react'
import { Form } from 'antd'
import { useMe } from '../../../../../hooks'

import {
  GET_ALL_WASTE_FRACTION_SLIM,
  GET_CONTAINER_TYPE_SLIM,
  GET_PICKUP_SETTING_SLIM,
  GET_WASTE_FRACTION_SLIM,
  GET_CONTAINER_INFO,
} from '../api'
import { getSummaryDataConfig } from './utils'
import {
  OverrideHardcodedStyling,
  SummaryDataGroupWrapper,
  SummaryDataWrapper,
  SummaryEmptyFormText,
} from './wrappers'
import { DataEntry } from './data-entry'
import {
  getTicketPriorityChoice,
  TCrmServiceServiceTypeChoices,
} from '../../../../../api/consts'
import { SelectAssigneeItem } from '../../../../select/SelectAssignee'

export type SummaryDataProps = {
  formIsEmpty: boolean
  containerId?: string
  ticketType: TCrmServiceServiceTypeChoices
  getFieldValue: $TSFixMe
  ticketTypeName: string
  propertyId: string | undefined
}

export const SummaryData = ({
  formIsEmpty,
  containerId,
  getFieldValue,
  ticketType,
  ticketTypeName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propertyId,
}: SummaryDataProps) => {
  const me = useMe()

  const { data: containerData, loading: containerLoading } = useQuery(
    GET_CONTAINER_INFO,
    {
      variables: {
        id: containerId,
      },
      skip: !containerId,
    }
  )

  const { data: containerTypeData, loading: containerTypeLoading } = useQuery(
    GET_CONTAINER_TYPE_SLIM,
    {
      variables: {
        id: getFieldValue('toType'),
      },
      skip: !getFieldValue('toType') || ticketType !== 'CHANGE_CONTAINER_TYPE',
    }
  )

  const {
    data: pickupSettingChangeData,
    loading: pickupSettingChangeDataLoading,
  } = useQuery(GET_PICKUP_SETTING_SLIM, {
    variables: {
      id: getFieldValue('toType'),
    },
    skip:
      !getFieldValue('toType') ||
      ticketType !== 'CHANGE_CONTAINER_PICKUP_SETTING',
  })

  const { data: pickupSettingData, loading: pickupSettingDataLoading } =
    useQuery(GET_PICKUP_SETTING_SLIM, {
      variables: {
        id: getFieldValue('pickupSetting'),
      },
      skip: !getFieldValue('pickupSetting') || ticketType !== 'ADD_CONTAINER',
    })

  const { data: wasteFractionData, loading: wasteFractionLoading } = useQuery(
    GET_WASTE_FRACTION_SLIM,
    {
      variables: {
        id: getFieldValue('wasteFraction'),
      },
      skip: !getFieldValue('wasteFraction'),
    }
  )

  const { data: allWasteFractionsData, loading: allWasteFractionsLoading } =
    useQuery(GET_ALL_WASTE_FRACTION_SLIM, {
      variables: {
        ids: getFieldValue('wasteFractions'),
      },
      skip: !getFieldValue('wasteFractions'),
    })

  const summaryLoading = containerLoading

  const WasteFractions = allWasteFractionsData?.allWasteFractions?.edges
    ?.map((wasteFraction: $TSFixMe) => wasteFraction?.node?.name)
    .join(', ')

  const summaryDataConfig = getSummaryDataConfig(ticketType)

  return (
    <SummaryDataWrapper>
      {!formIsEmpty ? (
        <>
          <SummaryDataGroupWrapper>
            <DataEntry data={ticketTypeName} label={<T _str="Ticket" />} />
            {summaryDataConfig?.address && (
              <DataEntry
                data={containerData?.container?.location?.name}
                label={<T _str="Address" />}
                loading={summaryLoading}
              />
            )}
            {summaryDataConfig?.containerId && (
              <DataEntry
                label={<T _str="Container ID" />}
                data={containerData?.container?.containerId}
                loading={summaryLoading}
              />
            )}
            {summaryDataConfig?.containerType && (
              <DataEntry
                label={<T _str="Container type" />}
                data={containerData?.container?.containerType?.name}
                loading={summaryLoading || containerTypeLoading}
                changeData={containerTypeData?.containerType?.name}
              />
            )}
            {summaryDataConfig?.pickup && (
              <DataEntry
                label={<T _str="Pickup" />}
                // TODO fix
                data={
                  pickupSettingData?.pickupSetting?.name ||
                  getFieldValue('pickupSetting') ||
                  containerData?.container?.pickupSetting?.name
                }
                loading={
                  summaryLoading ||
                  pickupSettingChangeDataLoading ||
                  pickupSettingDataLoading
                }
                changeData={pickupSettingChangeData?.pickupSetting?.name}
              />
            )}
            {summaryDataConfig?.wasteFraction && (
              <DataEntry
                label={<T _str="Waste fraction" />}
                data={
                  wasteFractionData?.wasteFraction?.name ||
                  WasteFractions ||
                  containerData?.container?.wasteFraction?.name
                }
                loading={
                  summaryLoading ||
                  wasteFractionLoading ||
                  allWasteFractionsLoading
                }
              />
            )}
          </SummaryDataGroupWrapper>
          {(summaryDataConfig.priority || summaryDataConfig.preferredDate) && (
            <SummaryDataGroupWrapper>
              {summaryDataConfig.preferredDate && (
                <DataEntry
                  label={<T _str="Preferred date" />}
                  data={
                    getFieldValue('date')?.dateASAP ? (
                      <T _str="As soon as possible" />
                    ) : (
                      getFieldValue('date')?.date?.format('YYYY-MM-DD')
                    )
                  }
                  loading={summaryLoading}
                />
              )}
              {summaryDataConfig.priority && (
                <DataEntry
                  label={<T _str="Priority" />}
                  data={getTicketPriorityChoice(getFieldValue('priority'))}
                  loading={summaryLoading}
                />
              )}
            </SummaryDataGroupWrapper>
          )}
          <SummaryDataGroupWrapper>
            {summaryDataConfig.creator && (
              <DataEntry
                label={<T _str="Creator" />}
                data={`${me.firstName} ${me.lastName}`}
                loading={summaryLoading}
              />
            )}
            {summaryDataConfig.assignee && (
              <DataEntry
                label={<T _str="Assigned to" />}
                data={
                  <OverrideHardcodedStyling>
                    <Form.Item shouldUpdate noStyle name="assignee">
                      <SelectAssigneeItem />
                    </Form.Item>
                  </OverrideHardcodedStyling>
                }
                loading={summaryLoading}
              />
            )}
          </SummaryDataGroupWrapper>
        </>
      ) : (
        <SummaryEmptyFormText>
          <T _str="The summary will be shown once all required fields have been filled." />
        </SummaryEmptyFormText>
      )}
    </SummaryDataWrapper>
  )
}
