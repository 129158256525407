import { RangeSelectorWithRadio } from '@wastehero/storybook/lib/components'
import { SelectProps } from 'antd'
import { T } from '@transifex/react'
import React from 'react'
import {
  SelectDriver,
  SelectWasteFraction,
  SelectVehicle,
  SelectContainer,
  SelectContainerType,
  SelectProperty,
  SelectPropertyGroup,
} from '../..'
import {
  createFilter,
  useFiltersContext,
  FiltersProiver as Provider,
  initialFilters,
} from './filters.context'

const defaultProps: Partial<SelectProps> = {
  maxTagCount: 'responsive',
  style: {
    width: '100%',
  },
}

export const FiltersProiver: React.FC = (props) => (
  <Provider {...props} initialValue={initialFilters} />
)

export const FilterByDateRange = () => {
  const [{ dateRange }, set] = useFiltersContext()

  return (
    <RangeSelectorWithRadio
      value={dateRange}
      onChange={(val) => set({ dateRange: val })}
    />
  )
}

export const FilterByDriver = createFilter({
  key: 'drivers',
  Component: SelectDriver,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
  },
})

export const FilterByWasteFraction = createFilter({
  key: 'wasteFractions',
  Component: SelectWasteFraction,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
  },
})

export const FilterByVehicle = createFilter({
  key: 'vehicles',
  Component: SelectVehicle,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
  },
})

export const FilterByContainer = createFilter({
  key: 'containers',
  Component: SelectContainer,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
  },
})

export const FilterByContainerType = createFilter({
  key: 'containerTypes',
  Component: SelectContainerType,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
  },
})

export const FilterByProperty = createFilter({
  key: 'property',
  Component: SelectProperty,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})

export const FilterByPropertyGroup = createFilter({
  key: 'propertyGroup',
  Component: SelectPropertyGroup,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    maxTagCount: 'responsive' as const,
    placeholder: <T _str="Property group" />,
  },
})
