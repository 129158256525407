import React from 'react'
import { Typography } from 'antd'
import { capitalize } from 'lodash'
import MomentTimelineCollapsible, {
  TMomentTimelineCollapsibleProps,
} from '@wastehero/storybook/lib/components/MomentTimelineCollapsible'
import { useT } from '@transifex/react'

import { ExpandIcon } from './elements'

export type TProps<TData extends Record<string, unknown>> = Pick<
  TMomentTimelineCollapsibleProps<TData>,
  'data' | 'renderTimelineItem' | 'loading'
>

export * from './TimelineItem'

const { Text } = Typography

const UI = <Data extends Record<string, unknown> = Record<string, unknown>>({
  data,
  renderTimelineItem,
  loading,
}: TProps<Data>) => {
  const t = useT()

  const transToday = t('Today')
  const transYesterday = t('Yesterday')

  return (
    <MomentTimelineCollapsible<Data>
      data={data}
      loadingNum={1}
      renderTitle={(name, itemsLength) => (
        <div style={{ marginBottom: 8 }}>
          <Text
            strong
            style={{
              background: '#F5F5F5',
              padding: '2px 4px',
              marginRight: '4px',
            }}
          >
            {capitalize(name)}
          </Text>
          <Text type="secondary">({itemsLength})</Text>
        </div>
      )}
      renderTimelineItem={renderTimelineItem}
      collapseProps={{
        ghost: true,
        expandIconPosition: 'right',
        expandIcon: ExpandIcon,
      }}
      collapsePropsProvided={(keys) => ({ defaultActiveKey: keys.slice(0, 5) })}
      loading={loading}
      formats={{
        /* Todo:
      The following Yesterday and Today are not localized. Make them localized.
    */
        lastDay: `[${transYesterday}] DD/MM/YYYY`,
        sameDay: `[${transToday}] DD/MM/YYYY`,
        lastWeek: 'DD/MM/YYYY',
        nextWeek: 'DD/MM/YYYY',
        sameElse: 'DD/MM/YYYY',
      }}
    />
  )
}

export default UI
