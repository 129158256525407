import React from 'react'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { getSavedActiveProjects } from '../../../components/shared/utils'
import {
  GET_ALL_DEPOTS,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'

const formatResponse = ({
  node: { id, name },
}: {
  node: GetCallResponseEdgeNode
}): Opt => ({
  label: name,
  value: id,
  key: id,
})

type SelectDepotsProps = {
  variables?: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps'] &
  Pick<AsyncSelectWithFetchMoreProps, 'render'>

export const SelectDepots = ({
  variables,
  render,
  ...props
}: SelectDepotsProps) => {
  const activeProjects = useSelector((state) => getSavedActiveProjects(state))
  const queryVariables = {
    activeProjects,
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allDepots.edges.map((val: $TSFixMe) => formatResponse(val))
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_ALL_DEPOTS}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      render={render}
      selectProps={{
        placeholder: <T _str="Depots" />,
        allowClear: true,
        ...props,
      }}
      value={props.value}
    />
  )
}
