import React, { FC, ReactNode } from 'react'
import { Row, Col } from 'antd'

import styled from 'styled-components'
import { apiUtilCreatePrefixer } from '../api/apiUtilCreatePrefixer'

type ListView1Props = {
  jsx: {
    filters: ReactNode[]
    addBtn: ReactNode
    search: ReactNode
    searchPost?: ReactNode
    columnFilter: ReactNode
    table: ReactNode
  }
}

const p = apiUtilCreatePrefixer(`Layouts-ListView1-`)
const classes = {
  section1: p`section1`,
  section2: p`section2`,
  table: p`table`,
}

const Column = styled(Col)``

const Wrapper = styled(Row)`
  &&& {
    ${Column} {
      padding: 1rem !important;
      background: white;

      &.${classes.section1}, ${classes.section2} {
        margin-bottom: 1rem;
      }

      &.${classes.table} {
        padding: 0 !important;
      }
    }

    .ant-table-pagination {
      margin-bottom: 0px;
    }
  }
`

export type ListView1ExtendedProps = FC<ListView1Props> & {
  classes: typeof classes
}

const ListView1: ListView1ExtendedProps = ({ jsx, children }) => {
  return (
    <Wrapper>
      <Column span={24} className={classes.section1}>
        <Row justify="space-between">
          <Row gutter={8}>
            {jsx.filters.map((filter) => (
              <Col>{filter}</Col>
            ))}
          </Row>
          {jsx.addBtn}
        </Row>
      </Column>
      <Column span={24} className={classes.section2}>
        <Row gutter={8} align="middle">
          <Col flex="auto">
            {jsx.search}
            {jsx.searchPost}
          </Col>
          <Col flex="none">{jsx.columnFilter}</Col>
        </Row>
      </Column>
      <Column span={24} className={classes.table}>
        {jsx.table}
      </Column>
      {children}
    </Wrapper>
  )
}

ListView1.classes = classes
export { ListView1 }
