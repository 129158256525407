import { useState, Dispatch, SetStateAction } from 'react'
import { DEFAULT_PAGE_SIZE } from './pagination.config'

type SetState<T> = Dispatch<SetStateAction<T>>

type State = { page: number; pageSize: number; orderBy: string; order: string }

export type Init = Partial<State>

export const useTableState = (
  init?: Init
): [
  State,
  {
    setPage: SetState<number>
    setPageSize: SetState<number>
    setOrder: SetState<string>
    setOrderBy: SetState<string>
  }
] => {
  const [pageSize, setPageSize] = useState(init?.pageSize || DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(init?.page || 1)
  const [orderBy, setOrderBy] = useState(init?.orderBy || ``)
  const [order, setOrder] = useState(init?.order || '')

  return [
    {
      page,
      pageSize,
      orderBy,
      order,
    },
    {
      setPage,
      setPageSize,
      setOrder,
      setOrderBy,
    },
  ]
}
