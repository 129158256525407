import { ApolloClient, ApolloLink, split, from } from '@apollo/client'

import { InMemoryCache } from '@apollo/client/cache'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'

import history from '../../history'
import introspectionQueryResultData from './fragmentTypes.json'
import introspectionToPossibleTypes from './cache'
import { wsLink } from './ws'

const errorMsg401 = 'You do not have permission to perform this action'

const { host, protocol } = window.location

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_SSL === 'true' ? 'https:' : protocol}//${
    process.env.REACT_APP_API_URL || host
  }/graph-api/`,
}) as unknown

export const getJWTToken = () => {
  return localStorage.getItem('reactQLJWT')
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getJWTToken()
  operation.setContext({
    headers: {
      authorization: token ? `JWT ${token}` : '',
    },
  })

  return forward(operation)
})

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  if (
    (networkError &&
      (networkError as $TSFixMe).statusCode === 401 &&
      history.location.pathname !== '/login') ||
    (graphQLErrors?.length &&
      graphQLErrors.find((error) => error.message === errorMsg401))
  ) {
    history.push('/login')
  }
})

const cache = new InMemoryCache({
  possibleTypes: introspectionToPossibleTypes(introspectionQueryResultData),
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink as ApolloLink
)

const apolloClient = new ApolloClient({
  link: from([logoutLink, authMiddleware, link]),
  cache,
})

export default apolloClient
