import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { UpOutlined, MinusOutlined, DownOutlined } from '@ant-design/icons'

import { TPriorityType } from './data'

export const mockPriorityTypeLabelMap: Record<TPriorityType, ReactNode> = {
  low: <T _str="Low" />,
  medium: <T _str="Medium" />,
  high: <T _str="High" />,
}

export const mockPriorityTypeIconMap: Record<TPriorityType, ReactNode> = {
  low: {
    icon: DownOutlined,
    color: '#1890FF',
  },
  medium: {
    icon: MinusOutlined,
    color: '#FFA940',
  },
  high: {
    icon: UpOutlined,
    color: '#FF4D4F',
  },
}
