import React, { FC, ReactNode } from 'react'
import { T } from '@transifex/react'
import PopupLayout1 from '../../Layout'
import { routingMap } from '../../../../../reducers/routing/routing-map'
import { getCellTicketNameWithTitle } from '../../../../cells/getters/TicketName'
import { TPopupPickupOrderDetailProps, TStopProps } from '..'
import StopView from './StopView'

export type TStopType = 'container' | 'ticket' | 'depot'

// eslint-disable-next-line consistent-return
export const getTypeAndData = (
  data: $TSFixMe
): { type?: TStopType; data?: $TSFixMe; title?: ReactNode; href?: string } => {
  if (data?.ticket?.id)
    return {
      type: 'ticket',
      data: { ...data, container: data?.container ?? data?.ticket?.container },
      title: getCellTicketNameWithTitle(data?.ticket),
      href: routingMap.app.tickets['/:id'](data?.ticket?.id)._,
    }
  if (data?.depot?.id)
    return {
      type: 'depot',
      data,
      title: data?.depot.name,
      href: routingMap.app['fleet-management'].locations[':locationId'](
        data?.depot?.id
      )._,
    }
  if (data?.container?.id)
    return {
      type: 'container',
      data,
      title: (
        <T
          _str="Container {containerId}"
          containerId={data?.container?.containerId}
        />
      ),
      href: routingMap.app['asset-management'].containers[':containerId'](
        data?.container?.id
      )._,
    }
  return {}
}

const Stop: FC<TPopupPickupOrderDetailProps & TStopProps> = ({
  onClose = () => {},
  props: { id, isNext },
}) => {
  return (
    <PopupLayout1 onClose={onClose} title={<T _str="Stop" />}>
      <StopView id={id} isNext={isNext} />
    </PopupLayout1>
  )
}

export default Stop
