import { gql } from '@apollo/client'

const FrgCompanyBillingInformationType = gql`
  fragment FrgCompanyBillingInformationType on BillingInformationType {
    name
    address
    address2
    city
    invoiceEmail
    vatNumber
    zipCode
    invoicePhone
    country
  }
`

export const UPDATE_BILLING_INFORMATION = gql`
  ${FrgCompanyBillingInformationType}
  mutation UpdateBillingInformation(
    # args for the company
    $companyId: ID!
    # args for the billing information
    $address: String!
    $address2: String!
    $city: String!
    $country: String!
    $zipCode: String!
    $invoiceEmail: String!
    $invoicePhone: String!
    $name: String!
    $vatNumber: String!
  ) {
    updateBillingInformation(
      companyId: $companyId
      address: $address
      address2: $address2
      city: $city
      country: $country
      invoiceEmail: $invoiceEmail
      invoicePhone: $invoicePhone
      name: $name
      vatNumber: $vatNumber
      zipCode: $zipCode
    ) {
      billingInformation {
        ...FrgCompanyBillingInformationType
      }
    }
  }
`

export const GET_BILLING_INFORMATION_QUERY = gql`
  ${FrgCompanyBillingInformationType}
  query GetCompanyShippingInformationForUpdateForm($id: ID!) {
    company(id: $id) {
      billinginformation {
        ...FrgCompanyBillingInformationType
      }
    }
  }
`
