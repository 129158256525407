import React, { ReactNode, FC, useEffect } from 'react'
import {
  Row,
  Col,
  Space,
  DatePicker,
  Switch,
  SwitchProps,
  Form,
  Input,
  Select,
  DatePickerProps,
} from 'antd'
import moment from 'moment'
import { RcFile } from 'antd/lib/upload'
import styled from 'styled-components'
import { T } from '@transifex/react'
import UploadMultipleWithButton from '@wastehero/storybook/lib/components/Upload/UploadMultipleWithButton'
import { Typography } from '@wastehero/storybook/lib/components'

import { jsxFieldRequried } from '../../../../utils/yup-validation'
import { formatPayment } from '../../../../utils/currency'
import {
  ticketTicketPriorityChoicesIconMap,
  ticketTicketPriorityChoicesSelectOptions,
} from '../../../../api/consts'
import { SelectContainer, SelectDepots } from '../../../select'
import { Opt } from '../../../../ui/options'
import {
  paymentIntervalsLabelMapPerInterval,
  TPaymentIntervals,
} from '../../../../consts'

// eslint-disable-next-line react/no-unused-prop-types
export type TFieldProps = {
  disabled?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  prefixName?: (string | number)[]
}

export const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`

const DatePickerSimpleString = ({
  value,
  onChange,
  inputFormat,
  ...rest
}: Omit<DatePickerProps, 'onChange' | 'value'> & {
  value?: string
  onChange?: (value?: string) => void
  inputFormat?: string
}) => (
  <DatePicker
    {...rest}
    value={value ? moment(value, inputFormat) : undefined}
    onChange={(_v, dateString) => onChange?.(dateString || undefined)}
  />
)

const SwitchDateAsap = (p: SwitchProps) => (
  <Space size="small" align="start">
    <T _str="As soon as possible" />
    <Switch size="small" {...p} />
  </Space>
)

const SizingWithFormItem: FC<{ height?: number }> = ({
  children,
  height = 32,
}) => (
  <div style={{ height, display: 'flex', alignItems: 'center' }}>
    {children}
  </div>
)

const RowColSpaceBetween: FC<{ label: ReactNode }> = ({ children, label }) => (
  <Row justify="space-between" align="top">
    <Col>
      <SizingWithFormItem>{label}</SizingWithFormItem>
    </Col>
    <Col>{children}</Col>
  </Row>
)

export type TServiceFeeSchema = {
  removeServiceFee: boolean
  data: {
    serviceFee: {
      amount: number
      currency: string
      recurringInterval: TPaymentIntervals
    }
  }
}

export const ServiceFee = ({ disabled }: TFieldProps) => {
  const serviceFee = Form.useWatch(['data', 'serviceFee']) || {}

  return (
    <RowColSpaceBetween label={<T _str="Service fee" />}>
      <Space size="middle">
        <SizingWithFormItem>
          {!!serviceFee.amount && (
            <Typography strong>
              {formatPayment(serviceFee.amount, serviceFee.currency)}{' '}
              {serviceFee.recurringInterval && (
                <>
                  /{' '}
                  {
                    paymentIntervalsLabelMapPerInterval[
                      serviceFee.recurringInterval as TPaymentIntervals
                    ]
                  }
                </>
              )}
            </Typography>
          )}
          <Form.Item hidden name={['data', 'serviceFee']} />
        </SizingWithFormItem>
        <Space>
          <SizingWithFormItem>
            <T _str="Remove fee" />
          </SizingWithFormItem>
          <FormItem name="removeServiceFee" valuePropName="checked">
            <Switch size="small" disabled={disabled} />
          </FormItem>
        </Space>
      </Space>
    </RowColSpaceBetween>
  )
}

export type TDescriptionSchema = { description: string }

export const Description = ({ disabled, prefixName = [] }: TFieldProps) => {
  return (
    <Form.Item
      label={<T _str="Description" />}
      name={[...prefixName, 'description']}
    >
      <Input.TextArea rows={4} disabled={disabled} />
    </Form.Item>
  )
}

export type TDateSchema = {
  date: {
    dateASAP?: boolean
    date?: string
  }
}

const DateDatePicker = ({ disabled }: TFieldProps) => {
  const dateASAP = Form.useWatch(['date', 'dateASAP'])
  const date = Form.useWatch(['date', 'date'])
  const form = Form.useFormInstance()

  useEffect(() => {
    if (dateASAP) {
      // remove the value
      form.setFields([
        {
          name: ['date', 'date'],
          value: null,
        },
      ])
    }
  }, [dateASAP, form])

  return (
    <FormItem
      name={['date', 'date']}
      rules={[{ required: !dateASAP, message: jsxFieldRequried }]}
      dependencies={['date', 'dateASAP']}
    >
      <DatePickerSimpleString
        disabled={disabled || dateASAP}
        inputFormat="YYYY-MM-DD"
        value={date}
        onChange={(v) => {
          form.setFields([
            {
              name: ['date', 'date'],
              value: v as string,
            },
          ])
        }}
      />
    </FormItem>
  )
}

export const Date = ({ disabled }: TFieldProps) => {
  return (
    <RowColSpaceBetween label={<T _str="Prefered date" />}>
      <Space size="middle" align="start">
        <DateDatePicker disabled={disabled} />
        <FormItem name={['date', 'dateASAP']} valuePropName="checked">
          <SwitchDateAsap disabled={disabled} />
        </FormItem>
      </Space>
    </RowColSpaceBetween>
  )
}

export type TPrioritySchema = {
  priority: string
}

export const Priority = ({ disabled, prefixName = [] }: TFieldProps) => (
  <RowColSpaceBetween label={<T _str="Priority" />}>
    <FormItem
      name={[...prefixName, 'priority']}
      rules={[{ required: true, message: jsxFieldRequried }]}
    >
      <Select
        disabled={disabled}
        placeholder={<T _str="Priority" />}
        style={{ minWidth: 150 }}
        options={ticketTicketPriorityChoicesSelectOptions.map(
          ({ label, value }) => {
            const Icon = ticketTicketPriorityChoicesIconMap[value]
            return {
              label: Icon ? (
                <Space>
                  <Icon />
                  {label}
                </Space>
              ) : (
                label
              ),
              value,
            }
          }
        )}
      />
    </FormItem>
  </RowColSpaceBetween>
)

export type TAttachmentsSchema = {
  attachments: RcFile[]
}

export const Attachments = ({ disabled, prefixName = [] }: TFieldProps) => (
  <Form.Item
    name={[...prefixName, 'attachments']}
    valuePropName="fileList"
    getValueFromEvent={({ fileList }: $TSFixMe) => fileList}
  >
    <UploadMultipleWithButton beforeUpload={() => false} disabled={disabled} />
  </Form.Item>
)

export type TSelectContainerTypeSchema = {
  containerType: string
  data: {
    containerTypeOptions: Omit<Opt, 'Icon'>[]
    containerTypeDisabled?: boolean
  }
}

export const SelectContainerType = ({ disabled }: TFieldProps) => {
  const options = Form.useWatch(['data', 'containerTypeOptions'])
  const containerTypeDisabled = Form.useWatch(['data', 'containerTypeDisabled'])

  return (
    <RowColSpaceBetween label={<T _str="Container type" />}>
      <Form.Item
        name="containerType"
        rules={[{ required: true, message: jsxFieldRequried }]}
        style={{ margin: 0 }}
      >
        <Select
          disabled={disabled || containerTypeDisabled}
          style={{ width: 300 }}
          options={options}
        />
      </Form.Item>
      <Form.Item hidden name={['data', 'containerTypeOptions']} />
      <Form.Item hidden name={['data', 'containerTypeDisabled']} />
    </RowColSpaceBetween>
  )
}

export type TSelectPickupSettingSchema = {
  pickupSetting: string
  data: {
    pickupSettingOptions: Omit<Opt, 'Icon'>[]
    pickupSettingDisabled?: boolean
  }
}

export const SelectPickupSetting = ({ disabled }: TFieldProps) => {
  const options = Form.useWatch(['data', 'pickupSettingOptions'])
  const pickupSettingDisabled = Form.useWatch(['data', 'pickupSettingDisabled'])
  return (
    <RowColSpaceBetween label={<T _str="Pickup setting" />}>
      <Form.Item
        name="pickupSetting"
        rules={[{ required: true, message: jsxFieldRequried }]}
        style={{ margin: 0 }}
      >
        <Select
          disabled={disabled || pickupSettingDisabled}
          style={{ width: 300 }}
          options={options}
        />
      </Form.Item>
      <Form.Item hidden name={['data', 'pickupSettingOptions']} />
      <Form.Item hidden name={['data', 'pickupSettingDisabled']} />
    </RowColSpaceBetween>
  )
}

export type TSelectWasteFractionSchema = {
  wasteFraction: string
  data: {
    wasteFractionOptions: Omit<Opt, 'Icon'>[]
    wasteFractionDisabled?: boolean
  }
}

export const SelectWasteFraction = ({ disabled }: TFieldProps) => {
  const options = Form.useWatch(['data', 'wasteFractionOptions'])
  const wasteFractionDisabled = Form.useWatch(['data', 'wasteFractionDisabled'])

  return (
    <RowColSpaceBetween label={<T _str="Waste Fraction" />}>
      <Form.Item
        name="wasteFraction"
        rules={[{ required: true, message: jsxFieldRequried }]}
        style={{ margin: 0 }}
      >
        <Select
          disabled={disabled || wasteFractionDisabled}
          style={{ width: 300 }}
          options={options}
        />
      </Form.Item>
      <Form.Item hidden name={['data', 'wasteFractionOptions']} />
      <Form.Item hidden name={['data', 'wasteFractionDisabled']} />
    </RowColSpaceBetween>
  )
}

export type TSelectWasteFractionsSchema = {
  wasteFractions: string[]
  data: {
    wasteFractionsOptions: Omit<Opt, 'Icon'>[]
    wasteFractionsDisabled?: boolean
  }
}

export const SelectWasteFractions = ({ disabled }: TFieldProps) => {
  const options = Form.useWatch(['data', 'wasteFractionsOptions'])
  const wasteFractionsDisabled = Form.useWatch([
    'data',
    'wasteFractionsDisabled',
  ])
  return (
    <RowColSpaceBetween label={<T _str="Waste Fraction" />}>
      <Form.Item
        name="wasteFractions"
        rules={[{ required: true, message: jsxFieldRequried }]}
        style={{ margin: 0 }}
      >
        <Select
          disabled={disabled || wasteFractionsDisabled}
          style={{ width: 300 }}
          mode="multiple"
          options={options}
        />
      </Form.Item>
      <Form.Item hidden name={['data', 'wasteFractionsOptions']} />
      <Form.Item hidden name={['data', 'wasteFractionsDisabled']} />
    </RowColSpaceBetween>
  )
}
export type TChangeContainerTypeContainerSourceSchema = {
  containerSource: {
    depot: string
    containerToAdd: string
  }
}

export type TContainerInfoProps = {
  pickupSetting: string
  wasteFraction: string
  containerType: string
}

export const ChangeContainerTypeContainerSource = ({
  disabled,
  pickupSetting,
  wasteFraction,
}: TFieldProps &
  Pick<TContainerInfoProps, 'pickupSetting' | 'wasteFraction'>) => {
  const depot = Form.useWatch(['containerSource', 'depot'])
  const containerType = Form.useWatch(['containerType'])
  return (
    <RowColSpaceBetween label={<T _str="Container source" />}>
      <Space direction="vertical">
        <FormItem
          name={['containerSource', 'depot']}
          label={<T _str="Depot" />}
        >
          <SelectDepots
            style={{ width: 300 }}
            disabled={disabled}
            variables={{
              type: 'container_storage',
            }}
          />
        </FormItem>
        <FormItem
          name={['containerSource', 'containerToAdd']}
          label={<T _str="Container" />}
        >
          <SelectContainer
            style={{ width: 300 }}
            disabled={disabled || !depot || !containerType}
            variables={{
              storedAtDepot: depot ? [depot] : [],
              containerTypes: [containerType],
              pickupSetting: [pickupSetting],
              wasteFractionIds: wasteFraction,
            }}
          />
        </FormItem>
      </Space>
    </RowColSpaceBetween>
  )
}

export type TAddContainerFormContainerSourceSchema = {
  containerSource: {
    depot: string
    containerToAdd: string
  }
}

export const AddContainerFormContainerSource = ({ disabled }: TFieldProps) => {
  const depot = Form.useWatch(['containerSource', 'depot'])
  const containerType = Form.useWatch(['containerType'])
  const pickupSetting = Form.useWatch(['pickupSetting'])
  const wasteFraction = Form.useWatch(['wasteFraction'])
  const fieldDisabled = !containerType || !pickupSetting || !wasteFraction
  return (
    <RowColSpaceBetween label={<T _str="Container source" />}>
      <Space direction="vertical">
        <FormItem
          name={['containerSource', 'depot']}
          label={<T _str="Depot" />}
        >
          <SelectDepots
            style={{ width: 300 }}
            disabled={disabled || fieldDisabled}
            variables={{
              type: 'container_storage',
            }}
          />
        </FormItem>
        <FormItem
          name={['containerSource', 'containerToAdd']}
          label={<T _str="Container" />}
        >
          <SelectContainer
            style={{ width: 300 }}
            disabled={disabled || fieldDisabled || !depot}
            variables={{
              storedAtDepot: depot ? [depot] : [],
              containerTypes: [containerType],
              pickupSetting: [pickupSetting],
              wasteFractionIds: wasteFraction,
            }}
          />
        </FormItem>
      </Space>
    </RowColSpaceBetween>
  )
}

export type TContainerDestinationSchema = {
  containerDestination: {
    depot: string
  }
}

export const ContainerDestination = ({ disabled }: TFieldProps) => {
  return (
    <RowColSpaceBetween label={<T _str="Container destination" />}>
      <FormItem
        name={['containerDestination', 'depot']}
        label={<T _str="Depot" />}
      >
        <SelectDepots
          style={{ width: 300 }}
          disabled={disabled}
          variables={{
            type: 'container_storage',
          }}
        />
      </FormItem>
    </RowColSpaceBetween>
  )
}

export type TNewContainerIdSchema = {
  newContainerID: string
}

export const NewContainerId = ({ disabled, ...rules }: TFieldProps) => (
  <RowColSpaceBetween label={<T _str="New container ID" />}>
    <FormItem name="newContainerID" {...rules}>
      <Input disabled={disabled} />
    </FormItem>
  </RowColSpaceBetween>
)
