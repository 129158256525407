import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useModalWithButton } from '@wastehero/storybook/lib/components'
import { T } from '@transifex/react'
import { Tag, Space } from 'antd'

import { useTableUI, Datum } from './ui'
import { GET_PROPERTY_AGREEMENTS, TResponse, TVariables } from './api'
import { useFiltersContext } from '../filters.context'

import { useTablePaginationAndSorterAPIHelperPersistent } from '../../../../../../../../../../hooks'
import { DebouncedSearch } from '../../../../../../../../../../ui'
import { useCreateRequestFormModal } from '../../../../../../components/create-request'

import ChangesHistory from '../../../../../../components/changeHistory'

import { useCustomerContext } from '../../../../customer.context'
import {
  Link,
  useRoutingHistory,
} from '../../../../../../../../../../reducers/routing/child-router-factory'
import { formatPayment } from '../../../../../../../../../../utils/currency'
import {
  convertFromMonthlyNumeric,
  paymentIntervalsLabelMapPerInterval,
} from '../../../../../../../../../../consts'

const formatDatumContainer = ({
  container: {
    id: containerMainId,
    containerId,
    containerType,
    latestPickUp,
    nextPickUp,
    pickupSetting,
    wasteFraction: { name: wasteFractionName },
  },
  containerPrice: { amount, recurringInterval },
  prices: { changeContainerType, changePickupSetting },
  id,
}: TResponse['property']['agreements']['edges'][0]['node']): Datum => {
  return {
    id,
    last: latestPickUp,
    next: nextPickUp,
    name: (
      <Space>
        <Link
          to={({ routingMap }) =>
            routingMap.app['customer-management'].assets['/:id'](
              containerMainId
            )._
          }
        >
          {containerId}
        </Link>
        <Tag>{wasteFractionName}</Tag>
      </Space>
    ),
    price: (
      <Space size={4}>
        {formatPayment(amount / 100, 'dkk')}/
        {
          paymentIntervalsLabelMapPerInterval[
            convertFromMonthlyNumeric[recurringInterval]
          ]
        }
      </Space>
    ),
    type: {
      value: containerType.id,
      prices: changeContainerType.map(
        ({ price, containerType: { id: id1, name } }) => ({
          id: id1,
          name,
          price: price.amount / 100,
          interval: convertFromMonthlyNumeric[price.recurringInterval],
        })
      ),
    },
    pickupScheme: {
      value: pickupSetting.id,
      prices: changePickupSetting.map(
        ({ price, pickupSetting: { id: id1, name } }) => ({
          id: id1,
          name,
          price: price.amount / 100,
          interval: convertFromMonthlyNumeric[price.recurringInterval],
        })
      ),
    },
    actions: ['addRequest', 'extraEmptying', 'showHistory'],
    _data: {
      containerId: containerMainId,
    },
  }
}

const formatDatumNonContainer = ({
  recurringPrice: { amount, recurringInterval, name },
  id,
}: TResponse['property']['agreements']['edges'][0]['node']): Datum => {
  return {
    id,
    name,
    price: (
      <>
        {formatPayment(amount / 100, 'dkk')} /
        {
          paymentIntervalsLabelMapPerInterval[
            convertFromMonthlyNumeric[recurringInterval]
          ]
        }
      </>
    ),
    actions: [],
    _data: {
      containerId: '',
    },
  }
}

const formatDatum = (
  node: TResponse['property']['agreements']['edges'][0]['node']
): Datum => {
  if (node.container) return formatDatumContainer(node)
  return formatDatumNonContainer(node)
}

const useTable = () => {
  const [history, { routingMap }] = useRoutingHistory()
  const { id: propertyId } = useCustomerContext()
  /* Create Request Modal */
  const {
    modal: jsxCreateRequestFormModal,
    openModal: openCreateRequestFormModal,
  } = useCreateRequestFormModal({ type: 'container' })

  /* Filters */
  const [{ containerType, pickUpScheme, wasteFraction }] = useFiltersContext()
  const [search, setSearch] = useState('')

  // eslint-disable-next-line no-empty-pattern

  const {
    paginationVars: { first, offset },
    onTableChangeHandler,
    state: { pageSize, page },
  } = useTablePaginationAndSorterAPIHelperPersistent<Datum>(
    `useTablePaginationAndSorterAPIHelperPersistent-customer-management__customers-overview__customer__overview__components__table-agreements__table`,
    {
      pageSize: 20,
      order: '',
      orderBy: '',
    }
  )

  /* API */
  const { data: { property } = {}, loading } = useQuery<TResponse, TVariables>(
    GET_PROPERTY_AGREEMENTS,
    {
      variables: {
        first,
        offset,
        search,
        propertyId,
        wasteFraction,
        containerType,
        pickupSetting: pickUpScheme,
      },
    }
  )

  const propertyCompanyId = property?.company?.id

  const {
    jsx: { modal: jsxChangesHistoryModal },
    utils: { openModal: openChangesHistoryModal },
  } = useModalWithButton({
    children: () => <ChangesHistory />,
    modelProps: {
      title: <T _str="Change History" />,
      width: 1200,
      footer: null,
      bodyStyle: {
        padding: 0,
      },
    },
  })

  const agreements = property?.agreements.edges.map(({ node }) =>
    formatDatum(node)
  )

  const createVars = (containerId: string) => ({
    company: propertyCompanyId || ``,
    container: containerId,
    property: propertyId,
    ticketType: '',
  })
  const onSuccess = ({ id: newTicketId }: { id: string }) =>
    history.push(routingMap.app.tickets['/:id'](newTicketId)._)

  /* UI filters columns */
  const { table, toggler } = useTableUI({
    loading,
    onChange: onTableChangeHandler,
    dataSource: agreements,
    pagination: {
      total: 20,
      pageSize,
      current: page,
      style: {
        padding: `0 1rem`,
      },
      hideOnSinglePage: true,
    },
    scroll: {
      y: '100%',
      x: 1000,
    },
    on: {
      addRequest: ({ _data: { containerId } }) => {
        openCreateRequestFormModal({
          id: containerId,
          form: {
            type: 'EXTRA_EMPTYING',
            onSuccess,
            containerId: createVars(containerId).container,
            propertyId: createVars(containerId).property,
          },
        })
      },
      info: () => {},
      extraEmptying: ({ _data: { containerId } }) => {
        openCreateRequestFormModal({
          id: containerId,
          form: {
            type: 'EXTRA_EMPTYING',
            onSuccess,
            containerId: createVars(containerId).container,
            propertyId: createVars(containerId).property,
          },
        })
      },
      showHistory: () => {
        openChangesHistoryModal()
      },
    },
    extended: {
      changeContainerType: (toType, { _data }) =>
        openCreateRequestFormModal({
          id: _data.containerId,
          form: {
            type: 'CHANGE_CONTAINER_TYPE', // Will be replaced with Extra emptying.
            onSuccess,
            containerId: createVars(_data.containerId).container,
            propertyId: createVars(_data.containerId).property,
            initialValues: { containerType: toType },
          },
        }),
      changePickupScheme: (toType, { _data: { containerId } }) =>
        openCreateRequestFormModal({
          id: containerId,
          form: {
            type: 'CHANGE_CONTAINER_PICKUP_SETTING', // Will be replaced with Extra emptying.
            onSuccess,
            containerId: createVars(containerId).container,
            propertyId: createVars(containerId).property,
            initialValues: {
              pickupSetting: toType,
            },
          },
        }),
    },
  })

  return {
    table: (
      <>
        {table}
        {jsxCreateRequestFormModal}
        {jsxChangesHistoryModal}
      </>
    ),
    columnsFilterAndOrder: toggler,
    search: (
      <DebouncedSearch debounceChange={(evt) => setSearch(evt.target.value)} />
    ),
  }
}

export default useTable
