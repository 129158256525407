export type TCellTicketLocationProps = {
  value: {
    location?: {
      name: string
    }
    container?: {
      location?: {
        name: string
      }
    }
    property?: {
      location?: {
        name: string
      }
    }
  }
}

export const getCellTicketLocation = ({
  value: { container, location, property },
}: TCellTicketLocationProps) => {
  return location?.name || container?.location?.name || property?.location?.name
}
