import React, { FC } from 'react'

import {
  FormSectionVerticalWithDividers,
  TFormSectionVerticalWithDividersProps,
} from '../../../../ui/FormSectionVerticalWithDividers'

import {
  Description,
  ServiceFee,
  TDescriptionSchema,
  TServiceFeeSchema,
  Date,
  TDateSchema,
  Attachments,
  TAttachmentsSchema,
  TFieldProps,
  SelectContainerType,
  TSelectContainerTypeSchema,
  SelectPickupSetting,
  TSelectPickupSettingSchema,
  SelectWasteFraction,
  TSelectWasteFractionSchema,
  SelectWasteFractions,
  TSelectWasteFractionsSchema,
  Priority,
  TPrioritySchema,
  ChangeContainerTypeContainerSource,
  TChangeContainerTypeContainerSourceSchema,
  NewContainerId,
  TNewContainerIdSchema,
  ContainerDestination,
  TContainerDestinationSchema,
  AddContainerFormContainerSource,
  TAddContainerFormContainerSourceSchema,
  TContainerInfoProps,
} from './form-items'
import { TCrmServiceServiceTypeChoices } from '../../../../api/consts'
import { array } from '../../../../utils/array'

import {
  TicketPricingOptionsAddContainer,
  TicketPricingOptionsChangeContainerType,
  TicketPricingOptionsChangePickupSetting,
  TicketPricingOptionsBulkWastePickup,
  TicketPricingOptionsOtherContainerLevel,
  TicketPricingOptionsOtherPropertyLevel,
} from './TicketPricing'
import { TPricingHandlerProps } from './TicketPricing/types'

type TConfig = {
  editMode: boolean
}

export type TBaseFormSchema = TDescriptionSchema &
  TServiceFeeSchema &
  TDateSchema &
  TAttachmentsSchema &
  TPrioritySchema &
  Partial<
    TSelectContainerTypeSchema &
      TSelectPickupSettingSchema &
      TSelectWasteFractionSchema &
      TSelectWasteFractionsSchema &
      TChangeContainerTypeContainerSourceSchema &
      TNewContainerIdSchema &
      TContainerDestinationSchema &
      TAddContainerFormContainerSourceSchema
  >

export type TLevel = 'container' | 'property'

export type Field =
  | 'serviceFee'
  | 'description'
  | 'date'
  | 'priority'
  | 'attachments'
  | 'pricing'
  | 'serviceTypeAddon'

export type TBaseFormProps = {
  disabled?: boolean
  serviceType?: TCrmServiceServiceTypeChoices
  config: TConfig
  /** only pass when serviceType is CHANGE_CONTAINER_TYPE. Used by ChangeContainerTypeContainerSource */
  containerInfo?: TContainerInfoProps
  /** addonPreFields */
  addonPreFields?: TFormSectionVerticalWithDividersProps['fields']
  /** level */
  level?: TLevel
  /** fields to exclude */
  excludeFields?: Field[]
  prefixName?: (string | number)[]
} & Omit<TPricingHandlerProps, 'containerInfo'>
export const addonFieldsByServiceType: Partial<
  Record<TCrmServiceServiceTypeChoices, FC<TFieldProps & TContainerInfoProps>[]>
> = {
  CHANGE_CONTAINER_TYPE: [
    SelectContainerType,
    ChangeContainerTypeContainerSource,
    NewContainerId,
    ContainerDestination,
  ],
  CHANGE_CONTAINER_PICKUP_SETTING: [SelectPickupSetting],
  BULK_WASTE_PICKUP: [SelectWasteFractions],
  ADD_CONTAINER: [
    SelectContainerType,
    SelectWasteFraction,
    SelectPickupSetting,
    AddContainerFormContainerSource,
    NewContainerId,
  ],
  REMOVE_CONTAINER: [ContainerDestination],
}

const addonTicketPricingMap: Partial<
  Record<TCrmServiceServiceTypeChoices | TLevel, FC<TPricingHandlerProps>>
> = {
  ADD_CONTAINER: TicketPricingOptionsAddContainer,
  CHANGE_CONTAINER_TYPE: TicketPricingOptionsChangeContainerType,
  CHANGE_CONTAINER_PICKUP_SETTING: TicketPricingOptionsChangePickupSetting,
  BULK_WASTE_PICKUP: TicketPricingOptionsBulkWastePickup,
  EXTRA_EMPTYING: TicketPricingOptionsOtherContainerLevel,
  REMOVE_CONTAINER: TicketPricingOptionsOtherContainerLevel,
  container: TicketPricingOptionsOtherContainerLevel,
  property: TicketPricingOptionsOtherPropertyLevel,
}

export const BaseForm = ({
  disabled,
  serviceType,
  config,
  containerInfo,
  addonPreFields = [],
  containerId,
  propertyId,
  ticketTypeId,
  level,
  excludeFields = [],
  prefixName,
}: TBaseFormProps) => {
  const { editMode } = config
  const Pricing =
    // eslint-disable-next-line no-nested-ternary
    !level || excludeFields.includes('pricing')
      ? undefined
      : serviceType
      ? addonTicketPricingMap[serviceType]
      : addonTicketPricingMap[level]

  return (
    <div>
      {Pricing && (
        <Pricing
          containerInfo={containerInfo as TContainerInfoProps}
          containerId={containerId}
          propertyId={propertyId}
          ticketTypeId={ticketTypeId}
        />
      )}
      <FormSectionVerticalWithDividers
        fields={[
          ...addonPreFields,
          ...array<FC<TFieldProps & TContainerInfoProps> | JSX.Element>(
            !excludeFields.includes('serviceTypeAddon') &&
              serviceType &&
              addonFieldsByServiceType[serviceType],
            array<FC<TFieldProps>>(
              !excludeFields.includes('date') && Date,
              !editMode && !excludeFields.includes('priority') && Priority,
              !excludeFields.includes('serviceFee') && ServiceFee,
              !excludeFields.includes('description') && Description,
              !excludeFields.includes('attachments') && Attachments
            )
          ).map((C) => {
            if (typeof C === 'function')
              return (
                <C
                  prefixName={prefixName}
                  {...(containerInfo as TContainerInfoProps)}
                  disabled={disabled}
                />
              )
            return C
          }),
        ]}
      />
    </div>
  )
}
