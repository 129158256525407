import React, { FC } from 'react'
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { T } from '@transifex/react'
import moment from 'moment'
import StyledTag from '@wastehero/storybook/lib/components/StyledTag'
import { Typography } from '@wastehero/storybook/lib/components'
import { Row, Col, Divider } from 'antd'
import { getStyledTagProps } from '../../../../../api/helpers'
import { routingMap } from '../../../../../reducers/routing/routing-map'
import { GET_CONTAINER_GROUP_MAP_NODE, GET_MAP_PICKUP_ORDERS } from '../api'
import { TContainerGroupProps } from '..'

const ContainerGroupDetail: FC<
  TContainerGroupProps['props'] & {
    isNext?: boolean
    onSelect: (pickupOrderId: string) => void
    nextStopId?: string
  }
> = ({ ids, groupId, onSelect, nextStopId }) => {
  const { data: dataPickupOrders } = useQuery(GET_MAP_PICKUP_ORDERS, {
    variables: { orderIds: ids },
  })

  const { data, loading } = useQuery(GET_CONTAINER_GROUP_MAP_NODE, {
    variables: { id: groupId },
  })

  const pickupOrdersSorted = dataPickupOrders
    ? [...dataPickupOrders?.allPickupOrders?.edges].sort(
        (a: $TSFixMe, b: $TSFixMe) => a.node.stopNumber - b.node.stopNumber
      )
    : []

  const firstPickupOrder = pickupOrdersSorted[0]?.node

  const { name, location } = data?.containerGroup || {}

  return (
    <Row gutter={[0, 8]}>
      <Col xs={24}>
        <Row gutter={0}>
          {firstPickupOrder && (
            <Col>
              <StyledTag
                style={{
                  transform: `translate(-3px, 3px)`,
                  boxShadow: '3px -3px 0px 0px #bfbfbf',
                  width: 28,
                  height: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 4,
                }}
                {...getStyledTagProps(
                  firstPickupOrder,
                  nextStopId === firstPickupOrder?.id
                )}
              >
                {firstPickupOrder?.stopNumber}
              </StyledTag>
            </Col>
          )}
          <Col span={21}>
            <Typography
              ellipsis
              loading={loading}
              component="Link"
              href={
                routingMap.app['asset-management']['container-group']['/:id'](
                  groupId
                )._
              }
              weight="semi-bold"
              variant="body-14"
            >
              {name}
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row gutter={8} align="middle">
          <Col>
            <EnvironmentOutlined style={{ fontSize: 14, color: '#BFBFBF' }} />
          </Col>
          <Col>
            <Typography loading={loading} variant="body-14">
              {location?.name}
            </Typography>
          </Col>
        </Row>
      </Col>
      {firstPickupOrder?.scheduledDriverArrival && (
        <Col xs={24}>
          <Row gutter={8} align="middle">
            <Col>
              <ClockCircleOutlined style={{ fontSize: 14, color: '#BFBFBF' }} />
            </Col>
            <Col>
              <Typography loading={loading} variant="body-14">
                <T
                  _str="Scheduled at {scheduledDriverArrival}"
                  scheduledDriverArrival={moment(
                    firstPickupOrder?.scheduledDriverArrival,
                    'hh:mm:ss'
                  ).format('LLL')}
                />
              </Typography>
            </Col>
          </Row>
        </Col>
      )}
      <Col xs={24}>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      </Col>
      <Col xs={24}>
        <Typography
          style={{ color: '#8C8C8C' }}
          loading={loading}
          variant="body-14"
        >
          <T
            _str="All containers {containersCounts}"
            containersCounts={`(${ids.length})`}
          />
        </Typography>
      </Col>
      <Col xs={24}>
        <Row gutter={[0, 8]}>
          {pickupOrdersSorted.map(({ node }: $TSFixMe, indx) => (
            <>
              <Col
                onClick={() => onSelect(node.id)}
                style={{ cursor: 'pointer' }}
                xs={24}
              >
                <Row align="middle">
                  <Col>
                    <StyledTag
                      style={{
                        width: 28,
                        height: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      {...getStyledTagProps(node, node.id === nextStopId)}
                    >
                      {node.stopNumber}
                    </StyledTag>
                  </Col>
                  <Col>
                    <Typography style={{ color: '#1890FF' }} variant="body-14">
                      <T
                        _str="Container {containerId}"
                        containerId={node.container?.containerId}
                      />
                    </Typography>
                  </Col>
                  <Col style={{ marginLeft: 'auto' }}>
                    <RightOutlined />
                  </Col>
                </Row>
              </Col>
              {indx !== pickupOrdersSorted?.length - 1 && (
                <Col xs={24}>
                  <Divider style={{ margin: 0 }} />
                </Col>
              )}
            </>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default ContainerGroupDetail
