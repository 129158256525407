import React, { ReactNode, CSSProperties } from 'react'
import { T } from '@transifex/react'
import {
  PlusOutlined,
  ScheduleOutlined,
  HistoryOutlined,
} from '@ant-design/icons'
import { Space } from 'antd'

import {
  createTableUIStatefulColumns,
  CreateTableUIStatefulColumnsArgs,
  getSimpleColumn,
  getDateColumn,
  getActionsBtns,
  getNullableColumn,
  getSpaceColumn,
  getDropdownSimpleWithRadioBtns,
} from '@wastehero/storybook/lib/components/table-kit'

import { TPaymentIntervals } from '../../../../../../../../../../consts'
import { generatePriceChangeOptions } from '../../../../../../utils'

type TActions = 'addRequest' | 'info' | `showHistory` | `extraEmptying`

type TPriceSet = {
  price: number
  id: string
  name: string
  interval: TPaymentIntervals
}
type TType = {
  value: string
  prices: TPriceSet[]
}

type TPickupScheme = {
  value: string
  prices: TPriceSet[]
}

export type Datum = {
  id: string
  name: ReactNode
  type?: TType
  price: ReactNode
  last?: string
  next?: string
  pickupScheme?: TPickupScheme
  actions: TActions[]
  _data: {
    containerId: string
  }
}
const simpleColumn = getSimpleColumn()
const simpleDateColumn = getDateColumn('YYYY-MM-DD')

type ColumnKey = keyof Omit<Datum, 'id' | 'actions' | '_data'>

type StateFulProps = {
  changeContainerType: (type: string, data: Datum) => void
  changePickupScheme: (type: string, data: Datum) => void
}

type TCreateTableUIStatefulColumnsArgs = CreateTableUIStatefulColumnsArgs<
  Datum,
  ColumnKey,
  TActions,
  StateFulProps
>

const spaceFullWidth: CSSProperties = {
  width: '100%',
}

const columnsRenderMap: TCreateTableUIStatefulColumnsArgs['columnsRenderMap'] =
  ({ changeContainerType, changePickupScheme }) => ({
    last: {
      title: <T _str="Last" />,
      render: simpleDateColumn,
    },
    next: {
      title: <T _str="Next" />,
      render: simpleDateColumn,
    },
    name: {
      title: <T _str="Asset" />,
      render: simpleColumn,
      width: '20%',
      ellipsis: true,
    },
    price: {
      title: <T _str="Price / year" />,
      render: simpleColumn,
    },
    type: {
      title: <T _str="Type" />,
      ellipsis: true,
      width: 180,
      render: getSpaceColumn(
        getNullableColumn(
          getDropdownSimpleWithRadioBtns({
            getOpts: ({ value: currentOpt, prices }: TType) => {
              return generatePriceChangeOptions(
                currentOpt,
                prices.map(({ id, price, name, interval }) => ({
                  id,
                  label: name,
                  price,
                  interval,
                }))
              ).map(({ label, value, labelDiff }) => ({
                label: (
                  <Space size={4}>
                    {label} {labelDiff}
                  </Space>
                ),
                value,
                checked: value === currentOpt,
              }))
            },
            defaultToggler: ({ prices, value }: TType) => ({
              children: prices.find((v) => v.id === value)?.name,
              size: 'small',
            }),
            onSelect: ({ columnArgs, value }) =>
              changeContainerType(value, columnArgs[1]),
          })
        ),
        {
          style: spaceFullWidth,
        }
      ),
    },
    pickupScheme: {
      title: <T _str="Pickup scheme" />,
      ellipsis: true,
      width: 120,
      render: getSpaceColumn(
        getNullableColumn(
          getDropdownSimpleWithRadioBtns({
            getOpts: ({ value: currentOpt, prices }: TType) => {
              return generatePriceChangeOptions(
                currentOpt,
                prices.map(({ id, price, name, interval }) => ({
                  id,
                  label: name,
                  price,
                  interval,
                }))
              ).map(({ label, value, labelDiff }) => ({
                label: (
                  <Space size={4}>
                    {label} {labelDiff}
                  </Space>
                ),
                value,
                checked: value === currentOpt,
              }))
            },
            defaultToggler: ({ prices, value }: TType) => ({
              children: prices.find((v) => v.id === value)?.name,
              size: 'small',
            }),
            onSelect: ({ columnArgs, value }) =>
              changePickupScheme(value, columnArgs[1]),
          })
        ),
        {
          style: spaceFullWidth,
        }
      ),
    },
  })

const { useTableUI } = createTableUIStatefulColumns<
  Datum,
  ColumnKey,
  TActions,
  StateFulProps
>({
  actionsRender: ({ on: { addRequest, extraEmptying, showHistory } }) =>
    getActionsBtns<Datum>({
      btnProps: { size: 'small' },
      spaceProps: {
        size: 'small',
        style: {
          justifyContent: 'end',
          padding: '4px',
          width: '100%',
        },
      },
      actions: [
        {
          handler: extraEmptying,
          btnProps: {
            icon: <ScheduleOutlined />,
            size: 'small',
          },
          cond: ({ actions }) => actions.includes('extraEmptying'),
        },
        {
          handler: addRequest,
          btnProps: {
            icon: <PlusOutlined />,
            size: 'small',
          },
          cond: ({ actions }) => actions.includes('addRequest'),
        },
        {
          handler: showHistory,
          btnProps: {
            icon: <HistoryOutlined />,
            size: 'small',
          },
          cond: ({ actions }) => actions.includes('showHistory'),
        },
      ],
    }),
  tableColumnsTreeFilterAndOrderProps: {
    key: 'CustomersOverview.Customer.Overview.Table',
    init: {
      checked: ['name', 'last', 'next', 'price', 'type', 'pickupScheme'],
      order: ['name', 'type', 'pickupScheme', 'price', 'last', 'next'],
    },
  },
  columnsRenderMap,
})

export { useTableUI }
