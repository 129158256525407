import { T } from '@transifex/react'
import { Typography } from 'antd'
import React, { ReactNode } from 'react'
import { LayoutWithDividerv3 } from '../../../../../../../../layouts'
import { StyledSkeletonInput } from '../../../../../../../../ui/Skeleton'

const { Text } = Typography

type Data = {
  address: ReactNode
}

const titleMap: Map<keyof Data, ReactNode> = new Map()

titleMap.set('address', <T _str="Address" />)

export type THeaderUIProps = {
  data: Data
  loading?: boolean
}

const HeaderUI = ({ data, loading }: THeaderUIProps) => {
  const dataSource: { key: keyof Data; value: ReactNode; title: ReactNode }[] =
    Array.from(titleMap.keys()).map((key) => ({
      title: titleMap.get(key),
      key,
      value: data[key],
    }))

  return (
    <LayoutWithDividerv3
      content={dataSource.map(({ key, title, value }) => (
        <div
          key={key}
          style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}
        >
          <Text type="secondary" style={{ fontSize: '14px' }}>
            {title}
          </Text>
          {loading ? (
            <StyledSkeletonInput
              className="ant-skeleton-width-full"
              size="small"
              active
            />
          ) : (
            <Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {value}
            </Text>
          )}
        </div>
      ))}
      colsRatio={Array(dataSource.length).fill(100 / dataSource.length)}
    />
  )
}

export default HeaderUI
