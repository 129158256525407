import React from 'react'
import { Row, Col, Button, Skeleton } from 'antd'
import styled from 'styled-components'
import { DeleteOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'

const StyledSkeleton = styled(Skeleton)`
  &&& {
    .ant-skeleton-title {
      margin: 0px !important;
    }
  }
`

export type ListEle = {
  title: string
  id: string
  isDefault: boolean
}

type TActions = 'delete' | 'set-default'

export type ListProps = {
  list: ListEle[]
  loading?: boolean
  on: {
    delete: (id: string) => void
    setDefault: (id: string) => void
  }
  actions?: TActions[]
}

const List: React.FC<ListProps> = ({
  list,
  on: { delete: onDelete, setDefault: onSetDefault },
  loading,
  actions = ['set-default', 'delete'],
}) => {
  return (
    <Row>
      {loading &&
        Array(10)
          .fill(0)
          .map(() => {
            return (
              <Col span={24}>
                <div style={{ padding: `5px 0px` }}>
                  <StyledSkeleton paragraph={false} active title />
                </div>
              </Col>
            )
          })}
      {!loading &&
        list.map(({ id, title: eleTitle, isDefault }) => {
          return (
            <Col
              span={24}
              style={{
                borderBottom: `1px solid rgba(0, 0, 0, 0.06)`,
              }}
            >
              <Row
                gutter={20}
                justify="space-between"
                style={{ padding: `8px 0px` }}
              >
                <Col>{eleTitle}</Col>
                <Col>
                  <Row gutter={10}>
                    {actions.includes('set-default') && (
                      <Col>
                        {isDefault && (
                          <Button
                            size="small"
                            type="primary"
                            icon={<StarFilled />}
                          >
                            <>
                              <T _str="Primary" />
                            </>
                          </Button>
                        )}
                        {!isDefault && (
                          <Button
                            size="small"
                            type="default"
                            icon={<StarOutlined />}
                            onClick={() => {
                              onSetDefault(id)
                            }}
                          >
                            <>
                              <T _str="Select" />
                            </>
                          </Button>
                        )}
                      </Col>
                    )}
                    {actions.includes('delete') && (
                      <Col>
                        <Button
                          onClick={() => onDelete(id)}
                          disabled={isDefault}
                          type="text"
                          size="small"
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          )
        })}
    </Row>
  )
}

export default List
