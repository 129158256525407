import React, { ReactNode, useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'

import { Tabs, TabsProps } from 'antd'
import {
  useRouting,
  RoleChecker,
  RouteConfig,
  useRoleChecker,
} from '../reducers/routing/child-router-factory'
import { RoutingMap } from '../reducers/routing/utils'
import { TabNavigation } from './TabNavigation'

const { TabPane } = Tabs

const tabNavigationParamKey = `tabNavigation`

type CreateTabsNavigationProps<
  Keys extends string,
  Params extends Record<string, string>
> = {
  defaultLastActiveKey: Keys
  getParentPath: (routingMap: RoutingMap) => string
  getParentPathWithParams: (routingMap: RoutingMap, params: Params) => string
  noRedirect?: boolean
  getRouteConfig: (routingMap: RoutingMap) => RouteConfig<Keys>[]
  tabTitles: Record<Keys, ReactNode>
  disabled?: Record<Keys, boolean>
  antd?: Omit<TabsProps, 'activeKey'>
}

/**
 * TabsNavigationExtended
 * Renders Antd Tabs with support to navigation routing.
 */
export const TabsNavigationExtended = <
  Keys extends string,
  P extends Record<string, string> = Record<string, string>
>({
  defaultLastActiveKey,
  getParentPath,
  noRedirect = false,
  tabTitles,
  getRouteConfig,
  getParentPathWithParams,
  disabled,
  antd = {},
}: CreateTabsNavigationProps<Keys, P>): JSX.Element => {
  const [checkAccess] = useRoleChecker()
  const [lastActiveKey, setLastActiveKey] = useState<Keys>(defaultLastActiveKey)
  const { routingMap } = useRouting()
  const parentPath = getParentPath(routingMap)
  const params = useParams<P>()
  const parentPathPopulated = getParentPathWithParams(routingMap, params)

  const history = useRouting.useHistory()
  const match = useRouteMatch({
    path: parentPath,
    exact: true,
  })
  useEffect(() => {
    if (match && !noRedirect) {
      history.replace(`${parentPathPopulated}/${lastActiveKey}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match])

  return (
    <Switch>
      <Route path={`${parentPath}/:${tabNavigationParamKey}`}>
        <TabNavigation
          routeKey={tabNavigationParamKey}
          on={{
            change: (key) => {
              setLastActiveKey(key as Keys)
              history.replace(`${parentPathPopulated}/${key}`)
              antd?.onChange?.(key)
            },
          }}
          antd={antd}
        >
          {getRouteConfig(routingMap)
            .filter(({ roles }) => checkAccess(roles))
            .map(({ children, key, roles, fallback, conds }) => (
              <TabPane
                tab={tabTitles[key]}
                key={key}
                disabled={disabled ? disabled[key] : false}
              >
                <RoleChecker
                  allowedRoles={roles}
                  fallback={fallback}
                  conds={conds}
                >
                  {children}
                </RoleChecker>
              </TabPane>
            ))}
        </TabNavigation>
      </Route>
    </Switch>
  )
}
