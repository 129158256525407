import React from 'react'
import { T } from '@transifex/react'
import { LinkProps } from '../../reducers/routing/child-router-factory'

import { TGetLinkPropsFn, defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'
import { depotTypeLabelMap } from '../../api/consts'

export const getLinkPropsDepotNode: TGetLinkPropsFn = getLinkPropsMakeNullable(
  (
    /** Should be DepotType!
     * {
     *   name
     *   depotType
     * }
     */
    value,
    { includeLabel } = defaultConfig
  ): LinkProps => ({
    to: ({ routingMap }) =>
      routingMap.app['fleet-management'].locations[':locationId'](value.id)._,
    children: includeLabel ? (
      <T
        _str="{depotType}: {name}"
        name={value.name}
        // @ts-expect-error wip
        depotType={depotTypeLabelMap[value.depotType]}
      />
    ) : (
      value.name
    ),
  })
)
