import { gql } from '@apollo/client'

export type GetCallResponseEdgeNode = {
  id: string
  user: {
    firstName: string
    lastName: string
  }
}

export type GetCallResponse = {
  allDrivers: {
    edges: {
      node: GetCallResponseEdgeNode
    }[]
  }
}

export type GetCallVariables = {
  activeProjects?: string
  vehicles?: string
  search?: string
  first?: number
  offset?: number
}

export const GET_CALL = gql`
  query Component_Select_Driver_GetAllDrivers(
    $activeProjects: String
    $vehicles: String
    $search: String
    $first: Int
    $offset: Int
    $ids: [ID]
  ) {
    allDrivers(
      activeProjects: $activeProjects
      vehicles: $vehicles
      search: $search
      first: $first
      offset: $offset
      ids: $ids
    ) {
      edges {
        node {
          id
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`
