import { gql } from '@apollo/client'

export const GET_ALL = gql`
  query GetAllInvoicesPropertyDetailView(
    $search: String
    $createdAtFrom: DateTime
    $createdAtTo: DateTime
    $first: Int
    $offset: Int = 0
    $property: [ID]
    $status: [String]
    $order: String
    $orderBy: String
    $propertyGroup: [ID]
    $containerGroupIds: [ID]
    $containerIds: [ID]
  ) {
    allInvoices(
      search: $search
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      first: $first
      offset: $offset
      property: $property
      status: $status
      order: $order
      orderBy: $orderBy
      propertyGroup: $propertyGroup
      containerGroupIds: $containerGroupIds
      containerIds: $containerIds
    ) {
      totalCount
      edges {
        node {
          id
          invoiceNumber
          status
          total
          dueDate
          issuedAt
          createdAt
          property {
            id
            externalPropertyNumber
            location {
              id
              name
            }
          }
        }
      }
    }
  }
`
