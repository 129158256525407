import { RcFile } from 'antd/es/upload'

const antdUploadtoBase64 = (file: RcFile) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const array = (files: RcFile[]) =>
  Promise.all(files.map((file) => antdUploadtoBase64(file)))

antdUploadtoBase64.array = array

export { antdUploadtoBase64 }
