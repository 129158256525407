import React from 'react'
import { useQuery, QueryHookOptions, DocumentNode } from '@apollo/client'
import { Select as AntdSelect, SelectProps } from 'antd'
import { SelectValue } from 'antd/es/select'

import { Opt } from './types'
import { StyledSkeletonInput } from '../../ui/Skeleton'

export type { Opt } from './types'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

export type AsyncSelectOnMountProps<
  GetCallResponse = $TSFixMe,
  GetCallVariables = $TSFixMe,
  TValue extends string | number = string
> = {
  queryOptions?: QueryHookOptions<GetCallResponse, GetCallVariables>
  query: DocumentNode
  formatResponseToOptions: (data: GetCallResponse) => Opt<TValue>[]
  render?: (props: RenderFnProps) => JSX.Element
  selectProps?: Partial<SelectProps<SelectValue>>
}

export const AsyncSelectOnMount = <GetCallResponse, GetCallVariables>(
  props: AsyncSelectOnMountProps<GetCallResponse, GetCallVariables>
) => {
  const { query, queryOptions, formatResponseToOptions, render, selectProps } =
    props

  const { loading, data } = useQuery<GetCallResponse, GetCallVariables>(
    query,
    queryOptions
  )

  const options = data ? formatResponseToOptions(data) : []

  if (loading)
    return <StyledSkeletonInput className="ant-skeleton-width-full" />

  return (
    render?.({
      options,
      loading,
    }) || <AntdSelect options={options} loading={loading} {...selectProps} />
  )
}
