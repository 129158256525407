import { gql } from '@apollo/client'
import apiUtilGetKey from '../../../api/apiUtilCreatePrefixer'

const FrgCompanyShippingInformationType = gql`
  fragment FrgCompanyShippingInformationType on BillingInformationType {
    id
    shippingName
    shippingAddress
    shippingAddress2
    shippingCity
    shippingZipCode
    shippingCountry
  }
`

export const UPDATE_BILLING_INFORMATION = gql`
  ${FrgCompanyShippingInformationType}
  mutation UpdateBillingInformation(
    $shippingAddress: String!
    $shippingAddress2: String!
    $shippingCity: String!
    $shippingCountry: String!
    $shippingZipCode: String!
    $shippingName: String!
    # args for the company
    $companyId: ID!
  ) {
    updateShippingInformation(
      shippingAddress: $shippingAddress
      shippingAddress2: $shippingAddress2
      shippingCity: $shippingCity
      companyId: $companyId
      shippingCountry: $shippingCountry
      shippingName: $shippingName
      shippingZipCode: $shippingZipCode
    ) {
      billingInformation {
        ...FrgCompanyShippingInformationType
      }
    }
  }
`

export const GET_BILLING_INFORMATION_QUERY = gql`
  ${FrgCompanyShippingInformationType}
  query GetCompanyShippingInformationForUpdateForm($id: ID!) {
    company(id: $id) {
      billinginformation {
        ...FrgCompanyShippingInformationType
      }
    }
  }
`

export const K_GET_BILLING_INFORMATION_QUERY = apiUtilGetKey(
  GET_BILLING_INFORMATION_QUERY
)
