import { pathJoin } from '../../../utils'
import { createRoutesMapAccountSettings } from './account-settings'

export const createUserManagementRoutesMap = (prefix: string) => ({
  'shift-planner': pathJoin(prefix)('shift-planner').chain,
  'account-settings/:userId': (userId: string) =>
    pathJoin(prefix)('account-settings')(userId).chainMapAuto(
      createRoutesMapAccountSettings
    ),
})
