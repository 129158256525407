import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { getOptsFromValueMap } from '../../new-components/select/utils'

export type TCrmServiceServiceTypeChoices =
  | 'EXTRA_EMPTYING'
  | 'BULK_WASTE_PICKUP'
  | 'ADD_CONTAINER'
  | 'CHANGE_CONTAINER_PICKUP_SETTING'
  | 'CHANGE_CONTAINER_TYPE'
  | 'REMOVE_CONTAINER'

export const crmServiceServiceTypeChoicesLabelMap: Record<
  TCrmServiceServiceTypeChoices,
  ReactNode
> = {
  ADD_CONTAINER: <T _str="Add Container" />,
  BULK_WASTE_PICKUP: <T _str="Bulk Waste Pickup" />,
  CHANGE_CONTAINER_PICKUP_SETTING: <T _str="Change Container Pickup Setting" />,
  CHANGE_CONTAINER_TYPE: <T _str="Change Container Type" />,
  EXTRA_EMPTYING: <T _str="Extra Emptyting" />,
  REMOVE_CONTAINER: <T _str="Remove Container" />,
}

export const crmServiceServiceTypeChoicesOptions = getOptsFromValueMap(
  crmServiceServiceTypeChoicesLabelMap
)

export const crmServiceServiceTypeRequiringContainerSourceField: TCrmServiceServiceTypeChoices[] =
  ['ADD_CONTAINER', 'CHANGE_CONTAINER_TYPE']
export const crmServiceServiceTypeRequiringContainerDestinationField: TCrmServiceServiceTypeChoices[] =
  ['REMOVE_CONTAINER', 'CHANGE_CONTAINER_TYPE']
