import { gql } from '@apollo/client'

/* Fragments */
export const FrgDepot = gql`
  fragment FrgDepot on DepotType {
    id
    company {
      id
      name
    }
    location {
      id
      latitude
      longitude
      placeId
      name
    }
    logo
    name
    openingTime
    closingTime
    contactPerson {
      id
      email
      name
      phone
    }
    depotType
    wasteFractions {
      edges {
        node {
          id
          name
          wasteCategory
          wasteSubstance
          weightFactor
        }
      }
    }
  }
`

/* Queries */

export const GET_ALL_DEPOTS = gql`
  ${FrgDepot}
  query GetAllDepots(
    $name: String
    $type: String
    $pageSize: Int
    $offset: Int
    $order: String
    $orderBy: String
    $activeProjects: String
    $activeCompanies: [ID]
    $search: String
    $wasteFractions: String
  ) {
    allDepots(
      name: $name
      depotType_Iexact: $type
      first: $pageSize
      offset: $offset
      order: $order
      orderBy: $orderBy
      activeProjects: $activeProjects
      activeCompanies: $activeCompanies
      search: $search
      wasteFractions: $wasteFractions
    ) {
      totalCount
      edges {
        node {
          ...FrgDepot
          logo
        }
      }
    }
  }
`
