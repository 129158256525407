import { gql } from '@apollo/client'

export const GET = gql`
  query GetTicketEventLog($id: ID!) {
    ticketEventLog(id: $id) {
      ... on TicketEventDef {
        id
        eventType
        createdAt
        fromStatus
        toStatus
        fromAssignee {
          id
          firstName
          lastName
          logo
        }
        toAssignee {
          id
          firstName
          lastName
          logo
        }
        fromAssignedTeam {
          id
          name
        }
        toAssignedTeam {
          id
          name
        }
        fromPriority
        toPriority
        ticket {
          id
          ticketType {
            id
            name
            isContainerLevel
          }
          property {
            id
            location {
              name
            }
          }
          container {
            id
            containerId
            location {
              name
            }
          }
          location {
            name
          }
          assignee {
            id
            firstName
            lastName
          }
          assignedTeam {
            id
            name
          }
        }
      }
    }
  }
`
