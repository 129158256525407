import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { snakeCase } from 'lodash'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { message } from 'antd'

import { T } from '@transifex/react'
import { useTableUI, DataCell, ColumnKey } from './ui'
import { GET_ALL } from './api'
import { useFiltersContext } from '../filters.context'
import { DebouncedSearch } from '../../../../ui'
import { useTablePaginationAndSorterAPIHelperPersistent } from '../../../../hooks'
import {
  crmInvoiceStatusChoicesMutationValueMap,
  TCrmInvoiceStatusChoices,
} from '../../../../api/consts'
import { formatPayment } from '../../../../utils/currency'
import { ServiceLogsInitialFilters } from '../../service-logs'
import { getLinkPropsPropertyNode } from '../../../extract-links-props'

type SortKey = 'ascend' | 'descend'

const sortFieldMap: Partial<Record<ColumnKey, string>> = {
  invoiceId: 'invoiceNumber',
  amount: 'total',
}

const getSortField = (val: ColumnKey) => {
  return snakeCase(sortFieldMap[val] || val)
}

const formatDatum = ({
  id,
  invoiceNumber,
  status,
  total,
  dueDate,
  issuedAt,
  createdAt,
  property,
}: $TSFixMe): DataCell => {
  return {
    _id: id,
    amount: formatPayment(total / 100, 'dkk'),
    invoiceId: invoiceNumber,
    status,
    createdAt,
    issuedAt,
    dueDate,
    property: {
      linkProps: getLinkPropsPropertyNode(property),
    },
  }
}

const sortOrderMap: Record<string, SortKey> = {
  asc: 'ascend',
  desc: 'descend',
}

type InvoiceTableIntegrationProps = {
  initialFilters?: ServiceLogsInitialFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
  setIds: $TSFixMe
}

const useTable = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  setIds,
}: InvoiceTableIntegrationProps) => {
  /* Filters */
  const [
    {
      status,
      createdAtFrom,
      createdAtTo,
      property,
      propertyGroup,
      containerGroupIds,
      containerIds,
    },
    set,
  ] = useFiltersContext()
  const { search, jsx: jsxSearch } = DebouncedSearch.useDebouncedSearchSimple()

  useEffect(() => {
    if (initialFilters) {
      set(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters])

  const {
    paginationVars: { first, offset, order, orderBy },
    onTableChangeHandler,
    state: { pageSize, page },
  } = useTablePaginationAndSorterAPIHelperPersistent<DataCell>(
    `useTablePaginationAndSorterAPIHelperPersistent--app-customer-management--properties--id--ivoices`,
    {
      pageSize: 20,
      order: 'descend',
      orderBy: 'created_at',
    }
  )

  /* API */
  const { data: { allInvoices } = {}, loading } = useQuery(GET_ALL, {
    variables: {
      first,
      offset,
      search,
      order,
      orderBy: getSortField(orderBy as ColumnKey),
      createdAtFrom,
      createdAtTo,
      property,
      propertyGroup,
      containerGroupIds,
      containerIds,
      status:
        status &&
        status.map(
          (s) =>
            crmInvoiceStatusChoicesMutationValueMap[
              s as TCrmInvoiceStatusChoices
            ]
        ),
    },
    onError: (error) =>
      message.error(error?.message || <T _str="Something went wrong" />),
  })

  const dataSource = allInvoices?.edges.map(({ node }: $TSFixMe) =>
    formatDatum(node)
  )

  /* UI filters columns */
  const { table, toggler } = useTableUI({
    tableColumnsTreeFilterAndOrderProps,
    loading,
    onChange: onTableChangeHandler,
    dataSource,
    pagination: {
      total: allInvoices?.totalCount || 0,
      pageSize,
      current: page,
      style: {
        padding: `0 1rem`,
      },
    },
    scroll: {
      y: '100%',
      x: 1000,
    },
    extended: {},
    sorting: {
      fields: [
        'amount',
        'invoiceId',
        'status',
        'createdAt',
        'dueDate',
        'invoiceId',
        'property',
      ],
      sortField: orderBy as ColumnKey,
      sortOrder: order ? sortOrderMap[order] : 'ascend',
    },
    on: {
      invoice: ({ _id }) => {
        setIds(_id, 'invoice')
      },
      reciept: ({ _id }) => {
        setIds(_id, 'reciept')
      },
    },
  })

  return {
    table,
    columnsFilterAndOrder: toggler,
    search: jsxSearch,
  }
}

export default useTable
