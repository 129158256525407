import { gql } from '@apollo/client'

export const GET_CONTAINER_TYPE_SLIM = gql`
  query GetContainerTypeSlim($id: ID!) {
    containerType(id: $id) {
      id
      name
    }
  }
`

export const GET_PICKUP_SETTING_SLIM = gql`
  query GetPickupSettingSlim($id: ID!) {
    pickupSetting(id: $id) {
      id
      name
    }
  }
`

export const GET_WASTE_FRACTION_SLIM = gql`
  query GetWasteFractionSlim($id: ID!) {
    wasteFraction(id: $id) {
      id
      name
    }
  }
`

export const GET_ALL_WASTE_FRACTION_SLIM = gql`
  query GetAllWasteFractionSlim($ids: [ID]) {
    allWasteFractions(ids: $ids) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const GET_CONTAINER_INFO = gql`
  query GetContainerInfo($id: ID) {
    container(id: $id) {
      id
      containerId
      containerType {
        id
        name
      }
      location {
        id
        name
      }
      wasteFraction {
        id
        name
      }
      pickupSetting {
        id
        name
      }
    }
  }
`

export const GET_TICKET_TYPE = gql`
  query FormTicketSingleSummaryGetTicket($id: ID!) {
    ticketType(id: $id) {
      id
      name
      service {
        id
        serviceType
      }
    }
  }
`
