import React from 'react'
import { SelectableListWithSearch } from '@wastehero/storybook/lib/components'

import { useMe } from '../../../../hooks/useMe'
import { SelectTeam } from '../../Team'

type TSelectTeamAssigneeProps = {
  value: string
  onChange?: (val: string) => void
}

const SelectTeamAssignee = ({ value, onChange }: TSelectTeamAssigneeProps) => {
  const me = useMe()
  return (
    <SelectTeam
      variables={{
        company: me?.company?.id,
      }}
      render={({ options, loading, onSearch, loadingMore, onMore }) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <SelectableListWithSearch
            style={{ padding: '0px 8px' }}
            onSearch={onSearch}
            mode="simple"
            value={[value]}
            onChange={([v]) => onChange?.(v)}
            loading={loading}
            showSearch
            notFound={!loading && !loadingMore && !options.length}
            onMore={onMore}
          >
            {options.map(({ label, value: v }) => (
              <SelectableListWithSearch.Option value={v} label={label} />
            ))}
          </SelectableListWithSearch>
        </div>
      )}
    />
  )
}

export default SelectTeamAssignee
