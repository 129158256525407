import { gql } from '@apollo/client'
import { TDriverDriverType } from '../../../../api/consts'

type EdgeNodes<NodeType> = {
  edges: {
    node: NodeType
  }[]
}

export type DriverType = {
  id: string
  driverType: TDriverDriverType
  user: {
    id: string
    firstName: string
    lastName: string
  }
}

export type GetCallResponseEdgeNode = {
  id: string
  name: string
  drivers: EdgeNodes<DriverType>
}

export type GetCallResponse = {
  allVehicles: EdgeNodes<GetCallResponseEdgeNode>
}

export type GetCallVariables = {
  activeProjects?: string
  search?: string
  first?: number
  offset?: number
}

export const GET_CALL = gql`
  query Component_Select_Vehicle_GetAllVehicles_WithDriverUserName(
    $activeProjects: String
    $search: String
    $first: Int
    $offset: Int
    $ids: [ID]
  ) {
    allVehicles(
      activeProjects: $activeProjects
      search: $search
      first: $first
      offset: $offset
      ids: $ids
    ) {
      edges {
        node {
          id
          name
          drivers {
            edges {
              node {
                id
                driverType
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`
