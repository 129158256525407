import { gql } from '@apollo/client'

/* Fragments */

export const FrgInquiry = gql`
  fragment FrgInquiry on CollectionInquiryType {
    id
    createdAt
    priority
    deadline
    wasteVolume
    status
    wasteWeightEstimate
    name
    description
    timeSpent
    project {
      id
      name
    }
    location {
      longitude
      latitude
      placeId
      name
    }
    container {
      id
      location {
        longitude
        latitude
        placeId
        name
      }
    }
    inquiryTyping {
      id
      name
    }
    reporter {
      id
      firstName
      lastName
      driver {
        id
        vehicle {
          id
          name
        }
      }
    }
    wasteFraction {
      id
      wasteCategory
      wasteSubstance
      name
    }
    files {
      url
      name
    }
  }
`
