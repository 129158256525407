import { GeoJSONSource } from 'mapbox-gl'
import React, { useEffect } from 'react'
import { TGetMap, TMapbox, TSourcesPopupSupport } from '../types'

import {
  PopupContainerDetail,
  PopupContainersDetail,
} from './PopupContainerDetail'
import {
  PopupPropertyDetail,
  PopupPropertysDetail,
} from './PopupPropertyDetail'
import {
  PopupPickupOrderDetail,
  TPopupPickupOrderDetailProps,
} from './PopupPickupOrderDetail'
import { usePopup } from './usePopup'

type TModes = 'single' | 'multi' | `singleGroup` | `custom`

type TPopupState = { onClose?: () => void } & (
  | {
      type: 'single'
      sourceName: TSourcesPopupSupport
      props: { id: string }
    }
  | {
      type: 'singleGroup'
      sourceName: TSourcesPopupSupport
      props: { ids: string[] }
    }
  | {
      type: 'multi'
      sourceName: TSourcesPopupSupport
      props: { ids: string[] }
    }
  | ({
      type: 'custom'
    } & {
      sourceName: 'source-route-pickup-orders'
      props: TPopupPickupOrderDetailProps
    })
)

const popupRenderMap = {
  containers: {
    single: PopupContainerDetail,
    multi: PopupContainersDetail,
    singleGroup: PopupContainersDetail,
  },
  properties: {
    single: PopupPropertyDetail,
    multi: PopupPropertysDetail,
    singleGroup: PopupPropertysDetail,
  },
  pickupOrders: {
    custom: PopupPickupOrderDetail,
  },
}

type TLayerName = string[]

type TLayerNameMap = {
  containers: {
    single: TLayerName
    multi: TLayerName
  }
  properties: {
    single: TLayerName
    multi: TLayerName
  }
  pickupOrders: {
    custom: TLayerName
  }
}

const setMapCursor = (map: TMapbox, value: string) => {
  // eslint-disable-next-line no-param-reassign
  map.getCanvas().style.cursor = value
}

const pointSingleRender = ({
  layerName,
  map,
  render,
}: {
  map: TMapbox
  layerName: string
  render: (props: { id: string }, lngLat: $TSFixMe) => void
  source: string
}) => {
  map.on('click', layerName, (e: $TSFixMe) => {
    const feature = map.queryRenderedFeatures(e.point, {
      layers: [layerName],
    })[0]
    if (feature?.properties?.dataId) {
      render({ id: feature?.properties?.dataId }, e.lngLat)
    }
  })
}

const pointCustomRender = ({
  layerName,
  map,
  render,
}: {
  map: TMapbox
  layerName: string
  render: (props: { id: string }, lngLat: $TSFixMe) => void
  source: string
}) => {
  map.on('click', layerName, (e: $TSFixMe) => {
    const feature = map.queryRenderedFeatures(e.point, {
      layers: [layerName],
    })[0]
    if (feature?.properties?.popupCustom) {
      render(JSON.parse(feature?.properties?.popupCustom), e.lngLat)
    }
  })
}

const pointSingleGroupRender = ({
  layerName,
  map,
  render,
}: {
  map: TMapbox
  layerName: string
  render: (props: { ids: string[] }, lngLat: $TSFixMe) => void
  source: string
}) => {
  map.on('click', layerName, (e: $TSFixMe) => {
    const feature = map.queryRenderedFeatures(e.point, {
      layers: [layerName],
    })[0]
    if (feature?.properties?.dataIds) {
      render(
        {
          ids: JSON.parse(feature?.properties?.dataIds),
        },
        e.lngLat
      )
    }
  })
}

const pointMultiRender = ({
  layerName,
  map,
  render,
  source: sourceName,
}: {
  map: TMapbox
  layerName: string
  render: (props: { ids: string[] }, lngLat: $TSFixMe) => void
  source: string
}) => {
  map.on('click', layerName, (e: $TSFixMe) => {
    const feature = map.queryRenderedFeatures(e.point, {
      layers: [layerName],
    })[0]

    const source = map.getSource(sourceName) as GeoJSONSource
    if (feature?.properties?.cluster_id) {
      const clusterId = feature.properties.cluster_id

      // Ease the camera to the next cluster expansion
      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (!err) {
          if (zoom < 26) {
            // the zoom in
            map.easeTo({
              // @ts-expect-error Wip
              center: feature.geometry.coordinates,
              zoom,
            })
          } else {
            // open popup
            source.getClusterLeaves(
              clusterId,
              Infinity,
              0,
              (errGetLeaves, subFeatures) => {
                const allSubPoints =
                  subFeatures?.map((f) => f?.properties?.dataId) || []
                render({ ids: allSubPoints }, e.lngLat)
              }
            )
          }
        }
      })
    }
  })
}

const modeRenderer = {
  single: pointSingleRender,
  multi: pointMultiRender,
  singleGroup: pointSingleGroupRender,
  custom: pointCustomRender,
}

type TAddonPopupProps = {
  getMap: TGetMap
  layerNameMapping: TLayerNameMap
}

const AddonPopup = ({ getMap, layerNameMapping }: TAddonPopupProps) => {
  const [setContainerPopup, containerPopup] = usePopup<
    Omit<TPopupState, 'onClose'>
  >({
    children: (state, onClose) => {
      if (state) {
        // @ts-expect-error WIp
        const Fn = popupRenderMap[state.sourceName][state.type] as $TSFixMe
        return <Fn {...state.props} onClose={onClose} />
      }
      return <></>
    },
  })

  useEffect(() => {
    const map = getMap()
    if (map) {
      map.on('styledata', () => {
        // loop through all the provided layer names and add listeners.
        // 1. Remove any popup if click outside
        map.on('click', () => {
          setContainerPopup(undefined)
        })
        Object.keys(layerNameMapping).forEach((sourceName) => {
          // @ts-expect-error WIP
          Object.keys(layerNameMapping[sourceName]).forEach((popupType) => {
            // @ts-expect-error WIP
            ;(layerNameMapping[sourceName][popupType] as TLayerName).forEach(
              (layerName) => {
                const renderFn = modeRenderer[popupType as TModes]
                map.on('mouseenter', layerName, () => {
                  setMapCursor(map, 'pointer')
                })
                map.on('mouseleave', layerName, () => {
                  setMapCursor(map, '')
                })
                renderFn({
                  map,
                  layerName,
                  render: (props: $TSFixMe, lngLat: $TSFixMe) => {
                    setContainerPopup({
                      // @ts-expect-error Wip
                      sourceName,
                      lngLat,
                      // @ts-expect-error Wip
                      type: popupType,
                      props,
                    })
                  },
                  source: sourceName,
                })
              }
            )
          })
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return containerPopup
}

export default AddonPopup
