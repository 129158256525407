import React from 'react'
import { Row, Col, Divider, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'

import {
  FilterContainerType,
  FilterWasteFraction,
  FilterPickupScheme,
} from './filters.elements'
import { FiltersProiver } from './filters.context'
import useTable from './table'
import { useCreateRequestFormModal } from '../../../../../components/create-request'
import { useRoutingHistory } from '../../../../../../../../../reducers/routing/child-router-factory'
import { useCustomerContext } from '../../../customer.context'

const TableView = () => {
  const { columnsFilterAndOrder, search, table } = useTable()

  return (
    <div className="wh-vertical-scroller-container" style={{ rowGap: `8px` }}>
      <Row justify="space-between" style={{ padding: '1rem' }}>
        <Col flex="auto">{search}</Col>
        <Col flex="none">{columnsFilterAndOrder}</Col>
      </Row>
      <div className="wh-vertical-scroller-container-scroll wh-ant-fill-view-table">
        {table}
      </div>
    </div>
  )
}

const Agreements = () => {
  const [history, { routingMap }] = useRoutingHistory()
  const { id: propertyId } = useCustomerContext()

  const {
    modal: jsxCreateRequestFormModal,
    openModal: openCreateRequestFormModal,
  } = useCreateRequestFormModal({ type: 'property' })

  const onSuccess = ({ id: newTicketId }: { id: string }) =>
    history.push(routingMap.app.tickets['/:id'](newTicketId)._)

  return (
    <>
      <div className="wh-vertical-scroller-container">
        <div>
          <Row
            justify="space-between"
            wrap={false}
            style={{ padding: '0 1rem', margin: `1rem 0rem` }}
          >
            <Col>
              <Row gutter={[8, 8]}>
                {[
                  <FilterWasteFraction />,
                  <FilterContainerType />,
                  <FilterPickupScheme />,
                ].map((filter) => (
                  <Col>{filter}</Col>
                ))}
              </Row>
            </Col>
            <Col>
              <Button
                onClick={() =>
                  openCreateRequestFormModal({
                    id: propertyId,
                    form: {
                      type: 'BULK_WASTE_PICKUP',
                      onSuccess,
                      propertyId,
                    },
                  })
                }
                icon={<PlusOutlined />}
                type="primary"
              >
                <>
                  <T _str="Ticket" />
                </>
              </Button>
            </Col>
          </Row>
        </div>
        <div>
          <Divider
            style={{
              margin: 0,
              borderWidth: 5,
              borderColor: 'var(--bgColor)',
            }}
          />
        </div>
        <div className="wh-vertical-scroller-container-scroll">
          <TableView />
        </div>
      </div>
      {jsxCreateRequestFormModal}
    </>
  )
}

export default () => (
  <FiltersProiver initialValue={{}}>
    <Agreements />
  </FiltersProiver>
)
