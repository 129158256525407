import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TStatusType } from './data'

export const mockStatusTypeLabelMap: Record<TStatusType, ReactNode> = {
  created: <T _str="Created" />,
  completed: <T _str="Completed" />,
  pending: <T _str="Pending" />,
  rejected: <T _str="Rejected" />,
}

export const mockStatusTypeColorMap: Record<TStatusType, string> = {
  created: '#FFA940',
  completed: '#A0CB26',
  pending: '#909DA9',
  rejected: '#FF4D4F',
}
