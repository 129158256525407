import { useRef, useEffect, useState } from 'react'
import compare from 'react-fast-compare'

// Hook

function usePrevious(value: $TSFixMe) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

type R = Record<string, unknown>

export const mocker = <MockerResult extends R = R>(
  result: MockerResult
): Promise<MockerResult> =>
  new Promise((res) =>
    setTimeout(() => {
      res(result)
    }, 800)
  )

export const createGetAll = <
  MockerArgs extends R = R,
  MockerResult extends R = R
>(
  handler: (args: MockerArgs) => MockerResult
) => {
  const useMockApi = (
    args: MockerArgs
  ): { data: MockerResult | undefined; loading: boolean } => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<MockerResult | undefined>()
    const [counter, setCounter] = useState(0)
    const prevArgs = usePrevious(args)

    useEffect(() => {
      ;(async () => {
        setLoading(true)
        const resp = await mocker<MockerResult>(handler(args))
        setData(resp)
        setLoading(false)
      })()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter])

    useEffect(() => {
      if (!compare(prevArgs, args)) {
        setCounter((p) => p + 1)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [args])

    return {
      data,
      loading,
    }
  }
  return useMockApi
}

export const createGetOne =
  <MockerArgs extends R = R, MockerResult extends R = R>(
    handler: (args: MockerArgs) => MockerResult | undefined
  ) =>
  (args: MockerArgs): { data: MockerResult | undefined; loading: boolean } => {
    const [loading, setLoading] = useState(true)
    const [dataOne, setDataOne] = useState<MockerResult | undefined>()
    const [counter, setCounter] = useState(0)
    const prevArgs = usePrevious(args)

    useEffect(() => {
      ;(async () => {
        setLoading(true)
        const resp = await new Promise((res) =>
          setTimeout(() => {
            res(handler(args))
          }, 800)
        )
        setDataOne(resp as MockerResult)
        setLoading(false)
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter])

    useEffect(() => {
      if (!compare(prevArgs, args)) {
        setCounter((p) => p + 1)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [args])

    return {
      data: dataOne,
      loading,
    }
  }
