import { snakeCase } from 'lodash'

/**
 * Fn: convertEnumToNumber
 * Converts API enums like A_1, A_2 type of values to number
 * Use for mutation or query variables
 */
export const convertEnumToNumber = (v: string | string[]) => {
  if (Array.isArray(v)) return v.map((s) => s.split('_')[1])
  return v.split('_')[1]
}

/**
 * Fn: convertEnumToNumberSnakeCase
 * Converts API enums like ON_HOLD, IN_TRANSIT type of values to on_hold, in_transit
 * Use for mutation or query variables
 */
export const convertEnumToNumberSnakeCase = (v: string | string[]) => {
  if (Array.isArray(v)) return v.map((s) => snakeCase(s))
  return snakeCase(v)
}
