import React, { FC } from 'react'
import { Select, SelectProps } from 'antd'
import {
  Select as SelectFormik,
  SelectProps as SelectFormikProps,
} from 'formik-antd'
import { useQuery, gql } from '@apollo/client'

import { AsyncSelectType, Opt } from './types'

const GET_QUERY = gql`
  query Component_Select_IntegrationGps($company: ID) {
    allIntegrationGps(company: $company) {
      totalCount
      edges {
        node {
          id
          provider
        }
      }
    }
  }
`

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type Variables = {
  company?: string
}

type IntegrationGpsType = {
  render?: (props: RenderFnProps) => JSX.Element
} & AsyncSelectType<Variables> &
  Omit<SelectProps<Opt>, 'options' | 'loading'>

export const SelectIntegrationGps = ({
  variables,
  render,
  ...selectProps
}: IntegrationGpsType) => {
  const { data, loading } = useQuery(GET_QUERY, {
    variables,
  })

  const options: Opt[] =
    data?.allIntegrationGps?.edges.map(({ node }: $TSFixMe) => ({
      label: node.provider.toLowerCase(),
      value: node.id,
      key: node.id,
    })) || []

  return (
    render?.({
      options,
      loading,
    }) || (
      <Select
        options={options}
        showSearch
        loading={loading}
        filterOption
        labelInValue
        {...(selectProps || {})}
      />
    )
  )
}

export const SelectFormikIntegrationGps: FC<
  SelectFormikProps & { variables?: Variables }
> = ({ name, variables, ...rest }) => (
  <SelectIntegrationGps
    variables={variables}
    render={({ loading, options: opts }) => (
      <SelectFormik
        name={name}
        loading={loading}
        options={opts}
        showSearch
        filterOption
        optionFilterProp="label"
        {...rest}
      />
    )}
  />
)
