import React from 'react'
import { Select, SelectProps } from 'antd'

import { Opt } from './types'
import { wasteFractionWasteTypeLabelMap } from '../../api/consts'
import { getOptsFromValueMap } from './utils'

type RenderFnProps = {
  options: Opt[]
  loading: boolean
}

type SelectWasteFractionWasteTypeProps = {
  render?: (props: RenderFnProps) => JSX.Element
} & Omit<SelectProps<Opt>, 'options' | 'loading'>

const options = getOptsFromValueMap(wasteFractionWasteTypeLabelMap)

export const SelectWasteFractionWasteType = ({
  render,
  ...selectProps
}: SelectWasteFractionWasteTypeProps) => {
  return (
    render?.({
      options,
      loading: false,
    }) || (
      <Select
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...(selectProps || {})}
      />
    )
  )
}
