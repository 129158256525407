import React, { FC } from 'react'
import ContainerGroup from './ContainerGroup'
import SameStopGroup from './SameStopGroup'
import Stop from './Stop'

export type TPopupPickupOrderDetailType =
  | 'stop'
  | 'container-group'
  | 'stop-group'
  | 'same-stop-group'

export type TStopProps = {
  type: 'stop'
  props: {
    id: string
  }
}

export type TContainerGroupProps = {
  type: 'container-group'
  props: {
    // ids: string[]
    /** container groupid */
    groupId: string
    /** order ids */
    ids: string[]
  }
}

type TStopGroup = {
  type: 'stop-group'
  props: {
    ids: string[]
  }
}

export type TSameStopGroup = {
  type: 'same-stop-group'
  props: {
    ids: string[]
  }
}

export type TPopupPickupOrderDetailProps = (
  | TStopProps
  | TContainerGroupProps
  | TStopGroup
  | TSameStopGroup
) & { props: { isNext?: boolean; nextStopId?: string } } & {
  onClose?: () => void
}

const ComponentMap: Record<TPopupPickupOrderDetailType, FC<$TSFixMe>> = {
  stop: Stop,
  'container-group': ContainerGroup,
  'stop-group': SameStopGroup.WithoutHeader,
  'same-stop-group': SameStopGroup,
}

export const PopupPickupOrderDetail = ({
  type,
  props,
  onClose,
}: TPopupPickupOrderDetailProps) => {
  const Component = ComponentMap[type]
  if (!Component) return <div>{JSON.stringify({ type, props })}</div>
  return <Component type={type} onClose={onClose} props={props} />
}
