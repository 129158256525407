import React, { ReactNode } from 'react'
import { T } from '@transifex/react'

export type TEventTicketEventEventTypeChoices =
  | 'A_1'
  | 'A_2'
  | 'A_3'
  | `A_4`
  | 'A_10'

export const eventTicketEventEventTypeChoicesLabelMap: Record<
  TEventTicketEventEventTypeChoices,
  ReactNode
> = {
  A_1: <T _str="Status changed" />,
  A_2: <T _str="Assignee changed" />,
  A_3: <T _str="Priority changed" />,
  A_4: <T _str="Assigned team changed" />,
  A_10: <T _str="Modified" />,
}
