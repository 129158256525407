import { createContext, useContext } from 'react'

export type CustomerData = {
  id: string
  location: {
    name: string
    latitude?: number
    longitude?: number
  }
}

export const CustomerContext = createContext<CustomerData>(
  null as unknown as CustomerData
)

export const useCustomerContext = () => useContext(CustomerContext)
