import React from 'react'
import { ConfigProvider } from 'antd'
import { useLocale } from '@transifex/react'
import { Locale } from 'antd/lib/locale-provider'
import da from 'antd/lib/locale/da_DK'
import en from 'antd/lib/locale/en_US'

import { TLocales } from '../setupTransifex'

const localeMap: Record<TLocales, Locale> = {
  da,
  en,
}

const AntdI18n: React.FC = ({ children }) => {
  const locale = useLocale()

  return (
    <ConfigProvider locale={localeMap[locale as TLocales] || en}>
      {children}
    </ConfigProvider>
  )
}

export default AntdI18n
