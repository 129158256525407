import React, { ReactNode } from 'react'
import { List, ListProps } from 'antd'
import { chunk } from 'lodash'
import { LayoutWithDividerv3, LayoutWithDividerv3Props } from '.'

export type TListGridWithDivider = {
  /** default to 2.  */
  colsPerLine?: number
  listProps?: Omit<ListProps<ReactNode[]>, 'dataSource' | 'renderItem'>
} & Pick<LayoutWithDividerv3Props, 'content'>

const ListGridWithDivider = ({
  content: allContent,
  colsPerLine = 2,
  listProps,
}: TListGridWithDivider) => {
  const pairs = chunk(allContent, colsPerLine)

  return (
    <List
      {...listProps}
      dataSource={pairs}
      renderItem={(content: ReactNode[]) => {
        return (
          <List.Item>
            <LayoutWithDividerv3
              colsRatio={
                Array.from({ length: colsPerLine }).fill(
                  100 / colsPerLine
                ) as number[]
              }
              style={{ width: '100%' }}
              content={content}
            />
          </List.Item>
        )
      }}
    />
  )
}

export default ListGridWithDivider
