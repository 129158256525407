import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

/* Fragments */
export const FrgVehicleType = gql`
  fragment FrgVehicleType on VehicleTypeType {
    id
    name
    Type
    body
    bodyMechanization
    liftingMechanization
    loadingLocation
  }
`
/* Queries */
export const GET_ALL_VEHICLE_TYPES = gql`
  ${FrgVehicleType}
  query GetAllVehicleTypes(
    $pageSize: Int
    $offset: Int
    $name: String
    $order: String
    $orderBy: String
    $activeProjects: String
  ) {
    allVehicleTypes(
      first: $pageSize
      offset: $offset
      name_Istartswith: $name
      order: $order
      orderBy: $orderBy
      activeProjects: $activeProjects
    ) {
      totalCount
      edges {
        node {
          ...FrgVehicleType
          isUsed
        }
      }
    }
  }
`
export const K_GET_ALL_VEHICLE_TYPES = apiUtilGetKey(GET_ALL_VEHICLE_TYPES)
/* Mutations */
