import React from 'react'
import { T } from '@transifex/react'
import { RangeSelectorWithRadio } from '@wastehero/storybook/lib/components'
import { SelectProps } from 'antd'
import {
  SelectProject,
  SelectContainerType,
  SelectContainerStatus,
  SelectSensor,
  SelectWasteFraction,
  SelectProperty,
  SelectPropertyGroup,
} from '../../select'
import {
  createFilter,
  FiltersProiver as Provider,
  useFiltersContext,
  initialFilters,
} from './filters.context'

const defaultProps: Partial<SelectProps> = {
  style: {
    width: '100%',
  },
}

export const FiltersProiver: React.FC = (props) => (
  <Provider {...props} initialValue={initialFilters} />
)

export const FilterByDateRange = () => {
  const [{ dateRange }, set] = useFiltersContext()

  return (
    <RangeSelectorWithRadio
      value={dateRange}
      onChange={(val) => set({ dateRange: val })}
    />
  )
}

export const FilterByProject = createFilter({
  key: 'project',
  Component: SelectProject,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})

export const FilterByContainerStatus = createFilter({
  key: 'status',
  Component: SelectContainerStatus,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})

export const FilterByContainerType = createFilter({
  key: 'containerType',
  Component: SelectContainerType,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})

export const FilterByWasteFraction = createFilter({
  key: 'wasteFraction',
  Component: SelectWasteFraction,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})

export const FilterByProperty = createFilter({
  key: 'property',
  Component: SelectProperty,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    allowClear: true,
    maxTagCount: 'responsive' as const,
  },
})

export const FilterBySensor = createFilter({
  key: 'sensor',
  Component: SelectSensor,
  componentProps: {
    ...defaultProps,
  },
})

export const FilterByPropertyGroup = createFilter({
  key: 'propertyGroup',
  Component: SelectPropertyGroup,
  componentProps: {
    ...defaultProps,
    mode: 'multiple',
    maxTagCount: 'responsive' as const,
    placeholder: <T _str="Property group" />,
  },
})
