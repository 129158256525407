import React, { ReactNode, CSSProperties } from 'react'
import { Row, Col, Divider } from 'antd'
import styled from 'styled-components'

export type TFormSectionVerticalWithDividersProps = {
  fields: ReactNode[]
  style?: CSSProperties
  /**
   * hide the dividers
   * default to false
   */
  hideDivider?: boolean
}

const StyledRow = styled(Row)`
  .ant-form-item {
    margin: 0;
  }
`

export const FormSectionVerticalWithDividers = ({
  fields,
  style,
  hideDivider,
}: TFormSectionVerticalWithDividersProps) => (
  <StyledRow gutter={[16, 16]} style={style}>
    {fields.map((node, i, arr) => (
      <React.Fragment key={i}>
        <Col xs={24}>{node}</Col>
        {i < arr.length - 1 && !hideDivider && (
          <Divider style={{ margin: 0 }} />
        )}
      </React.Fragment>
    ))}
  </StyledRow>
)
