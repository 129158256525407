import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TagProps } from 'antd'
import { getOptsFromValueMap } from '../../new-components/select/utils'

export type TDeviceContainerStatusChoices =
  | 'AVAILABLE'
  | 'ON_HOLD'
  | 'DEFECT'
  | 'IN_STORAGE'
  | 'IN_TRANSIT'

export const deviceContainerStatusChoicesLabelMap: Record<
  TDeviceContainerStatusChoices,
  ReactNode
> = {
  AVAILABLE: <T _str="Available" />,
  DEFECT: <T _str="Defect" />,
  IN_STORAGE: <T _str="Storage" />,
  IN_TRANSIT: <T _str="In transit" />,
  ON_HOLD: <T _str="On hold" />,
}

export const deviceContainerStatusChoicesOptions = getOptsFromValueMap(
  deviceContainerStatusChoicesLabelMap
)

export const deviceContainerStatusChoicesTagColorMap: Record<
  TDeviceContainerStatusChoices,
  TagProps['color']
> = {
  AVAILABLE: 'green',
  DEFECT: 'error',
  IN_STORAGE: 'blue',
  IN_TRANSIT: 'yellow',
  ON_HOLD: 'orange',
}
