import React, { FC, useState } from 'react'
import { T } from '@transifex/react'
import { LeftOutlined } from '@ant-design/icons'
import { CssScrollerDiv } from '@wastehero/storybook/lib/components'
import { TContainerGroupProps, TPopupPickupOrderDetailProps } from '..'
import PopupLayout1 from '../../Layout'

import ContainerGroupDetail from './ContainerGroupDetail'
import PickupOrderDetail from './PickupOrderDetail'

const ContainerGroup: FC<TPopupPickupOrderDetailProps & TContainerGroupProps> =
  ({ onClose = () => {}, props: { groupId, ids, isNext, nextStopId } }) => {
    const [selectedPickupOrder, setSelectedPickupOrder] = useState('')

    return (
      <PopupLayout1
        onClose={onClose}
        addonBeforeTitle={
          selectedPickupOrder ? (
            <LeftOutlined
              style={{ fontSize: '1rem' }}
              onClick={() => setSelectedPickupOrder('')}
            />
          ) : undefined
        }
        title={<T _str="Containers Group" />}
      >
        <CssScrollerDiv
          style={{ height: 300, width: 380 }}
          defaultYScroller
          offset={10}
        >
          {selectedPickupOrder ? (
            <PickupOrderDetail
              id={selectedPickupOrder}
              nextStopId={nextStopId}
            />
          ) : (
            <ContainerGroupDetail
              onSelect={setSelectedPickupOrder}
              groupId={groupId}
              ids={ids}
              isNext={isNext}
              nextStopId={nextStopId}
            />
          )}
        </CssScrollerDiv>
      </PopupLayout1>
    )
  }

export default ContainerGroup
