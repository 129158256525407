import React, { Suspense } from 'react'
import { Router } from 'react-router-dom'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Helmet from 'react-helmet'
import { enableMapSet } from 'immer'

import { toast, ToastContainer } from 'react-toastify'
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { GlobalStyles as StorybookStyles } from '@wastehero/storybook/lib/styles'
import TranslationsProvider from '../translations'
import createTheme from '../components/main/theme'
import history from '../history'
import FavIcon from '../images/favicon-32x32.png'
import RouterFactory, {
  useRouting,
} from '../reducers/routing/child-router-factory'
import MainRouter from './router'
import { createConfig } from './route-config'
import AntdI18n from './AntdI18n'
import { WsDisconnectNotification } from '../components/graphql/ws'
import { AsyncModalPortal } from '../utils/asyncModalAction'
import { GlobalStyles, GlobalStylesAntFillViewList } from './global.styles'
import { BirdsEatBug } from './setupBirdsEatBug'
import { SuspenseLoading } from '../components/shared/loading/SuspensedLoading'

/**
 * See: Immer docs on why we need this.
 * Note: Don't remove this until/unless you are sure that we are not using JS Map in any immer produce function
 * through out our app. If you remove it, then it will break those actions
 */
enableMapSet()

type AppProps = {
  variables: {
    VHRed: string
    clusterColor: string
    VHRedDark: string
    VHGreen: string
    VHBlueDark: string
    VHGrey: string
    VHGreyLight: string
    cLightBlue: string
    cLightgrayBr: string
    cGrayInpBorder: string
    cGray: string
    cDGray: string
    cTextDark: string
    cEmperor: string
    noSidebarImage: boolean
    defaultBoxShadow: string
    defaultFont: string
    cCharcoal: string
    VHBlue: string
    buttonBlue: string
    sidebarBackgroundColor: string
    VHDarkBlue: string
    VHLightBlue: string
    VHDarkGrey: string
    HeaderColor: string
    cDarkBlue: string
    cSilver: string
    cSlate: string
    cLightGray: string
    cSnow: string
    cAntiFlashWhite: string
    chipBackgroundColor: string
    cWhite: string
    VHLightBlack: string
    cRichOcean: string
    VHOrange: string
    cOrange: string
    cLightPeach: string
    cLightRed: string
    cGreen: string
    cLemon: string
    cDarkOrange: string
    cIndependence: string
    VHGreyBackground: string
    VHInputAdornment: string
  }
}

const App = ({ variables }: AppProps) => {
  const theme = responsiveFontSizes(createTheme(variables))
  const { routingMap: map } = useRouting()

  BirdsEatBug.useInit()

  return (
    <div className="mainHolder">
      <Helmet>
        <title>WasteHero Platform</title>
        <meta name="description" content="WasteHero" />
        <link rel="icon" type="image/png" href={FavIcon} sizes="16x16" />
      </Helmet>
      <StorybookStyles />
      <GlobalStyles />
      <GlobalStylesAntFillViewList defaultYScroller />
      <Router history={history}>
        <TranslationsProvider>
          <>
            <AntdI18n>
              <MuiThemeProvider theme={theme}>
                <MainRouter>
                  <AsyncModalPortal />
                  <Suspense fallback={<SuspenseLoading />}>
                    <RouterFactory
                      config={createConfig(map)}
                      defaultRedirect="/app/dashboard"
                    />
                  </Suspense>
                </MainRouter>
              </MuiThemeProvider>
            </AntdI18n>
            <WsDisconnectNotification />
            <ToastContainer
              enableMultiContainer
              position={toast.POSITION.BOTTOM_RIGHT}
            />
          </>
        </TranslationsProvider>
      </Router>
    </div>
  )
}

const mapStateToProps = (state: $TSFixMe) => ({
  variables: state.settings.variables,
})

export default connect(mapStateToProps)(App)
