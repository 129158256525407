import moment, { Moment } from 'moment'
import {
  createStatefulContextSomePersistent,
  Value,
} from '../../../../../../../../contexts/factories/createStatefulContextSomePersistent'
import { useLastSavedActiveProjectsSnapshotFn } from '../../../../../../hooks'

import { ContainerSensorFilter } from './elements-filters/SensorFilter'

export type UIView = 'map' | 'table'

export const sensorFilterMap: Record<ContainerSensorFilter, $TSFixMe> = {
  all: null,
  with: false,
  without: true,
}

export type PersistentFilters = {
  project?: string[]
  containerType?: string[]
  status?: string[]
  sensor?: ContainerSensorFilter
  date: [Moment, Moment]
  wasteFraction?: string[]
}

export type NonPersistentFilters = {
  search?: string
  uiView: UIView
}

export const persistentInit: PersistentFilters = {
  date: [moment().startOf('week'), moment().endOf('week')],
}

export const nonPersistentInit: NonPersistentFilters = {
  uiView: 'table',
}

const { Context, Provider, useContext } = createStatefulContextSomePersistent<
  PersistentFilters,
  NonPersistentFilters
>(`Containers_Table_filters`)

const useFiltersContext = (): Value<
  PersistentFilters,
  NonPersistentFilters
> => {
  const [[a, b], [c, d]] = useContext()

  // Parsing the data to required form before we use it.
  // Why we need it.
  // This context is a persistent store and so it's value may come from localStorage.
  // In that case, we need to convert the dates back to Moment.
  return [
    [
      {
        ...a,
        date: [moment(a.date[0]), moment(a.date[1])],
      },
      b,
    ],
    [c, d],
  ]
}

export default {
  FiltersContext: Context,
  FiltersContextProvider: Provider,
}

export { Provider as FiltersProvider, useFiltersContext }

export const usePersistentFilter = <T extends $TSFixMe>(
  key: keyof PersistentFilters
): [T, (val: T) => void] => {
  const [[state, set]] = useFiltersContext()

  return [state[key] as T, (val: T) => set((p) => ({ ...p, [key]: val }))]
}

export const useNonPersistentFilter = <T extends $TSFixMe>(
  key: keyof NonPersistentFilters
): [T, (val: T) => void] => {
  const [, [state, set]] = useFiltersContext()

  return [state[key] as T, (val: T) => set((p) => ({ ...p, [key]: val }))]
}

export const useFiltersAutoResetOnSavedProjectsChange = () => {
  const [[, set]] = useFiltersContext()
  useLastSavedActiveProjectsSnapshotFn(
    `Containers_Table_filters_UseLastSavedActiveProjectsSnapshot`,
    () =>
      // only reseting those which are affected by the main projects selection.
      // The following filters uses the savedActiveProjects. See ./elements.filters.tsx
      set((p) => ({ ...p, containerType: [], wasteFraction: [], project: [] })),
    [set]
  )
}
