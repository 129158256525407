import { gql } from '@apollo/client'

const FrgFormProject = gql`
  fragment FrgFormProject on ProjectType {
    id
    name
    settings {
      id
      dashboardLocation {
        id
        longitude
        latitude
        name
      }
      country
      crmEnabled
      timezone
      currency
      clockFormat12
      drivingDistance
      weight
      volume
      temperature
    }
    description
    logo
  }
`

export const GET = gql`
  ${FrgFormProject}
  query FormProjectGet($id: ID!) {
    project(id: $id) {
      ...FrgFormProject
    }
  }
`

export const MUTATION = gql`
  ${FrgFormProject}
  mutation FormProjectMutation($input: UpdateOrCreateProjectInput!) {
    updateOrCreateProject(input: $input) {
      project {
        ...FrgFormProject
      }
    }
  }
`

export const MUTATION_2 = gql`
  ${FrgFormProject}
  mutation FormProjectSettingsMutation(
    $input: UpdateOrCreateProjectSettingsInput!
  ) {
    updateOrCreateProjectSettings(input: $input) {
      project {
        ...FrgFormProject
      }
    }
  }
`
