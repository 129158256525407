import * as Yup from 'yup'
import { jsxFieldRequried, jsxNumberOnly } from '../../../utils/yup-validation'

const BillingInformationFormScheme = Yup.object().shape({
  companyName: Yup.string().required(() => jsxFieldRequried),
  address: Yup.string().required(() => jsxFieldRequried),
  zipCode: Yup.number()
    .typeError(() => jsxNumberOnly)
    .required(() => jsxFieldRequried),
  cityName: Yup.string().required(() => jsxFieldRequried),
  country: Yup.string().required(() => jsxFieldRequried),
  vatNumber: Yup.string().required(() => jsxFieldRequried),
  invoiceEmail: Yup.string()
    .email()
    .required(() => jsxFieldRequried),
  phone: Yup.string().required(() => jsxFieldRequried),
})

export default BillingInformationFormScheme
