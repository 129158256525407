import { ColorScale } from 'plotly.js-gl3d-dist-min'

const greenToRed: ColorScale = [
  [0.0, '#9BD383'],
  [0.25, '#FDD569'],
  [0.5, '#FDD569'],
  [0.75, '#FF7B75'],
  [1, '#E72100'],
]

export default {
  greenToRed,
}
