import { gql } from '@apollo/client'

export const GET_ALL_TICKETS = gql`
  query TableTicketsGet(
    $activeProjects: String
    $orderBy: String
    $order: String
    $offset: Int = 0
    $first: Int
    $search: String
    $status: [String!]
    $ticketType: [ID!]
    $property: ID
    $propertyGroup: [ID]
    $containerGroupIds: [ID]
    $createdAtFrom: DateTime
    $createdAtTo: DateTime
    $ticketCategory: [ID!]
    $containerIds: [ID]
    $routeIds: [ID]
  ) {
    allTickets(
      activeProjects: $activeProjects
      orderBy: $orderBy
      order: $order
      offset: $offset
      first: $first
      search: $search
      status: $status
      ticketType: $ticketType
      property: $property
      propertyGroup: $propertyGroup
      containerGroupIds: $containerGroupIds
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      ticketTypeCategory: $ticketCategory
      containerIds: $containerIds
      routeIds: $routeIds
    ) {
      totalCount
      edges {
        node {
          id
          createdAt
          ticketType {
            id
            name
          }
          status
          priority
          property {
            id
            externalPropertyNumber
            location {
              name
            }
          }
          container {
            id
            containerId
            containerType {
              id
              name
            }
          }
          assignee {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
