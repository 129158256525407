import { gql } from '@apollo/client'

/* Fragments */
export const FrgCompanySettings = gql`
  fragment FrgCompanySettings on CompanySettingsType {
    loginImages
  }
`

/* Queries */
export const GET_COMPANY_SETTINGS = gql`
  ${FrgCompanySettings}
  query GetCompanySettings($dns: String!) {
    companySettings(dns: $dns) {
      ...FrgCompanySettings
    }
  }
`
/* Mutations */
