import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'
import React from 'react'
import { Opt } from '../options'

export type TSelectTimeFormatValue = '12' | '24'

const options: Opt<TSelectTimeFormatValue>[] = [
  { value: '12', label: <T _str="12-hour clock (PM/AM)" /> },
  { value: '24', label: <T _str="24-hour format" /> },
]

const SelectTimeFormat = (props: SelectProps) => {
  return <Select options={options} {...props} />
}

export default SelectTimeFormat
