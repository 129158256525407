import React from 'react'
import { EnvironmentOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { T } from '@transifex/react'
import { Typography } from '@wastehero/storybook/lib/components'
import StyledTag from '@wastehero/storybook/lib/components/StyledTag'
import { NO_DATA } from '@wastehero/storybook/lib/components/table-kit'
import { Col, Divider, Row, Tag } from 'antd'
import moment from 'moment'
import { getStyledTagProps } from '../../../../../api/helpers'
import { routingMap } from '../../../../../reducers/routing/routing-map'
import { ColWithHeadingAndData } from '../../PopupContainerDetail'
import { GET_CONTAINER_GROUP_MAP_PICKUP_ORDER } from '../api'

type PickupOrderDetailProps = {
  id: string
  nextStopId?: string
}

const PickupOrderDetail = ({ id, nextStopId }: PickupOrderDetailProps) => {
  const { data, loading } = useQuery(GET_CONTAINER_GROUP_MAP_PICKUP_ORDER, {
    variables: { id },
  })

  const {
    container,
    location,
    completedAt,
    scheduledDriverArrival,
    stopNumber,
  } = data?.pickupOrder || {}

  return (
    <Row gutter={[0, 8]} style={{ width: 380 }}>
      <Col xs={24}>
        <Row align="middle">
          {data?.pickupOrder && (
            <Col>
              <StyledTag
                style={{
                  width: 28,
                  height: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                {...getStyledTagProps(data?.pickupOrder, nextStopId === id)}
              >
                {stopNumber}
              </StyledTag>
            </Col>
          )}
          <Col>
            <Typography
              loading={loading}
              component="Link"
              href={
                routingMap.app['asset-management'].containers[':containerId'](
                  container?.id
                )._
              }
              weight="semi-bold"
              variant="body-14"
            >
              <T
                _str="Container {containerId}"
                containerId={container?.containerId}
              />
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row gutter={8} align="middle">
          <Col>
            <EnvironmentOutlined style={{ fontSize: 14, color: '#BFBFBF' }} />
          </Col>
          <Col>
            <Typography loading={loading} variant="body-14">
              {location?.name}
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      </Col>
      <ColWithHeadingAndData
        heading={<T _str="Scheduled" />}
        data={
          scheduledDriverArrival
            ? moment(scheduledDriverArrival, 'hh:mm:ss').format('LLL')
            : NO_DATA
        }
        loading={loading}
        xs={12}
      />
      <ColWithHeadingAndData
        heading={<T _str="Completed at" />}
        data={completedAt ? moment(completedAt).format('LLL') : NO_DATA}
        loading={loading}
        xs={12}
      />
      <ColWithHeadingAndData
        heading={<T _str="WasteFractions" />}
        data={<Tag>{container?.wasteFraction?.name}</Tag>}
        loading={loading}
        xs={12}
      />
      <ColWithHeadingAndData
        heading={<T _str="Ticket" />}
        data={container?.tickets?.totalCount}
        loading={loading}
        xs={12}
      />
    </Row>
  )
}

export default PickupOrderDetail
