import React, { FC, ReactNode } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Typography } from '@wastehero/storybook/lib/components'
import {
  CardWithHeader,
  CardWithHeaderProps,
} from '@wastehero/storybook/lib/layouts/cards/CardWithHeader'
import styled from 'styled-components'

type TPopupLayout1Props = {
  onClose: () => void
  addonBeforeTitle?: ReactNode
} & CardWithHeaderProps

const CardWithHeaderWrapper = styled.div`
  & .wh-Layouts-CardWithHeader-header {
    padding: 8px 16px !important;
  }
`

const PopupLayout1: FC<TPopupLayout1Props> = ({
  onClose,
  children,
  title,
  addonBeforeTitle,
  ...props
}) => {
  return (
    <CardWithHeaderWrapper>
      <CardWithHeader
        {...props}
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {addonBeforeTitle}
              </div>
              <div>
                <Typography
                  style={{ color: 'white' }}
                  weight="semi-bold"
                  variant="body-14"
                >
                  {title}
                </Typography>
              </div>
            </div>
            <CloseOutlined style={{ fontSize: '1rem' }} onClick={onClose} />
          </div>
        }
      >
        <div style={{ padding: '1rem' }}>{children}</div>
      </CardWithHeader>
    </CardWithHeaderWrapper>
  )
}

export default PopupLayout1

export const withPopupLayout1Static =
  (
    Component: FC<$TSFixMe>,
    add: Omit<TPopupLayout1Props, 'onClose'>
  ): FC<Pick<TPopupLayout1Props, 'onClose'>> =>
  ({ onClose, ...rest }) =>
    (
      <PopupLayout1 onClose={onClose} {...add}>
        <Component {...rest} />
      </PopupLayout1>
    )
