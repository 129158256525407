import { mockPaymentStatusTypeLabelMap } from './consts'

export type TPaymentStatusType = `draft` | `unpaid` | `paid`

export type MockPaymentStatusType = {
  value: TPaymentStatusType
}

export const API_MOCK_PAYMENT_STATUS_TYPE_DATA: MockPaymentStatusType[] = (
  Object.keys(mockPaymentStatusTypeLabelMap) as TPaymentStatusType[]
).map((k) => ({ value: k }))
