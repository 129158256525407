import { T } from '@transifex/react'
import { Tag } from 'antd'
import React from 'react'

export const booleanize = (val: $TSFixMe) => {
  switch (val) {
    case false:
      return (
        <Tag>
          <T _str="False" />
        </Tag>
      )
    case true:
      return (
        <Tag>
          <T _str="True" />
        </Tag>
      )
    case null:
    case '':
      return (
        <Tag>
          <T _str="None" />
        </Tag>
      )
    default:
      return val
  }
}
