import { pathJoin } from '../../../utils'
import { createOffersRoutesMap } from './offers'
import { createCustomerDetailRouteMap } from './customer-detailed-view'
import { createCustomerCreateRouteMap } from './create-customer'

export const createControlCenterRoutesMap = (prefix: string) => ({
  leads: pathJoin(prefix)('leads').chain,
  customers: pathJoin(prefix)('customers').chain,
  offers: pathJoin(prefix)('offers').chainMapAuto(createOffersRoutesMap),
  'customers/:id': (id: string) =>
    pathJoin(prefix)('customers')(id).chainMap(
      createCustomerDetailRouteMap(
        pathJoin(prefix)('customers')(id).value.slice(1)
      )
    ),
  create: pathJoin(prefix)('create').chainMapAuto(createCustomerCreateRouteMap),
  'test-sockets': pathJoin(prefix)('test-sockets').chain,
})
