import { dot, pathJoin } from '../../utils'
import { createRoutesMapAssetManagement } from './asset-management'
import { createTicketsRouteMap } from './tickets'
import { createRoutesMapFleetManagement } from './fleet-management'
import { createUserManagementRoutesMap } from './user-management'
import { createOperationManagementRouteMap } from './operation-management'
import { createControlCenterRoutesMap } from './control-center'
import { createSettingsPageRouteMap } from './settings'
import { createAnalyticsRoutesMap } from './analytics'
import { createCustomerManagementRouteMap } from './customer-management'
import { createMarketPlaceRouteMap } from './marketplace'
import { createUsersRouteMap } from './user'

export const createRouteMapMain = (prefix: string) => ({
  dashboard: dot(pathJoin(prefix)(`dashboard`).value),
  tickets: pathJoin(prefix)('tickets').chainMapAuto(createTicketsRouteMap),
  'asset-management': pathJoin(prefix)('asset-management').chainMapAuto(
    createRoutesMapAssetManagement
  ),
  'fleet-management': pathJoin(prefix)('fleet-management').chainMapAuto(
    createRoutesMapFleetManagement
  ),
  'user-management': pathJoin(prefix)('user-management').chainMap(
    createUserManagementRoutesMap(
      pathJoin(prefix)('user-management').value.slice(1)
    )
  ),
  'operation-management': pathJoin(prefix)('operation-management').chainMap(
    createOperationManagementRouteMap(
      pathJoin(prefix)('operation-management').value.slice(1)
    )
  ),
  controlCenter: pathJoin(prefix)('control-center').chainMap(
    createControlCenterRoutesMap(
      pathJoin(prefix)('control-center').value.slice(1)
    )
  ),
  reports: dot(pathJoin(prefix)('reports').value),
  support: dot(pathJoin(prefix)('support').value),
  settings: pathJoin(prefix)('settings').chainMap(
    createSettingsPageRouteMap(pathJoin(prefix)('settings').value.slice(1))
  ),
  notifications: dot(pathJoin(prefix)('notifications').value),
  'send-mqtt': dot(pathJoin(prefix)('send-mqtt').value),
  analytics: {
    _: pathJoin(prefix)('analytics').value,
    ...createAnalyticsRoutesMap(pathJoin(prefix)('analytics').value.slice(1)),
  },
  'customer-management': pathJoin(prefix)('customer-management').chainMap(
    createCustomerManagementRouteMap(
      pathJoin(prefix)('customer-management').value.slice(1)
    )
  ),
  'market-place': pathJoin(prefix)('market-place').chainMapAuto(
    createMarketPlaceRouteMap
  ),
  'users/:userId': (userId: string) =>
    pathJoin(prefix)('users')(userId).chainMapAuto(createUsersRouteMap),
})
