import { StyledTagProps } from '@wastehero/storybook/lib/components/StyledTag'
import { array } from '../../utils/array'
import { RoutesPickUpOrderStatusChoices } from '../consts'

/** the pickupOrder container is emptied. */
const isEmptied = (pickupOrder: $TSFixMe) =>
  (pickupOrder.status as RoutesPickUpOrderStatusChoices) === 'COMPLETED'

/** is pickup order completed i.e the container was emptied */
const isCompleted = (pickupOrder: $TSFixMe) =>
  !!pickupOrder.completedAt && isEmptied(pickupOrder)

/** the pickupOrder is still pending.  */
const isPending = (pickupOrder: $TSFixMe) => !pickupOrder.completedAt

/** is pickup order uncompleted i.e the container was not emptied and pickupOrder was completed */
const isUnCompleted = (pickupOrder: $TSFixMe) =>
  !!pickupOrder.completedAt && !isEmptied(pickupOrder)

const SUCCESS = '#85B400'
const DANGER = '#FF4D4F'

export const getStyledTagProps = (
  /** stop: PickUpOrderType  */
  stop: $TSFixMe,
  isNext?: boolean
): StyledTagProps => {
  const isCompletedValue = isCompleted(stop)

  return {
    variant: array<StyledTagProps['variant']>(
      isNext && 'default',
      !isPending(stop) && 'default',
      'bordered'
    )[0],
    color:
      array<StyledTagProps['color']>(
        isNext && '#1890FF',
        isUnCompleted(stop) && DANGER,
        isCompletedValue && SUCCESS
      )?.[0] || 'default',
  }
}

export const getLocation = (
  /**
   * Graphql: PickUpOrderType!
   */
  node: $TSFixMe
) => {
  const { container, depot, ticket } = node

  const r = array(
    container?.location,
    depot?.location,
    ticket?.location,
    ticket?.container?.location,
    ticket?.property?.location
  )[0]

  return r
}

export const getGroupKey = (
  /**
   * GraphqlType: PickupOrderType
   * {
   *  container {
   *   containerGroup {
   *     id
   *   }
   *  }
   * }
   */
  stop: $TSFixMe,
  mergeStatus?: boolean
) => {
  if (stop.container?.containerGroup?.id) {
    // then group is based on completed at.
    return array(
      mergeStatus && stop.container?.containerGroup?.id,
      stop.completedAt &&
        isEmptied(stop) &&
        (stop.container?.containerGroup?.id as string).concat(
          'completed-not-emptied'
        ),
      stop.completedAt &&
        (stop.container?.containerGroup?.id as string).concat('completed'),
      stop.container?.containerGroup?.id
    )[0]
    // eslint-disable-next-line no-param-reassign
  }
  return undefined
}

export const apiHelperPickupOrder = {
  isEmptied,
  isCompleted,
  isUnCompleted,
  isPending,
  getStyledTagProps,
  getGroupKey,
  getLocation,
}
