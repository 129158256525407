import { Space, Tag } from 'antd'
import React from 'react'
import { TeamOutlined } from '@ant-design/icons'
import {
  FromTo,
  TFromToProps,
  AvatarWithName,
} from '@wastehero/storybook/lib/components'

import {
  TEventTicketEventEventTypeChoices,
  TicketTicketStatusChoices,
  TicketTicketPriorityChoices,
  ticketTicketStatusChoicesLabelMap,
  ticketTicketPriorityChoicesTagColorMap,
  ticketTicketPriorityChoicesLabelMap,
} from '../api/consts'
import { ticketTicketStatusChoicesTagColorMap } from '../api/consts/TicketTicketStatusChoices'
import { ticketTicketPriorityChoicesIconMap } from '../api/consts/TicketTicketPriorityChoices'
import { Link } from '../reducers/routing/child-router-factory'

type User = {
  firstName: string
  lastName: string
  logo?: string
  id: string
}

type Team = {
  name: string
  id: string
}

export type TEventChangeTagProps = {
  eventType: TEventTicketEventEventTypeChoices
  fromStatus?: TicketTicketStatusChoices
  toStatus?: TicketTicketStatusChoices
  fromAssignee?: User
  toAssignee?: User
  fromPriority?: TicketTicketPriorityChoices
  toPriority?: TicketTicketPriorityChoices
  toAssignedTeam?: Team
  fromAssignedTeam?: Team
}

type SupportedTypes = keyof Omit<
  Record<TEventTicketEventEventTypeChoices, string>,
  'A_10'
>

const cellRenderMaps = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  A_1: (val: TicketTicketStatusChoices, _short: boolean) => (
    <Tag
      style={{ margin: 0 }}
      color={ticketTicketStatusChoicesTagColorMap[val]}
    >
      {ticketTicketStatusChoicesLabelMap[val]}
    </Tag>
  ),
  A_3: (val: TicketTicketPriorityChoices, short: boolean) => {
    const Icon = ticketTicketPriorityChoicesIconMap[val]

    if (Icon)
      return (
        <Space>
          {short && <Icon />}
          {!short && (
            <Tag color={ticketTicketPriorityChoicesTagColorMap[val]}>
              {ticketTicketPriorityChoicesLabelMap[val]}
            </Tag>
          )}
        </Space>
      )
    return <></>
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  A_2: ({ firstName = '', lastName = '', logo, id }: User) => (
    <Link to={({ routingMap }) => routingMap.app['users/:userId'](id)._}>
      <AvatarWithName
        avatarProps={{ src: logo, size: 'small' }}
        typographyProps={{ component: 'Link' }}
      >
        {firstName} {lastName}
      </AvatarWithName>
    </Link>
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  A_4: ({ name, id }: Team) => (
    <AvatarWithName avatarProps={{ icon: <TeamOutlined />, size: 'small' }}>
      {name}
    </AvatarWithName>
  ),
}

const EventChangeTag = ({
  eventType,
  fromAssignee,
  fromPriority,
  fromStatus,
  toAssignee,
  toPriority,
  toStatus,
  toAssignedTeam,
  fromAssignedTeam,
  short,
}: TEventChangeTagProps & Pick<TFromToProps, 'short'>) => {
  const from = fromAssignee || fromPriority || fromStatus || fromAssignedTeam
  const to = toAssignee || toPriority || toStatus || toAssignedTeam
  return (
    <FromTo
      // @ts-expect-error WIP
      from={from && cellRenderMaps[eventType as SupportedTypes]?.(from, short)}
      // @ts-expect-error WIP
      to={to && cellRenderMaps[eventType as SupportedTypes]?.(to, short)}
      short={short}
    />
  )
}

export default EventChangeTag
