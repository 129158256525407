import React from 'react'
import { T } from '@transifex/react'

import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'
import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'

const formatResponse = ({ node }: { node: GetCallResponseEdgeNode }): Opt => ({
  label: node.name,
  value: node.id,
})

export type SelectTeamProps = {
  variables?: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps'] &
  Pick<AsyncSelectWithFetchMoreProps, 'render'>

export const SelectTeam = ({
  variables,
  render,
  ...props
}: SelectTeamProps) => {
  const queryVariables = {
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allTeams.edges.map(formatResponse)
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      selectProps={{
        placeholder: <T _str="Team" />,
        allowClear: true,
        ...props,
      }}
      render={render}
      value={props.value}
    />
  )
}
