import React, { useState } from 'react'
import styled from 'styled-components'
import { Space } from 'antd'
import { T } from '@transifex/react'
import { RangeSelectorWithRadio } from '@wastehero/storybook/lib/components'

import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import useTable from './table'

import {
  FiltersProiver,
  FilterByStatus,
  FilterByDateRange,
  ResetFilters,
  FilterByProperty,
  FilterByPropertyGroup,
  FilterByContainers,
} from './filters.elements'
import { LayoutTableWithHideableFilters } from '../../../layouts/LayoutWithHideableFilters'
import { CardInvoice } from '../../cards'
import { ColumnKey } from './table/ui'
import { TFilters } from './filters.context'

const Wrapper = styled.div`
  & {
    .${RangeSelectorWithRadio.classes.wrapper} {
      justify-content: flex-end;
      flex-wrap: nowrap;
    }
  }
`
export type InvoiceTableInitialFilters = Omit<TFilters, 'dateRange'>

type InvoiceTableIntegrationProps = {
  initialFilters?: InvoiceTableInitialFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
  initialInvoice?: string
  initialReciept?: string
}

const TableIntegration = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  initialInvoice,
  initialReciept,
}: InvoiceTableIntegrationProps) => {
  const [invoiceId, setInvoiceId] = useState(initialInvoice)
  const [recieptId, setRecieptId] = useState(initialReciept)

  const setIds = (id?: string, type?: string) => {
    if (type === 'invoice') {
      setInvoiceId(id || '')
      setRecieptId('')
    } else if (type === 'reciept') {
      setInvoiceId('')
      setRecieptId(id || '')
    } else {
      setInvoiceId('')
      setRecieptId('')
    }
  }

  const { columnsFilterAndOrder, table, search } = useTable({
    initialFilters,
    tableColumnsTreeFilterAndOrderProps,
    setIds,
  })

  return (
    <Wrapper>
      <LayoutTableWithHideableFilters
        layoutOffset={216}
        jsx={{
          columnsFilter: columnsFilterAndOrder,
          dateFilter: (
            <Space>
              <FilterByDateRange />
              <ResetFilters />
            </Space>
          ),
          filters: [
            <FilterByStatus />,
            <FilterByProperty />,
            <FilterByPropertyGroup />,
            <FilterByContainers />,
          ],
          search,
          table,
        }}
        renderTableSection={(node) => (
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ width: '60%' }}>{node?.table}</div>
            <div
              style={{
                padding: '1rem',
                background: 'var(--bg-color)',
                width: '40%',
                height: '100%',
              }}
            >
              {invoiceId && (
                <CardInvoice id={invoiceId} mode="invoice" setIds={setIds} />
              )}
              {recieptId && (
                <CardInvoice id={recieptId} mode="reciept" setIds={setIds} />
              )}
              {!invoiceId && !recieptId && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'white',
                  }}
                >
                  <T _str="Please select an item on the left" />
                </div>
              )}
            </div>
          </div>
        )}
      />
    </Wrapper>
  )
}

const InvoiceTable = (props: InvoiceTableIntegrationProps) => {
  return (
    <FiltersProiver>
      <TableIntegration {...props} />
    </FiltersProiver>
  )
}

export default InvoiceTable
