import React from 'react'
import { Skeleton, Space, Empty, Divider } from 'antd'
import { T } from '@transifex/react'

import { useQuery } from '@apollo/client'

import { GET } from './api'
import EventChangeTag from '../../../../../../../new-components/EventChangeTag'
import CRMInfoList from '../../../ui/CRMInfoList'

export type TEventLogDetailProps = {
  id: string
  compact?: boolean
}

const EventLogDetail = ({ id, compact }: TEventLogDetailProps) => {
  const { data, loading } = useQuery(GET, {
    variables: {
      id,
    },
  })

  if (loading) return <Skeleton loading />
  if (!loading && !data) return <Empty description={<T _str="No data" />} />

  const {
    eventType,
    fromAssignee,
    fromPriority,
    fromStatus,
    toAssignee,
    toPriority,
    toStatus,
    fromAssignedTeam,
    toAssignedTeam,
    ticket,
  } = data?.ticketEventLog || {}

  const {
    location,
    container,
    property,
    assignee,
    assignedTeam,
    id: ticketId,
    ticketType,
  } = data?.ticketEventLog?.ticket || {}

  const eventChangeProps = {
    eventType,
    fromAssignee,
    fromPriority,
    fromStatus,
    toAssignee,
    toPriority,
    toStatus,
    fromAssignedTeam,
    toAssignedTeam,
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <EventChangeTag {...eventChangeProps} />
      <Divider style={{ margin: 0 }} />
      <CRMInfoList
        isContainerLevel={ticket?.ticketType?.isContainerLevel}
        compact={compact}
        loading={loading}
        content={{
          address:
            location?.name ||
            container?.location?.name ||
            property?.location?.name,
          assigne: assignee && {
            id: assignee?.id,
            name: assignee?.firstName
              ?.concat(` `)
              .concat(assignee?.lastName || ``),
          },
          assigneTeam: assignedTeam && {
            id: assignedTeam?.id,
            name: assignedTeam?.name,
          },
          container: container && {
            id: container.id,
            name: container.containerId,
          },
          property: property && {
            id: property.id,
            name: property.location?.name,
          },
          ticket: ticketId && {
            id: ticketId,
            name: ticketType.name,
          },
        }}
      />
    </Space>
  )
}

export default EventLogDetail
