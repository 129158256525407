import React from 'react'
import { T } from '@transifex/react'
import { array } from '../../../utils/array'

export type TCellRoute = {
  routeScheme: {
    name: string
  }
  driver?: {
    user: {
      firstName: string
      lastName: string
    }
  }
}

export const getCellRouteName = (
  /**
  * RouteType: {
  *   routeScheme {
        name
      }
      driver {
        user {
          firstName
          lastName
        }
      }
  * }
  */
  value: TCellRoute
) => {
  const driverFirstName = value.driver?.user.firstName
  const driverLastName = value.driver?.user.lastName

  return array(
    value.routeScheme.name,
    (driverFirstName || driverLastName) && '|',
    driverFirstName,
    driverLastName
  ).join(' ')
}

export const getCellRouteNameWithTitle = (
  /** Should be RouteDef! with location and externalPropertyNumber
   * Example:
   *  value {
        externalPropertyNumber
        location {
          name
        }
      }
   */
  value: TCellRoute
) => {
  const label = getCellRouteName(value)
  return <T _str="Route: {label}" label={label} />
}
