import React, { useState, ReactNode } from 'react'
import { Popup } from 'react-mapbox-gl'

type TState<T> =
  | ({
      lngLat: [number, number]
    } & T)
  | undefined

type TUsePopupArgs<T> = {
  children: (state: TState<T>, onClose: () => void) => ReactNode
}

export const usePopup = <T extends Record<string, unknown>>({
  children,
}: TUsePopupArgs<T>): [
  React.Dispatch<React.SetStateAction<TState<T>>>,
  JSX.Element
] => {
  const [state, setState] = useState<TState<T>>()

  return [
    setState,
    state ? (
      <Popup coordinates={state.lngLat}>
        <div style={{ position: 'relative' }}>
          {children(state, () => setState(undefined))}
        </div>
      </Popup>
    ) : (
      <></>
    ),
  ]
}
