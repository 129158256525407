import { mockStatusTypeLabelMap } from './consts'

export type TStatusType = `created` | `pending` | `completed` | `rejected`

export type MockStatusType = {
  value: TStatusType
}

export const API_MOCK_STATUS_TYPE_DATA: MockStatusType[] = (
  Object.keys(mockStatusTypeLabelMap) as TStatusType[]
).map((k) => ({ value: k }))
