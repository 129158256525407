import React from 'react'
import { T } from '@transifex/react'
import { useSelector } from 'react-redux'
import { getSavedActiveProjects } from '../../../components/shared/utils'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'

const formatResponse = ({
  node: {
    id,
    scheduledDay,
    vehicle: { name },
    driver: {
      user: { firstName, lastName },
    },
  },
}: {
  node: GetCallResponseEdgeNode
}): Opt => ({
  label: `${scheduledDay} | ${name} | ${firstName} ${lastName}`,
  value: id,
  key: id,
})

type SelectRouteProps = {
  variables: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps']

export const SelectRoute = ({ variables, ...props }: SelectRouteProps) => {
  const activeProjects = useSelector((state) => getSavedActiveProjects(state))
  const queryVariables = {
    activeProjects,
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allRoutes.edges.map((val: $TSFixMe) => formatResponse(val))
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      selectProps={{
        placeholder: <T _str="Route" />,
        allowClear: true,
        ...props,
      }}
      value={props.value}
    />
  )
}
