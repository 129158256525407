import React from 'react'
import { T } from '@transifex/react'
import {
  getSimpleColumn,
  getTagColumn,
  CreateTableUIStatefulColumnsArgs,
  createTableUIStatefulColumns,
  getDateColumn,
  getActionsDropDown,
} from '@wastehero/storybook/lib/components/table-kit'
import { capitalize } from 'lodash'
import FillLevelCell from '../../../../components/main/routes/assset-management/routes/containers/routes/exact/table/FillLevelCell'
import { getLinkColumn, LinkCell } from '../../../../ui/table-kit-extended'

export type TActions = 'edit' | `view`

export type DataCell = {
  _id: string
  projectName: string
  locationName: string
  battery: string
  rsi: string
  fillLevel: string
  fillLevelRaw: string
  sensorId: string
  description: string
  containerId: LinkCell
  containerType: string
  pickupSetting: string
  pickupMethod: string
  lastSeenDate: string
  latestPickUp: string
  nextPickUp: string
  status: string
  wasteFraction: string
  measurementSetting: string
  price: string
}

export type ColumnKey = keyof Omit<DataCell, '_id'>

export type SortKey = 'ascend' | 'descend'

const simpleColumn = getSimpleColumn()

const pickUpMethodColorMap = {
  DYNAMIC: 'green',
}

const statusColorMap = {
  AVAILABLE: 'green',
  ON_HOLD: 'orange',
  DEFECT: 'error',
}

export const columnsRenderMap: CreateTableUIStatefulColumnsArgs<
  DataCell,
  ColumnKey,
  TActions
>['columnsRenderMap'] = () => ({
  projectName: {
    title: <T _str="Project name" />,
    render: simpleColumn,
    sorter: true,
  },
  locationName: {
    title: <T _str="Address" />,
    render: simpleColumn,
    sorter: true,
  },
  battery: {
    title: <T _str="Battery" />,
    render: simpleColumn,
    sorter: true,
  },
  rsi: {
    title: <T _str="RSSI" />,
    render: simpleColumn,
    sorter: true,
  },
  fillLevel: {
    title: <T _str="Fill level" />,
    render: (val) => <FillLevelCell fillPercentage={val} />,
    sorter: true,
  },
  fillLevelRaw: {
    title: <T _str="Distance measured" />,
    render: (val) => (val ? <span>{val?.toFixed(2)} m</span> : '-'),
    sorter: true,
  },
  sensorId: {
    title: <T _str="Sensor ID" />,
    render: simpleColumn,
  },
  description: {
    title: <T _str="Description" />,
    render: simpleColumn,
    sorter: true,
  },
  containerId: {
    title: <T _str="Container ID" />,
    render: getLinkColumn(),
    sorter: true,
  },
  wasteFraction: {
    title: <T _str="Waste fraction" />,
    render: simpleColumn,
    sorter: true,
  },
  containerType: {
    title: <T _str="Container type" />,
    render: simpleColumn,
    sorter: true,
  },
  pickupSetting: {
    title: <T _str="Pickup setting" />,
    render: simpleColumn,
    sorter: true,
  },
  pickupMethod: {
    title: <T _str="Pickup method" />,
    render: getTagColumn(pickUpMethodColorMap, (val) => capitalize(val)),
    sorter: true,
  },
  lastSeenDate: {
    title: <T _str="Last measurement" />,
    render: getDateColumn('YYYY-MM-DD HH:mm'),
  },
  latestPickUp: {
    title: <T _str="Last collection" />,
    render: getDateColumn('YYYY-MM-DD HH:mm'),
    sorter: true,
  },
  nextPickUp: {
    title: <T _str="Next collection" />,
    render: getDateColumn('YYYY-MM-DD HH:mm'),
    sorter: true,
  },
  status: {
    title: <T _str="Status" />,
    render: getTagColumn(statusColorMap, (val) => capitalize(val)),
    sorter: true,
  },
  measurementSetting: {
    title: <T _str="Measurement setting" />,
    render: simpleColumn,
  },
  price: {
    title: <T _str="Price" />,
    render: simpleColumn,
  },
})

const { useTableUI } = createTableUIStatefulColumns<
  DataCell,
  ColumnKey,
  TActions
>({
  actionsRender: ({ on: { edit, view, customRender } }) => {
    if (customRender) {
      return customRender
    }
    return getActionsDropDown({
      actions: [
        [<T _str="View" />, view],
        [<T _str="Edit" />, edit],
      ],
    })
  },
  columnsRenderMap,
  tableColumnsTreeFilterAndOrderProps: {
    init: {
      checked: [
        'locationName',
        'containerId',
        'wasteFraction',
        'containerType',
        'pickupSetting',
        'latestPickUp',
        'status',
      ],
      order: [
        'projectName',
        'locationName',
        'battery',
        'rsi',
        'fillLevel',
        'fillLevelRaw',
        'sensorId',
        'description',
        'containerId',
        'containerType',
        'pickupSetting',
        'pickupMethod',
        'lastSeenDate',
        'latestPickUp',
        'nextPickUp',
        'status',
        'wasteFraction',
        'measurementSetting',
        'price',
      ],
    },
  },
})

export { useTableUI }
