const getNextStopIndex = (
  /**
   * Graphql: RouteType
   * Schema: {
   *  stops {
   *   edges {
   *    node {
   *      stopNumber
   *      completedAt
   *    }
   *   }
   *  }
   * }
   */
  route: $TSFixMe
) => {
  return Math.min(
    ...route.stops.edges
      .filter((e: $TSFixMe) => !e.node.completedAt)
      .map((e: $TSFixMe) => e.node.stopNumber)
  )
}

const getNextStop = (
  /**
   * Graphql: RouteType
   * Schema: {
   *  stops {
   *   edges {
   *    node {
   *      stopNumber
   *      completedAt
   *    }
   *   }
   *  }
   * }
   */
  route: $TSFixMe
): Record<string, unknown> => {
  const nextStop = getNextStopIndex(route)
  return route.stops.edges.find((e: $TSFixMe) => e.node.stopNumber === nextStop)
    ?.node
}

export const apiHelperRoute = {
  getNextStopIndex,
  getNextStop,
}
