import apolloClient from '../../../../components/graphql/apollo'

import { TGetClusterPointsFn } from '../../types'

import { GET_CLUSTER_POINTS, TVariables } from './api'

export const getPropertySourceClusterPoints: TGetClusterPointsFn<TVariables> = (
  clusterId,
  bbox,
  filters
) => {
  return apolloClient
    .query({
      query: GET_CLUSTER_POINTS,
      variables: {
        clusterId,
        bbox,
        ...filters,
      },
    })
    .then(({ data }) => {
      return data?.allProperties?.edges?.map((e: $TSFixMe) => e.node.id) || []
    })
}
