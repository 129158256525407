import React, { ReactNode } from 'react'
import {
  paymentIntervalsLabelMapPerInterval,
  TPaymentIntervals,
} from '../../../../../consts'
import { formatPayment } from '../../../../../utils/currency'

export type PricingOption<T extends string = string> = {
  price: number
  label: ReactNode
  id: T
  interval: TPaymentIntervals
}

export const generatePriceChangeOptions = (
  current: string,
  prices: PricingOption[]
) => {
  const { price: currentPrice } = prices.find(({ id }) => id === current) || {}

  if (!currentPrice) return []

  return prices.map(({ id, price, label, interval }) => ({
    labelDiff: (
      <>
        (
        {id === current ? (
          'current'
        ) : (
          <>
            {formatPayment(price, 'dkk')}/
            {paymentIntervalsLabelMapPerInterval[interval]}
          </>
        )}
        )
      </>
    ),
    label,
    value: id,
  }))
}
