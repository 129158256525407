import { pathJoin } from '../../../utils'
import { createPropertyGroupsRouteMap } from './property-groups'
import { createContainerRoutesMap } from './assets'
import { createPropertyRoutesMap } from './property'

export const createCustomerManagementRouteMap = (prefix: string) => ({
  'inquiry-management': pathJoin(prefix)('inquiry-management').chain,
  'property-groups': pathJoin(prefix)('property-groups').chainMapAuto(
    createPropertyGroupsRouteMap
  ),
  property: pathJoin(prefix)('properties').chainMapAuto(
    createPropertyRoutesMap
  ),
  assets: pathJoin(prefix)('assets').chainMapAuto(createContainerRoutesMap),
})
