import React from 'react'
import { T } from '@transifex/react'

type Ticket = {
  ticketType: {
    name: string
  }
  container?: {
    location: { name?: string }
  }
  property?: {
    location: { name?: string }
  }
}

export const getCellTicketName = (
  /** Should be TicketDefF! with container, property, and ticketType
   * Example:
   *  ticket {
        ticketType {
          name
        }
        container {
          location {
            name
          }
        }
        property {
          location {
            name
          }
        }
      }
   */
  ticket: Ticket
) => {
  return `${ticket?.ticketType?.name} | ${
    ticket?.container?.location?.name || ticket?.property?.location?.name
  }`
}

export const getCellTicketNameWithTitle = (
  /** Should be TicketDefF! with container, property, and ticketType
   * Example:
   *  ticket {
        ticketType {
          name
        }
        container {
          location {
            name
          }
        }
        property {
          location {
            name
          }
        }
      }
   */
  ticket: Ticket
) => {
  const label = getCellTicketName(ticket)
  return <T _str="Ticket: {label}" label={label} />
}
