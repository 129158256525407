import moment, { Moment } from 'moment'
import { Collapse, Row, Col } from 'antd'
import styled from 'styled-components'
import React, { FC, ReactNode } from 'react'

import { ExpandIcon } from './elements'

export type TTimelineItemProps = {
  id: string
  title: ReactNode
  time: Moment
}

const StyledCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-header-text {
    width: 100%;
  }
`

export const Wrapper: FC = ({ children }) => (
  <Collapse ghost expandIconPosition="right" expandIcon={ExpandIcon}>
    {children}
  </Collapse>
)

export const TimelineItem: FC<TTimelineItemProps> = ({
  time,
  title,
  id,
  children,
}) => {
  return (
    <Wrapper>
      <StyledCollapsePanel
        className="panel-level-1"
        header={
          <div style={{ marginLeft: 8, width: '100%' }}>
            <Row
              justify="space-between"
              wrap={false}
              gutter={16}
              style={{ width: '100%' }}
              align="middle"
            >
              <Col flex="auto">{title}</Col>
              <Col flex="none">{moment(time).format('HH:mm')}</Col>
            </Row>
          </div>
        }
        key={id}
      >
        {children}
      </StyledCollapsePanel>
    </Wrapper>
  )
}
