import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import { TServiceType } from './data'

export const mockServiceTypeLabelMap: Record<TServiceType, ReactNode> = {
  inquiry: <T _str="Inquiry" />,
  pick_up: <T _str="Pickup" />,
  request: <T _str="Request" />,
  message: <T _str="Message" />,
}
