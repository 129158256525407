import { gql } from '@apollo/client'

export type TVariables = {
  activeProjects?: string
  bbox?: $TSFixMe
  zoomLevel?: number
  search?: string
  propertyGroup?: string[]
}

export const GET = gql`
  query Mapv3PropertySourceGet(
    $activeProjects: String
    $bbox: BBoxInput!
    $zoomLevel: Int!
    $search: String
    $propertyGroup: [ID!]
  ) {
    propertyMap(
      activeProjects: $activeProjects
      bbox: $bbox
      zoomLevel: $zoomLevel
      search: $search
      propertyGroup: $propertyGroup
    ) {
      points {
        id
        latitude
        longitude
      }
      clusters {
        id
        latitude
        longitude
        totalCount
      }
    }
  }
`

export const GET_CLUSTER_POINTS = gql`
  query GetPropertyClusterPoints(
    $clusterId: String!
    $bbox: BBoxInput!
    $activeProjects: String
    $search: String
    $propertyGroup: [ID!]
  ) {
    allProperties(
      clusterId: $clusterId
      bbox: $bbox
      search: $search
      propertyGroup: $propertyGroup
      activeProjects: $activeProjects
    ) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
  }
`
