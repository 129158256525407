import React, { FC } from 'react'
import { SelectProps } from 'antd'

type SimpleUseStateFiltersResponse<State extends Record<string, unknown>> = [
  State,
  React.Dispatch<React.SetStateAction<State>>
]

type THook<State> = () => [
  State,
  (newSubject: Partial<State>) => void,
  React.Dispatch<React.SetStateAction<State>>
]

const createEnhancedFiltersSimpleState =
  <State extends Record<string, unknown>>(
    hook: () => SimpleUseStateFiltersResponse<State>
  ): THook<State> =>
  () => {
    const [state, setState] = hook()

    const set = (newSubject: Partial<State>) => {
      setState((p) => ({ ...p, newSubject }))
    }

    return [state, set, setState]
  }

export type TCreateSelectFilterArgs<
  State,
  ExtendedProps = Record<string, unknown>
> = {
  Component: FC<SelectProps & ExtendedProps>
  key: keyof State
  componentProps?: Partial<SelectProps & ExtendedProps>
}

const c2SelectFilter =
  <State extends Record<string, unknown>>(hook: THook<State>) =>
  <ExtendedProps extends Record<string, unknown> = Record<string, unknown>>({
    Component,
    key,
    componentProps,
  }: TCreateSelectFilterArgs<State, ExtendedProps>) =>
  () => {
    const [state, set] = hook()

    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ onChange: (val: any) => void; value: State... Remove this comment to see the full error message
      <Component
        {...componentProps}
        onChange={(val: $TSFixMe) => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ [x: string]: any; }' is not as... Remove this comment to see the full error message
          set({ [key]: val })
        }}
        value={state[key]}
      />
    )
  }

createEnhancedFiltersSimpleState.c2SelectFilter = c2SelectFilter

export const createEnhancedFiltersSimpleStateWithCreateFilter = <
  State extends Record<string, unknown>
>(
  hook: () => SimpleUseStateFiltersResponse<State>
) => {
  const newHook = (): [
    State,
    (newSubject: Partial<State>) => void,
    React.Dispatch<React.SetStateAction<State>>
  ] => {
    const [state, setState] = hook()

    const set = (newSubject: Partial<State>) => {
      setState((p) => ({ ...p, ...newSubject }))
    }

    return [state, set, setState]
  }

  const createFilter = c2SelectFilter(newHook as THook<State>)

  return {
    useFiltersContext: newHook,
    createFilter,
  }
}

export { createEnhancedFiltersSimpleState }
