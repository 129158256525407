import styled from 'styled-components'

export const SummaryWrapper = styled.div`
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
  flex: 1 1;
`

export const SummaryDataWrapper = styled.div`
  /* neutral/gray 300 */
  background: #f5f5f5;
  padding: 1rem;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  flex: 1 1;
`

export const SummaryFormWrapper = styled.div`
  padding: 1rem;
  flex: 0 1;
`

export const SummaryTitleWrapper = styled.div`
  /* Sub-header/16px/Semibold */

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
`

export const SummaryDataGroupWrapper = styled.div`
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 0.5px solid #d9d9d9;
`

export const SummaryDataEntryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 0.5rem;

  .data-entry-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50%;
    min-width: 50%;
    text-align: end;

    .ant-select {
      text-align: start;
    }
  }

  #ellipsis-field:hover {
    overflow: auto;
    white-space: normal;
    height: auto;
  }
`

export const IgnoreFormStyling = styled.div`
  height: 100%;
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-form-item-control-input-content {
    height: 100%;
  }
`

export const SummaryEmptyFormText = styled.div`
  /* Body 14 / regular */

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* WH token/text/secondary text */

  color: #8c8c8c;
`

export const OverrideHardcodedStyling = styled.div`
  .team-user-count {
    display: none;
  }

  .user-avatar-div {
    display: none;
  }

  .ant-btn-lg {
    height: 32px;
    max-width: 100%;
    min-width: 100%;
    padding: 4px 11px;
    font-size: 14px;
    text-align: start;
  }

  .ant-typography
    ant-typography-ellipsis
    ant-typography-single-line
    ant-typography-ellipsis-single-line {
    width: 90% !important;
  }
`
