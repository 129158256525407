import React from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'
import {
  createAsyncSelectOnMount,
  AsyncSelectOnMountPropsExtended,
  THookCreateAsyncSelectOnMountResponse,
} from './factories/createAsyncSelectOnMount'
import { useSavedActiveProjects } from '../../hooks/useSavedActiveProjects'

import {
  formatSelectOptionsFromEdgesNodeNameId,
  DataType,
} from './factories/response-to-options/getEdgesNodeNameId'

const GET_QUERY = gql`
  query GetSelectPropertyGroup($company: ID, $activeProjects: String) {
    allPropertyGroups(company: $company, activeProjects: $activeProjects) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

type TVariables = {
  company?: string
  activeProjects?: string
}

type TResponse = DataType<'allPropertyGroups'>

/* @ts-expect-error We will add it before we export. */
const SelectPropertyGroup: THookCreateAsyncSelectOnMountResponse<TVariables> & {
  /**
   * @deprecated
   * Use the main select.
   */
  WithActiveProjects: THookCreateAsyncSelectOnMountResponse<TVariables>
} = createAsyncSelectOnMount<TResponse, TVariables>({
  formatResponse: (data) =>
    formatSelectOptionsFromEdgesNodeNameId<TResponse>(
      data,
      'allPropertyGroups'
    ),
  defaultSelectProps: {
    placeholder: <T _str="Property group" />,
    allowClear: true,
    optionFilterProp: 'label',
  },
  query: GET_QUERY,
  usesActiveProjects: true,
})

const UseActiveProjects = ({
  variables,
  ...props
}: AsyncSelectOnMountPropsExtended<TVariables>) => {
  const activeProjects = useSavedActiveProjects()

  return (
    <SelectPropertyGroup
      {...props}
      variables={{ activeProjects, ...variables }}
    />
  )
}

SelectPropertyGroup.WithActiveProjects = UseActiveProjects

export { SelectPropertyGroup }
