import { gql } from '@apollo/client'

export type TVariables = { routeId?: string; search?: string }

export const GET = gql`
  fragment FrgLocation on LocationWithContainerType {
    id
    latitude
    longitude
  }

  query MapGetRoutePickupOrdersSource($routeId: ID!, $search: String) {
    route(id: $routeId) {
      id
      stops(search: $search) {
        edges {
          node {
            id
            orderId
            status
            completedAt
            stopNumber
            container {
              id
              measurement {
                id
                fillPercentage
              }
              containerGroup {
                id
              }
              location {
                ...FrgLocation
              }
            }
            depot {
              id
              name
              depotType
              location {
                ...FrgLocation
              }
            }
            ticket {
              id
              location {
                ...FrgLocation
              }
              property {
                id
                location {
                  id
                  latitude
                  longitude
                }
              }
              container {
                id
                containerId
                location {
                  ...FrgLocation
                }
              }
            }
            stopNumber
          }
        }
      }
    }
  }
`
