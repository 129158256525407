import React from 'react'
import { T } from '@transifex/react'
import { LinkProps } from '../../reducers/routing/child-router-factory'
import { CellAvatarUserSet1, TCellAvatarUserSet1Props } from '../cells'

import { defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'

export type TGetLinkPropsUserNodeValue = TCellAvatarUserSet1Props['value'] & {
  id: string
}

export const getLinkPropsUserNode =
  getLinkPropsMakeNullable<TGetLinkPropsUserNodeValue>(
    (
      /** Should be UserType! with user node included.
    {
      id
      firstName
      lastName
      logo
    }
   */
      value,
      { includeLabel } = defaultConfig
    ): LinkProps => ({
      to: ({ routingMap }) =>
        routingMap.app['user-management']['account-settings/:userId'](value.id)
          ._,
      children: includeLabel ? (
        <T
          _str="User: {name}"
          name={
            <CellAvatarUserSet1
              value={value}
              typographyProps={{ component: 'Link' }}
            />
          }
        />
      ) : (
        <CellAvatarUserSet1
          value={value}
          typographyProps={{ component: 'Link' }}
        />
      ),
    })
  )
