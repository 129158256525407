import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

/* Fragments */
/* Queries */

export const GET_ALL_USERS = gql`
  query GetAllUsers(
    $offset: Int
    $first: Int
    $activeProjects: String
    $jobTitle: String
    $isDriver: Boolean
    $q: String
    $orderBy: String
    $order: String
    $isActive: Boolean
    $company: ID
  ) {
    allUsers(
      activeProjects: $activeProjects
      jobtitle_Title_Icontains: $jobTitle
      isDriver: $isDriver
      q: $q
      order: $order
      orderBy: $orderBy
      offset: $offset
      first: $first
      isActive: $isActive
      company: $company
    ) {
      totalCount
      edges {
        node {
          id
          logo
          username
          firstName
          lastName
          email
          phoneNumber
          isMaster
          isAdmin
          isSuperuser
          isActive
          companyRole {
            id
            name
          }
          teams {
            edges {
              node {
                id
                name
              }
            }
          }
          userprojectSet {
            edges {
              node {
                id
                isSuper
                project {
                  id
                  name
                }
              }
            }
          }
          company {
            id
            name
          }
          jobtitle {
            id
            title
          }
        }
      }
    }
  }
`
export const K_GET_ALL_USERS = apiUtilGetKey(GET_ALL_USERS)

export const GET_USER = gql`
  query GetUserForManagment($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      username
      logo
      email
      phoneNumber
      isActive
      language
      company {
        id
      }
      isAdmin
      isMaster
      isDemo
      isReseller
      jobtitle {
        id
        title
        pendingTitle
      }
      userprojectSet {
        edges {
          node {
            project {
              name
            }
            apiKey
          }
        }
      }
    }
  }
`

/* Mutations */

export const UPDATE_USER = gql`
  mutation UpdateUserWithId(
    $userId: ID!
    $phoneNumber: String
    $firstName: String!
    $lastName: String!
    $logo: String
    $language: String!
  ) {
    updateUser(
      selfId: $userId
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      language: $language
      logo: $logo
    ) {
      user {
        id
        isAdmin
        isMaster
        isDemo
        username
        firstName
        lastName
        email
        phoneNumber
        logo
        language
        isActive
        company {
          id
          name
        }
        jobTitle {
          id
          title
          pendingTitle
        }
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      status
    }
  }
`

export const MUTATION_RESEND_INVITATION = gql`
  mutation MutationResendInvitation($userProjectId: ID!) {
    resendInvitation(userProjectId: $userProjectId) {
      status
    }
  }
`

export const MUTATION_INVITE_USER = gql`
  mutation MutationInviteUser(
    $email: String!
    $projectIds: [ID]!
    $companyId: ID!
  ) {
    inviteUser(email: $email, projectIds: $projectIds, companyId: $companyId) {
      userProjects {
        id
        user {
          username
          id
        }
      }
    }
  }
`

export const MUTATION_INVITE_USER_EXTENDED = gql`
  mutation MutationInviteUserExtended(
    $emails: [String!]!
    $projectIds: [ID!]!
    $roleId: ID
    $teamId: ID
  ) {
    inviteUserExtended(
      emails: $emails
      projectIds: $projectIds
      roleId: $roleId
      teamId: $teamId
    ) {
      users {
        id
      }
    }
  }
`
