import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Form } from 'antd'

import { TBaseFormSchema } from '../form-ticket'
import { TPricingHandlerProps } from './types'

const GET = gql`
  query FormTicketSinglePricingOptionsOtherPropertyLevel(
    $propertyIds: [ID!]!
    $ticketTypeId: ID!
  ) {
    ticketPricing {
      otherPropertyLevel(
        propertyIds: $propertyIds
        ticketTypeId: $ticketTypeId
      ) {
        servicePrice {
          id
          amount
        }
      }
    }
  }
`

export const TicketPricingOptionsOtherPropertyLevel = ({
  propertyId,
  ticketTypeId,
}: TPricingHandlerProps) => {
  const form = Form.useFormInstance<TBaseFormSchema>()
  const { data } = useQuery(GET, {
    variables: { propertyIds: [propertyId], ticketTypeId },
  })

  const { amount } = data?.ticketPricing?.otherPropertyLevel?.servicePrice || {}

  useEffect(() => {
    form.setFieldsValue({
      data: {
        containerTypeOptions: [],
        wasteFractionOptions: [],
        pickupSettingOptions: [],
        wasteFractionsOptions: [],
        serviceFee: {
          amount: amount / 100,
          currency: 'dkk',
        },
      },
    })
  }, [amount, form])

  return <></>
}
