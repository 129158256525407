import React from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'
import {
  createAsyncSelectOnMount,
  THookCreateAsyncSelectOnMountResponse,
} from './factories/createAsyncSelectOnMount'

const GET_QUERY = gql`
  query getRouteScheme($routeSchemeId: ID!) {
    routeScheme(id: $routeSchemeId) {
      id
      vehicles {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

type TVariables = {
  routeSchemeId: string
}

type TResponse = {
  routeScheme: {
    vehicles: {
      edges: {
        node: {
          id: string
          name: string
        }
      }[]
    }
  }
}

/* @ts-expect-error We will add it before we export. */
const SelectRouteSchemeVehicles: THookCreateAsyncSelectOnMountResponse<TVariables> & {
  WithActiveProjects: THookCreateAsyncSelectOnMountResponse<TVariables>
} = createAsyncSelectOnMount<TResponse, TVariables>({
  formatResponse: (data) =>
    data?.routeScheme.vehicles.edges.map(({ node: { id, name } }) => ({
      label: name,
      value: id,
      key: id,
    })),
  defaultSelectProps: {
    placeholder: <T _str="Vehicles" />,
    allowClear: true,
  },
  query: GET_QUERY,
})

export { SelectRouteSchemeVehicles }
