import { mockInvoiceTypeLabelMap } from './consts'

export type TInvoiceType = `recurring` | `one_time`

export type MockInvoiceType = {
  value: TInvoiceType
}

export const API_MOCK_INVOICE_TYPE_DATA: MockInvoiceType[] = (
  Object.keys(mockInvoiceTypeLabelMap) as TInvoiceType[]
).map((k) => ({ value: k }))
