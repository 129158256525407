import React from 'react'
import { T } from '@transifex/react'
import {
  Select as SelectFormik,
  SelectProps as SelectFormikProps,
} from 'formik-antd'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'
import {
  AsyncSelectOnMount,
  Opt,
  AsyncSelectOnMountProps,
} from '../AsyncSelectOnMount'

const formatResponse = ({
  node: { id, name },
}: {
  node: GetCallResponseEdgeNode
}): Opt => ({
  label: name,
  value: id,
})

type SelectProjectProps = {
  variables?: GetCallVariables
} & AsyncSelectOnMountProps['selectProps'] &
  Pick<AsyncSelectOnMountProps, 'render'>

export const SelectProject = ({
  variables,
  render,
  ...props
}: SelectProjectProps) => {
  return (
    <AsyncSelectOnMount<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allProjects.edges.map((val: $TSFixMe) => formatResponse(val))
      }
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
        variables,
      }}
      selectProps={{
        placeholder: <T _str="Project" />,
        allowClear: true,
        optionFilterProp: 'label',
        ...props,
      }}
      render={render}
    />
  )
}

export const SelectFormikProject: React.FC<
  SelectFormikProps & { variables?: GetCallVariables }
> = ({ name, variables, ...rest }) => (
  <SelectProject
    variables={variables}
    render={({ loading, options }) => (
      <SelectFormik
        name={name}
        loading={loading}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...rest}
      />
    )}
  />
)
