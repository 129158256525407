import React, { lazy } from 'react'

import {
  authenticatedRedirect,
  nonAuthenticatedRedirect,
  CreateConfigFn,
} from '../reducers/routing/utils'

const Login = lazy(() => import('../pages/login'))
const Register = lazy(() => import('../pages/register'))
const ResetPassword = lazy(() => import('../pages/reset'))
const ChangePassword = lazy(() => import('../pages/changePassword'))
const Main = lazy(() => import('../components/main'))
const CreateCompany = lazy(() => import('../pages/company/create'))
const UploadImagesForInquiries = lazy(() => import('../components/inquiries'))
const PrivacyPolicy = lazy(() => import('../components/privacy-policy'))
const UserRegister = lazy(() => import('../pages/user-register'))
const UserInvite = lazy(() => import('../pages/user-invite'))

type Keys =
  | `login`
  | `register`
  | `company`
  | `forgetPassword`
  | `app`
  | `inquiries-upload`
  | `privacy-policy`
  | `reset`
  | `user-register`
  | `user-invite/:email/:id`

export const createConfig: CreateConfigFn<Keys> = (map) => [
  {
    key: 'login',
    roles: {
      and: ['non-authenticated'],
    },
    children: React.createElement(Login),
    path: map.login._,
    exact: true,
    fallback: (roles) => authenticatedRedirect(map)(roles, `/something-wrong`),
  },
  {
    key: 'register',
    roles: {
      and: ['non-authenticated'],
    },
    children: <Register />,
    path: map.register._,
    fallback: (roles) => authenticatedRedirect(map)(roles, `/register`),
  },
  {
    key: 'company',
    path: map.company.create._,
    roles: {
      and: ['authenticated'],
    },
    children: <CreateCompany />,
    exact: true,
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
  },
  {
    key: 'forgetPassword',
    roles: {
      and: ['non-authenticated'],
    },
    path: map.forgetPassword._,
    exact: true,
    children: React.createElement(ResetPassword),
    fallback: (roles) => authenticatedRedirect(map)(roles, `/forgot-password`),
  },
  {
    key: 'inquiries-upload',
    path: map['inquiries-upload'][':driverId/:inquiryId'](
      `:driverId`,
      `:inquiryId`
    )._,
    exact: true,
    children: React.createElement(UploadImagesForInquiries),
    roles: {
      flag: '*',
    },
    fallback: () => `/`,
  },
  {
    key: 'privacy-policy',
    path: map['privacy-policy']._,
    children: <PrivacyPolicy />,
    roles: {
      flag: '*',
    },
    fallback: () => map.app.dashboard._,
  },
  {
    key: `reset`,
    path: map.reset._,
    children: React.createElement(ChangePassword),
    roles: {
      and: ['non-authenticated'],
    },
    exact: true,
    fallback: (roles) => authenticatedRedirect(map)(roles, `/login`),
  },
  {
    key: 'app',
    path: map.app._,
    roles: {
      and: ['authenticated'],
    },
    children: <Main />,
    fallback: (roles) => nonAuthenticatedRedirect(map)(roles),
  },
  {
    key: 'user-register',
    path: map['user-register/:email/:id'](':email', ':id')._,
    roles: {
      and: ['non-authenticated'],
    },
    children: <UserRegister />,
    fallback: (roles) => authenticatedRedirect(map)(roles),
  },
  {
    key: 'user-invite/:email/:id',
    path: map['user-invite/:email/:id'](':email', ':id')._,
    roles: {
      and: ['non-authenticated'],
    },
    children: <UserInvite />,
    fallback: () => map.login._,
  },
]
