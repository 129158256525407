import React, { useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Form } from 'antd'

import { TBaseFormSchema } from '../form-ticket'
import { TPricingHandlerProps } from './types'

const GET = gql`
  query FormTicketSinglePricingOptionsOtherContainerLevel(
    $containerIds: [ID!]!
    $ticketTypeId: ID!
  ) {
    ticketPricing {
      otherContainerLevel(
        containerIds: $containerIds
        ticketTypeId: $ticketTypeId
      ) {
        servicePrice {
          id
          amount
        }
      }
    }
  }
`

export const TicketPricingOptionsOtherContainerLevel = ({
  containerId,
  ticketTypeId,
}: TPricingHandlerProps) => {
  const form = Form.useFormInstance<TBaseFormSchema>()
  const { data } = useQuery(GET, {
    variables: { containerIds: [containerId], ticketTypeId },
  })

  const { amount } =
    data?.ticketPricing?.otherContainerLevel?.servicePrice || {}

  useEffect(() => {
    form.setFieldsValue({
      data: {
        containerTypeOptions: [],
        wasteFractionOptions: [],
        pickupSettingOptions: [],
        wasteFractionsOptions: [],
        serviceFee: {
          amount: amount / 100,
          currency: 'dkk',
        },
      },
    })
  }, [amount, form])

  return <></>
}
