import { gql } from '@apollo/client'
import { TDepotType } from '../../../api/consts'

export type GetCallResponseEdgeNode = {
  id: string
  name: string
  depotType: string
}

export type GetCallResponse = {
  allDepots: {
    edges: {
      node: GetCallResponseEdgeNode
    }[]
  }
}

export type GetCallVariables = {
  name?: string
  type?: TDepotType
  pageSize?: number
  offset?: number
  order?: string
  orderBy?: string
  activeProjects?: string
  activeCompanies?: string[]
  search?: string
  wasteFractions?: string
}

/* Queries */

export const GET_ALL_DEPOTS = gql`
  query Component_Select_Depots_GetAllDepots(
    $name: String
    $type: String
    $pageSize: Int
    $offset: Int
    $order: String
    $orderBy: String
    $activeProjects: String
    $activeCompanies: [ID]
    $search: String
    $wasteFractions: String
    $ids: [ID]
  ) {
    allDepots(
      name: $name
      depotType_Iexact: $type
      first: $pageSize
      offset: $offset
      order: $order
      orderBy: $orderBy
      activeProjects: $activeProjects
      activeCompanies: $activeCompanies
      search: $search
      wasteFractions: $wasteFractions
      ids: $ids
    ) {
      totalCount
      edges {
        node {
          id
          name
          depotType
        }
      }
    }
  }
`
