import { gql } from '@apollo/client'

export type TResponse = {
  ticketEventLog: {
    id: string
    createdAt: string
    body: string
    senderPropertyUser: {
      id: string
      name: string
    }
    receiverPropertyUser: {
      id: string
      name: string
    }
    viaSms: boolean
    viaEmail: boolean
    ticket: {
      id: string
    }
  }
}

export const GET = gql`
  query GetTicketEventLog($id: ID!) {
    ticketEventLog(id: $id) {
      ... on TicketMessageDef {
        id
        createdAt
        body
        senderPropertyUser {
          id
          name
        }
        receiverPropertyUser {
          id
          name
        }
        viaSms
        viaEmail
        ticket {
          id
          ticketType {
            id
            name
            isContainerLevel
          }
          property {
            id
            location {
              name
            }
          }
          container {
            id
            containerId
            location {
              name
            }
          }
          location {
            name
          }
          assignee {
            id
            firstName
            firstName
          }
        }
      }
    }
  }
`
