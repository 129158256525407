import { pathJoin } from '../../../utils'
import { createRoutesMapContainers } from './containers'
import { createRoutesMapContainerGroup } from './container-group'

export const createRoutesMapAssetManagement = (prefix: string) => ({
  containers: pathJoin(prefix)('containers').chainMapAuto(
    createRoutesMapContainers
  ),
  'container-group': pathJoin(prefix)(`container-group`).chainMapAuto(
    createRoutesMapContainerGroup
  ),
})
