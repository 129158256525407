import React from 'react'
import ReactDOM from 'react-dom'
import 'react-dates/initialize'
import './scss/styles.global.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'animate.css'
import './scss/styles.mapbox.scss'
import 'antd/dist/antd.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'typeface-roboto'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import client from './components/graphql/apollo'
import store from './store'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './setupSentry'
import './setupTransifex'
import './fonts.css'

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById('main')
)
serviceWorker.unregister()
