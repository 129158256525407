import React from 'react'
import { T } from '@transifex/react'
import { LinkProps } from '../../reducers/routing/child-router-factory'
import { CellAvatarUserSet1 } from '../cells'

import { TGetLinkPropsFn, defaultConfig } from './types'
import { getLinkPropsMakeNullable } from './utils'

export const getLinkPropsDriverNode: TGetLinkPropsFn = getLinkPropsMakeNullable(
  (
    /** Should be DriverType! with user node included.
   * Example:
   *  driver {
        id
        user {
          id
          firstName
          lastName
          logo
        }
      }
   */
    driver,
    { includeLabel } = defaultConfig
  ): LinkProps => ({
    to: ({ routingMap }) =>
      routingMap.app['fleet-management'].drivers[':driverId'](driver.id)._,
    children: includeLabel ? (
      <T
        _str="Driver: {name}"
        name={
          <CellAvatarUserSet1
            value={driver.user || {}}
            typographyProps={{ component: 'Link' }}
          />
        }
      />
    ) : (
      <CellAvatarUserSet1
        value={driver.user || {}}
        typographyProps={{ component: 'Link' }}
      />
    ),
  })
)
