import React, { ReactNode } from 'react'
import { Typography, Divider } from 'antd'

type THeadingWithDividerProps = {
  title: ReactNode
  sub: ReactNode
}

/**
 * @deprecated - Will be removed.
 */
export const HeadingWithDivider = ({
  title,
  sub,
}: THeadingWithDividerProps) => (
  <div>
    <Typography.Text style={{ color: '#909DA9' }}>{title}</Typography.Text>
    <Divider style={{ margin: `8px 0px` }} />
    {sub}
  </div>
)
