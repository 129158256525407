/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import {
  createTableUIStatefulColumns,
  CreateTableUIStatefulColumnsArgs,
  getSimpleColumn,
  getDateColumn,
  getActionsBtns,
  getTagColumn,
  getNullableColumn,
} from '@wastehero/storybook/lib/components/table-kit'
import {
  TicketTicketPriorityChoices,
  ticketTicketPriorityChoicesLabelMap,
  TicketTicketStatusChoices,
  ticketTicketStatusChoicesLabelMap,
  ticketTicketPriorityChoicesTagColorMap,
  ticketTicketStatusChoicesTagColorMap,
} from '../../../../api/consts'
import {
  getLinkColumn,
  GetLinkColumnProps,
} from '../../../../ui/table-kit-extended'

type LinkCell = {
  name: ReactNode
  linkProps: GetLinkColumnProps['linkProps']
}

export type DataCell = {
  id: string
  createdAt: string
  ticketType: string
  status: string
  priority: {
    name: string
    link: string
  }
  assignee: string
  property: LinkCell
  container?: LinkCell
  containerType?: string
}

export type SortKey = 'ascend' | 'descend'

export type ColumnKey = keyof Omit<DataCell, 'id'>

type TActions = 'view'
type StateFulProps = {
  sortField?: ColumnKey
  sortOrder?: SortKey
}

type TCreateTableUIStatefulColumnsArgs = CreateTableUIStatefulColumnsArgs<
  DataCell,
  ColumnKey,
  TActions,
  StateFulProps
>

const simpleColumn = getSimpleColumn()
const simpleTag = getNullableColumn(getTagColumn())
const dateColumn = getDateColumn('YYYY-MM-DD HH:mm')
const statusColumn = getTagColumn(
  ticketTicketStatusChoicesTagColorMap,
  (val) => ticketTicketStatusChoicesLabelMap[val as TicketTicketStatusChoices]
)
const priorityColumn = getTagColumn(
  ticketTicketPriorityChoicesTagColorMap,
  (val) =>
    ticketTicketPriorityChoicesLabelMap[val as TicketTicketPriorityChoices]
)

export const columnsRenderMap: TCreateTableUIStatefulColumnsArgs['columnsRenderMap'] =
  ({ sortOrder, sortField }) => ({
    assignee: {
      title: <T _str="Assignee" />,
      sorter: true,
      sortOrder: (sortField === 'assignee' && sortOrder) || undefined,
      render: simpleColumn,
    },
    createdAt: {
      title: <T _str="Created at" />,
      sorter: true,
      sortOrder: (sortField === 'createdAt' && sortOrder) || undefined,
      render: dateColumn,
    },
    priority: {
      title: <T _str="Priority" />,
      sorter: true,
      sortOrder: (sortField === 'priority' && sortOrder) || undefined,
      render: priorityColumn,
    },
    status: {
      title: <T _str="Status" />,
      sorter: true,
      sortOrder: (sortField === 'status' && sortOrder) || undefined,
      render: statusColumn,
    },
    ticketType: {
      title: <T _str="Ticket type" />,
      sorter: true,
      sortOrder: (sortField === 'ticketType' && sortOrder) || undefined,
      render: simpleColumn,
    },
    property: {
      title: <T _str="Property" />,
      render: getLinkColumn<$TSFixMe, LinkCell>(({ name }) => name),
    },
    container: {
      title: <T _str="Container" />,
      render: getNullableColumn(
        getLinkColumn<$TSFixMe, LinkCell>(({ name }) => name),
        undefined,
        (val: LinkCell) => !!(val && val.name && val.linkProps.to)
      ),
    },
    containerType: {
      title: <T _str="Container type" />,
      render: simpleTag,
    },
  })

const { useTableUI } = createTableUIStatefulColumns<
  DataCell,
  ColumnKey,
  TActions,
  StateFulProps
>({
  actionsRender: ({ on: { view } }) =>
    getActionsBtns<DataCell>({
      btnProps: { size: 'small' },
      spaceProps: {
        size: 'small',
        style: {
          justifyContent: 'end',
          padding: '4px',
          width: '100%',
        },
      },
      actions: [
        {
          handler: view,
          btnProps: {
            size: 'small',
            children: <T _str="View" />,
          },
        },
      ],
    }),
  tableColumnsTreeFilterAndOrderProps: {
    key: 'CRM.Tickets.TicketsTable.treeFilterAndOrderKey',
    init: {
      checked: [
        'priority',
        'status',
        'assignee',
        'createdAt',
        'ticketType',
        'property',
        'container',
        'containerType',
      ],
      order: [
        'property',
        'container',
        'priority',
        'status',
        'assignee',
        'createdAt',
        'ticketType',
        'containerType',
      ],
    },
  },
  columnsRenderMap,
})

export { useTableUI }
