import React, { useEffect, ReactNode } from 'react'
import { useQuery } from '@apollo/client'
import { snakeCase } from 'lodash'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { TableRowSelection } from 'antd/lib/table/interface'
import { DataCell, useTableUI, ColumnKey, SortKey } from './ui'
import { useFiltersContext, TFilters } from '../filters.context'
// import { DebouncedSearch } from '../../../../ui'
import {
  useSavedActiveProjects,
  useTablePaginationAndSorterAPIHelper,
} from '../../../../hooks'
import { GET_CONTAINER } from '../../../../api'
import { useRoutingHistory } from '../../../../reducers/routing/child-router-factory'
import { useFormProviderContext } from '../../../../components/main/context'
import { Column } from '../../../../components/main/routes/assset-management/routes/containers/routes/exact/table/ui'
import { withLength } from '../../../../components/main/routes/assset-management/routes/containers/routes/exact/utils'
import ExpandedContent from '../../../../components/main/routes/assset-management/routes/containers/routes/exact/table/ExpandContent'
import {
  GET_ALL_CONTAINERS,
  GET_FILL_LEVEL_MEASUREMENTS_BY_ID,
} from '../../../../components/main/routes/assset-management/routes/containers/routes/exact/api'

const formatData = (node: $TSFixMe): Column => {
  return {
    _id: node.id,
    projectName: node?.project?.name,
    locationName: node?.location.name,
    battery: node?.measurement?.batteryPercentage,
    rsi: node?.measurement?.rsi,
    fillLevel: node?.measurement?.fillPercentage,
    fillLevelRaw: node?.measurement?.fillLevel,
    sensorId: node?.deviceToContainerSet?.edges?.[0]?.node?.device?.devId,
    description: node?.description,
    containerId: {
      linkProps: {
        to: ({ routingMap }) =>
          routingMap.app['asset-management'].containers[':containerId'](node.id)
            ._,
        children: node.containerId,
      },
    },
    containerType: node?.containerType?.name,
    pickupSetting: node?.pickupSetting?.name,
    pickupMethod: node?.pickupMethod,
    lastSeenDate: node?.measurement?.createdAt,
    latestPickUp: node?.latestPickUp,
    nextPickUp: node?.nextPickUp,
    status: node?.status,
    wasteFraction: node?.wasteFraction?.name,
    measurementSetting:
      node?.deviceToContainerSet?.edges[0]?.node?.measurementSettings?.name,
  }
}

export type UseContainerTableProps = {
  search?: string
  customActionRender?: (data: DataCell) => ReactNode
  rowSelection?: TableRowSelection<DataCell>
  initialFilters?: TFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
}

export const useContainerTable = ({
  search,
  rowSelection,
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
  customActionRender,
}: UseContainerTableProps) => {
  const [
    {
      dateRange,
      containerType,
      project,
      sensor,
      status,
      wasteFraction,
      property,
      propertyGroup,
      containerGroupIds,
    },
    set,
  ] = useFiltersContext()

  const savedActiveProjects = useSavedActiveProjects()

  const setForm = useFormProviderContext()[1]
  const [history, { routingMap }] = useRoutingHistory()

  useEffect(() => {
    if (initialFilters) {
      set(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters])

  /* PAGINATION AND SORT */
  const {
    onTableChangeHandler,
    paginationVars,
    state: { pageSize, page, orderBy, order },
  } = useTablePaginationAndSorterAPIHelper<DataCell>()

  const { data, loading } = useQuery(GET_ALL_CONTAINERS, {
    variables: {
      ...paginationVars,
      search,
      activeProjects: withLength(project) || savedActiveProjects,
      containerTypes: withLength(containerType),
      sensor_Isnull: sensor || null,
      wasteFractionIds: withLength(wasteFraction),
      status: withLength(status),
      from: dateRange[0]?.toISOString(),
      to: dateRange[1].toISOString(),
      pageSize: paginationVars.first,
      orderBy: snakeCase(orderBy),
      propertyIds: property || null,
      propertyGroup,
      containerGroupIds,
      // eslint-disable-next-line no-nested-ternary
      order: order ? (order === 'ascend' ? 'asc' : 'desc') : undefined,
    },
  })

  const { table, toggler } = useTableUI({
    loading,
    onChange: onTableChangeHandler,
    tableColumnsTreeFilterAndOrderProps,
    dataSource:
      data?.allContainers?.edges.map((e: $TSFixMe) =>
        formatData({ ...e.node })
      ) || undefined,
    pagination: {
      pageSize,
      current: page,
      total: data?.allContainers?.totalCount,
      style: {
        padding: '0 1rem',
      },
      showSizeChanger: true,
    },
    scroll: {
      y: '100%',
      x: 1000,
    },
    extended: {
      sortField: orderBy as ColumnKey,
      sortOrder: order as SortKey,
    },
    sorting: {
      sortField: orderBy as ColumnKey,
      sortOrder: order as SortKey,
      fields: [],
    },
    rowKey: ({ _id }) => _id,
    on: {
      view: (col) => {
        history.push(
          // eslint-disable-next-line no-underscore-dangle
          routingMap.app['asset-management'].containers[':containerId'](col._id)
            ._
        )
      },
      edit: (col) => {
        setForm({ editForm: true })
        history.push(
          // eslint-disable-next-line no-underscore-dangle
          routingMap.app['asset-management'].containers[':containerId'](col._id)
            ._
        )
      },
      customRender: customActionRender,
    },
    expandable: {
      rowExpandable: (col) => !!col.sensorId,
      expandedRowRender: ({ _id }) => (
        <ExpandedContent
          query={GET_CONTAINER}
          queryVariables={{
            id: _id,
            fillLevelMeasurementSetCreatedAt_Lte: dateRange[1],
            fillLevelMeasurementSetCreatedAt_Gte: dateRange[0],
          }}
          measurementQuery={GET_FILL_LEVEL_MEASUREMENTS_BY_ID}
        />
      ),
    },
    rowSelection,
  })

  return {
    jsx: {
      columnsFilter: toggler,
      table,
    },
  }
}
