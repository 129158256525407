import React, { FC } from 'react'
import { Row, Col, Form as AntdForm, Space } from 'antd'
import { FormikConfig } from 'formik'
import { Form, Input, Select, SubmitButton } from 'formik-antd'
import { T, useT } from '@transifex/react'
import { CardElement, CardElementProps } from '@stripe/react-stripe-js'

import { createForm } from '@wastehero/storybook/lib/components/formik-kit'
import { SelectCountry } from '@wastehero/storybook/lib/components'

import { TFormSchema } from './types'
import { isRequired, validationSchema } from './validation'

const CARD_OPTIONS: CardElementProps['options'] = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#cbcbcb',
      color: '#cbcbcb',
      fontSize: '14px',
      fontVariant: 'tabular-nums',
      ':-webkit-autofill': {
        color: '#cbcbcb',
      },
      '::placeholder': {
        color: '#cbcbcb',
      },
    },
    invalid: {
      iconColor: '#cbcbcb',
      color: '#cbcbcb',
    },
  },
}

const { options: countryOptions } = SelectCountry

type UIProps = Record<string, unknown>

const { FormItem, withFormik } = createForm<TFormSchema, UIProps>({
  isRequired,
  validationSchema,
})

export * from './types'

export const initialValues: TFormSchema = {
  country: '',
  name: '',
}

const FormUI: FC<UIProps> = () => {
  const t = useT()

  const translationNameOfTheCard = t('Name of the card')
  const translationCountry = t('Select a country')
  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <FormItem name="name" label={<T _str="Cardholder name" />}>
            <Input name="name" placeholder={translationNameOfTheCard} />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="country" label={<T _str="Country" />}>
            <Select
              name="country"
              options={countryOptions}
              showSearch
              optionFilterProp="label"
              placeholder={translationCountry}
            />
          </FormItem>
        </Col>
        <Col xs={24}>
          <AntdForm.Item label={<T _str="Card details" />} required>
            <div
              style={{
                borderStyle: 'solid',
                width: '100%',
                height: 32,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#d9d9d9',
                padding: '0.4rem',
              }}
            >
              <CardElement options={CARD_OPTIONS} />
            </div>
          </AntdForm.Item>
        </Col>
        <Col xs={24}>
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <SubmitButton>
              <T _str="Submit" />
            </SubmitButton>
          </Space>
        </Col>
      </Row>
    </Form>
  )
}

export type FormConfig = Omit<FormikConfig<TFormSchema>, 'validationSchema'>

export default withFormik(FormUI)
