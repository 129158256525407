import React from 'react'
import { Badge } from 'antd'
import { getFillLevelColour } from '../utils'

const FillLevelPercentageCell = ({
  fillPercentage,
}: {
  fillPercentage: number
}): JSX.Element => {
  if (fillPercentage || fillPercentage === 0)
    return (
      <span>
        <Badge color={getFillLevelColour(fillPercentage)} />
        {`${fillPercentage.toFixed(2)} %`}
      </span>
    )

  return <span>-</span>
}

export default FillLevelPercentageCell
