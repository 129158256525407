import { createStatefulContextSimple } from '../../contexts'

type TState = {
  editForm: boolean
}

const { Provider: FormProvider, useContext: useFormProviderContext } =
  createStatefulContextSimple<TState>()

export { FormProvider, useFormProviderContext }
