import { gql } from '@apollo/client'

export const FrgTicketInfo = gql`
  fragment FrgTicketInfo on TicketDef {
    id
    ticketType {
      id
      name
    }
    container {
      id
      containerId
      location {
        name
      }
    }
    property {
      id
      location {
        name
      }
    }
  }
`

export const GET_ALL_LOGS = gql`
  ${FrgTicketInfo}
  query GetPropertyLogs($id: ID!, $fromDate: Date, $first: Int) {
    property(id: $id) {
      eventLog(fromDate: $fromDate, first: $first) {
        ... on TicketEventDef {
          id
          ticket {
            ...FrgTicketInfo
          }
          eventType
          createdAt
          fromStatus
          toStatus
          fromAssignee {
            id
          }
          toAssignee {
            id
          }
          fromAssignedTeam {
            id
            name
          }
          toAssignedTeam {
            id
            name
          }
          fromPriority
          toPriority
          __typename
        }
        ... on TicketCommentDef {
          id
          createdAt
          body
          __typename
          ticket {
            ...FrgTicketInfo
          }
        }
        ... on TicketMessageDef {
          id
          createdAt
          body
          receiverPropertyUser {
            id
          }
          __typename
          viaSms
          viaEmail
          ticket {
            ...FrgTicketInfo
          }
        }
      }
    }
  }
`

export const GET_ALL_PROPERTY_GROUP_LOGS = gql`
  ${FrgTicketInfo}
  query GetPropertyGroupLogs($id: ID!, $fromDate: Date, $first: Int) {
    propertyGroup(id: $id) {
      eventLog(fromDate: $fromDate, first: $first) {
        ... on TicketEventDef {
          id
          ticket {
            ...FrgTicketInfo
          }
          eventType
          createdAt
          fromStatus
          toStatus
          fromAssignee {
            id
          }
          toAssignee {
            id
          }
          fromAssignedTeam {
            id
            name
          }
          toAssignedTeam {
            id
            name
          }
          fromPriority
          toPriority
          __typename
        }
        ... on TicketCommentDef {
          id
          createdAt
          body
          __typename
          ticket {
            ...FrgTicketInfo
          }
        }
        ... on TicketMessageDef {
          id
          createdAt
          body
          receiverPropertyUser {
            id
          }
          __typename
          viaSms
          viaEmail
          ticket {
            ...FrgTicketInfo
          }
        }
      }
    }
  }
`

export const GET_ALL_CONTAINER_GROUP_LOGS = gql`
  ${FrgTicketInfo}
  query GetContainerGroupLogs($id: ID!, $fromDate: Date, $first: Int) {
    containerGroup(id: $id) {
      eventLog(fromDate: $fromDate, first: $first) {
        ... on TicketEventDef {
          id
          ticket {
            ...FrgTicketInfo
          }
          eventType
          createdAt
          fromStatus
          toStatus
          fromAssignee {
            id
          }
          toAssignee {
            id
          }
          fromAssignedTeam {
            id
            name
          }
          toAssignedTeam {
            id
            name
          }
          fromPriority
          toPriority
          __typename
        }
        ... on TicketCommentDef {
          id
          createdAt
          body
          __typename
          ticket {
            ...FrgTicketInfo
          }
        }
        ... on TicketMessageDef {
          id
          createdAt
          body
          receiverPropertyUser {
            id
          }
          __typename
          viaSms
          viaEmail
          ticket {
            ...FrgTicketInfo
          }
        }
      }
    }
  }
`

export const GET_CONTAINER_LOGS = gql`
  ${FrgTicketInfo}
  query GetContainerLogs($id: ID!, $fromDate: Date, $first: Int) {
    container(id: $id) {
      activeAgreement {
        eventLog(fromDate: $fromDate, first: $first) {
          ... on TicketEventDef {
            id
            ticket {
              id
              ...FrgTicketInfo
            }
            eventType
            createdAt
            fromStatus
            toStatus
            fromAssignee {
              id
            }
            toAssignee {
              id
            }
            fromAssignedTeam {
              id
              name
            }
            toAssignedTeam {
              id
              name
            }
            fromPriority
            toPriority
            __typename
          }
          ... on TicketCommentDef {
            id
            createdAt
            body
            __typename
            ticket {
              ...FrgTicketInfo
            }
          }
          ... on TicketMessageDef {
            id
            createdAt
            body
            receiverPropertyUser {
              id
            }
            __typename
            viaSms
            viaEmail
            ticket {
              ...FrgTicketInfo
            }
          }
        }
      }
    }
  }
`
