import { gql } from '@apollo/client'
import { FrgPropertyForInfoCard } from '../../api'

export type GetPropertyVariables = {
  id: string
}

export type GetPropertyResponse = {
  property: {
    id: string
    externalPropertyNumber: string
    company: {
      id: string
      name: string
      description: string
    }
    location: {
      name: string
      longitude: number
      latitude: number
    }
    type: {
      id: string
      name: string
    }
    note: string
    metaData: string
  }
}

export const GET_PROPERTY = gql`
  ${FrgPropertyForInfoCard}
  query CRM_CustomerOverview_Customer_GetProperty($id: ID!) {
    property(id: $id) {
      ...FrgPropertyForInfoCard
      type {
        id
      }
      note
    }
  }
`

export const MUTATION_LOG_VIEW_PROPERTY_EVENT = gql`
  mutation ($propertyId: ID!) {
    logViewPropertyEvent(propertyId: $propertyId) {
      success
    }
  }
`
