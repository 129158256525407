import React, { useState, ReactNode, useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { DebouncedSearch, TDebouncedSearchProps } from './DebouncedSearch'

type ChildrenArgs = {
  openSearchView: () => void
}

type THookDebounceSearchViewTogglerArgs = {
  children: (args: ChildrenArgs) => ReactNode
  searchProps?: TDebouncedSearchProps
}

export const useDebounceSearchViewToggler = ({
  children,
  searchProps = {},
}: THookDebounceSearchViewTogglerArgs) => {
  const [showSearch, setShowSearch] = useState(false)
  const [query, setQuery] = useState(``)

  const closeAddon = (
    <CloseOutlined
      onClick={() => {
        setShowSearch(false)
        setQuery(``)
      }}
    />
  )
  const openSearchView = () => setShowSearch(true)

  const { jsx, search } = DebouncedSearch.useDebouncedSearchSimple({
    suffix: closeAddon,
    ...searchProps,
  })

  useEffect(() => {
    setQuery(search)
  }, [search])

  return {
    jsx: showSearch ? jsx : children({ openSearchView }),
    search: query,
  }
}
