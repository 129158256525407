import React from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'
import {
  createAsyncSelectOnMount,
  AsyncSelectOnMountPropsExtended,
  THookCreateAsyncSelectOnMountResponse,
} from './factories/createAsyncSelectOnMount'
import { useSavedActiveProjects } from '../../hooks/useSavedActiveProjects'

import {
  formatSelectOptionsFromEdgesNodeNameId,
  DataType,
} from './factories/response-to-options/getEdgesNodeNameId'

const GET_QUERY = gql`
  query ($companyId: ID, $activeProjects: String) {
    allPickupSettings(companyId: $companyId, activeProjects: $activeProjects) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

type TVariables = {
  companyId?: string
  activeProjects?: string
}

type TResponse = DataType<'allPickupSettings'>

/* @ts-expect-error We will add it before we export. */
const SelectPickupScheme: THookCreateAsyncSelectOnMountResponse<TVariables> & {
  /**
   * @deprecated
   * Use the main select.
   */
  WithActiveProjects: THookCreateAsyncSelectOnMountResponse<TVariables>
} = createAsyncSelectOnMount<TResponse, TVariables>({
  formatResponse: (data) =>
    formatSelectOptionsFromEdgesNodeNameId<TResponse>(
      data,
      'allPickupSettings'
    ),
  defaultSelectProps: {
    placeholder: <T _str="Pickup Scheme" />,
    allowClear: true,
  },
  query: GET_QUERY,
  usesActiveProjects: true,
})

const UseActiveProjects = ({
  variables,
  ...props
}: AsyncSelectOnMountPropsExtended<TVariables>) => {
  const activeProjects = useSavedActiveProjects()

  return (
    <SelectPickupScheme
      {...props}
      variables={{ activeProjects, ...variables }}
    />
  )
}

SelectPickupScheme.WithActiveProjects = UseActiveProjects

export { SelectPickupScheme }
