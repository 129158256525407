import React from 'react'
import { T } from '@transifex/react'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import { GET_ALL_COMPANIES } from '../../../api'

const allCompaniesQuery = {
  query: GET_ALL_COMPANIES,
  variables: { withUsers: true },
}

export default class Settings {
  currency: $TSFixMe

  drivingDistance: $TSFixMe

  measuringDistanceVariant: $TSFixMe

  temperatureVariant: $TSFixMe

  timezone: $TSFixMe

  volumeVariant: $TSFixMe

  weightVariant: $TSFixMe

  constructor(projectSettings: $TSFixMe) {
    this.temperatureVariant = projectSettings.temperature
    this.timezone = projectSettings.timezone
    this.measuringDistanceVariant = projectSettings.measuringDistance || 'M'
    this.weightVariant = projectSettings.weight || 'KG'
    this.volumeVariant = projectSettings.volume || 'L'
    this.drivingDistance = projectSettings.drivingDistance || 'M'
    this.currency = projectSettings.currency || 'USD'
  }

  computeTemperature(value: $TSFixMe) {
    switch (this.temperatureVariant) {
      case '°C':
        return value
      case '°F':
        return (value * 9) / 5 + 32
      default:
        return value
    }
  }

  computeMeasuringDistance(value: $TSFixMe) {
    switch (this.measuringDistanceVariant) {
      case 'M':
        return value
      case 'MM':
        return value * 1000
      case 'CM':
        return value * 100
      case 'YD':
        return value * 1.093613
      case 'IN':
        return value * 39.370079
      case 'FT':
        return value * 3.28084
      default:
        return value
    }
  }

  computeDrivingDistance(value: $TSFixMe) {
    switch (this.drivingDistance) {
      case 'M':
        return value
      case 'KM':
        return value * 0.001
      case 'MI':
        return value * 0.00062137
      case 'YD':
        return value * 1.093613
      case 'FT':
        return value * 3.28084
      default:
        return value
    }
  }

  computeWeight(value: $TSFixMe) {
    switch (this.weightVariant) {
      case 'KG':
        return value
      case 'T':
        return value * 0.001
      case 'LBS':
        return value * 2.2046
      default:
        return value
    }
  }

  computeVolume(value: $TSFixMe) {
    switch (this.volumeVariant) {
      case 'M3':
        return value
      case 'L':
        return value * 1000
      case 'YD3':
        return value * 1.308
      case 'FT3':
        return value * 28.316847
      default:
        return value
    }
  }

  computeWeightToVolume(value: $TSFixMe, reverse = false) {
    const weightCof = this.computeWeight(1)
    const volumeCof = this.computeVolume(1)

    return reverse
      ? (value * volumeCof) / weightCof
      : (value * weightCof) / volumeCof
  }

  getDate(date: $TSFixMe) {
    if (!date) {
      return ''
    }

    let momentDate = moment(date)
    if (this.timezone) {
      momentDate = momentDate.tz(this.timezone)
    }
    return momentDate.format('DD/MM/YYYY')
  }

  getDateTime(date: $TSFixMe) {
    if (!date) {
      return '-'
    }

    let momentDate = moment(date)
    if (this.timezone) {
      momentDate = momentDate.tz(this.timezone)
    }
    return momentDate.format('DD/MM/YYYY HH:mm')
  }

  getTime(time: $TSFixMe) {
    if (!time) {
      return '-'
    }

    let momentTime = moment.utc(time, 'HH:mm:ss')
    if (this.timezone) {
      momentTime = momentTime.tz(this.timezone)
    }
    return momentTime.format('HH:mm')
  }

  getMeasuringDistance(value: $TSFixMe) {
    if (!value) {
      return '-'
    }

    const fillLevel = this.computeMeasuringDistance(value)
    return `${fillLevel.toFixed(2)} ${this.measuringDistanceVariant}`
  }

  getDistance(value: $TSFixMe) {
    if (!value) {
      return '-'
    }

    const fillLevel = this.computeDrivingDistance(value)

    return `${fillLevel.toFixed(2)} ${this.drivingDistance}`
  }

  getWeight(value: $TSFixMe) {
    if (!value) {
      toast.error(<T _str="{value} is required" value={value} />)
      return ''
    }

    const weightLevel = this.computeWeight(value)
    return `${weightLevel.toFixed(2)} ${this.weightVariant}`
  }

  getVolume(value: $TSFixMe) {
    if (!value) {
      toast.error(<T _str="{value} is required" value={value} />)
      return ''
    }

    const volumeLevel = this.computeVolume(value)
    return `${volumeLevel.toFixed(2)} ${this.volumeVariant}`
  }

  getWeightToVolume(value: $TSFixMe, reverse: $TSFixMe) {
    if (!value) {
      return null
    }

    return this.computeWeightToVolume(value, reverse)
  }

  getTemperature(value: $TSFixMe) {
    if (!value) {
      return '-'
    }
    const temperature = this.computeTemperature(value)
    return `${temperature} ${this.temperatureVariant}`
  }
}

export const getCost = async (
  fromCurrence: $TSFixMe,
  toCurrency: $TSFixMe,
  value: $TSFixMe
) => {
  if (!value) {
    return '-'
  }
  try {
    const response = await fetch(
      `https://api.exchangerate.host/convert?from=${fromCurrence}&to=${toCurrency}&amount=${value}`
    )
    const parsedResponse = await response.json()
    return parsedResponse.result.toFixed(2)
  } catch (err) {
    return value
  }
}

const prepareVolume = (value: $TSFixMe, volumeVariant: $TSFixMe) => {
  switch (volumeVariant) {
    case 'M3':
      return value
    case 'L':
      return value / 1000
    case 'YD3':
      return value / 1.308
    case 'FT3':
      return value / 28.316847
    default:
      return value
  }
}

const prepareWeight = (value: $TSFixMe, weightVariant: $TSFixMe) => {
  switch (weightVariant) {
    case 'KG':
      return value
    case 'T':
      return value / 0.001
    case 'LBS':
      return value / 2.2046
    default:
      return value
  }
}

/* eslint-disable no-param-reassign */
export const prepareVehicleValues = async (
  values: $TSFixMe,
  settings: $TSFixMe
) => {
  const { currency, volumeVariant, weightVariant } = settings[values.project]

  if (currency !== 'USD') {
    values.fixedCost = await getCost(currency, 'USD', values.fixedCost)
    values.fuelCostPerKm = await getCost(currency, 'USD', values.fuelCostPerKm)
    values.fuelCostPerLtr = await getCost(
      currency,
      'USD',
      values.fuelCostPerLtr
    )
  }

  if (values.fuelTankCapacity.length) {
    values.fuelTankCapacity = values.fuelTankCapacity.map((item: $TSFixMe) =>
      prepareVolume(item, volumeVariant)
    )
  }

  values.cargoVolume = prepareVolume(values.cargoVolume, volumeVariant)
  values.fuelConsumption = prepareVolume(values.fuelConsumption, volumeVariant)
  values.maxPayload = prepareWeight(values.maxPayload, weightVariant)
  values.vehicleWasteCapacity.forEach((item: $TSFixMe) => {
    item.maxAllowedVolume = prepareVolume(item.maxAllowedVolume, volumeVariant)
    item.maxAllowedWeight = prepareWeight(item.maxAllowedWeight, weightVariant)
  })
}
/* eslint-enable no-param-reassign */

export const getActiveProjects = (state: $TSFixMe) => {
  const {
    settings: {
      user: { activeProjects: { edges: activeProjects = [] } = {} } = {},
    } = {},
  } = state
  return activeProjects
}

export const getMapCenter = (state: $TSFixMe) => {
  const { activeProjects } = state.settings
  const centerLocation = {}

  // No selected projects - get mapCenter of user Company
  if (!activeProjects.length) {
    const userCompany = state.settings.user.company
    if (userCompany) {
      const { latitude, longitude } = userCompany.dashboardLocation
      ;(centerLocation as $TSFixMe).lat = latitude
      ;(centerLocation as $TSFixMe).lng = longitude
    } else {
      ;(centerLocation as $TSFixMe).lat = 56.13573
      ;(centerLocation as $TSFixMe).lng = 8.96548
    }
  }

  // Selected only 1 project - get mapCenter of the Project or the Company
  else if (activeProjects.length === 1) {
    const projectLocation = activeProjects[0].settings.dashboardLocation
    const { latitude, longitude } =
      projectLocation || activeProjects[0].company.dashboardLocation
    ;(centerLocation as $TSFixMe).lat = latitude
    ;(centerLocation as $TSFixMe).lng = longitude
  } else {
    const companyProjectsCount = {}

    // Count how many projects from each company are selected
    activeProjects.forEach((project: $TSFixMe) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (companyProjectsCount[project.company.id]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        companyProjectsCount[project.company.id] += 1
      } else {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        companyProjectsCount[project.company.id] = 1
      }
    })

    const companyIds = Object.keys(companyProjectsCount)

    // All projects are from the same company - get mapCenter of the Company
    if (companyIds.length === 1) {
      const { latitude, longitude } = activeProjects.find(
        (project: $TSFixMe) => project.company.id === companyIds[0]
      ).company.dashboardLocation
      ;(centerLocation as $TSFixMe).lat = latitude
      ;(centerLocation as $TSFixMe).lng = longitude
    }

    // There's company with only 1 selected project - get mapCenter of the Project
    /* eslint-disable-next-line no-restricted-syntax */
    for (const key of companyIds) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (companyProjectsCount[key] === 1) {
        const selectedProject = activeProjects.find(
          // eslint-disable-next-line no-loop-func
          (project: $TSFixMe) => project.company.id === key
        )
        const { latitude, longitude } = selectedProject.settings
          .dashboardLocation
          ? selectedProject.settings.dashboardLocation
          : selectedProject.company.dashboardLocation
        ;(centerLocation as $TSFixMe).lat = latitude
        ;(centerLocation as $TSFixMe).lng = longitude
        break
      }
    }

    // Get mapCenter of the first company
    const { latitude, longitude } = activeProjects.find(
      (project: $TSFixMe) => project.company.id === companyIds[0]
    ).company.dashboardLocation
    ;(centerLocation as $TSFixMe).lat = latitude
    ;(centerLocation as $TSFixMe).lng = longitude
  }

  return centerLocation
}

export const getSettingsForProject = (activeProject: $TSFixMe) => {
  const { settings: settingsConfig = {} } = activeProject || {}
  return new Settings(settingsConfig)
}

export const getUserSettings = (state: $TSFixMe) => {
  const activeProjects = getActiveProjects(state)

  return activeProjects.reduce(
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
    (acc, { node: activeProject }) => ({
      ...acc,
      [activeProject.id]: getSettingsForProject(activeProject),
    }),
    {}
  )
}

export const WH_ADMIN = 'wh-admin'
export const STAFF = 'staff'
export const RESELLER = 'reseller'
export const MASTER = 'master'
export const SUPER = 'super'
export const USER = 'user'
export const DEMO = 'demo'

export const ACCOUNT_TYPES = {
  CUSTOMER: 'CUSTOMER',
  WASTE_HERO: 'WASTE_HERO',
  PARTNER: 'PARTNER',
  DEMO: 'DEMO',
}

export const allUserPermissions = [
  { value: WH_ADMIN, label: <T _str="WH-Admin" /> },
  { value: STAFF, label: <T _str="Staff" /> },
  { value: RESELLER, label: <T _str="Partner" /> },
  { value: MASTER, label: <T _str="Admin" /> },
  { value: SUPER, label: <T _str="Project owner" /> },
  { value: USER, label: <T _str="User" /> },
  { value: DEMO, label: <T _str="Demo User" /> },
]

export const allProjectLevelUserPermissions = [
  { value: MASTER, label: <T _str="Admin" /> },
  { value: SUPER, label: <T _str="Project owner" /> },
  { value: USER, label: <T _str="User" /> },
]

export const getUserPermissionForProject = (user: $TSFixMe, project = {}) => {
  if (user.isAdmin) {
    return WH_ADMIN
  }
  if (user.isStaff) {
    return STAFF
  }
  if (user.isReseller) {
    return RESELLER
  }
  if (user.isMaster) {
    return MASTER
  }
  return (project as $TSFixMe).userAccessLevel || USER
}

export const getAvailableUserPermissions = (permission: $TSFixMe) => {
  switch (permission) {
    case WH_ADMIN:
      return [USER, SUPER, MASTER, RESELLER, DEMO, WH_ADMIN, STAFF]
    case STAFF:
      return [USER, SUPER, MASTER, RESELLER, DEMO, STAFF]
    case RESELLER:
      return [USER, SUPER, MASTER, RESELLER]
    case MASTER:
      return [USER, SUPER, MASTER]
    case SUPER:
      return [USER, SUPER]
    case USER:
      return [USER]
    default:
      return [USER]
  }
}

export const updateUserCache =
  (project: $TSFixMe) =>
  async (
    cache: $TSFixMe,
    {
      data: {
        assignUser: { userProject },
      },
    }: $TSFixMe
  ) => {
    const { allCompanies: cachedCompanies } = cache.readQuery(allCompaniesQuery)
    const filteredEdges = cachedCompanies.edges.map((comp: $TSFixMe) => {
      const deepCopyCompany = JSON.parse(JSON.stringify(comp))
      const {
        node: { projectSet },
      } = comp
      if (projectSet.edges) {
        return {
          ...deepCopyCompany,
          node: {
            ...deepCopyCompany.node,
            projectSet: {
              ...deepCopyCompany.node.projectSet,
              edges: projectSet.edges.map(
                // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'proj' implicitly has an 'any' typ... Remove this comment to see the full error message
                ({ node: proj, ...otherProjectData }) => {
                  if (proj.id === project) {
                    const projectCopy = { ...proj }
                    const newProjectCopyData = {
                      ...projectCopy,
                      userprojectSet: {
                        ...projectCopy.userprojectSet,
                        edges: [
                          ...proj.userprojectSet.edges,
                          {
                            __typename: 'UserProjectTypeEdge',
                            node: {
                              ...userProject,
                            },
                          },
                        ],
                      },
                    }
                    return {
                      node: newProjectCopyData,
                      ...otherProjectData,
                    }
                  }
                  return { node: proj, ...otherProjectData }
                }
              ),
            },
          },
        }
      }
      return deepCopyCompany
    })
    cache.writeQuery({
      ...allCompaniesQuery,
      data: {
        allCompanies: { ...cachedCompanies, edges: filteredEdges },
      },
    })
  }

export const checkIfRegularUser = (user: $TSFixMe) =>
  !(user.isAdmin || user.isMaster || user.isReseller || user.isSuperuser)
