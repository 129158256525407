import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApolloClient, useMutation } from '@apollo/client'
import { useHistory } from 'react-router'

import LoadingLayout from '../components/shared/loading'

import { setUser, setRouting } from '../actions'
import { MUTATION_VERIFY_TOKEN, GET_ME } from './api'
import { useLogoutMutation } from '../api/auth'

const Router: React.FC = ({ children }) => {
  const history = useHistory()
  const { init } = useSelector((state: $TSFixMe) => state.settings.routing)
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [checkTokenMutationQuery] = useMutation(MUTATION_VERIFY_TOKEN)
  const [logoutMutationQuery] = useLogoutMutation()

  useEffect(() => {
    // init when the app is loaded;
    async function getUser() {
      try {
        const response = await client.query({ query: GET_ME })
        if (response.data && response.data.me) {
          dispatch(setUser(response.data.me))
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('err', e)
      }
    }

    function setUserAsNonAuthenticated() {
      dispatch(
        setRouting({
          roles: [`non-authenticated`],
          init: true,
        })
      )
      window.localStorage.removeItem('reactQLJWT')
    }

    async function checkTokenRefreshTokenOrLogout() {
      const currentReactQLJWTToken = window.localStorage.getItem('reactQLJWT')
      // const rememberMe = window.localStorage.getItem('rememberMe')

      if (currentReactQLJWTToken) {
        checkTokenMutationQuery({
          variables: {
            token: currentReactQLJWTToken,
          },
        })
          .then(({ data: { verifyToken = null } = {} }) => {
            if (verifyToken) {
              getUser()
            } else {
              setUserAsNonAuthenticated()
            }
          })
          .catch(() => {
            setUserAsNonAuthenticated()
          })
      } else {
        setUserAsNonAuthenticated()
      }
    }
    checkTokenRefreshTokenOrLogout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTokenMutationQuery, client, dispatch, history, logoutMutationQuery])

  if (!init) return <LoadingLayout isLoading />

  return <>{children}</>
}

export default Router
