import React from 'react'
import { T } from '@transifex/react'
import { gql } from '@apollo/client'
import {
  createAsyncSelectOnMount,
  THookCreateAsyncSelectOnMountResponse,
} from './factories/createAsyncSelectOnMount'

export const GET_QUERY = gql`
  query GetVehicleForTrailers($vehicleId: ID!) {
    vehicle(id: $vehicleId) {
      id
      vehicleType {
        trailers {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

type TVariables = {
  vehicleId?: string
}

type TResponse = {
  vehicle: {
    vehicleType: {
      trailers: {
        edges: {
          node: {
            id: string
            name: string
          }
        }[]
      }
    }
  }
}

/* @ts-expect-error We will add it before we export. */
const SelectVehicleTrailers: THookCreateAsyncSelectOnMountResponse<TVariables> & {
  WithActiveProjects: THookCreateAsyncSelectOnMountResponse<TVariables>
} = createAsyncSelectOnMount<TResponse, TVariables>({
  formatResponse: (data) =>
    data?.vehicle.vehicleType.trailers.edges.map(({ node }) => ({
      key: node.id,
      value: node.id,
      label: node.name,
    })),
  defaultSelectProps: {
    placeholder: <T _str="Vehicle Trailers" />,
    allowClear: true,
  },
  query: GET_QUERY,
})

export { SelectVehicleTrailers }
