import { gql } from '@apollo/client'
import apiUtilGetKey from './apiUtilCreatePrefixer'

/* Fragments */
/* Queries */
export const GET_ALL_INQUIRY_TYPES = gql`
  query GetAllInquiryTypings($activeProjects: String, $search: String) {
    allInquiryTypings(activeProjects: $activeProjects, search: $search) {
      edges {
        node {
          id
          name
          status
          project {
            id
            name
          }
        }
      }
    }
  }
`
export const K_GET_ALL_INQUIRY_TYPES = apiUtilGetKey(GET_ALL_INQUIRY_TYPES)

/* Mutations */
