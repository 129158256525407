import React, { useEffect, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Form } from 'antd'

import { uniqBy } from 'lodash'
import { TBaseFormSchema } from '../form-ticket'
import { TPricingHandlerProps } from './types'
import { Opt } from '../../../../../ui/options'
import {
  convertFromMonthlyNumeric,
  TPaymentIntervals,
} from '../../../../../consts'
import { useResetOnFieldChange } from '../../../../antdform-utils/useResetOnFieldChange'

const GET = gql`
  query FormTicketSingleUsePricingGet($propertyIds: [ID!]!) {
    ticketPricing {
      addContainer(propertyIds: $propertyIds) {
        containerPrice {
          id
          amount
          recurringInterval
          containerType {
            id
            name
          }
          wasteFraction {
            id
            name
          }
          pickupSetting {
            id
            name
          }
        }
        servicePrice {
          id
          amount
        }
      }
    }
  }
`

export const TicketPricingOptionsAddContainer = ({
  propertyId,
}: TPricingHandlerProps) => {
  if (!propertyId) {
    throw new Error(
      'propertyId required for PriceHandler: TicketPricingOptionsAddContainer '
    )
  }

  const form = Form.useFormInstance<TBaseFormSchema>()
  /* listening to form values */
  const containerType = Form.useWatch('containerType')
  const wasteFraction = Form.useWatch('wasteFraction')
  const pickupSetting = Form.useWatch('pickupSetting')

  const { data } = useQuery(GET, { variables: { propertyIds: [propertyId] } })

  const allOptions: Array<
    [Opt, Opt, Opt, [number, TPaymentIntervals], number]
  > = useMemo(
    () =>
      data?.ticketPricing?.addContainer?.map((d: $TSFixMe) => {
        const {
          amount,
          recurringInterval,
          containerType: ct,
          wasteFraction: wf,
          pickupSetting: ps,
        } = d.containerPrice

        return [
          { label: ct.name, value: ct.id },
          { label: wf.name, value: wf.id },
          { label: ps.name, value: ps.id },
          [
            amount / 100,
            convertFromMonthlyNumeric[recurringInterval] as TPaymentIntervals,
          ],
          d.servicePrice?.amount / 100,
        ] as [Opt, Opt, Opt, [number, TPaymentIntervals], number]
      }) || [],
    [data?.ticketPricing?.addContainer]
  )

  const allContainerOptions = useMemo(
    () =>
      uniqBy(
        allOptions?.map(([ct]: $TSFixMe) => ct),
        (v) => v.value
      ),
    [allOptions]
  )

  const allWasteFractionOptions = useMemo(
    () =>
      uniqBy(
        allOptions
          ?.filter(([ct]: $TSFixMe) => ct.value === containerType)
          ?.map(([, wf]: $TSFixMe) => wf),
        (v) => v.value
      ),
    [allOptions, containerType]
  )

  const allPickupSettingOptions = useMemo(
    () =>
      uniqBy(
        allOptions
          ?.filter(
            ([ct, wf]: $TSFixMe) =>
              ct.value === containerType && wf.value === wasteFraction
          )
          ?.map(([, , ps]: $TSFixMe) => ps),
        (v) => v.value
      ),
    [allOptions, containerType, wasteFraction]
  )

  const serviceFee = useMemo(() => {
    const [, , , , serviceFeeAmount] =
      allOptions.find(
        ([ct, wf, ps]) =>
          containerType === ct.value &&
          wf.value === wasteFraction &&
          ps.value === pickupSetting
      ) || []

    return {
      amount: serviceFeeAmount,
      currency: 'dkk',
    }
  }, [allOptions, containerType, pickupSetting, wasteFraction])

  useResetOnFieldChange({
    field: 'containerType',
    dependants: ['wasteFraction'],
  })
  useResetOnFieldChange({
    field: 'wasteFraction',
    dependants: ['pickupSetting'],
  })

  useEffect(() => {
    form.setFieldsValue({
      data: {
        containerTypeOptions: allContainerOptions,
        wasteFractionOptions: allWasteFractionOptions,
        pickupSettingOptions: allPickupSettingOptions,
        wasteFractionsOptions: [],
        serviceFee,
        containerTypeDisabled: false,
        pickupSettingDisabled: !containerType || !wasteFraction,
        wasteFractionDisabled: !containerType,
        wasteFractionsDisabled: false,
      },
    })
  }, [
    allContainerOptions,
    allPickupSettingOptions,
    allWasteFractionOptions,
    containerType,
    form,
    serviceFee,
    wasteFraction,
  ])

  return <></>
}
