import { lime, red, blue, grey } from '@ant-design/colors'
import { apiHelperPickupOrder } from '../../../../api/helpers'
import { array } from '../../../../utils/array'

export const pickupOrderColors = {
  nextStop: {
    color: blue.at(4),
    stroke: blue.at(1),
    textColor: 'white',
  },
  unCompleted: {
    color: red.at(4),
    stroke: red.at(2),
    textColor: 'white',
  },
  completed: {
    color: lime.at(6),
    stroke: lime.at(5),
    textColor: 'white',
  },
  pending: {
    color: 'white',
    stroke: grey.at(1),
    textColor: 'black',
  },
}

export const getRouteStopContainerCircleProperties = (
  /** stop: PickUpOrderType  */
  stop: $TSFixMe,
  isNext?: boolean
): { color: string; strokeColor: string; textColor: string } => {
  const isCompleted = apiHelperPickupOrder.isCompleted(stop)

  return {
    strokeColor:
      array<string>(
        isNext && pickupOrderColors.nextStop.stroke,
        apiHelperPickupOrder.isUnCompleted(stop) &&
          pickupOrderColors.unCompleted.stroke,
        isCompleted && pickupOrderColors.completed.stroke
      )?.[0] ||
      pickupOrderColors.pending.stroke ||
      ``,
    color:
      array<string>(
        isNext && pickupOrderColors.nextStop.color,
        apiHelperPickupOrder.isUnCompleted(stop) &&
          pickupOrderColors.unCompleted.color,
        isCompleted && pickupOrderColors.completed.color
      )?.[0] ||
      pickupOrderColors.pending.color ||
      ``,
    textColor:
      array<string>(
        isNext && pickupOrderColors.nextStop.textColor,
        apiHelperPickupOrder.isUnCompleted(stop) &&
          pickupOrderColors.unCompleted.textColor,
        isCompleted && pickupOrderColors.completed.textColor
      )?.[0] ||
      pickupOrderColors.pending.textColor ||
      'black',
  }
}
