import { pathJoin } from '../../../../utils'
import { createRouteMapCreateRouteScheme } from './create'
import { createRouteMapEditRouteScheme } from './detailed-view'

export const createRouteSchemeRouteMap = (prefix: string) => ({
  create: pathJoin(prefix)('create').chainMapAuto(
    createRouteMapCreateRouteScheme
  ),
  'edit/:id': (id: string) =>
    pathJoin(prefix)('edit')(id).chainMapAuto(createRouteMapEditRouteScheme),
})
