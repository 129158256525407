import React from 'react'
import { T } from '@transifex/react'

type Project = {
  name: string
}

export const getCellProjectName = (
  /** Should be ProjectDef!
   * Example:
   *  project {
   *    name
   *  }
   */
  value: Project
) => {
  return value?.name
}

export const getCellProjectNameWithTitle = (
  /** Should be ProjectDef!
   * Example:
   *  project {
   *    name
   *  }
   */
  value: Project
) => {
  const label = getCellProjectName(value)
  return <T _str="Project: {label}" label={label} />
}
