import { mockPickupTypeLabelMap } from './consts'

export type TPickupType = `static` | `dynamic`

export type MockPickupType = {
  value: TPickupType
}

export const API_MOCK_PICKUP_TYPE_DATA: MockPickupType[] = (
  Object.keys(mockPickupTypeLabelMap) as TPickupType[]
).map((k) => ({ value: k }))
