import Chance from 'chance'

const chance = new Chance()

const raw = Array.from({ length: 100 }, () => ({
  id: chance.guid(),
  containerId: chance
    .string({ length: 3, numeric: true })
    .concat(chance.string({ length: 1, alpha: true, casing: 'upper' })),
}))

export const API_MOCK_CONTAINER_DATA = raw
