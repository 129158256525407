import { pathJoin } from '../../../../utils'
import { createAssetManagementRouteMap } from './asset-management'
import { createOperationManagementRouteMap } from './operation-management'

export const createProjectRouteMap = (prefix: string) => ({
  'general-settings': pathJoin(prefix)('general-settings').chain,
  'asset-management': pathJoin(prefix)('asset-management').chainMap(
    createAssetManagementRouteMap(
      pathJoin(prefix)('asset-management').value.slice(1)
    )
  ),
  'operation-management': pathJoin(prefix)('operation-management').chainMap(
    createOperationManagementRouteMap(
      pathJoin(prefix)('operation-management').value.slice(1)
    )
  ),
})
