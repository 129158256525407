import React from 'react'
import { T } from '@transifex/react'
import {
  Select as SelectFormik,
  SelectProps as SelectFormikProps,
} from 'formik-antd'
import {
  GET_CALL,
  GetCallResponseEdgeNode,
  GetCallVariables,
  GetCallResponse,
} from './api'

import {
  AsyncSelectWithFetchMore,
  Opt,
  AsyncSelectWithFetchMoreProps,
} from '../AsyncSelectWithFetchMore'
import { useSavedActiveProjects } from '../../../hooks'

const formatResponse = ({
  node: { id, name },
}: {
  node: GetCallResponseEdgeNode
}): Opt => ({
  label: name,
  value: id,
  key: id,
})

type SelectContainerMetaTypeProps = {
  variables?: GetCallVariables
} & AsyncSelectWithFetchMoreProps['selectProps'] &
  Pick<AsyncSelectWithFetchMoreProps, 'render'>

export const SelectContainerMetaType = ({
  variables,
  render,
  ...props
}: SelectContainerMetaTypeProps) => {
  const activeProjects = useSavedActiveProjects()
  const queryVariables = {
    activeProjects,
    ...variables,
  }
  return (
    <AsyncSelectWithFetchMore<GetCallResponse, GetCallVariables>
      formatResponseToOptions={(data) =>
        data.allCompanyContainerMetaTypes.edges.map(formatResponse)
      }
      queryVariables={queryVariables}
      refreshDependencies={[queryVariables]}
      query={GET_CALL}
      queryOptions={{
        notifyOnNetworkStatusChange: true,
      }}
      value={props.value}
      selectProps={{
        placeholder: <T _str="Container Meta Type" />,
        allowClear: true,
        ...props,
      }}
      render={render}
    />
  )
}

export const SelectFormikContainerMetaType: React.FC<
  SelectFormikProps & { variables: GetCallVariables }
> = ({ name, variables, ...rest }) => (
  <SelectContainerMetaType
    variables={variables}
    value={rest.value}
    render={({ loading, options }) => (
      <SelectFormik
        name={name}
        loading={loading}
        options={options}
        showSearch
        filterOption
        optionFilterProp="label"
        {...rest}
      />
    )}
  />
)
