import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { Divider } from 'antd'
import classnames from 'classnames'

export type LayoutWithDividerv4Props = {
  footer?: ReactNode
  footerDivider?: boolean
  /**
   * Append divider at the top
   * default to false */
  topDivider?: boolean
  style?: CSSProperties
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>
  dividerWrapperProps?: React.HTMLAttributes<HTMLDivElement>
  footerProps?: React.HTMLAttributes<HTMLDivElement>
  /** in Px. */
  dividerMargin?: number
  /** in Px. */
  dividerWidth?: number
  dividerColor?: string
  colsRatio?: number[]
  /** Same length as colsRatio  */
  content: ReactNode[]
  className?: string
}

const px = (val: number) => `${val}px`

const matchIndex = (index: number, length: number) => {
  if (index < length) return index
  return index % length
}

/**
 * LayoutWithDividerv4
 */
export const LayoutWithDividerv4 = ({
  footer,
  footerDivider = false,
  style = {},
  footerProps = {},
  wrapperProps = {},
  dividerWrapperProps = {},
  dividerMargin = 16,
  dividerWidth = 1,
  colsRatio = [50, 50],
  content,
  children,
  topDivider,
  dividerColor,
  className,
}: PropsWithChildren<LayoutWithDividerv4Props>) => {
  const numberOfColsPerRow = colsRatio.length
  const numebrOfDividers = numberOfColsPerRow - 1

  return (
    <div
      style={{ ...style, ...(wrapperProps.style || {}) }}
      {...wrapperProps}
      className={classnames(className, `wh-vertical-scroller-container`)}
    >
      {topDivider && (
        <Divider
          style={{
            borderTopWidth: dividerWidth,
            margin: 0,
            borderColor: dividerColor,
          }}
        />
      )}
      <div className="wh-vertical-scroller-container-scroll">
        <div
          {...dividerWrapperProps}
          style={{
            ...dividerWrapperProps.style,
            display: 'flex',
            height: '100%',
          }}
        >
          {React.Children.toArray(
            content.map((contentC, i) => {
              const number = colsRatio[matchIndex(i, numberOfColsPerRow)]
              const isLast = i === numberOfColsPerRow - 1

              return (
                <>
                  <div
                    style={{
                      width: `calc(${number}% - ${px(
                        dividerMargin * numebrOfDividers
                      )} - ${px(dividerWidth * numebrOfDividers)})`,
                    }}
                  >
                    {contentC}
                  </div>
                  {!isLast && (
                    <div>
                      <Divider
                        type="vertical"
                        style={{
                          height: '100%',
                          margin: `0 ${px(dividerMargin)}`,
                          borderLeftWidth: dividerWidth,
                          borderColor: dividerColor,
                        }}
                      />
                    </div>
                  )}
                </>
              )
            })
          )}
        </div>
      </div>
      {footerDivider && (
        <Divider
          type="horizontal"
          style={{
            width: '100%',
            borderTopWidth: dividerWidth,
            margin: 0,
            borderColor: dividerColor,
          }}
        />
      )}
      {footer && <div {...footerProps}>{footer}</div>}
      {children}
    </div>
  )
}
