import { gql } from '@apollo/client'

export type TVariables = { routeId?: string }

export const GET = gql`
  query MapGetRouteSource($routeId: ID!) {
    routeGeojson(routeId: $routeId) {
      features {
        geometry {
          coordinates
        }
      }
    }
  }
`
