// eslint-disable-next-line import/prefer-default-export
export const apiUtilCreatePrefixer =
  (str = '') =>
  (val: TemplateStringsArray) =>
    `${str}${val}`

export default function apiUtilGetKey({ definitions }: $TSFixMe) {
  const { value } = definitions[definitions.length - 1]?.name
  return value
}
