import { gql } from '@apollo/client'

export const GET_STOP_MAP_NODE = gql`
  query FormStopGetMapNode($id: ID!) {
    pickupOrder(id: $id) {
      id
      status
      stopNumber
      container {
        id
        containerId
        wasteFraction {
          id
          name
        }
        tickets {
          totalCount
        }
      }
      ticket {
        id
        location {
          name
        }
        ticketType {
          name
        }
        container {
          id
          containerId
          wasteFraction {
            id
            name
          }
          tickets {
            totalCount
          }
          location {
            name
          }
        }
      }
      depot {
        id
        name
        openingTime
        closingTime
        wasteFractions {
          edges {
            node {
              id
              name
            }
          }
        }
        containerSet {
          edges {
            node {
              id
              containerType {
                id
                name
              }
            }
          }
        }
      }
      location {
        name
      }
      scheduledDriverArrival
      completedAt
    }
  }
`

export const GET_CONTAINER_GROUP_MAP_NODE = gql`
  query GetContainerGroupMapNode($id: ID!) {
    containerGroup(id: $id) {
      id
      name
      location {
        name
      }
    }
  }
`

export const GET_MAP_PICKUP_ORDERS = gql`
  query GetContainerGroupMapNodePickupOrders($orderIds: [String]) {
    allPickupOrders(orderIds: $orderIds) {
      edges {
        node {
          id
          completedAt
          status
          container {
            id
            containerId
          }
          ticket {
            id
            container {
              id
              containerId
            }
          }
          depot {
            id
            name
          }
          location {
            name
          }
          scheduledDriverArrival
          orderId
          stopNumber
        }
      }
    }
  }
`

export const GET_CONTAINER_GROUP_MAP_PICKUP_ORDER = gql`
  query GetContainerGroupMapNodePickupOrder($id: ID!) {
    pickupOrder(id: $id) {
      id
      status
      container {
        id
        containerId
        wasteFraction {
          id
          name
        }
        tickets {
          totalCount
        }
      }
      location {
        name
      }
      completedAt
      scheduledDriverArrival
      stopNumber
    }
  }
`
