import React, { ReactNode, useMemo } from 'react'
import { Dropdown, Tabs, Button, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { T } from '@transifex/react'
import styled from 'styled-components'
import { Typography } from '@wastehero/storybook/lib/components'

import SelectUser from './SelectUser'
import SelectTeam from './SelectTeam'
import UserValue from './UserValue'
import TeamValue from './TeamValue'

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0 16px;
  }
`

type TTabs = 'user' | 'team'

type TSelectAssigneeMenuProps = {
  onAssignUser: (id: string) => void
  onAssignTeam: (id: string) => void
  value: string
  defaultActiveTab: TTabs
  addonBeforeTabs?: ReactNode
}

const Content = ({
  defaultActiveTab,
  onAssignTeam,
  onAssignUser,
  value,
  addonBeforeTabs,
}: TSelectAssigneeMenuProps) => {
  const tabs: Array<{ key: TTabs; content: ReactNode; title: ReactNode }> =
    useMemo(
      () => [
        {
          key: 'user',
          title: <T _str="Users" />,
          content: (
            <SelectUser value={value} onChange={(val) => onAssignUser(val)} />
          ),
        },
        {
          key: 'team',
          title: <T _str="Teams" />,
          content: (
            <SelectTeam value={value} onChange={(val) => onAssignTeam(val)} />
          ),
        },
      ],
      [onAssignTeam, onAssignUser, value]
    )

  return (
    <Menu
      style={{
        boxShadow: `0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)`,
      }}
    >
      <div
        style={{
          width: 300,
          padding: '12px 0px',
          display: 'flex',
          rowGap: 8,
          flexDirection: 'column',
        }}
      >
        {addonBeforeTabs}
        <StyledTabs defaultActiveKey={defaultActiveTab}>
          {tabs.map(({ content, key, title }) => (
            <Tabs.TabPane tab={title} key={key}>
              <div style={{ height: 400 }}>{content}</div>
            </Tabs.TabPane>
          ))}
        </StyledTabs>
      </div>
    </Menu>
  )
}

type TSelectAssigneeProps = TSelectAssigneeMenuProps

const SelectAssignee = ({
  value,
  defaultActiveTab,
  ...props
}: TSelectAssigneeProps) => {
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Content value={value} defaultActiveTab={defaultActiveTab} {...props} />
      }
    >
      <Button size="large" style={{ width: 200 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '80%',
              maxWidth: '80%',
            }}
          >
            {value && defaultActiveTab === 'user' && <UserValue id={value} />}
            {value && defaultActiveTab === 'team' && <TeamValue id={value} />}
            {!value && <T _str="Unassigned" />}
          </div>
          <div style={{ width: '10%' }}>
            <DownOutlined style={{ color: 'gray', fontSize: 12 }} />
          </div>
        </div>
      </Button>
    </Dropdown>
  )
}

export default SelectAssignee

export const SelectAssigneeItem = ({ value, onChange }: $TSFixMe) => {
  return (
    <SelectAssignee
      value={value?.assignee || value?.assignedTeam}
      defaultActiveTab={
        (value?.assignee && 'user') || (value?.assignedTeam && 'team') || `user`
      }
      addonBeforeTabs={
        <Typography type="secondary" style={{ padding: `0 16px` }}>
          <T _str="Assign to" />
        </Typography>
      }
      onAssignUser={(userId) =>
        /* set form value to new option. */
        onChange({
          assignedTeam: ``,
          assignee: userId,
        })
      }
      onAssignTeam={(teamId) =>
        /* set form value to new option. */
        onChange({
          assignedTeam: teamId,
          assignee: ``,
        })
      }
    />
  )
}
