import moment, { Moment } from 'moment'
import { createStatefulContextSimple } from '../../../contexts'
import { createEnhancedFiltersSimpleStateWithCreateFilter } from '../../filters-utils/createEnhancedFiltersSimpleState'

export type TFilters = {
  dateRange: [Moment, Moment]
  category?: string[]
  type?: string
}

export const initialFilters: TFilters = {
  dateRange: [moment().startOf('year'), moment().endOf('year')],
}

const { Provider, useContext } = createStatefulContextSimple<TFilters>()

const { createFilter, useFiltersContext } =
  createEnhancedFiltersSimpleStateWithCreateFilter(useContext)

export { Provider as FiltersProiver, useFiltersContext, createFilter }
