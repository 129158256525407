import React from 'react'
import { ButtonProps } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { IconButton } from './IconButton'

/**
 * @deprecated - Use ant-design simple button with PlusOutlined icon.
 */
export const ButtonPlusOutlined = (props: ButtonProps) => {
  return <IconButton {...props} icon={<PlusOutlined />} />
}
