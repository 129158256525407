import React, { useEffect, useState } from 'react'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { useQuery } from '@apollo/client'
import { snakeCase } from 'lodash'
import { message, Space, Button } from 'antd'

import { T } from '@transifex/react'
import { useTableUI, DataCell, SortKey, ColumnKey } from './ui'

import { TFilters, useFiltersContext } from '../filters.context'

import { DebouncedSearch } from '../../../../ui'
import {
  useSavedActiveProjects,
  useTablePaginationAndSorterAPIHelperPersistent,
} from '../../../../hooks'
import {
  TicketTicketStatusChoices,
  ticketTicketStatusChoicesMutationValueMap,
} from '../../../../api/consts'
import { useRoutingHistory } from '../../../../reducers/routing/child-router-factory'
import { GET_ALL_TICKETS } from './api'
import { getLinkPropsPropertyNode } from '../../../extract-links-props'

const sortOrderMap: Record<string, SortKey> = {
  asc: 'ascend',
  desc: 'descend',
}

const formatDatum = ({
  id,
  createdAt,
  ticketType,
  status,
  priority,
  assignee,
  property,
  container,
}: $TSFixMe): DataCell => {
  return {
    assignee: assignee?.firstName?.concat(' ').concat(assignee?.lastName),
    createdAt,
    id,
    priority,
    status,
    ticketType: ticketType?.name,
    property: {
      name: getLinkPropsPropertyNode(property, { includeLabel: false })
        .children,
      linkProps: getLinkPropsPropertyNode(property),
    },
    container: {
      name: container?.containerId,
      linkProps: {
        to: ({ routingMap }) =>
          routingMap.app['customer-management'].assets['/:id'](container?.id)._,
      },
    },
    containerType: container?.containerType?.name,
  }
}

export type TicketInitialFilters = TFilters

type useTicketTableProps = {
  initialFilters?: TicketInitialFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
}

const useTable = ({ initialFilters }: useTicketTableProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [history, { routingMap }] = useRoutingHistory()
  const activeProjects = useSavedActiveProjects()

  /* Filters */
  const [
    {
      status,
      ticketType,
      property,
      propertyGroup,
      containerGroupIds,
      containerIds,
      routeIds,
    },
    set,
  ] = useFiltersContext()
  const { search, jsx: jsxSearch } = DebouncedSearch.useDebouncedSearchSimple()

  useEffect(() => {
    if (initialFilters) {
      set(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters])

  const {
    paginationVars: { first, offset, order, orderBy },
    onTableChangeHandler,
    state: { pageSize, page },
  } = useTablePaginationAndSorterAPIHelperPersistent<DataCell>(
    `useTablePaginationAndSorterAPIHelperPersistent-AllTicketTable`,
    {
      pageSize: 20,
      order: 'descend',
      orderBy: 'created_at',
    }
  )

  /* API */
  const { data: { allTickets } = {}, loading } = useQuery(GET_ALL_TICKETS, {
    variables: {
      first,
      offset,
      search,
      activeProjects,
      order,
      orderBy: snakeCase(orderBy),
      status:
        ticketTicketStatusChoicesMutationValueMap[
          status as TicketTicketStatusChoices
        ],
      ticketType,
      propertyGroup,
      containerGroupIds,
      containerIds,
      routeIds,
      // @ts-expect-error WIP
      property: property?.length > 0 ? property : null,
    },
    onError: (error) =>
      message.error(error?.message || <T _str="Something went wrong" />),
  })

  const tickets = allTickets?.edges.map(({ node }: $TSFixMe) =>
    formatDatum(node)
  )

  /* UI filters columns */
  const { table, toggler } = useTableUI({
    loading,
    onChange: onTableChangeHandler,
    dataSource: tickets,
    pagination: {
      total: allTickets?.totalCount || 0,
      pageSize,
      current: page,
      style: {
        padding: `0 1rem`,
      },
    },
    scroll: {
      y: '100%',
      x: 1000,
    },
    extended: {
      sortField: orderBy as ColumnKey,
      sortOrder: sortOrderMap[order || ''],
    },
    on: {
      view: ({ id }) => {
        history.push(routingMap.app.tickets['/:id'](id)._)
      },
    },
    rowSelection: {
      preserveSelectedRowKeys: true,
      selectedRowKeys,
      onChange: (v) => setSelectedRowKeys(v as string[]),
    },
    rowKey: ({ id }) => id,
  })

  return {
    table: <>{table}</>,
    columnsFilterAndOrder: toggler,
    search: jsxSearch,
    bulkActions: (
      <Space>
        {!!selectedRowKeys.length && (
          <Button onClick={() => setSelectedRowKeys([])}>
            <T _str="Deselect {num} tickets" num={selectedRowKeys.length} />
          </Button>
        )}
        <Button
          onClick={() => {
            history.push({
              pathname: routingMap.app.tickets['edit-bulk']._,
              state: {
                tickets: selectedRowKeys,
              },
            })
          }}
          type="primary"
          disabled={!selectedRowKeys.length}
        >
          <T _str="Bulk edit" />
        </Button>
      </Space>
    ),
  }
}

export default useTable
