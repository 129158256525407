import { pathJoin } from '../../../../../utils'
import { createPricingRoutesMap } from './pricing'
import { createMetaValueRoutesMap } from './meta-values'

export const createCRMRoutesMap = (prefix: string) => ({
  pricing: pathJoin(prefix)('pricing').chainMapAuto(createPricingRoutesMap),
  services: pathJoin(prefix)('services').chain,
  'price-variables': pathJoin(prefix)('price-variables').chain,
  'property-type': pathJoin(prefix)('property-type').chain,
  'meta-values': pathJoin(prefix)('meta-values').chainMapAuto(
    createMetaValueRoutesMap
  ),
  'service-type': pathJoin(prefix)('service-type').chain,
  integrations: pathJoin(prefix)('integrations').chain,
  import: pathJoin(prefix)('import').chain,
})
