import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import {
  TabsStyledWrapper,
  CardWithHeader,
} from '@wastehero/storybook/lib/layouts'
import { Keys, createConfig } from './route-config'
import { TabsNavigationExtended } from '../../../../../../../ui/TabNavigationExtended'

export { createRouteMap } from './route-config'

const tabTitles: Record<Keys, ReactNode> = {
  general: <T _str="General" />,
}

const ContainerDetailsTabNavigation = () => {
  return (
    <CardWithHeader title={<T _str="Create project" />}>
      <TabsStyledWrapper>
        <TabsNavigationExtended<Keys, { id: string }>
          defaultLastActiveKey="general"
          getParentPath={(map) => map.app.settings.projects.create._}
          getParentPathWithParams={(map) => map.app.settings.projects.create._}
          getRouteConfig={createConfig}
          tabTitles={tabTitles}
          antd={{
            style: {
              background: `white`,
            },
          }}
        />
      </TabsStyledWrapper>
    </CardWithHeader>
  )
}

export default ContainerDetailsTabNavigation
