import React from 'react'
import { History } from 'history'

import {
  LayoutWithBreadCrumbT,
  LayoutProps,
  LayoutWithBreadCrumb as LayoutWithBreadCrumbBase,
} from '@wastehero/storybook/lib/layouts'

import {
  Routing,
  useRouting,
} from '../../reducers/routing/child-router-factory'

export type { LayoutProps } from '@wastehero/storybook/lib/layouts/LayoutWithBreadCrumb'

type WithRouting = {
  getProps: (args: { history: History; routing: Routing }) => LayoutProps
}

type ExtendedPropsT = LayoutWithBreadCrumbT & {
  WithRouting: React.FC<WithRouting>
}

const LayoutWithBreadCrumb: ExtendedPropsT =
  LayoutWithBreadCrumbBase as ExtendedPropsT

const WithRouting: React.FC<WithRouting> = ({ children, getProps }) => {
  const routing = useRouting()
  const history = useRouting.useHistory()
  return (
    <LayoutWithBreadCrumb {...getProps({ history, routing })}>
      {children}
    </LayoutWithBreadCrumb>
  )
}
LayoutWithBreadCrumb.WithRouting = WithRouting

export { LayoutWithBreadCrumb }
