import React, { useEffect } from 'react'
import { T } from '@transifex/react'
import { snakeCase } from 'lodash'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { TTableColumnsTreeFilterAndOrderProps } from '@wastehero/storybook/lib/components/table-kit'
import { useModalWithButton } from '@wastehero/storybook/lib/components'
import { DataCell, useTableUI, ColumnKey, SortKey } from './ui'
import { useFiltersContext } from '../filters.context'
import { DebouncedSearch } from '../../../../ui'
import {
  useSavedActiveProjects,
  useTablePaginationAndSorterAPIHelper,
} from '../../../../hooks'
import { GET_ALL_PICK_UP_ORDERS } from '../../../../api'
import { ServiceLogsInitialFilters } from '..'
import EditForm from '../form/Edit'

const { useDebouncedSearchSimple } = DebouncedSearch

const formatData = ({
  id,
  container,
  completedAt,
  weight,
  fillLevel,
  fillPercentage,
  measurementFillLevel,
  measurementFillPercentage,
  ticket,
  route,
  orderId,
}: $TSFixMe): DataCell => {
  return {
    id,
    routeId: route?.id,
    orderId,
    address: container?.location?.name,
    containerId: container?.containerId,
    completedAt: completedAt
      ? moment(completedAt).format('YYYY-MM-DD HH:mm')
      : undefined,
    weight: weight ? `${weight.toFixed(2)} kg` : undefined,
    fillLevel: fillLevel ? `${fillLevel.toFixed(2)} cm` : undefined,
    fillLevelPercentage: fillPercentage
      ? `${fillPercentage.toFixed(2)} %`
      : undefined,
    measurementFillLevel: measurementFillLevel
      ? `${measurementFillLevel.toFixed(2)} cm`
      : undefined,
    measurementFillLevelPercentage: measurementFillPercentage
      ? `${measurementFillPercentage.toFixed(2)} %`
      : undefined,
    ticket: ticket?.id ? <T _str="Yes" /> : <T _str="No" />,
    wasteFraction: container?.wasteFraction?.name,
    project: route?.project?.name,
    driver: {
      linkProps: {
        to: ({ routingMap }) =>
          routingMap.app['fleet-management'].drivers[':driverId'](
            route?.driver?.id
          ).configuration._,
        children: `${route?.driver?.user?.firstName} ${route?.driver?.user?.lastName}`,
      },
    },
  }
}

type useServiceLogsTableProps = {
  initialFilters?: ServiceLogsInitialFilters
  tableColumnsTreeFilterAndOrderProps?: Partial<
    TTableColumnsTreeFilterAndOrderProps<ColumnKey>
  >
}

type UpdateModalState = {
  pickupOrderId?: string
  orderId?: string
  routeId?: string
}

export const useServiceLogsTable = ({
  initialFilters,
  tableColumnsTreeFilterAndOrderProps,
}: useServiceLogsTableProps) => {
  const [
    {
      dateRange,
      drivers,
      wasteFractions,
      containers,
      vehicles,
      containerTypes,
      property,
      propertyGroup,
      containerGroupIds,
    },
    set,
  ] = useFiltersContext()
  const { search, jsx: jsxSearch } = useDebouncedSearchSimple()

  const savedActiveProjects = useSavedActiveProjects()

  useEffect(() => {
    if (initialFilters) {
      set(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters])

  /* PAGINATION AND SORT */
  const {
    onTableChangeHandler,
    paginationVars,
    state: { pageSize, page, orderBy, order },
  } = useTablePaginationAndSorterAPIHelper<DataCell>()

  const dateFilter = dateRange
    ? {
        completedAt_Gte: moment(dateRange[0]).toISOString(),
        completedAt_Lte: moment(dateRange[1]).toISOString(),
      }
    : {}

  const { data, loading } = useQuery(GET_ALL_PICK_UP_ORDERS, {
    variables: {
      ...paginationVars,
      activeProjects: savedActiveProjects,
      search,
      driverIds: drivers,
      wasteFractionIds: wasteFractions,
      containerIds: containers,
      vehicleIds: vehicles,
      propertyIds: property,
      containerTypeIds: containerTypes,
      propertyGroup,
      ...dateFilter,
      orderBy: snakeCase(orderBy),
      containerGroupIds,
      // eslint-disable-next-line no-nested-ternary
      order: order ? (order === 'ascend' ? 'asc' : 'desc') : undefined,
    },
  })

  const {
    jsx: { modal: updateModal },
    utils: { openModal: openUpdateModal, setState: setUpdateModalState },
  } = useModalWithButton<Record<string, unknown>, UpdateModalState>({
    children: ({ state, closeModal }) => (
      <EditForm
        on={{ success: closeModal }}
        orderId={state?.orderId}
        routeId={state?.routeId}
        pickupOrderId={state?.pickupOrderId}
      />
    ),
    modelProps: {
      title: <T _str="Update Pickup Order" />,
      width: 720,
      footer: null,
      bodyStyle: {
        padding: '1.5rem',
      },
    },
  })

  const { table, toggler } = useTableUI({
    tableColumnsTreeFilterAndOrderProps,
    loading,
    onChange: onTableChangeHandler,
    dataSource:
      data?.allPickupOrders?.edges.map((e: $TSFixMe) =>
        formatData({ ...e.node })
      ) || undefined,
    pagination: {
      pageSize,
      current: page,
      total: data?.allPickupOrders?.totalCount,
      style: {
        padding: '0 1rem',
      },
      showSizeChanger: true,
    },
    scroll: {
      y: '100%',
      x: 1000,
    },
    extended: {
      sortField: orderBy as ColumnKey,
      sortOrder: order as SortKey,
    },
    sorting: {
      fields: [
        'containerId',
        'completedAt',
        'weight',
        'fillLevel',
        'measurementFillLevel',
        'measurementFillLevelPercentage',
        'ticket',
        'wasteFraction',
      ],
      sortField: orderBy as ColumnKey,
      sortOrder: order as SortKey,
    },
    on: {
      edit: ({ orderId, routeId, id }) => {
        setUpdateModalState({
          pickupOrderId: id,
          orderId,
          routeId,
        })
        openUpdateModal()
      },
    },
  })

  return {
    jsx: {
      columnsFilter: toggler,
      search: jsxSearch,
      table,
      modal: updateModal,
    },
  }
}
