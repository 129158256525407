import React from 'react'
import { DocumentNode } from '@apollo/client'

import { Opt } from '../types'

import {
  AsyncSelectOnMount,
  AsyncSelectOnMountProps,
} from '../AsyncSelectOnMount'
import { useSavedActiveProjects } from '../../../hooks'

type THookCreateAsyncSelectOnMountArgs<GetCallResponse> = {
  formatResponse: (data: GetCallResponse) => Opt[]
  query: DocumentNode
  defaultSelectProps: AsyncSelectOnMountProps['selectProps']
  usesActiveProjects?: boolean
} & Pick<AsyncSelectOnMountProps, 'queryOptions'>

export type AsyncSelectOnMountPropsExtended<GetCallVariables> = {
  variables?: GetCallVariables
} & AsyncSelectOnMountProps['selectProps']

export type THookCreateAsyncSelectOnMountResponse<GetCallVariables> = (
  args: AsyncSelectOnMountPropsExtended<GetCallVariables> &
    Pick<AsyncSelectOnMountProps, 'queryOptions'> &
    Pick<AsyncSelectOnMountProps, 'render'>
) => JSX.Element

export const createAsyncSelectOnMount = <GetCallResponse, GetCallVariables>({
  formatResponse,
  query,
  defaultSelectProps,
  queryOptions,
  usesActiveProjects,
}: THookCreateAsyncSelectOnMountArgs<GetCallResponse>): THookCreateAsyncSelectOnMountResponse<GetCallVariables> => {
  const useActiveProjects = usesActiveProjects
    ? useSavedActiveProjects
    : () => false

  return ({
    variables,
    queryOptions: queryOptionsOverride,
    render,
    ...props
  }) => {
    const activeProjects = useActiveProjects()

    return (
      <AsyncSelectOnMount<GetCallResponse, GetCallVariables>
        formatResponseToOptions={(data) => formatResponse(data)}
        query={query}
        queryOptions={{
          notifyOnNetworkStatusChange: true,
          variables: {
            ...(activeProjects ? { activeProjects } : {}),
            ...variables,
          },
          ...queryOptions,
          ...queryOptionsOverride,
        }}
        selectProps={{
          allowClear: true,
          ...defaultSelectProps,
          ...props,
        }}
        render={render}
      />
    )
  }
}
