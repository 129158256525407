import { T } from '@transifex/react'
import { Select, SelectProps } from 'antd'
import React from 'react'
import { Opt } from '../options'

export type TSelectUnitTemperatureValue = '°C' | '°F'

const options: Opt<TSelectUnitTemperatureValue>[] = [
  { value: '°C', label: <T _str="Celsius (°C)" /> },
  { value: '°F', label: <T _str="Fahrenheit (°F)" /> },
]

const SelectUnitTemperature = (props: SelectProps) => {
  return <Select options={options} {...props} />
}

export default SelectUnitTemperature
