import React from 'react'
import { Row, Col } from 'antd'
import { gql, useQuery } from '@apollo/client'
import {
  Typography,
  StyledSkeletonInput,
} from '@wastehero/storybook/lib/components'
import { T } from '@transifex/react'

const GET = gql`
  query SelectAssigneeGetOneTeam($id: ID!) {
    team(id: $id) {
      id
      name
      members {
        totalCount
      }
    }
  }
`

type TValueProps = {
  id: string
}

const Value = ({ id }: TValueProps) => {
  const { data, loading } = useQuery(GET, { variables: { id } })

  if (loading)
    return (
      <StyledSkeletonInput size="small" className="ant-skeleton-width-full" />
    )

  const { name, members } = data?.team
  const users = members.totalCount

  return (
    <Row style={{ width: 150, display: 'flex', columnGap: 8 }} wrap={false}>
      <Col flex="none" className="team-user-count">
        <Typography ellipsis type="secondary">
          (
          {users > 0 ? (
            <>
              <T _str="{count} users" count={users} />
            </>
          ) : (
            <T _str="No users" />
          )}
          )
        </Typography>
      </Col>
      <Col flex="auto" style={{ textAlign: 'start' }}>
        <Typography ellipsis>{name}</Typography>
      </Col>
    </Row>
  )
}

export default Value
