import React, { ReactNode } from 'react'
import { T } from '@transifex/react'
import {
  getSimpleColumn,
  getTagColumn,
  CreateTableUIStatefulColumnsArgs,
  createTableUIStatefulColumns,
  getActionsDropDown,
  getNullableColumn,
} from '@wastehero/storybook/lib/components/table-kit'
import { Typography } from '@wastehero/storybook/lib/components'
import { getLinkColumn, LinkCell } from '../../../../ui/table-kit-extended'

export type TActions = `edit`

export type DataCell = {
  id: string
  orderId: string
  routeId?: string
  address: string
  containerId: string
  completedAt?: string
  weight?: string
  fillLevel?: string
  fillLevelPercentage?: string
  measurementFillLevel?: string
  measurementFillLevelPercentage?: string
  ticket: ReactNode
  wasteFraction?: string
  project?: string
  driver?: LinkCell
}

export type ColumnKey = keyof Omit<DataCell, 'id' | 'routeId' | 'orderId'>
export type SortKey = 'ascend' | 'descend'

type ExtendedState = {
  sortField: ColumnKey
  sortOrder: SortKey
}

const simpleColumn = getSimpleColumn()
const linkColumn = getLinkColumn()

const NoDataText = () => (
  <Typography variant="body-14" style={{ color: '#909DA9' }}>
    <T _str="No Data" />
  </Typography>
)

export const columnsRenderMap: CreateTableUIStatefulColumnsArgs<
  DataCell,
  ColumnKey,
  TActions,
  ExtendedState
>['columnsRenderMap'] = () => ({
  address: {
    title: <T _str="Address" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
  },
  containerId: {
    title: <T _str="Container ID" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
    sorter: true,
  },
  completedAt: {
    title: <T _str="Completed at" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
    sorter: true,
  },
  weight: {
    title: <T _str="Weight" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
    sorter: true,
  },
  fillLevel: {
    title: <T _str="Fill level" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
    sorter: true,
  },
  fillLevelPercentage: {
    title: <T _str="Fill level / %" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
  },
  measurementFillLevel: {
    title: <T _str="Measurement fill level" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
    sorter: true,
  },
  measurementFillLevelPercentage: {
    title: <T _str="Measurement fill level / %" />,
    render: getNullableColumn(simpleColumn, <NoDataText />),
    sorter: false,
  },
  ticket: {
    title: <T _str="Ticket" />,
    render: simpleColumn,
  },
  wasteFraction: {
    title: <T _str="Waste fraction" />,
    render: getNullableColumn(
      getTagColumn(undefined, (val) => val),
      <NoDataText />
    ),
  },
  project: {
    title: <T _str="Project" />,
    render: getNullableColumn(simpleColumn),
  },
  driver: {
    title: <T _str="Driver" />,
    render: getNullableColumn(linkColumn, <NoDataText />),
  },
})

const { useTableUI } = createTableUIStatefulColumns<
  DataCell,
  ColumnKey,
  TActions,
  ExtendedState
>({
  actionsRender: ({ on: { edit } }) =>
    getActionsDropDown({
      actions: [[<T _str="Edit" />, edit]],
    }),
  columnsRenderMap,
  tableColumnsTreeFilterAndOrderProps: {
    key: 'Route.List',
    init: {
      checked: [
        'address',
        'containerId',
        'completedAt',
        'weight',
        'fillLevel',
        'fillLevelPercentage',
        'measurementFillLevel',
        'measurementFillLevelPercentage',
        'ticket',
        'wasteFraction',
        'project',
        'driver',
      ],
      order: [
        'address',
        'containerId',
        'completedAt',
        'weight',
        'fillLevel',
        'fillLevelPercentage',
        'measurementFillLevel',
        'measurementFillLevelPercentage',
        'ticket',
        'wasteFraction',
        'project',
        'driver',
      ],
    },
  },
})

export { useTableUI }
