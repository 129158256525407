import React, { FC, useCallback, useMemo } from 'react'
import {
  Input,
  Form,
  Row,
  Col,
  ColProps,
  Switch,
  FormItemProps,
  Modal,
  Space,
} from 'antd'
import {
  SelectCountry,
  CssScrollerDiv,
} from '@wastehero/storybook/lib/components'
import { T, useT } from '@transifex/react'
import styled from 'styled-components'
import { SingleImageWithAvatar } from '@wastehero/storybook/lib/components/antdform-kit/elements'
import LocationInput from '@wastehero/storybook/lib/components/Form/Elements/Location'
import { useApolloClient, useQuery } from '@apollo/client'
import {
  Footer,
  FormFn,
  FormRequiredProps,
  TCreateAntdFormElementsFormProps,
  useStore,
  WithFormInitializer,
} from '../../antdform-kit-v2'
import { useRoutingHistory } from '../../../reducers/routing/child-router-factory'
import { GET, MUTATION, MUTATION_2 } from './api'
import { LayoutWithDividerv3 } from '../../../layouts'
import { array } from '../../../utils/array'
import SelectTimezone from '../../select/Timezone'
import { SelectCurrency } from '../../select'
import SelectTimeFormat, {
  TSelectTimeFormatValue,
} from '../../../ui/select/TimeFormat'
import SelectUnitLength from '../../../ui/select/UnitLength'
import SelectUnitMass from '../../../ui/select/UnitMass'
import SelectUnitVolume from '../../../ui/select/UnitVolume'
import SelectUnitTemperature from '../../../ui/select/UnitTemperature'
import { jsxFieldRequried } from '../../../utils/yup-validation'
import { useMe } from '../../../hooks'

const Styled = styled.div`
  height: 100%;
  padding: 1rem;
  .ant-form-item {
    margin: 0;
  }
`

export type TFormProjectProps = {
  id?: string
} & FormRequiredProps &
  Pick<TCreateAntdFormElementsFormProps, 'initMode'>

export type TFormProjectSchema = {
  name: string
  settings: {
    location: { name: string; latitude: number; longitude: number }
    country: string
    crmEnabled: boolean
    timezone: string
    currency: string
    clockFormat12: TSelectTimeFormatValue
    drivingDistance: string
    weight: string
    volume: string
    temperature: string
  }
  description: string
  logo: string
}

const REQUIRED_PROPS: FormItemProps = {
  required: true,
  rules: [{ required: true, message: jsxFieldRequried }],
}

const FormProject: FC<TFormProjectProps> & {
  Footer: typeof Footer
} = WithFormInitializer(({ id, formId, initMode }) => {
  const me = useMe()
  // Todo:@saad - do we need company id as prop ?
  const companyId = useMemo(() => me.company.id, [me.company.id])

  const apollo = useApolloClient()
  const createMode = !id
  const t = useT()
  const mode = useStore((store) => store[formId].mode)
  const [history, { routingMap }] = useRoutingHistory()

  const { data, loading } = useQuery(GET, {
    variables: {
      id,
    },
    skip: !id,
  })

  const navigateToDetailView = useCallback(
    (pid: string) => {
      if (createMode) {
        // then it's create mode.
        history.push(routingMap.app.settings['project/:id'](pid)._)
      }
    },
    [createMode, history, routingMap.app.settings]
  )

  const onSubmit = ({
    name,
    description,
    logo,
    settings: {
      clockFormat12,
      country,
      crmEnabled,
      currency,
      drivingDistance,
      temperature,
      timezone,
      volume,
      weight,
      location,
    },
  }: TFormProjectSchema) => {
    const {
      name: dashboardLocationName,
      latitude: dashboardLocationLatitude,
      longitude: dashboardLocationLongitude,
    } = location

    return apollo
      .mutate({
        mutation: MUTATION,
        variables: {
          input: {
            id,
            company: companyId,
            name,
            description,
          },
        },
      })
      .then(({ data: { updateOrCreateProject } }: $TSFixMe) => {
        const projectId = updateOrCreateProject.project.id
        return apollo
          .mutate({
            mutation: MUTATION_2,
            variables: {
              input: {
                id: updateOrCreateProject.project.settings.id,
                clockFormat12: clockFormat12 === '12',
                country,
                crmEnabled,
                currency,
                drivingDistance,
                temperature,
                timezone,
                volume,
                weight,
                dashboardLocationName,
                dashboardLocationLongitude,
                dashboardLocationLatitude,
                logo: data?.project?.logo !== logo ? logo || null : undefined,
                removeLogo: !logo,
              },
            },
          })
          .catch((e) => {
            if (createMode) {
              // Todo:@saad Handle this error in much better way.
              // Right now we are only using error.message but the error can be given in other format too.
              // See the main error handler used by antdform-kit
              Modal.warning({
                title: (
                  <T _str="Project created but the settings are not saved." />
                ),
                content: (
                  <Space direction="vertical">
                    <Space direction="vertical">{e?.message}</Space>
                  </Space>
                ),
              })
              navigateToDetailView(projectId)
            } else {
              throw new Error(e)
            }
          })
      })
      .then(({ data: { updateOrCreateProjectSettings } }: $TSFixMe) =>
        navigateToDetailView(updateOrCreateProjectSettings.project.id)
      )
  }

  const formDisabled = mode === 'view'

  const {
    name,
    settings: {
      dashboardLocation: location,
      country,
      crmEnabled,
      timezone,
      currency,
      clockFormat12,
      drivingDistance,
      weight,
      volume,
      temperature,
    } = {} as TFormProjectSchema['settings'],
    description,
    logo,
  } = data?.project || {}

  const initialValuesForCreate: Partial<TFormProjectSchema> = {
    settings: {
      clockFormat12: `24`,
      currency: `DKK`,
      location: {
        name: ``,
        latitude: 0,
        longitude: 0,
      },
      drivingDistance: 'KM',
      weight: 'KG',
      volume: 'L',
      temperature: '°F',
      timezone: `Europe/Paris`,
      country: `DK`,
      crmEnabled: false,
    },
  }
  const initialValuesForEdit: TFormProjectSchema = {
    name,
    description,
    logo,
    settings: {
      country,
      crmEnabled,
      timezone,
      currency,
      clockFormat12: clockFormat12 ? '12' : '24',
      drivingDistance,
      weight,
      volume,
      temperature,
      location,
    },
  }

  const formItems: Array<
    Array<JSX.Element | [JSX.Element, { colProps?: ColProps }]>
  > = useMemo<
    Array<Array<JSX.Element | [JSX.Element, { colProps?: ColProps }]>>
  >(
    () => [
      [
        <Form.Item
          name="name"
          label={<T _str="Project name" />}
          {...REQUIRED_PROPS}
        >
          <Input disabled={formDisabled} placeholder={t('Project name')} />
        </Form.Item>,
        <Form.Item name={['settings', 'location']} label={<T _str="Address" />}>
          <LocationInput />
        </Form.Item>,
        <Form.Item name="description" label={<T _str="Description" />}>
          <Input.TextArea
            disabled={formDisabled}
            placeholder={t('Description')}
            rows={4}
          />
        </Form.Item>,
      ],
      [
        <Form.Item name="logo" label={<T _str="Logo" />}>
          <SingleImageWithAvatar
            componentProps={{ upload: { disabled: formDisabled } }}
          />
        </Form.Item>,
        ...array(
          <Form.Item
            name={['settings', 'country']}
            label={<T _str="Country" />}
            {...REQUIRED_PROPS}
          >
            <SelectCountry disabled={formDisabled} placeholder={t('Country')} />
          </Form.Item>,
          <Form.Item
            name={['settings', 'crmEnabled']}
            label={<T _str="Crm enabled" />}
            valuePropName="checked"
          >
            <Switch size="small" disabled={formDisabled} />
          </Form.Item>,
          <Form.Item
            name={['settings', 'timezone']}
            label={<T _str="Time zone" />}
            {...REQUIRED_PROPS}
          >
            <SelectTimezone disabled={formDisabled} />
          </Form.Item>,
          <Form.Item
            name={['settings', 'currency']}
            label={<T _str="Currency" />}
            {...REQUIRED_PROPS}
          >
            <SelectCurrency disabled={formDisabled} />
          </Form.Item>,
          <Form.Item
            name={['settings', 'clockFormat12']}
            label={<T _str="Hour format" />}
            {...REQUIRED_PROPS}
          >
            <SelectTimeFormat disabled={formDisabled} />
          </Form.Item>,
          <Form.Item
            name={['settings', 'drivingDistance']}
            label={<T _str="Distance" />}
          >
            <SelectUnitLength disabled={formDisabled} />
          </Form.Item>,
          <Form.Item name={['settings', 'weight']} label={<T _str="Weight" />}>
            <SelectUnitMass disabled={formDisabled} />
          </Form.Item>,
          <Form.Item name={['settings', 'volume']} label={<T _str="Volume" />}>
            <SelectUnitVolume disabled={formDisabled} />
          </Form.Item>,
          <Form.Item
            name={['settings', 'temperature']}
            label={<T _str="Temperature" />}
          >
            <SelectUnitTemperature disabled={formDisabled} />
          </Form.Item>
        ).map((ele): [JSX.Element, { colProps?: ColProps }] => [
          ele,
          { colProps: { xs: 12 } },
        ]),
      ],
    ],
    [formDisabled, t]
  )
  return (
    <Styled>
      <FormFn
        formId={formId}
        requiredMark="optional"
        msgSuccessName={<T _str="Property group" />}
        createMode={createMode}
        loading={loading}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={
          createMode ? initialValuesForCreate : initialValuesForEdit
        }
        className="fill-height"
        initMode={initMode}
      >
        <div className="wh-vertical-scroller-container">
          <div className="wh-vertical-scroller-container-scroll">
            <CssScrollerDiv defaultYScroller offset={16}>
              <LayoutWithDividerv3
                content={formItems.map((fields) => {
                  return (
                    <Row gutter={[12, 16]}>
                      {React.Children.toArray(
                        fields.map((f) => {
                          if (Array.isArray(f)) {
                            const [ele, { colProps }] = f
                            return (
                              <Col xs={24} {...colProps}>
                                {ele}
                              </Col>
                            )
                          }
                          return <Col xs={24}>{f}</Col>
                        })
                      )}
                    </Row>
                  )
                })}
                colsRatio={[50, 50]}
                dividerWidth={2}
                dividerWrapperProps={{ style: { height: '100%' } }}
                wrapperProps={{ style: { height: '100%' } }}
              />
            </CssScrollerDiv>
          </div>
          <div>
            <Footer createMode={false} formId={formId} />
          </div>
        </div>
      </FormFn>
    </Styled>
  )
}) as FC<TFormProjectProps> & {
  Footer: typeof Footer
}

FormProject.Footer = Footer

export { FormProject }
