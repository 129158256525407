import React, { useState, FC, ReactNode } from 'react'
import { useT } from '@transifex/react'
import { Input, InputProps } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'

export type TDebouncedSearchProps = {
  debounceTime?: number
  debounceChange?: InputProps['onChange']
} & InputProps

type THookUseDebouncedSearchSimple = (
  args?: TDebouncedSearchProps & { initialValue?: string }
) => {
  jsx: ReactNode
  search: string
}

type TDebouncedSearchWithHooks = FC<TDebouncedSearchProps> & {
  useDebouncedSearchSimple: THookUseDebouncedSearchSimple
}

const DebouncedSearch: TDebouncedSearchWithHooks = ({
  debounceTime = 500,
  debounceChange = () => {},
  onChange,
  ...props
}) => {
  const t = useT()

  const translationSearch = t('Search')
  const debouncedChange = debounce(debounceChange, debounceTime)

  return (
    <Input
      placeholder={translationSearch}
      prefix={<SearchOutlined />}
      {...props}
      onChange={(e) => {
        e.persist()
        onChange?.(e)
        if (e.target) {
          debouncedChange(e)
        }
      }}
    />
  )
}

const useDebouncedSearchSimple: THookUseDebouncedSearchSimple = ({
  initialValue = ``,
  ...props
} = {}) => {
  const [value, setValue] = useState(initialValue)

  return {
    jsx: (
      <DebouncedSearch
        {...props}
        debounceChange={(evt) => {
          props?.debounceChange?.(evt)
          setValue(evt.target.value)
        }}
      />
    ),
    search: value,
  }
}

DebouncedSearch.useDebouncedSearchSimple = useDebouncedSearchSimple

export { DebouncedSearch }
